<div class="filter-div" *ngIf="filterConfig">
    <div class="filter-component">
        <span class="p-float-label float-label-span">
            <p-dropdown
                class="dropdown-element"
                [options]="filterConfig.filterOptions"
                [(ngModel)]="selected"
                ngDefaultControl
                (onChange)="onChange($event)"
                optionLabel="viewValue"
                [showClear]="true"
                id="float-label"
                data-testid="filter-select">
            </p-dropdown>
            <label for="float-label">{{filterConfig.label}}</label>
        </span>
    </div>
</div>
