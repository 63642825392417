import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ActivityRoutingModule } from './activity-routing.module';
import { ActivityComponent } from './activity.component';
import { CoreModule } from '../core/core.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from '../components/shared.module';
import { ActivityModalComponent } from './activity-modal/activity-modal.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ActivityTableComponent } from './activity-table/activity-table.component';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { InputTextModule } from 'primeng/inputtext';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonModule } from 'primeng/button';

@NgModule({
    declarations: [ActivityComponent, ActivityModalComponent, ActivityTableComponent],
    imports: [
        ActivityRoutingModule,
        CommonModule,
        CoreModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        SharedModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatSortModule,
        MatTableModule,
        MatPaginatorModule,
        MatCardModule,
        MatInputModule,
        InputTextModule,
        MatIconModule,
        MatCheckboxModule,
        MatDividerModule,
        MatOptionModule,
        MatSelectModule,
        MatSnackBarModule,
        MatTooltipModule,
        ButtonModule,
    ],
})
export class ActivityModule {}
