import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap, first } from 'rxjs/operators';

import { ConfigurationService } from '../services/configuration.service';
import { IEnvConfiguration } from '../configuration.model';
import { AuthService } from '../services/auth.service';

@Injectable()
export class ConfigurationInterceptor implements HttpInterceptor {
    constructor(
        private configurationService: ConfigurationService,
        private authService: AuthService,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.configurationService.envConfig.pipe(
            first(),
            mergeMap((config: IEnvConfiguration) => {
                const idToken = this.authService.identityToken;
                const accessToken = this.authService.accessToken;

                // This handles full url requests, so it won't use the default host (shield)
                // These routes use the identity_token, so that istio can read the roles of the authenticated user
                if (
                    req.url.includes(window.location.origin) ||
                    req.url.includes(config.mockHost) ||
                    (req.url.split(':').length > 0 &&
                        (req.url.split(':')[0] === 'http' || req.url.split(':')[0] === 'https'))
                ) {
                    // If the request is reaching out to the identity provider, we don't mess with it.
                    // ms-bank-validation requires an access token, so the logic must drop into the "else" block.
                    if (
                        !req.url.includes('https://srsacquiom.onelogin.com/oidc/2') &&
                        !req.url.includes(this.configurationService.envConfig.getValue().bankValidationHost)
                    ) {
                        const reqClone = req.clone({
                            headers: req.headers.set('Authorization', `Bearer ${idToken}`),
                        });
                        return next.handle(reqClone);
                    } else if (req.url.includes(this.configurationService.envConfig.getValue().bankValidationHost)) {
                        const reqClone = req.clone({
                            headers: req.headers.set('Authorization', `Bearer ${accessToken}`),
                        });
                        return next.handle(reqClone);
                    }
                    return next.handle(req);
                }

                // If we are relying on the default host (shield), then we use the access token, because shield uses
                // the OneLogin introspection endpoint and fetches the id token on its own.
                const url = `${config.host}${req.url}`;
                const finalReq = req.clone({
                    url,
                    headers: req.headers.set('Authorization', `Bearer ${accessToken}`),
                });

                return next.handle(finalReq);
            }),
        );
    }
}
