import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { AuthGuard } from './core/guards/auth-guard.service';
import { CallbackComponent } from './components/callback/callback.component';
import { AllowedRoles } from './core/guards/allowed-roles.guard';
import { OneLoginRoles } from './core/services/auth.service';

const routes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    {
        path: 'login',
        children: [
            { path: '', component: LoginComponent },
            { path: 'callback', component: CallbackComponent },
        ],
    },
    { path: '**', redirectTo: '' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
