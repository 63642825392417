import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CachedBankCodesRoutingModule } from './cached-bank-codes-routing.module';
import { CachedBankCodesComponent } from './cached-bank-codes.component';
import { TableComponent } from './table/table.component';
import { CoreModule } from '../core/core.module';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { SliderPanelComponent } from './slider-panel/slider-panel.component';
import { ModalComponent } from './modal/modal.component';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedModule } from '../components/shared.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    declarations: [CachedBankCodesComponent, TableComponent, SliderPanelComponent, ModalComponent],
    imports: [
        CommonModule,
        CachedBankCodesRoutingModule,
        CoreModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        MatSortModule,
        MatTableModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatTooltipModule,
        MatDialogModule,
        SharedModule,
        MatProgressSpinnerModule,
    ],
})
export class CachedBankCodesModule {}
