import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
    AdminRequestHelper,
    CreateEditUserTypes,
    DDUserAccess,
    DealAccessFormType,
    DealDashboardAccessRecord,
    DealPartialRecord,
    DealRecord,
    DealSearchColumns,
    GetType,
    UserAccessService,
    ModalCloseConfig,
    ModalConfigData,
    RoleRecord,
    ShareholderAccessFormType,
    ShareholderPartialRecord,
    ShareholderPortalAccessRecord,
    ShareholderRecord,
    ShareholderSearchColumns,
    ShareHolderUserAccess,
    UserAccessAction_DD,
    UserAccessAction_SHARP,
    UserActionEnum,
    UserDeal,
    UserFullUi,
    UserPartialDDAccessRecord,
    UserPartialSHARPAccessRecord,
    UserRecord,
} from '../shared';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { UserAccessSnackbarComponent } from '../user-access-snackbar/user-access-snackbar.component';
import { UserAccessModalComponent } from '../user-access-modal/user-access-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../core/services/user.service';
import { debounceTime, distinctUntilChanged, from, Observable, of, startWith, switchMap, takeUntil } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { HttpErrorResponse } from '@angular/common/http';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSelectChange } from '@angular/material/select';

@Component({
    selector: 'app-create-edit-user',
    templateUrl: './create-edit-user.component.html',
    styleUrls: ['./create-edit-user.component.scss'],
})
export class CreateEditUserComponent implements OnInit {
    @Input() mode: CreateEditUserTypes = CreateEditUserTypes.CREATE_USER;
    @Input() userToEditId: string; // From the Users table.

    @Output() showCreateEditUserCloseEvent = new EventEmitter<void>();

    @ViewChild('dealDashboardDealInput') dealDashboardDealInput: ElementRef<HTMLInputElement>;

    public internalAdminModal: MatDialogRef<UserAccessModalComponent>;

    public filteredDealDashboardDeals: Observable<DealPartialRecord[]>;
    public filteredShareholderRecords: Observable<ShareholderPartialRecord[]>;
    public filteredShareholderPortalDeals: Observable<DealPartialRecord[]>;
    public userToEdit: UserFullUi;

    // Selecting existing users
    public selectedExistingUser: UserRecord;
    public addExistingUserRecords: UserRecord[];
    private selectedUserId: string;
    // This object is used to submit to the API for processing.
    private userToSubmit: UserDeal;

    // Deal Dashboard Access fields
    // Represents one row in the UI, including is disabled, and the action (add, update, delete, no change).
    public ddUserAccess: DDUserAccess[] = [];

    public ddActions: UserAccessAction_DD[] = [];
    public initialFetchOfDealDashboardDeals: DealPartialRecord[] = [];
    public dealRecords: DealRecord[];

    // SHARP access fields
    // Represents one row in the UI, including is disabled, and the action (add, update, delete, no change).
    public shareHolderUserAccess: ShareHolderUserAccess[] = [];

    public initialFetchOfShareholderPortalDeals: DealPartialRecord[] = [];
    public shareholderRecords: ShareholderRecord[];
    public initialFetchOfShareholderRecords: ShareholderPartialRecord[] = [];

    public separatorKeysCodes: number[] = [ENTER, COMMA];

    public dealDashboardRoleRecords: RoleRecord[];
    // roles services in ms-user-access
    public shareholderRoleRecords: RoleRecord[] = [
        {
            id: 1,
            name: 'Administrator',
            description: '',
        },
        {
            id: 2,
            name: 'Inviter',
            description: '',
        },
        {
            id: 3,
            name: 'Owner',
            description: '',
        },
        {
            id: 4,
            name: 'Viewer',
            description: '',
        },
    ];
    public dealRoleRecordRevokeAccess: RoleRecord = {
        id: 99,
        name: 'Revoke Access',
        description: 'Revoke access role record',
    };
    public shareholderRoleRecordRevokeAccess: RoleRecord = {
        id: 99,
        name: 'Remove Access',
        description: '',
    };

    // Add Existing User's first user record is an option for creating a new user
    // instead of selecting an existing user.
    public CreateNewUserRecord: UserRecord = {
        id: '9876543210',
        firstName: 'Create',
        lastName: 'New User',
        email: 'Set up a new user with access',
        auth0Id: '0',
    };

    public userFormTitle: string;
    public userForm = new FormGroup({
        existingUser: new FormControl<number>(null),
        firstName: new FormControl<String>(null, [Validators.required]),
        lastName: new FormControl<String>(null, [Validators.required]),
        email: new FormControl<String>(null, [Validators.required, Validators.email]),
        dealAccess: new FormArray<DealAccessFormType>([]),
        shareholderAccess: new FormArray<ShareholderAccessFormType>([]),
    });
    public addExistingUserSelected: boolean = false;
    public FormControlActionAdd = 'a'; // Checked in HTML to control display of Revoke Access.
    public fetchingUsers = false;
    public fetchingDealsUsers = false;
    public fetchingShareholders = false;
    public fetchingShareholderDeals = false;

    get dealAccess() {
        return this.userForm.controls['dealAccess'] as FormArray;
    }

    get shareholderAccess() {
        return this.userForm.controls['shareholderAccess'] as FormArray;
    }

    private requestHelper: AdminRequestHelper;
    private requestHelperDeals: AdminRequestHelper;
    private requestHelperShareholderUserAccess: AdminRequestHelper;
    private requestHelperDealUserAccess: AdminRequestHelper;

    // these roles will need to be entered into the database, or
    // if we are retrieving them from another source we should return them in the
    private requestHelperRoles: AdminRequestHelper;

    // this create user record object allows the create new user
    // option to be available in the add existing user dropdown,
    private modalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: true,
        minWidth: '500px',
        minHeight: '200px',
        autoFocus: 'first-header',
    };

    constructor(
        private userAccessService: UserAccessService,
        private snackBar: MatSnackBar,
        private userService: UserService,
        private matDialog: MatDialog,
    ) {}

    ngOnInit(): void {
        this.requestHelper = new AdminRequestHelper(GetType.users);
        this.requestHelperDeals = new AdminRequestHelper(GetType.deals);
        this.requestHelperShareholderUserAccess = new AdminRequestHelper(GetType.shareholders);
        this.requestHelperRoles = new AdminRequestHelper(GetType.roles);
        this.requestHelperDealUserAccess = new AdminRequestHelper(GetType.dealUserAccess);

        this.fetchRoles();

        this.fetchingDealsUsers = true;
        this.requestHelperDeals.sortField = 'nsId';
        this.requestHelperDeals.sortDir = 'desc';
        this.fetchDeals();

        this.fetchingShareholders = true;
        this.requestHelperShareholderUserAccess.sid = null;
        this.requestHelperShareholderUserAccess.sortField = ShareholderSearchColumns[0];
        this.requestHelperShareholderUserAccess.sortDir = 'asc';
        this.fetchShareholders();

        if (this.mode === CreateEditUserTypes.EDIT_USER) {
            this.initForEditUser();
        } else if (this.mode === CreateEditUserTypes.ADD_EXISTING_USER) {
            this.userFormTitle = 'Add Existing User';

            // Fetch a page of users just for something to show and force the
            // user to search.
            this.requestHelper.sortField = 'lastName';
            this.requestHelper.sortDir = 'asc';
            this.fetchUsers();
        } else {
            this.initForCreateUser();
        }
    }

    public async onAddExistingUserChange(selectionChange: MatSelectChange) {
        const uid = selectionChange.value;

        // Did the user select the option to create a new user? If so, show the
        // create user form.
        if (uid === this.CreateNewUserRecord.id) {
            this.mode = CreateEditUserTypes.CREATE_USER;
            this.userToEdit = null;
            this.userToEditId = null;

            this.initForCreateUser();
            return;
        }

        this.selectedExistingUser = this.addExistingUserRecords.find((user) => user.id === uid);
        this.addExistingUserSelected = true;
        this.selectedUserId = uid;

        // Fetch the full user record with access records.
        this.fetchSingleUser(uid, true)
            .then((userFullUi: UserFullUi) => {
                if (userFullUi != null) {
                    this.userToEdit = userFullUi;
                    this.initializeUiForm(userFullUi);
                }
            })
            .catch((error) => {
                this.handleError(error);
            });

        // Fetch all deals assigned to the selected user.
        this.requestHelperDealUserAccess.uid = this.selectedUserId;
        this.fetchingDealsUsers = true;

        this.fetchDealUserAccessRecords()
            .then((deals: DealPartialRecord[]) => {})
            .catch((error) => {
                this.handleError(error);
            });

        // Fetch All Shareholder User Access records for the selected user.
        this.fetchShareholderUserAccessRecords(0, uid);
    }

    public addDealAccessClick(index?: number, deal?: DealPartialRecord, role?: RoleRecord) {
        const ddUserAccess: DDUserAccess = {
            action:
                (this.mode === CreateEditUserTypes.EDIT_USER || this.mode === CreateEditUserTypes.ADD_EXISTING_USER) &&
                index !== undefined
                    ? UserAccessAction_DD.NoChange
                    : UserAccessAction_DD.Add,
            inputDisabled: false,
            deals: [],
            role,
        };
        this.ddUserAccess.push(ddUserAccess);

        const dealAccessForm = new FormGroup({
            action: new FormControl<string>(this.FormControlActionAdd),
            deal: new FormControl<string>(null, [Validators.required]),
            role: new FormControl<number>(null, [Validators.required]),
        });

        // User entered search criteria, filter for it now.
        this.filteredDealDashboardDeals = dealAccessForm.controls.deal.valueChanges.pipe(
            startWith(null),
            distinctUntilChanged(), // Execute only if a different value is emitted.
            debounceTime(500), // Fire the callback method only if not fired in a specific interval.
            switchMap((deal: string | DealPartialRecord | null): Observable<DealPartialRecord[]> => {
                if (deal) {
                    return from(this.filterDealDashboardDeals(deal));
                } else {
                    return of(this.initialFetchOfDealDashboardDeals.slice());
                }
            }),
            takeUntil(this.showCreateEditUserCloseEvent),
        );

        this.dealAccess.push(dealAccessForm);

        if (
            (this.mode === CreateEditUserTypes.EDIT_USER || this.mode === CreateEditUserTypes.ADD_EXISTING_USER) &&
            index !== undefined
        ) {
            ddUserAccess.inputDisabled = true;
            ddUserAccess.deals.push(deal);
            ddUserAccess.role = role;

            this.dealAccess.controls[index].get('deal').setValue(deal);
            this.dealAccess.controls[index].get('deal').disable();

            // this.dealAccess.controls[index].get('role').setValue(role);
            this.dealAccess.controls[index].get('role').setValue(role.id);

            this.dealAccess.controls[index].get('deal').setErrors({});
            this.dealAccess.controls[index].get('deal').clearValidators();
            this.dealAccess.controls[index].get('deal').updateValueAndValidity();
        }
    }

    public addShareholderAccessClick(
        index?: number,
        shareholder?: ShareholderPartialRecord,
        deal?: DealPartialRecord,
        role?: RoleRecord,
    ) {
        const shareHolderUserAccess: ShareHolderUserAccess = {
            action:
                (this.mode === CreateEditUserTypes.EDIT_USER || this.mode === CreateEditUserTypes.ADD_EXISTING_USER) &&
                index !== undefined
                    ? UserAccessAction_SHARP.NoChange
                    : UserAccessAction_SHARP.Add,
            inputDisabled: false,
            shareholder,
            deal,
            role,
        };
        this.shareHolderUserAccess.push(shareHolderUserAccess);

        const shareholderAccessForm = new FormGroup({
            action: new FormControl<string>(this.FormControlActionAdd),
            shareholder: new FormControl<string>(null, [Validators.required]),
            deal: new FormControl<string>(null, [Validators.required]),
            role: new FormControl<number>(null, [Validators.required]),
        });

        // User entered Shareholder search criteria, filter for it now.
        this.filteredShareholderRecords = shareholderAccessForm.controls.shareholder.valueChanges.pipe(
            startWith(null),
            debounceTime(500), // Fire the callback method only if not fired in a specific interval.
            distinctUntilChanged(), // Execute only if a different value is emitted.
            switchMap(
                (shareholder: string | ShareholderPartialRecord | null): Observable<ShareholderPartialRecord[]> => {
                    if (shareholder) {
                        return from(this.filterShareholderRecords(shareholder));
                    } else {
                        return of(this.initialFetchOfShareholderRecords.slice());
                    }
                },
            ),
            takeUntil(this.showCreateEditUserCloseEvent),
        );

        // User entered Deal search criteria, filter for it now.
        this.filteredShareholderPortalDeals = shareholderAccessForm.controls.deal.valueChanges.pipe(
            startWith(null),
            debounceTime(500), // Fire the callback method only if not fired in a specific interval.
            distinctUntilChanged(), // Execute only if a different value is emitted.
            switchMap(
                (deal: string | DealPartialRecord | null): Observable<DealPartialRecord[]> =>
                    deal
                        ? from(this.filterShareholderPortalDeals(deal))
                        : of(this.initialFetchOfShareholderPortalDeals.slice()),
            ),
            takeUntil(this.showCreateEditUserCloseEvent),
        );

        this.shareholderAccess.push(shareholderAccessForm);

        if (
            (this.mode === CreateEditUserTypes.EDIT_USER || this.mode === CreateEditUserTypes.ADD_EXISTING_USER) &&
            index !== undefined
        ) {
            shareHolderUserAccess.inputDisabled = true;
            shareHolderUserAccess.shareholder = shareholder;
            shareHolderUserAccess.deal = deal;
            shareHolderUserAccess.role = role;

            this.shareholderAccess.controls[index].get('shareholder').setValue(shareholder.shareholder);
            this.shareholderAccess.controls[index].get('shareholder').disable();

            this.shareholderAccess.controls[index].get('deal').setValue(deal.name);
            this.shareholderAccess.controls[index].get('deal').disable();

            this.shareholderAccess.controls[index].get('role').setValue(role.id);

            this.shareholderAccess.controls[index].get('deal').setErrors({});
            this.shareholderAccess.controls[index].get('deal').clearValidators();
            this.shareholderAccess.controls[index].get('deal').updateValueAndValidity();
        }
    }

    /**
     * Remove a DD Access row from the UI
     * @param index The row number to delete
     */
    public deleteDealAccessRow(index: number) {
        this.ddUserAccess.splice(index, 1);
        this.dealAccess.removeAt(index);
    }

    public dealSelected(event: MatAutocompleteSelectedEvent, index: number): void {
        const dealToAdd: DealPartialRecord = event.option.value;

        // Make sure the deal is not already in the list.
        const dealName = this.ddUserAccess[index].deals.find((deal) => deal.name === dealToAdd.name);
        if (!dealName) {
            this.ddUserAccess[index].deals.push(dealToAdd);
        }

        this.dealDashboardDealInput.nativeElement.value = '';
        // Set the form value to null so when the user clicks in there again it will pop up the page-worth of deals.
        this.dealAccess.controls[index].get('deal').setValue(null);

        this.dealAccess.controls[index].get('deal').setErrors({});
        this.dealAccess.controls[index].get('deal').clearValidators();
        this.dealAccess.controls[index].get('deal').updateValueAndValidity();
    }

    public shareholderSelected(event: MatAutocompleteSelectedEvent, index: number): void {
        this.shareHolderUserAccess[index].shareholder = event.option.value;

        // Set the form value to null so when the user clicks in there again it will pop up the page-worth of deals.
        // this.shareholderAccess.controls[index].get('shareholder').setValue(event.option.value);
        this.shareholderAccess.controls[index].get('shareholder').setValue(event.option.value.shareholder);

        this.shareholderAccess.controls[index].get('shareholder').setErrors({});
        this.shareholderAccess.controls[index].get('shareholder').clearValidators();
        this.shareholderAccess.controls[index].get('shareholder').updateValueAndValidity();
    }

    public shareholderDealSelected(event: MatAutocompleteSelectedEvent, index: number): void {
        this.shareHolderUserAccess[index].deal = event.option.value;

        // Set the form value to null so when the user clicks in there again it will pop up the page-worth of deals.
        this.shareholderAccess.controls[index].get('deal').setValue(event.option.value.name);

        this.shareholderAccess.controls[index].get('deal').setErrors({});
        this.shareholderAccess.controls[index].get('deal').clearValidators();
        this.shareholderAccess.controls[index].get('deal').updateValueAndValidity();
    }

    public onRoleChange(selectionChange, section: string, index: number) {
        let isRevoked = false;
        if (section === 'shareholderAccess') {
            let role = this.shareholderRoleRecords.find((role) => role.id === selectionChange.value);
            if (!role && selectionChange.value === this.shareholderRoleRecordRevokeAccess.id) {
                role = this.shareholderRoleRecordRevokeAccess;
                isRevoked = true;
            }

            this.shareholderAccess.controls[index].get('role').setValue(selectionChange.value);
            this.shareHolderUserAccess[index].role = role;

            // If in edit mode and the action is not already set to Add (indicating a
            // new access record), then this is an update.
            if (isRevoked) {
                this.shareHolderUserAccess[index].action = UserAccessAction_SHARP.Delete;
            } else if (
                (this.mode === CreateEditUserTypes.EDIT_USER || this.mode === CreateEditUserTypes.ADD_EXISTING_USER) &&
                this.shareHolderUserAccess[index].action != UserAccessAction_SHARP.Add
            ) {
                this.shareHolderUserAccess[index].action = UserAccessAction_SHARP.Update;
                this.shareholderAccess.controls[index].get('action').setValue(UserAccessAction_SHARP.Update);
            } else if (this.mode !== CreateEditUserTypes.EDIT_USER) {
                this.shareHolderUserAccess[index].action = UserAccessAction_SHARP.Add;
                this.shareholderAccess.controls[index].get('action').setValue(UserAccessAction_SHARP.Add);
            }
        } else {
            let role = this.dealDashboardRoleRecords.find((role) => role.id === selectionChange.value);

            if (!role && selectionChange.value === this.dealRoleRecordRevokeAccess.id) {
                role = this.dealRoleRecordRevokeAccess;
                isRevoked = true;
            }

            this.dealAccess.controls[index].get('role').setValue(selectionChange.value);
            this.ddUserAccess[index].role = role;

            // If in edit mode and the action is not already set to Add (indicating a
            // new access record), then this is an update.
            if (isRevoked) {
                this.ddUserAccess[index].action = UserAccessAction_DD.Delete;
            } else if (
                (this.mode === CreateEditUserTypes.EDIT_USER || this.mode === CreateEditUserTypes.ADD_EXISTING_USER) &&
                this.ddUserAccess[index].action != UserAccessAction_DD.Add
            ) {
                this.ddUserAccess[index].action = UserAccessAction_DD.Update;
                this.dealAccess.controls[index].get('action').setValue(UserAccessAction_DD.Update);
            } else if (this.mode !== CreateEditUserTypes.EDIT_USER) {
                this.ddUserAccess[index].action = UserAccessAction_DD.Add;
                this.dealAccess.controls[index].get('action').setValue(UserAccessAction_DD.Add);
            }
        }
    }

    /**
     * Remove a SHARP Access row from the UI
     * @param index The row number to delete
     */
    public deleteShareholderAccessRow(index: number) {
        this.shareholderAccess.removeAt(index);
        this.shareHolderUserAccess.splice(index, 1);
    }

    public addDealChip(event: MatChipInputEvent) {
        event.chipInput!.clear();
    }

    public removeDealChip(deal: DealPartialRecord, i: number): void {
        const index = this.ddUserAccess[i].deals.findIndex((currentDeal) => (currentDeal.name = deal.name));

        if (index >= 0) {
            this.ddUserAccess[i].deals.splice(index, 1);
        }

        this.dealDashboardDealInput.nativeElement.value = '';

        this.dealAccess.value[i].action = null;
        this.dealAccess.value[i].deal = null;
        this.dealAccess.value[i].role = null;
    }

    public cancelClicked() {
        this.userForm.reset();

        this.mode = CreateEditUserTypes.CREATE_USER;

        this.dealAccess.clear();
        this.ddUserAccess = [];

        this.shareholderAccess.clear();
        this.shareHolderUserAccess = [];

        this.requestHelper.uid = null;
        this.requestHelperDeals.uid = null;

        this.showCreateEditUserCloseEvent.emit();
    }

    public identifyDeal(index, deal) {
        return deal;
    }

    public identifyShareholder(index, shareholder) {
        return shareholder;
    }

    public onSubmit(f: FormGroup) {
        let dealDashboardAccess: DealDashboardAccessRecord[] = [];
        let shareholderPortalAccess: ShareholderPortalAccessRecord[] = [];

        if (!f.valid) {
            f.markAllAsTouched();
            return;
        }

        // Construct a new deal dashboard access array from the data added or updated by the user.
        this.ddUserAccess?.forEach((ddAccess) => {
            const foundDealIds: number[] = [];

            ddAccess.deals?.forEach((deal) => {
                foundDealIds.push(deal.id);
            });

            dealDashboardAccess.push({
                action: ddAccess.action,
                dealIds: foundDealIds,
                roleId: ddAccess.role.id,
            });
        });

        // Look for DD access records that were deleted, and add them as 'deletes'.
        if (this.mode === CreateEditUserTypes.EDIT_USER || this.mode === CreateEditUserTypes.ADD_EXISTING_USER) {
            this.userToEdit.dealDashboardAccess.forEach((ddAccess: UserPartialDDAccessRecord) => {
                let found = false;
                dealDashboardAccess.some((currentAccess) => {
                    if (currentAccess.dealIds.includes(ddAccess.deal.id)) {
                        found = true;
                    }
                });

                if (!found) {
                    dealDashboardAccess.push({
                        action: UserAccessAction_DD.Delete,
                        dealIds: [ddAccess.deal.id],
                        roleId: ddAccess.role.id,
                    });
                }
            });
        }

        // Construct a new shareholder portal access array from the data added or updated by the user.
        this.shareHolderUserAccess?.forEach((sharpAccess) => {
            shareholderPortalAccess.push({
                action: sharpAccess.action,
                shareholderId: sharpAccess.shareholder.nsId,
                dealId: sharpAccess.deal.id,
                roleId: sharpAccess.role.id,
            });
        });

        // Look for SHARP access records that were deleted, and add them as 'deletes'.
        if (this.mode === CreateEditUserTypes.EDIT_USER || this.mode === CreateEditUserTypes.ADD_EXISTING_USER) {
            this.userToEdit.shareholderPortalAccess.forEach((shAccess: UserPartialSHARPAccessRecord) => {
                let found = false;
                shareholderPortalAccess.some((currentAccess) => {
                    if (currentAccess.shareholderId === shAccess.shareholder.nsId) {
                        found = true;
                    }
                });

                if (!found) {
                    shareholderPortalAccess.push({
                        action: UserAccessAction_SHARP.Delete,
                        shareholderId: shAccess.shareholder.nsId,
                        dealId: shAccess.deal.id,
                        roleId: shAccess.role.id,
                    });
                }
            });
        }

        this.userToSubmit = {
            user: {
                id: null,
                firstName: f.value.firstName,
                lastName: f.value.lastName,
                email: f.value.email,
                auth0Id: null,
            },
            dealDashboardAccess,
            shareholderPortalAccess,
            byId: this.userService.user.sub,
            byName: this.userService.user.name,
        };

        // Set the user non-form-related properties on the new user object about to be submitted.
        if (this.mode === CreateEditUserTypes.EDIT_USER || this.mode === CreateEditUserTypes.ADD_EXISTING_USER) {
            this.userToSubmit.user.id = this.userToEdit.userUi.id;
            this.userToSubmit.user.auth0Id = this.userToEdit.userUi.auth0Id;
        }

        // The pop-up modal to confirm revoking access only applies when editing a user.
        if (this.mode === CreateEditUserTypes.EDIT_USER) {
            // Check for either a revoked access role id, or the action has
            // been changed to Delete.
            const revokeAccessDDFound: boolean = dealDashboardAccess.some(
                (dealAccessRow) =>
                    dealAccessRow.roleId === this.dealRoleRecordRevokeAccess.id ||
                    dealAccessRow.action === UserAccessAction_DD.Delete,
            );

            let revokeAccessSHARPFound = false;
            if (!revokeAccessDDFound) {
                // Check for either a revoked access role id, or the action has
                // been changed to Delete.
                revokeAccessSHARPFound = shareholderPortalAccess.some(
                    (shAccessRow) =>
                        shAccessRow.roleId === this.shareholderRoleRecordRevokeAccess.id ||
                        shAccessRow.action === UserAccessAction_SHARP.Delete,
                );
            }

            if (revokeAccessDDFound || revokeAccessSHARPFound) {
                const modalConfig: MatDialogConfig<ModalConfigData> = {
                    ...this.modalConfigTemplate,
                    data: {
                        title: 'Confirm Revoke Access',
                        action: UserActionEnum.revokeAccessDD,
                        messageHTML: `This will remove the user's assigned role to the selected deal(s)
                                      as well as save any other changes on the user.`,
                    },
                };
                this.openModal(modalConfig);
            } else {
                this.editUser(this.userToSubmit);
            }
        } else {
            this.createUser(this.userToSubmit);
        }
    }

    private initForCreateUser() {
        this.userFormTitle = 'Create User';

        this.userToEdit = {
            userUi: {
                id: null,
                firstName: '',
                lastName: '',
                email: '',
                auth0Id: '',
            },
            dealDashboardAccess: [],
            shareholderPortalAccess: [],
        };
    }

    private async initForEditUser() {
        this.userFormTitle = 'Edit User';

        // Fetch the user to edit.
        this.fetchSingleUser(this.userToEditId, true)
            .then((userFullUi: UserFullUi) => {
                if (userFullUi != null) {
                    this.userToEdit = userFullUi;
                    this.initializeUiForm(userFullUi);
                }
            })
            .catch((error) => {
                this.handleError(error);
            });
    }

    private initializeUiForm(userFullUi: UserFullUi) {
        this.userForm.controls.firstName.setValue(userFullUi.userUi.firstName);
        this.userForm.controls.lastName.setValue(userFullUi.userUi.lastName);
        this.userForm.controls.email.setValue(userFullUi.userUi.email);

        // Set up deal access records
        userFullUi.dealDashboardAccess?.forEach((access, idx) => {
            this.addDealAccessClick(idx, access.deal, access.role);
        });

        // Set up shareholder access records
        userFullUi.shareholderPortalAccess?.forEach((access, idx) => {
            this.addShareholderAccessClick(idx, access.shareholder, access.deal, access.role);
        });
    }

    private filterDealDashboardDeals(value: string | DealPartialRecord): Promise<DealPartialRecord[]> {
        let filterValue: string;
        if (typeof value === 'string') {
            filterValue = value.toLowerCase();
        } else {
            filterValue = value.name.toLowerCase();
        }

        this.fetchingDealsUsers = true;

        this.requestHelperDeals.page = 0;
        this.requestHelperDeals.size = 100;
        this.requestHelperDeals.sortField = DealSearchColumns[0];
        this.requestHelperDeals.sortDir = 'asc';
        this.requestHelperDeals.srchVal = filterValue;
        // limit search to name field which is all that the user sees.
        this.requestHelperDeals.srchFields = DealSearchColumns.slice(0, 1);

        return this.fetchDeals(true);
    }

    private filterShareholderRecords(value: string | ShareholderPartialRecord): Promise<ShareholderPartialRecord[]> {
        let filterValue: string;
        if (typeof value === 'string') {
            filterValue = value.toLowerCase();
        } else {
            filterValue = value.shareholder.toLowerCase();
        }

        this.requestHelperShareholderUserAccess.page = 0;
        this.requestHelperShareholderUserAccess.size = 100;
        this.requestHelperShareholderUserAccess.sortField = ShareholderSearchColumns[0];
        this.requestHelperShareholderUserAccess.sortDir = 'asc';
        this.requestHelperShareholderUserAccess.srchVal = filterValue;
        // limit search to name field which is all that the user sees.
        this.requestHelperShareholderUserAccess.srchFields = ShareholderSearchColumns.slice(0, 1);

        return this.fetchShareholders(true);
    }

    private filterShareholderPortalDeals(value: string | DealPartialRecord): Promise<DealPartialRecord[]> {
        let filterValue: string;
        if (typeof value === 'string') {
            filterValue = value.toLowerCase();
        } else {
            filterValue = value.name.toLowerCase();
        }

        this.fetchingShareholderDeals = true;

        this.requestHelperDeals.page = 0;
        this.requestHelperDeals.size = 100;
        this.requestHelperDeals.sortField = DealSearchColumns[0];
        this.requestHelperDeals.sortDir = 'asc';
        this.requestHelperDeals.srchVal = filterValue;
        // limit search to name field which is all that the user sees.
        this.requestHelperDeals.srchFields = DealSearchColumns.slice(0, 1);

        return this.fetchDeals(true);
    }

    private fetchShareholders(returnUserObj: boolean = false): Promise<ShareholderPartialRecord[]> {
        this.fetchingShareholders = true;

        return new Promise((resolve) => {
            this.userAccessService.getShareholders(this.requestHelperShareholderUserAccess).subscribe({
                next: (res: any) => {
                    this.fetchingShareholders = false;
                    const shareholderPartialRecords = res.body.map((shareholder) => {
                        return {
                            shareholder: shareholder.shareholder,
                            nsId: shareholder.nsId,
                        };
                    });

                    if (returnUserObj) {
                        resolve(shareholderPartialRecords);
                    } else {
                        this.initialFetchOfShareholderRecords = shareholderPartialRecords;
                        resolve([]);
                    }
                    this.shareholderRecords = res.body;
                },
                error: (error) => this.handleError(error),
            });
        });
    }

    /**
     * The "single shareholder" comes from the original list of shareholders,
     * so this search is to retrieve the User Access records for the user.
     * @param sid
     * @param uid
     * @private
     */
    private fetchShareholderUserAccessRecords(sid?: number, uid?: string) {
        this.fetchingDealsUsers = true;

        if (sid) {
            // Fetch shareholders user access records for the selected shareholder.
            this.requestHelperShareholderUserAccess.sid = sid;
        }

        if (uid) {
            // Fetch shareholders user access records for the selected user.
            this.requestHelperShareholderUserAccess.uid = uid;
        }

        this.userAccessService.getShareholdersUserAccessRecords(this.requestHelperShareholderUserAccess).subscribe({
            next: (res: any) => {
                this.fetchingShareholderDeals = false;
                this.shareHolderUserAccess = res.body;

                // if (this.mode === CreateEditUserTypes.EDIT_USER || this.mode === CreateEditUserTypes.ADD_EXISTING_USER) {
                //     this.shareHolderUserAccess = [res.body[0]];
                // }
            },
            error: (error) => this.handleError(error),
        });
    }

    /**
     * Fetch deals.
     * @private
     */
    private fetchDeals(returnUserObj: boolean = false): Promise<DealPartialRecord[]> {
        return new Promise((resolve) => {
            this.userAccessService.getDeals(this.requestHelperDeals).subscribe({
                next: (res: any) => {
                    this.fetchingDealsUsers = false;
                    this.fetchingShareholderDeals = false;

                    const dealPartialRecords = res.body.map((deal) => {
                        return {
                            id: deal.id,
                            name: deal.name,
                        };
                    });

                    if (returnUserObj) {
                        resolve(dealPartialRecords);
                    } else {
                        this.initialFetchOfDealDashboardDeals = dealPartialRecords;
                        this.dealRecords = res.body;
                        this.initialFetchOfShareholderPortalDeals = this.initialFetchOfDealDashboardDeals;
                        resolve([]);
                    }
                },
                error: (error) => this.handleError(error),
            });
        });
    }

    /**
     * Fetch Deal User Access records
     * @private
     */
    private fetchDealUserAccessRecords(): Promise<DealPartialRecord[]> {
        return new Promise((resolve) => {
            this.userAccessService.getDealUserAccessRecords(this.requestHelperDealUserAccess).subscribe({
                next: (res: any) => {
                    this.fetchingDealsUsers = false;
                    this.fetchingShareholderDeals = false;

                    const dealPartialRecords = res.body.map((deal) => {
                        return {
                            id: deal.id,
                            name: deal.name,
                        };
                    });

                    resolve(dealPartialRecords);
                },
                error: (error) => this.handleError(error),
            });
        });
    }

    private fetchRoles() {
        this.userAccessService.getRoles(this.requestHelperRoles).subscribe({
            next: (res: any) => {
                this.dealDashboardRoleRecords = res.body;
            },
            error: (error) => this.handleError(error),
        });
    }

    private fetchUsers() {
        this.fetchingUsers = true;
        this.userAccessService.getUsers(this.requestHelper).subscribe({
            next: (res: any) => {
                this.fetchingUsers = false;
                if (this.mode === CreateEditUserTypes.EDIT_USER) {
                    this.userToEdit = res.body;
                } else if (this.mode === CreateEditUserTypes.ADD_EXISTING_USER) {
                    this.addExistingUserRecords = res.body;
                }
            },
            error: (error) => this.handleError(error),
        });
    }

    private fetchSingleUser(uid: string, returnUserObj: boolean = false): Promise<UserFullUi | void> {
        this.fetchingUsers = true;
        this.selectedUserId = uid;

        // Retrieve all values for a single user record, which has joins to other
        // tables not required by the users table. Since pagination, sorting, and
        // searching are not required, a new request helper is created to leave the
        // original untouched.
        const tempRequestHelper = new AdminRequestHelper(GetType.users);
        tempRequestHelper.uid = uid;

        return new Promise((resolve) => {
            this.userAccessService.getUsers(tempRequestHelper).subscribe({
                next: (res: any) => {
                    this.fetchingUsers = false;
                    // handling this in the front end for now, will need to revisit if we'd like
                    // to handle this in the service instead and make changes there
                    if (returnUserObj) {
                        resolve(res.body);
                    } else {
                        this.userToEdit = res.body;
                        resolve();
                    }
                },
                error: (error) => {
                    this.handleError(error);
                },
            });
        });
    }

    private openModal(modalConfig: MatDialogConfig) {
        this.internalAdminModal = this.matDialog.open(UserAccessModalComponent, modalConfig);
        this.internalAdminModal.componentInstance.confirmButtonEvent.subscribe((emittedValue: ModalCloseConfig) => {
            if (emittedValue) {
                this.internalAdminModal.close();

                switch (emittedValue.action) {
                    case UserActionEnum.revokeAccessDD: {
                        if (this.mode === CreateEditUserTypes.EDIT_USER) {
                            this.editUser(this.userToSubmit);
                        } else {
                            this.createUser(this.userToSubmit);
                        }

                        break;
                    }
                }
            }
        });

        this.internalAdminModal.componentInstance.cancelButtonEvent.subscribe((emittedValue) => {
            if (emittedValue) {
                this.internalAdminModal.close();
            }
        });
    }

    private createUser(user: UserDeal) {
        this.userAccessService.createUser(user).subscribe({
            next: (res: any) => {
                this.snackBar.openFromComponent(UserAccessSnackbarComponent, {
                    data: `User has been created`,
                    duration: 10000,
                });
                this.dealDashboardDealInput.nativeElement.value = '';
                this.cancelClicked();
            },
            error: (error) => this.handleError(error),
        });
    }

    private editUser(user: UserDeal) {
        this.userAccessService.editUser(user).subscribe({
            next: (res: any) => {
                this.snackBar.openFromComponent(UserAccessSnackbarComponent, {
                    data: `User has been edited`,
                    duration: 10000,
                });
                this.cancelClicked();
            },
            error: (error) => this.handleError(error),
        });
    }

    private handleError(error: HttpErrorResponse): void {
        this.fetchingUsers = false;
        this.fetchingDealsUsers = false;
        this.fetchingShareholders = false;
        this.fetchingShareholderDeals = false;
        error['stack'] ? console.error(error['stack']) : console.error(error);

        let msg: string;
        if (error.status === 400 && error.error.message.includes('User with provided email address already exists.')) {
            msg = 'User with provided email address already exists.';
            this.userForm.controls.email.setErrors({
                emailAlreadyExists: true,
                invalid: true,
            });
        }
        if (error?.status >= 400 && error?.status < 500) {
            msg = error?.error?.message ? error.error.message : 'Server error';
        } else {
            msg = 'Server error';
        }

        this.snackBar.open(msg, undefined, {
            duration: 10000,
        });
    }

    protected readonly CreateEditUserTypes = CreateEditUserTypes;
}
