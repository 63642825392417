import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ShareholderRecord } from '../shared';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

@Component({
    selector: 'app-shareholder-table',
    templateUrl: './shareholder-table.component.html',
    styleUrls: ['./shareholder-table.component.scss'],
})
export class ShareholderTableComponent implements AfterViewInit, OnChanges {
    @Input() shareholderRecords: ShareholderRecord[];
    @Input() searchText: string;
    @Input() initialSort: MatSortable;
    @Input() page: number;
    @Input() pageSize: number;
    @Input() totalRecords: number = 0;
    @Input() fetching: boolean;
    @Output() pageEvent = new EventEmitter<PageEvent>();
    @Output() sortEvent = new EventEmitter<Sort>();

    @ViewChild(MatSort) sort: MatSort;

    public dataSource: MatTableDataSource<ShareholderRecord>;
    public displayedColumns = ['shareholder'];

    private readonly SHAREHOLDER_URL = '/internal-admin/shareholders';

    constructor(
        private router: Router,
        private cdr: ChangeDetectorRef,
    ) {
        // Assign the data to the data source for the table to render
        this.dataSource = new MatTableDataSource(this.shareholderRecords);
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;

        if (this.sort) {
            this.sort.sort({ ...this.initialSort, disableClear: false });
            this.cdr.detectChanges();
        }
    }

    ngOnChanges(_: SimpleChanges): void {
        this.dataSource = new MatTableDataSource(this.shareholderRecords);
    }

    handlePageEvent(e: PageEvent) {
        this.pageEvent.emit(e);
    }

    handleSortChange(s: Sort) {
        this.sortEvent.emit(s);
    }

    handleShareholderSelected(s: ShareholderRecord) {
        this.router.navigate([this.SHAREHOLDER_URL], {
            queryParams: { shareholderId: s.nsId },
        });
    }
}
