import { Injectable } from '@angular/core';
import { Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

export type AllowedRolesData = {
    oneOfRoles: string[];
};

@Injectable({
    providedIn: 'root',
})
export class AllowedRoles {
    constructor(
        private router: Router,
        private authService: AuthService,
        private userService: UserService,
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let canActivate: boolean = false;
        // @ts-ignore
        const allowedRolesData: AllowedRolesData = route.data.AllowedRolesData;
        if (allowedRolesData === undefined) {
            return canActivate;
        }

        const allowedRoles: string[] = allowedRolesData.oneOfRoles;
        if (allowedRoles === undefined) {
            return canActivate;
        }

        for (let i = 0; i < allowedRoles.length; i++) {
            const roleFound = this.userService.user.roles.find((element: string) => {
                return element.toLowerCase() === allowedRoles[i].toLowerCase();
            });
            if (roleFound !== undefined) {
                canActivate = true;
                break;
            }
        }
        return canActivate;
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return true;
    }

    canLoad(
        route: Route,
        segments: UrlSegment[],
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let canLoad: boolean = false;
        // @ts-ignore
        const allowedRolesData: AllowedRolesData = route.data.AllowedRolesData;
        if (allowedRolesData === undefined) {
            return canLoad;
        }

        const allowedRoles: string[] = allowedRolesData.oneOfRoles;
        if (allowedRoles === undefined) {
            return canLoad;
        }

        for (let i = 0; i < allowedRoles.length; i++) {
            const roleFound = this.userService.user.roles.find((element: string) => {
                return element.toLowerCase() === allowedRoles[i].toLowerCase();
            });
            if (roleFound !== undefined) {
                canLoad = true;
                break;
            }
        }
        return canLoad;
    }
}
