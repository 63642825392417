<div id="main-div" class="main-div">
    <table mat-table matSort [dataSource]="dataSource" class="table-div">
        <ng-container matColumnDef="brokerageAccountName" class="brokerage-header-cell">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="brokerageAccountName"> Brokerage Account Name</th>
            <td mat-cell
                *matCellDef="let element"
                appHighlight
                [searchedWords]="searchText"
                [text]="element.brokerageAccountName"
                [ignoreCase]="true"
                classToApply="selected"
            ></td>
        </ng-container>

        <ng-container matColumnDef="brokerageAccountNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="brokerageAccountNumber"> Brokerage Account Number</th>
            <td mat-cell
                *matCellDef="let element"
                appHighlight
                [searchedWords]="searchText"
                [text]="element.brokerageAccountNumber"
                [ignoreCase]="true"
                classToApply="selected"
            ></td>
        </ng-container>

        <ng-container matColumnDef="bankRoutingNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="bankRoutingNumber"> Bank Routing Number</th>
            <td mat-cell
                *matCellDef="let element"
                appHighlight
                [searchedWords]="searchText"
                [text]="element.bankRoutingNumber"
                [ignoreCase]="true"
                classToApply="selected"
            ></td>
        </ng-container>

        <ng-container matColumnDef="bankName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="bankName"> Bank Name</th>
            <td mat-cell
                *matCellDef="let element"
                appHighlight
                [searchedWords]="searchText"
                [text]="element.bankName"
                [ignoreCase]="true"
                classToApply="selected"
            ></td>
        </ng-container>

        <ng-container matColumnDef="countryCode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="countryCode"> Country Code</th>
            <td mat-cell
                *matCellDef="let element"
                appHighlight
                [searchedWords]="searchText"
                [text]="element?.countryCode != null ? element.countryCode : ''"
                [ignoreCase]="true"
                classToApply="selected"
            ></td>
        </ng-container>

        <ng-container matColumnDef="details" class="mat-header-cell" style="vertical-align: middle;">
            <th mat-header-cell *matHeaderCellDef> Details</th>
            <td mat-cell *matCellDef="let element; index as i;">
                <span id="edit-span-{{i}}"
                    class="material-symbols-outlined action-icon"
                    (click)="editRow(element)"
                    *appAccessCheckSection="editSectionTitle"
                >edit</span>
                <span id="delete-span-{{i}}"
                    class="material-symbols-outlined action-icon"
                    (click)="deleteRow(element)"
                    *appAccessCheckSection="deleteSectionTitle"
                >delete</span>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell no-data" colspan="5"><span *ngIf="!fetching">No data</span></td>
        </tr>
    </table>
</div>
