import { ActivityRecord } from './activity.types';

export const activityRecordsMock: ActivityRecord[] = [
    {
        id: 1,
        userName: 'Juliana Yundt',
        userId: '98',
        action: 'added',
        products: 'Deal Dashboard',
        deals: 'Project Rodriguez, Beer and Langworth',
        dealIds: '34',
        dealUserRole: 'Deal Advanced Viewer',
        properties: null,
        byName: 'Alyssa Vuong',
        byId: '',
        sourceSystem: 'IntAdm',
        activityDt: 1704988493849,
        userStatus: 'Active',
    },
    {
        id: 2,
        userName: 'Kaitlin Gutmann',
        userId: '75',
        action: 'added',
        products: 'Deal Dashboard',
        deals: 'Project Bergstrom Inc',
        dealIds: '37',
        dealUserRole: 'Deal Basic Viewer',
        properties: null,
        byName: 'Chetan Vihite',
        byId: '',
        sourceSystem: 'IntAdm',
        activityDt: 1705334093849,
        userStatus: 'Active',
    },
    {
        id: 3,
        userName: 'Daniela Lesch',
        userId: '77',
        action: 'removed',
        products: 'Deal Dashboard',
        deals: 'Project Abernathy - Hayes',
        dealIds: '36',
        dealUserRole: 'Deal Basic Viewer',
        properties: null,
        byName: 'Jessica Norval',
        byId: '',
        sourceSystem: 'IntAdm',
        activityDt: 1708530893849,
        userStatus: 'Active',
    },
    {
        id: 4,
        userName: 'Angus Ortiz',
        userId: '66',
        action: 'added',
        products: 'Deal Dashboard',
        deals: 'Project Leannon and Sons',
        dealIds: '33',
        dealUserRole: 'Deal Advanced Viewer',
        properties: null,
        byName: 'Alan Shalkey',
        byId: '',
        sourceSystem: 'IntAdm',
        activityDt: 1703606093849,
        userStatus: 'Active',
    },
    {
        id: 5,
        userName: 'Janiya Auer',
        userId: '64',
        action: 'removed',
        products: 'Deal Dashboard',
        deals: 'Project Abernathy - Hayes',
        dealIds: '36',
        dealUserRole: 'Deal Basic Viewer',
        properties: null,
        byName: 'Chetan Vihite',
        byId: '',
        sourceSystem: 'IntAdm',
        activityDt: 1708703693849,
        userStatus: 'Active',
    },
    {
        id: 6,
        userName: 'Jake Doyle',
        userId: '56',
        action: 'removed',
        products: 'Deal Dashboard',
        deals: "Project O'Keefe Inc",
        dealIds: '41',
        dealUserRole: 'Deal Administrator',
        properties: null,
        byName: 'Justin Gidman',
        byId: '',
        sourceSystem: 'IntAdm',
        activityDt: 1702310093849,
        userStatus: 'Active',
    },
    {
        id: 7,
        userName: 'Daniela Lesch',
        userId: '77',
        action: 'removed',
        products: 'Deal Dashboard',
        deals: 'Project Ledner LLC',
        dealIds: '23',
        dealUserRole: 'Deal Administrator',
        properties: null,
        byName: 'Alan Shalkey',
        byId: '',
        sourceSystem: 'IntAdm',
        activityDt: 1701791693849,
        userStatus: 'Active',
    },
    {
        id: 8,
        userName: 'Lonie King',
        userId: '78',
        action: 'removed',
        products: 'Deal Dashboard',
        deals: 'Project Feil, Farrell and Langworth',
        dealIds: '17',
        dealUserRole: 'Deal Advanced Viewer',
        properties: null,
        byName: 'Alan Shalkey',
        byId: '',
        sourceSystem: 'IntAdm',
        activityDt: 1709049293849,
        userStatus: 'Active',
    },
    {
        id: 9,
        userName: 'Mattie Kling',
        userId: '79',
        action: 'invited',
        products: 'Deal Dashboard',
        deals: 'Project Beatty LLC',
        dealIds: '40',
        dealUserRole: 'Deal Advanced Viewer',
        properties: null,
        byName: 'Jessica Norval',
        byId: '',
        sourceSystem: 'IntAdm',
        activityDt: 1707494093849,
        userStatus: 'Invited',
    },
    {
        id: 10,
        userName: 'Fausto Schaefer',
        userId: '81',
        action: 'removed',
        products: 'Deal Dashboard',
        deals: 'Project Mraz and Sons',
        dealIds: '25',
        dealUserRole: 'Deal Administrator',
        properties: null,
        byName: 'Jessica Norval',
        byId: '',
        sourceSystem: 'IntAdm',
        activityDt: 1706716493849,
        userStatus: 'Active',
    },
];
