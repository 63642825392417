import { Component, ViewEncapsulation } from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from './core/services/user.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
    public error: Error;

    constructor(
        private authService: AuthService,
        private snackBar: MatSnackBar,
        private userService: UserService,
    ) {}

    public openSnackBar(message: string, action?: string) {
        this.snackBar.open(message, action, { verticalPosition: 'top', horizontalPosition: 'center' });
    }

    public get isDoneLoading$() {
        return this.authService.isAuthDoneLoading$;
    }

    public get isTokenExpiring() {
        return this.authService.isTokenExpiring;
    }

    public get refreshLoginFailed() {
        return this.authService.refreshLoginFailed;
    }

    public get sessionTime() {
        return this.authService.sessionTime;
    }

    public get isAuthenticated$() {
        return this.userService.isAuthenticated$;
    }

    public logout() {
        this.authService.logout();
    }

    public refreshLogin() {
        this.authService.refreshLogin();
    }
}
