import { Component, HostListener, OnInit } from '@angular/core';
import { UserService } from '../../core/services/user.service';
import { NavigationHelperService } from '../../core/services/navigation-helper.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-armory-sidebar',
    templateUrl: './armory-sidebar.component.html',
    styleUrls: ['./armory-sidebar.component.scss'],
})
export class ArmorySidebarComponent implements OnInit {
    public currentApp: string;
    public submenuExpanded = false;
    public opened: boolean = false; // The subscriber will flip this to true in ngOnInit().

    @HostListener('window:resize', ['$event'])
    onResize() {}

    constructor(
        private userService: UserService,
        private navigationHelperService: NavigationHelperService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.navigationHelperService.selectedApp$.subscribe((appName) => {
            this.currentApp = appName;
        });

        this.navigationHelperService.submenu$.subscribe((submenu) => {
            if (submenu?.appName) {
                this.currentApp = submenu.appName;
            }

            if (submenu?.childPaths && Array.isArray(submenu.childPaths) && submenu.childPaths.length > 0) {
                this.submenuExpanded = true;
            }
        });

        this.navigationHelperService.sidebarToggle$.subscribe(() => {
            this.opened = !this.opened;
        });
    }

    public get allowedApps$() {
        return this.userService.allowedApps$;
    }

    public get submenuItems$() {
        return this.navigationHelperService.submenu$;
    }

    public get routerUrl() {
        return this.router.url;
    }

    public appClicked(appName: string) {
        if (this.currentApp !== appName) {
            this.navigationHelperService.clearSubmenu();
        }

        this.currentApp = appName;
    }

    public toggleIcon(event) {
        // This is to prevent the routerLink from activating.
        event.stopPropagation();
        event.preventDefault();
        this.submenuExpanded = !this.submenuExpanded;
    }
}
