import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import {
    ModalConfigData,
    ModalReviewConfigData,
    ReviewStatusType,
    VendorDetailConfig,
    VendorRecord,
    VendorService,
    VendorTabNames,
} from './shared';
import { ModalComponent } from './modal/modal.component';
import { FilterConfig, FilterOption } from '../components/filter/filter.model.types';
import { AvailableColumns } from './table/table.columns.types';
import { ModalSimpleComponent } from './modal-simple/modal-simple.component';
import { SimpleModalConfigData, SimpleModalResult } from './modal-simple/modal-simple.config.type';
import { cloneDeep } from 'lodash-es';
import { UserService } from '../core/services/user.service';
import { ModalReviewComponent } from './modal-review/modal-review.component';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Sort } from '@angular/material/sort';

interface VendorSearchHelper {
    search: string;
    sortDir: string;
    sortId: string;
    vendorRecords: VendorRecord[];
    vendorRecordsFiltered: VendorRecord[];
}

@Component({
    selector: 'app-vendors',
    templateUrl: './vendors.component.html',
    styleUrls: ['./vendors.component.scss'],
})
export class VendorsComponent implements OnInit, AfterViewInit {
    // The static:false makes that Angular check if is under a *ngIf.
    // The read:ElementRef makes that the input is seen as ElementRef, not MatInput.
    @ViewChild('dealCountInput', { static: false, read: ElementRef }) dealCountInput: ElementRef;

    public readonly EXPORT_VENDOR_SELECTED = 'Export Selected';
    public readonly EXPORT_VENDOR_DEFAULT = 'Export';
    public readonly ADD_SECTION_TITLE = 'Add';
    public readonly DEAL_COUNT_LABEL = 'Deal Count';
    public readonly TAB_IN_REVIEW = 0;
    public readonly TAB_APPROVED = 1;
    public readonly TAB_REJECTED = 2;
    private readonly VENDOR_URL = '/vendors';
    private readonly DEFAULT_FILTER = 'None';

    public selectedTabIndex: 0 | 1 | 2 = this.TAB_IN_REVIEW;
    public showDetails: boolean = false;
    public vendorModal: MatDialogRef<ModalComponent>;
    public vendorReviewModal: MatDialogRef<ModalReviewComponent>;
    public vendorSimpleModal: MatDialogRef<ModalSimpleComponent>;
    public result: boolean = false;
    public vendorConfig: VendorDetailConfig;
    public exportVendorsInProgress = false;
    public fetching = true;
    public myReviewOnlySelected;
    public form: FormGroup = new FormGroup({
        dealCount: new FormControl<number>(null),
    });
    public selectedFilter: FilterOption;
    public vendorSearchHelpers: VendorSearchHelper[] = Array.from({ length: 3 }, (_, index) => ({
        search: '',
        sortDir: 'desc',
        sortId: index === this.TAB_IN_REVIEW ? 'dealCount' : index === this.TAB_APPROVED ? 'updatedDt' : 'rejectionDt',
        vendorRecords: [],
        vendorRecordsFiltered: [],
    }));

    public inReviewColumns: AvailableColumns[] = [
        AvailableColumns.PayeeName,
        AvailableColumns.AccountTitle,
        AvailableColumns.AccountNumber,
        AvailableColumns.AbaSwift,
        AvailableColumns.Subsidiary,
        AvailableColumns.TaxpayerId,
        AvailableColumns.ReviewType,
        AvailableColumns.DealCount,
        AvailableColumns.Details,
    ];
    public approvedColumns: AvailableColumns[] = [
        AvailableColumns.PayeeName,
        AvailableColumns.AccountTitle,
        AvailableColumns.AccountNumber,
        AvailableColumns.AbaSwift,
        AvailableColumns.Subsidiary,
        AvailableColumns.TaxpayerId,
        AvailableColumns.BankCountry,
        AvailableColumns.Currency,
        AvailableColumns.SortCode,
        AvailableColumns.UpdatedDt,
        AvailableColumns.Details,
    ];
    public rejectedColumns: AvailableColumns[] = [
        AvailableColumns.PayeeName,
        AvailableColumns.AccountTitle,
        AvailableColumns.AccountNumber,
        AvailableColumns.AbaSwift,
        AvailableColumns.Subsidiary,
        AvailableColumns.TaxpayerId,
        AvailableColumns.RejectionDate,
        AvailableColumns.Details,
    ];

    private dealCountFilter: FilterOption = {
        value: this.DEAL_COUNT_LABEL,
        viewValue: this.DEAL_COUNT_LABEL,
    };
    public filterConfig: FilterConfig = {
        label: 'Filter by',
        filterOptions: [
            { value: this.DEFAULT_FILTER, viewValue: this.DEFAULT_FILTER },
            { value: 'new', viewValue: 'New Eligible Vendor' },
            { value: 'edit-review', viewValue: 'Edit to Approved Vendor' },
            { value: 'reversal-review', viewValue: 'Reverse Rejected Vendor' },
            { value: 'reviewed', viewValue: 'Reviewed Vendors' },
            this.dealCountFilter,
        ],
    };

    protected readonly VendorTabNames = VendorTabNames;

    private selectedIds: number[];
    private filterValue: string;
    private isPageLoading = true;
    private modalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: false,
        minWidth: '600px',
        minHeight: '400px',
    };

    private modalReviewConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: false,
        maxWidth: '90vw',
        maxHeight: '90vh',
    };

    private rejectModalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: false,
        minWidth: '560px',
        minHeight: '530px',
    };

    private reverseModalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: false,
        minWidth: '570px',
        minHeight: '600px',
    };

    private newNoteModalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: false,
        minWidth: '500px',
        minHeight: '320px',
    };

    private exportVendorsModalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: false,
        minWidth: '500px',
        minHeight: '270px',
    };

    private hideVendorModalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: false,
        minWidth: '570px',
        minHeight: '300px',
    };

    constructor(
        public matDialog: MatDialog,
        private snackBar: MatSnackBar,
        private vendorPaymentsService: VendorService,
        private userService: UserService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe({
            next: (params: Params) => {
                const tabIndex = +params.tab;
                this.selectedTabIndex = Number.isInteger(tabIndex) ? (tabIndex as 0 | 1 | 2) : 0;

                this.vendorSearchHelpers[this.selectedTabIndex].sortDir = params['sortDir'] ?? 'desc';
                this.vendorSearchHelpers[this.selectedTabIndex].sortId = params['sortId'] ?? 'dealCount';
                this.vendorSearchHelpers[this.selectedTabIndex].search = params['search'] ?? '';

                this.selectedFilter =
                    this.filterConfig.filterOptions.find((option) => params['filterBy']?.startsWith(option.value)) ??
                    this.filterConfig.filterOptions[this.TAB_IN_REVIEW];

                if (params['dealCount']) {
                    this.form.controls.dealCount.setValue(params['dealCount']);
                }

                this.myReviewOnlySelected = params['myReviewOnly'] === 'true';

                this.isPageLoading = false;
            },
        });

        this.fetch();
    }

    ngAfterViewInit(): void {
        this.cdr.detectChanges();
    }

    public handleSortChange(s: Sort, tab: number) {
        this.vendorSearchHelpers[tab].sortId = s.active;
        this.vendorSearchHelpers[tab].sortDir = s.direction;

        this.router.navigate([this.VENDOR_URL], {
            queryParams: {
                sortId: this.vendorSearchHelpers[tab].sortId,
                sortDir: this.vendorSearchHelpers[tab].sortDir,
                clear: null,
            },
            queryParamsHandling: 'merge',
        });
        this.cdr.detectChanges();
    }

    public onTabChanged() {
        this.router.navigate([this.VENDOR_URL], {
            queryParams: {
                tab: this.selectedTabIndex,
                search: this.vendorSearchHelpers[this.selectedTabIndex].search,
                sortId: this.vendorSearchHelpers[this.selectedTabIndex].sortId,
                sortDir: this.vendorSearchHelpers[this.selectedTabIndex].sortDir,
            },
            queryParamsHandling: 'merge',
        });

        this.fetch();
    }

    public rejectInReviewVendor($event: VendorRecord) {
        // Each vendor status is rejected differently
        switch ($event.status) {
            case ReviewStatusType.NEW: {
                // New eligible vendors in review get a modal to confirm the
                // rejection and enter a reason why.
                const rejectVendorConfig: MatDialogConfig<SimpleModalConfigData> = {
                    ...this.rejectModalConfigTemplate,
                    data: {
                        title: 'Reject This Vendor Account?',
                        action: 'reject',
                        selected: $event,
                    },
                };

                this.openSimpleModal(rejectVendorConfig);

                break;
            }
            case ReviewStatusType.DELETE_REVIEW:
            case ReviewStatusType.EDIT_REVIEW:
            case ReviewStatusType.REVERSAL_REVIEW: {
                this.vendorPaymentsService.rejectInReviewVendorEditDelete($event).subscribe({
                    next: () => {},
                    error: (error) => this.handleError(error),
                });

                this.closeSliderPanel();

                this.fetch();

                break;
            }
        }
    }

    public hideInReviewVendor($event: VendorRecord) {
        // Each vendor status is rejected differently
        switch ($event.status) {
            case ReviewStatusType.NEW: {
                // New eligible vendors in review get a modal to confirm the
                // hide request.
                const rejectVendorConfig: MatDialogConfig<SimpleModalConfigData> = {
                    ...this.hideVendorModalConfigTemplate,
                    data: {
                        title: 'Hide Vendor',
                        action: 'hide',
                        selected: $event,
                    },
                };

                this.openSimpleModal(rejectVendorConfig);

                break;
            }
        }
    }

    public submitVendorDetails($event: VendorRecord) {
        // In this case the action is only for when it has been reviewed but not approved,
        // because other status types are possible than those originating from the In Review tab.
        if ($event.reviewedBy && $event.reviewedDt && !$event.approvedBy && !$event.approvedDt) {
            this.vendorPaymentsService.approveInReviewVendor($event).subscribe({
                next: () => {
                    this.closeSliderPanel();

                    this.fetch();

                    this.snackBar.open(
                        'Success! The vendor has been approved and moved to the list of Approved Vendors.',
                        undefined,
                        {
                            duration: 6000,
                        },
                    );
                },
                error: (error) => this.handleError(error),
            });
        } else {
            switch ($event.status) {
                case ReviewStatusType.NEW:
                case ReviewStatusType.DELETE_REVIEW:
                case ReviewStatusType.EDIT_REVIEW:
                case ReviewStatusType.REVERSAL_REVIEW: {
                    this.vendorPaymentsService.markInReviewVendorAsReviewed($event).subscribe({
                        next: () => {
                            this.closeSliderPanel();

                            this.fetch();

                            this.snackBar.open('Vendor successfully marked as reviewed.', undefined, {
                                duration: 6000,
                            });
                        },
                        error: (error) => this.handleError(error),
                    });

                    break;
                }
                case ReviewStatusType.REJECTED:
                case ReviewStatusType.DELETED: {
                    // Rejected or deleted vendors on the Rejected tab need
                    // confirmation before moving them back to In Review.
                    const rejectVendorConfig: MatDialogConfig<SimpleModalConfigData> = {
                        ...this.reverseModalConfigTemplate,
                        data: {
                            title: 'Confirm Reversal?',
                            action: 'reverse',
                            selected: $event,
                        },
                    };

                    this.openSimpleModal(rejectVendorConfig);

                    break;
                }
            }
        }
    }

    public addRow() {
        const addRowConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Add New Vendor',
                action: 'create',
            },
        };
        this.openModal(addRowConfig);
    }

    public handleEditReject($event: ModalConfigData) {
        switch ($event.action) {
            case 'edit': {
                // Cloning this vendor record to avoid altering the underlying approved vendor record.
                $event.selected = cloneDeep($event.selected);

                const editRowConfig: MatDialogConfig<ModalConfigData> = {
                    ...this.modalConfigTemplate,
                    data: $event,
                };
                this.openModal(editRowConfig);

                break;
            }
            case 'reject': {
                const rejectVendorConfig: MatDialogConfig<SimpleModalConfigData> = {
                    ...this.rejectModalConfigTemplate,
                    data: {
                        title: 'Reject Vendor Account?',
                        action: 'reject',
                        selected: $event.selected,
                    },
                };

                this.openSimpleModal(rejectVendorConfig);

                break;
            }
        }
    }

    public handleReviewEvent($event: ModalReviewConfigData) {
        switch ($event.action) {
            case 'review': {
                // Cloning this vendor record to avoid altering the underlying approved vendor record.
                $event.selected = cloneDeep($event.selected);

                const reviewConfig: MatDialogConfig<ModalReviewConfigData> = {
                    ...this.modalReviewConfigTemplate,
                    data: $event,
                };
                this.openReviewModal(reviewConfig);

                break;
            }
        }
    }

    public openSlideoutPanel($event: VendorRecord) {
        this.vendorConfig = {
            selected: $event,
            // Disable the submit button if the reviewer is the same person who
            // submitted the edit/delete/reversal, or if the approver is the same
            // person who marked the vendor as reviewed.

            // Note that change submitted by is an ID while reviewed by is a NAME.
            submitDisabled:
                $event?.changeSubmittedById === this.userService.user.sub ||
                $event.reviewedBy === this.userService.user.name,
        };

        // Get All notes for this vendor record
        this.vendorPaymentsService
            .getNotesForVendorByVendorId($event.id)
            .subscribe((notes) => (this.vendorConfig.notes = notes));

        // Get All activity for this vendor record
        this.vendorPaymentsService.getActivityForVendorByVendorId($event.id).subscribe((activity) => {
            this.vendorConfig.activity = activity;

            // Sort the activities in descending order.
            this.vendorConfig.activity?.sort((a1, a2) => a2.activityDate - a1.activityDate);

            let reviewedDt = null;
            let approvedDt = null;
            this.vendorConfig.activity?.forEach((activity) => {
                switch (activity.action) {
                    case 'Approved': {
                        // Keep the most recent Approved Date
                        if (!approvedDt || approvedDt < activity.activityDate) {
                            approvedDt = activity.activityDate;
                        }
                        break;
                    }
                    case 'Reviewed': {
                        // Keep the most recent Reviewed Date
                        if (!reviewedDt || reviewedDt < activity.activityDate) {
                            reviewedDt = activity.activityDate;
                        }
                        break;
                    }
                }
            });

            // The button labels are dependent on any activity that may be going on.
            //
            // If reviewed but no approval date, or there is a review date newer
            // than the last approval date, then it is a "new" review that needs to
            // be approved.
            if ((reviewedDt && !approvedDt) || reviewedDt > approvedDt) {
                this.vendorConfig.title = 'Account Details';
                this.vendorConfig.primaryButtonTitle = 'Approve';
                this.vendorConfig.secondaryButtonTitle = 'Reject';
            } else {
                switch ($event.status) {
                    case ReviewStatusType.NEW: {
                        this.vendorConfig.title = 'New Vendor Details';
                        this.vendorConfig.primaryButtonTitle = 'Mark as Reviewed';
                        this.vendorConfig.secondaryButtonTitle = 'Reject';
                        this.vendorConfig.tertiaryButtonTitle = 'Hide';

                        break;
                    }
                    case ReviewStatusType.EDIT_REVIEW: {
                        this.vendorConfig.title = 'Account Details';
                        this.vendorConfig.primaryButtonTitle = 'Mark as Reviewed';
                        this.vendorConfig.secondaryButtonTitle = 'Reject Edit';

                        break;
                    }
                    case ReviewStatusType.DELETE_REVIEW: {
                        this.vendorConfig.title = 'Account Details';
                        this.vendorConfig.primaryButtonTitle = 'Mark as Reviewed';
                        this.vendorConfig.secondaryButtonTitle = 'Reject Delete';

                        break;
                    }
                    case ReviewStatusType.REVERSAL_REVIEW: {
                        this.vendorConfig.title = 'Account Details';
                        this.vendorConfig.primaryButtonTitle = 'Mark as Reviewed';
                        this.vendorConfig.secondaryButtonTitle = 'Reject Reversal';

                        break;
                    }
                    case ReviewStatusType.DELETED:
                    case ReviewStatusType.REJECTED: {
                        this.vendorConfig.title = 'Account Details';
                        this.vendorConfig.primaryButtonTitle = 'Reverse Rejection';

                        break;
                    }
                    default: {
                        this.vendorConfig.title = 'Account Details';
                        this.vendorConfig.primaryButtonTitle = null;
                        this.vendorConfig.secondaryButtonTitle = null;

                        break;
                    }
                }
            }
        });

        this.showDetails = true;
    }

    public closeSliderPanel() {
        this.showDetails = false;
    }

    public performSearch(searchVal: string) {
        this.vendorSearchHelpers[this.selectedTabIndex].search = searchVal;

        this.router.navigate([this.VENDOR_URL], {
            queryParams: {
                search: this.vendorSearchHelpers[this.selectedTabIndex].search,
            },
            queryParamsHandling: 'merge',
        });

        this.vendorSearchHelpers[this.selectedTabIndex].vendorRecordsFiltered = this.refreshTable(
            this.vendorSearchHelpers[this.selectedTabIndex].vendorRecords,
            this.filterValue,
            searchVal,
        );
    }

    public filterTable($event: FilterOption, focusOnInput: boolean = true) {
        if (this.selectedTabIndex !== this.TAB_IN_REVIEW) {
            return;
        }

        this.selectedFilter = $event;
        if ($event?.viewValue.startsWith(this.DEAL_COUNT_LABEL)) {
            if (this.form.controls.dealCount.value === null) {
                this.form.controls.dealCount.setValue(1);
            }

            this.dealCountFilter.value = `${this.DEAL_COUNT_LABEL} ${this.form.controls.dealCount.value}`;

            if (focusOnInput) {
                // The delay parameter is omitted, meaning execute "immediately", or more accurately, the next event cycle.
                setTimeout(() => {
                    // This fails with "this.dealCountInput is undefined" if not within setTimeout().
                    this.dealCountInput.nativeElement.focus();
                });
            }
        }

        if (!$event || $event.value === 'None') {
            this.filterValue = null;
        } else {
            this.filterValue = $event.value;
        }

        this.router.navigate([this.VENDOR_URL], {
            queryParams: {
                filterBy: $event?.value,
            },
            queryParamsHandling: 'merge',
        });

        this.vendorSearchHelpers[this.selectedTabIndex].vendorRecordsFiltered = this.refreshTable(
            this.vendorSearchHelpers[this.selectedTabIndex].vendorRecords,
            this.filterValue,
            this.vendorSearchHelpers[this.selectedTabIndex].search,
        );
    }

    public onDealCountChange(value) {
        if (this.selectedTabIndex !== this.TAB_IN_REVIEW) {
            return;
        }

        if (value && value.length === 1 && isNaN(value)) {
            this.form.controls.dealCount.setValue(
                this.form.controls.dealCount.value
                    .toString()
                    .substring(0, this.form.controls.dealCount.value.length - 1),
            );
        } else if (!value) {
            this.form.controls.dealCount.setValue(1);
        }

        if (this.form.controls?.dealCount?.value !== '') {
            this.dealCountFilter.value = `${this.DEAL_COUNT_LABEL} ${this.form.controls.dealCount.value}`;
            this.filterValue = this.dealCountFilter.value;
        }

        this.router.navigate([this.VENDOR_URL], {
            queryParams: {
                dealCount: this.form.controls.dealCount.value,
            },
            queryParamsHandling: 'merge',
        });

        this.vendorSearchHelpers[this.selectedTabIndex].vendorRecordsFiltered = this.refreshTable(
            this.vendorSearchHelpers[this.selectedTabIndex].vendorRecords,
            this.filterValue,
            this.vendorSearchHelpers[this.selectedTabIndex].search,
        );
    }

    public refreshTable(vendors: VendorRecord[], filterValue: string, searchVal: string): VendorRecord[] {
        let tempArray: VendorRecord[] = vendors;

        if (this.selectedTabIndex === this.TAB_IN_REVIEW) {
            tempArray = this.applyFilter(vendors, filterValue);
        }

        if (searchVal) {
            tempArray = this.applySearch(tempArray, searchVal);
        }

        if (this.selectedTabIndex === this.TAB_IN_REVIEW && this.myReviewOnlySelected) {
            tempArray = this.filterByMyReviewOnly(tempArray);
        }

        return tempArray;
    }

    public addVendorNote($event: VendorRecord) {
        const newNoteConfig: MatDialogConfig<SimpleModalConfigData> = {
            ...this.newNoteModalConfigTemplate,
            data: {
                title: 'New Note',
                action: 'newNote',
                selected: $event,
            },
        };

        this.openSimpleModal(newNoteConfig);
    }

    public isApprovedTab(): boolean {
        return Object.values(VendorTabNames)[this.selectedTabIndex] === VendorTabNames.APPROVED;
    }

    public exportVendors() {
        if (this.exportVendorsInProgress) {
            const newNoteConfig: MatDialogConfig<SimpleModalConfigData> = {
                ...this.exportVendorsModalConfigTemplate,
                data: {
                    title: 'Export Approved Vendors',
                    action: 'export',
                    selected: null,
                    exportIds: this.selectedIds,
                },
            };

            this.openSimpleModal(newNoteConfig);
        } else {
            this.approvedColumns.splice(0, 0, AvailableColumns.ExportVendor);
            this.exportVendorsInProgress = true;
        }
    }

    public idsForExport($event: any) {
        this.selectedIds = $event;
    }

    public myReviewOnlySelectedChange($event: any) {
        this.myReviewOnlySelected = $event;

        if (this.myReviewOnlySelected) {
            this.vendorSearchHelpers[this.TAB_IN_REVIEW].vendorRecordsFiltered = this.filterByMyReviewOnly();
        } else {
            this.checkIfShouldFilterTable();
        }

        this.router.navigate([this.VENDOR_URL], {
            queryParams: {
                myReviewOnly: $event,
            },
            queryParamsHandling: 'merge',
        });
    }

    private fetch() {
        this.fetching = true;
        switch (this.selectedTabIndex) {
            case this.TAB_IN_REVIEW: {
                this.vendorPaymentsService.getInReviewVendors().subscribe({
                    next: (res) => {
                        this.fetching = false;
                        if (res && res.length > 0) {
                            res.forEach((row) => {
                                if (row.reviewedBy && row.reviewedDt && !row.approvedBy && !row.approvedDt) {
                                    row.reviewType = 'Reviewed';
                                } else {
                                    switch (row.status) {
                                        case ReviewStatusType.NEW: {
                                            row.reviewType = 'New Eligible Vendor';
                                            break;
                                        }
                                        case ReviewStatusType.EDIT_REVIEW: {
                                            row.reviewType = 'Edit to Approved Vendor';
                                            break;
                                        }
                                        case ReviewStatusType.REVERSAL_REVIEW: {
                                            row.reviewType = 'Reverse Rejected Vendor';
                                            break;
                                        }
                                        default: {
                                            row.reviewType = '';
                                        }
                                    }
                                }
                            });
                            this.vendorSearchHelpers[this.TAB_IN_REVIEW].vendorRecordsFiltered =
                                this.vendorSearchHelpers[this.TAB_IN_REVIEW].vendorRecords = res;
                        } else {
                            this.vendorSearchHelpers[this.TAB_IN_REVIEW].vendorRecordsFiltered =
                                this.vendorSearchHelpers[this.TAB_IN_REVIEW].vendorRecords = [];
                        }
                        this.checkIfShouldFilterTable();
                    },
                    error: (error) => this.handleError(error),
                });

                break;
            }
            case this.TAB_APPROVED: {
                this.vendorPaymentsService.getApprovedVendors().subscribe({
                    next: (res) => {
                        this.fetching = false;
                        this.vendorSearchHelpers[this.TAB_APPROVED].vendorRecordsFiltered = this.vendorSearchHelpers[
                            this.TAB_APPROVED
                        ].vendorRecords = res;
                        this.checkIfShouldFilterTable();
                    },
                    error: (error) => this.handleError(error),
                });

                break;
            }
            case this.TAB_REJECTED: {
                this.vendorPaymentsService.getRejectedVendors().subscribe({
                    next: (res) => {
                        this.fetching = false;
                        this.vendorSearchHelpers[this.TAB_REJECTED].vendorRecordsFiltered = this.vendorSearchHelpers[
                            this.TAB_REJECTED
                        ].vendorRecords = res;
                        this.checkIfShouldFilterTable();
                    },
                    error: (error) => this.handleError(error),
                });
            }
        }
    }

    private checkIfShouldFilterTable() {
        if (this.vendorSearchHelpers[this.selectedTabIndex].search) {
            this.performSearch(this.vendorSearchHelpers[this.selectedTabIndex].search);
        }
        if (this.selectedFilter) {
            this.filterTable(this.selectedFilter, false);
        }
        if (this.form.controls.dealCount.value && this.selectedFilter?.value?.startsWith(this.DEAL_COUNT_LABEL)) {
            this.onDealCountChange(this.form.controls.dealCount.value);
        }
        if (this.selectedTabIndex === this.TAB_IN_REVIEW && this.myReviewOnlySelected) {
            this.vendorSearchHelpers[this.TAB_IN_REVIEW].vendorRecordsFiltered = this.filterByMyReviewOnly();
        }
    }

    private filterByMyReviewOnly(array?: VendorRecord[]): VendorRecord[] {
        if (!array) {
            array = this.vendorSearchHelpers[this.TAB_IN_REVIEW].vendorRecordsFiltered;
        }
        return array.filter((record) => record.reviewedBy !== this.userService.user.name);
    }

    private applyFilter(array: VendorRecord[], value: string): VendorRecord[] {
        let tempVendors: VendorRecord[] = [];

        if (array && array.length > 0) {
            switch (value) {
                case 'None': {
                    tempVendors = [...array];

                    break;
                }
                case 'new': {
                    tempVendors = array.filter((vendor) => vendor.status?.toUpperCase() === ReviewStatusType.NEW);
                    break;
                }
                case 'edit-review': {
                    tempVendors = array.filter(
                        (vendor) => vendor.status?.toUpperCase() === ReviewStatusType.EDIT_REVIEW,
                    );

                    break;
                }
                case 'delete-review': {
                    tempVendors = array.filter(
                        (vendor) => vendor.status?.toUpperCase() === ReviewStatusType.DELETE_REVIEW,
                    );

                    break;
                }
                case 'reversal-review': {
                    tempVendors = array.filter(
                        (vendor) => vendor.status?.toUpperCase() === ReviewStatusType.REVERSAL_REVIEW,
                    );

                    break;
                }
                case 'reviewed': {
                    tempVendors = array.filter(
                        (vendor) => vendor.reviewedBy && vendor.reviewedDt && !vendor.approvedBy && !vendor.approvedDt,
                    );

                    break;
                }
                case 'sort-newer':
                case 'sort-older': {
                    tempVendors = array.sort(
                        (a: VendorRecord, b: VendorRecord) =>
                            (a.createdDate - b.createdDate) * (value === 'sort-newer' ? 1 : -1),
                    );

                    break;
                }
                case value?.startsWith('Deal Count') ? value : '': {
                    let temp = value.substring(10)?.trim();

                    const dealCount = temp ? +temp : 2;
                    tempVendors = array.filter((vendor) => vendor.dealCount >= dealCount);

                    break;
                }
                default: {
                    tempVendors = [...array];

                    break;
                }
            }
        }

        return tempVendors;
    }

    private applySearch(array: VendorRecord[], searchVal: string): VendorRecord[] {
        let tempVendors: VendorRecord[] = [];

        if (!searchVal || searchVal.trim() == '') {
            // No search value, return all records
            tempVendors = array;
        } else if (array && array.length > 0) {
            const temp = searchVal.toLowerCase();
            if (this.selectedTabIndex === this.TAB_IN_REVIEW) {
                tempVendors = array.filter((val) => {
                    return (
                        val.payeeName.toLowerCase().indexOf(temp) >= 0 ||
                        val.accountNumber.toLowerCase().indexOf(temp) >= 0 ||
                        (val.accountTitle && val.accountTitle.toLowerCase().indexOf(temp) >= 0) ||
                        (val.abaSwift && val.abaSwift.toLowerCase().indexOf(temp) >= 0) ||
                        (val.taxpayerId && val.taxpayerId.toString().indexOf(temp) >= 0)
                    );
                });
            } else if (this.selectedTabIndex === this.TAB_APPROVED || this.selectedTabIndex === this.TAB_REJECTED) {
                tempVendors = array.filter((val) => {
                    return (
                        val.payeeName.toLowerCase().indexOf(temp) >= 0 ||
                        val.accountNumber.toLowerCase().indexOf(temp) >= 0 ||
                        (val.accountTitle && val.accountTitle.toLowerCase().indexOf(temp) >= 0) ||
                        (val.abaSwift && val.abaSwift.toLowerCase().indexOf(temp) >= 0) ||
                        (val.bankCountry && val.bankCountry.toLowerCase().indexOf(temp) >= 0) ||
                        (val.currency && val.currency.toLowerCase().indexOf(temp) >= 0) ||
                        (val.sortCode && val.sortCode.toLowerCase().indexOf(temp) >= 0) ||
                        (val.taxpayerId && val.taxpayerId.toString().indexOf(temp) >= 0)
                    );
                });
            }
        }

        return tempVendors;
    }

    private openModal(modalConfig: MatDialogConfig) {
        this.vendorModal = this.matDialog.open(ModalComponent, modalConfig);
        this.vendorModal.afterClosed().subscribe((res: { updateData: boolean; areChangesSaved: boolean }) => {
            if (!res || !res.areChangesSaved) {
                this.snackBar.open('Last changes were not saved and have been discarded', undefined, {
                    duration: 6000,
                });
            }
            if (res?.updateData) {
                this.snackBar.open(
                    'Your account is currently In Review. Please note that certain actions may be limited or require additional verification during this time.',
                    undefined,
                    {
                        duration: 6000,
                    },
                );

                this.fetch();
            }
        });
    }

    private openReviewModal(modalConfig: MatDialogConfig) {
        this.vendorReviewModal = this.matDialog.open(ModalReviewComponent, modalConfig);
        this.vendorReviewModal.afterClosed().subscribe((res: { updateData: boolean; areChangesSaved: boolean }) => {
            if (!res || !res.areChangesSaved) {
                this.snackBar.open('Last changes were not saved and have been discarded', undefined, {
                    duration: 6000,
                });
            }
            if (res?.updateData) {
                this.snackBar.open('Your changes have been saved.', undefined, {
                    duration: 6000,
                });

                this.fetch();
            }
        });
    }

    private openSimpleModal(modalConfig: MatDialogConfig) {
        this.vendorSimpleModal = this.matDialog.open(ModalSimpleComponent, modalConfig);
        this.vendorSimpleModal.afterClosed().subscribe((res: SimpleModalResult) => {
            if (res?.isRejected) {
                this.snackBar.open(
                    'Your account has been successfully been added to the Rejected section.',
                    undefined,
                    {
                        duration: 6000,
                    },
                );

                this.fetch();
            } else if (res?.isDeleted) {
                this.snackBar.open(
                    'Your account is currently In Review. Please note that certain actions may be limited or require additional verification during this time.',
                    undefined,
                    {
                        duration: 6000,
                    },
                );

                this.fetch();
            } else if (res?.isNoteCreated) {
                // Get All notes for this vendor record
                this.vendorPaymentsService
                    .getNotesForVendorByVendorId(modalConfig.data.selected.id)
                    .subscribe((notes) => {
                        this.vendorConfig.notes = notes;
                    });
            } else if (res?.exported) {
                this.approvedColumns = this.approvedColumns.filter(
                    (column) => column !== AvailableColumns.ExportVendor,
                );
                this.exportVendorsInProgress = false;

                this.snackBar.open('Your vendor details have been successfully exported.', undefined, {
                    duration: 6000,
                });
            } else if (res?.reversed) {
                this.snackBar.open(
                    'The vendor is now moved to the "In Review" tab as a "Reverse Rejected Vendor."',
                    undefined,
                    {
                        duration: 6000,
                    },
                );

                this.fetch();
            } else if (res?.hidden) {
                this.snackBar.open(
                    'The vendor is now hidden from the In Review List. It will reappear after another payment is processed.',
                    undefined,
                    {
                        duration: 6000,
                    },
                );

                this.fetch();
            }
        });
    }

    private handleError(error: HttpErrorResponse): void {
        this.fetching = false;
        console.error(error);
        this.snackBar.open('Server error', undefined, {
            duration: 10000,
        });
    }
}
