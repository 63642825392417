import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SimpleModalConfigData, SimpleModalResult } from './modal-simple.config.type';
import { ExportConfig, VendorService } from '../shared';

@Component({
    selector: 'app-modal-simple',
    templateUrl: './modal-simple.component.html',
    styleUrls: ['./modal-simple.component.scss'],
})
export class ModalSimpleComponent implements OnInit {
    public rejectForm = new FormGroup({
        rejectReason: new FormControl<string>('', [Validators.required, Validators.maxLength(1000)]),
    });

    public noteForm = new FormGroup({
        noteText: new FormControl<string>('', [Validators.required, Validators.maxLength(1000)]),
    });

    public exportForm = new FormGroup({
        filename: new FormControl<string>('', [Validators.required, Validators.maxLength(150)]),
    });

    private isRejected: boolean = false;
    private isDeleted: boolean = false;
    private isNoteCreated: boolean = false;
    private exported: boolean = false;
    private isReversed: boolean = false;
    private hidden: boolean = false;

    constructor(
        @Optional() public dialogRef: MatDialogRef<ModalSimpleComponent>,
        @Inject(MAT_DIALOG_DATA) public modalData: SimpleModalConfigData,
        private snackBar: MatSnackBar,
        private vendorPaymentsService: VendorService,
    ) {}

    ngOnInit(): void {
        // Close the dialog if the user presses the Escape key.
        this.dialogRef.keydownEvents().subscribe((event) => {
            if (event?.key === 'Escape') {
                this.closeModal();
            }
        });

        // Close the dialog if the user clicks outside the dialog.
        this.dialogRef.backdropClick().subscribe((e) => {
            if (e != null) {
                // null is getting passed during unit tests; this avoids testing problems.
                this.closeModal();
            }
        });

        // Supply a default filename for vendor exports
        const currentDate = new Date();
        let year = currentDate.getFullYear();
        let month = (1 + currentDate.getMonth()).toString().padStart(2, '0');
        let day = currentDate.getDate().toString().padStart(2, '0');
        let hour = currentDate.getHours().toString().padStart(2, '0');
        let min = currentDate.getMinutes().toString().padStart(2, '0');
        this.exportForm.controls.filename.setValue(
            `Approved Vendors Export ${month + '-' + day + '-' + year + ' ' + hour + ':' + min}`,
        );
    }

    public closeModal() {
        const dialogResult: SimpleModalResult = {
            isRejected: this.isRejected,
            isDeleted: this.isDeleted,
            isNoteCreated: this.isNoteCreated,
            exported: this.exported,
            reversed: this.isReversed,
            hidden: this.hidden,
        };

        this.dialogRef.close(dialogResult);
    }

    public submit() {
        switch (this.modalData.action) {
            case 'reject': {
                // From the simple modal only a new vendor record can be rejected.
                this.vendorPaymentsService
                    .rejectInReviewNewVendor(this.modalData.selected, this.rejectForm.controls?.rejectReason.value)
                    .subscribe({
                        next: () => {
                            this.isRejected = true;
                            this.closeModal();
                        },
                        error: (error) => this.handleError(error),
                    });

                break;
            }
            case 'newNote': {
                this.vendorPaymentsService
                    .addNote(this.modalData.selected, this.noteForm.controls?.noteText.value)
                    .subscribe({
                        next: () => {
                            this.isNoteCreated = true;
                            this.closeModal();
                        },
                        error: (error) => this.handleError(error),
                    });

                break;
            }
            case 'export': {
                const exportConfig: ExportConfig = {
                    filename: this.exportForm.controls?.filename.value,
                    ids: this.modalData.exportIds,
                    exportas: 'xlsx',
                };

                let filename = 'temp.xlsx';
                this.vendorPaymentsService.export(exportConfig).subscribe({
                    next: (res: any) => {
                        const errorMessage = `${res?.statusText}`;

                        switch (res.status) {
                            case 200:
                            case 204:
                                filename = res.headers.get('Content-Disposition')?.split('filename=')[1].split(';')[0];
                                if (!filename) {
                                    filename = 'default filename ' + new Date().toISOString();
                                }

                                break;
                            case 400:
                            case 401:
                            case 403:
                            default:
                                throw new Error(errorMessage);
                        }

                        let binaryData = [];
                        binaryData.push(res.body);
                        let downloadLink = document.createElement('a');
                        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: res.type }));
                        if (filename) {
                            downloadLink.setAttribute('download', filename);
                        }
                        document.body.appendChild(downloadLink);
                        downloadLink.click();

                        this.exported = true;
                        this.closeModal();
                    },
                    error: (error) => this.handleError(error),
                });

                break;
            }
            case 'reverse': {
                this.vendorPaymentsService
                    .reverseRejection(this.modalData.selected, this.noteForm.controls?.noteText.value)
                    .subscribe({
                        next: () => {
                            this.isReversed = true;
                            this.closeModal();
                        },
                        error: (error) => this.handleError(error),
                    });

                break;
            }
            case 'hide': {
                this.vendorPaymentsService.hideVendor(this.modalData.selected).subscribe({
                    next: () => {
                        this.hidden = true;
                        this.closeModal();
                    },
                    error: (error) => this.handleError(error),
                });

                break;
            }
            default: {
                console.error('ModalSimpleComponent.submit() unknown action:', this.modalData.action);
            }
        }
    }

    public isRejectDisabled(): boolean {
        return !this.rejectForm.valid;
    }

    public isNoteDisabled(): boolean {
        return !this.noteForm.valid;
    }

    public isReversalDisabled(): boolean {
        return !this.noteForm.valid;
    }

    public isExportDisabled(): boolean {
        return !this.exportForm.valid;
    }

    private handleError(error: HttpErrorResponse): void {
        console.error(error);
        this.snackBar.open('Server error', undefined, {
            duration: 10000,
            panelClass: ['swatch-red'],
        });
    }
}
