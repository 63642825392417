import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { InputTextModule } from 'primeng/inputtext';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ButtonModule } from 'primeng/button';
import { VendorPaymentsRoutingModule } from './vendor-payments.routing.module';
import { VendorPaymentsComponent } from './vendor-payments.component';
import { ModalComponent } from './modal/modal.component';
import { TableComponent } from './table/table.component';
import { SliderPanelComponent } from './slider-panel/slider-panel.component';
import { ModalSimpleComponent } from './modal-simple/modal-simple.component';
import { ModalReviewComponent } from './modal-review/modal-review.component';
import { SharedModule } from '../components/shared.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    declarations: [
        VendorPaymentsComponent,
        TableComponent,
        SliderPanelComponent,
        ModalComponent,
        ModalSimpleComponent,
        ModalReviewComponent,
    ],
    imports: [
        VendorPaymentsRoutingModule,
        CommonModule,
        InputTextModule,
        MatButtonModule,
        MatIconModule,
        MatSortModule,
        MatTableModule,
        ReactiveFormsModule,
        CoreModule,
        MatCardModule,
        MatCheckboxModule,
        MatDividerModule,
        MatFormFieldModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        MatSnackBarModule,
        MatTooltipModule,
        ButtonModule,
        MatTabsModule,
        MatDialogModule,
        MatMenuModule,
        SharedModule,
        MatProgressSpinnerModule,
    ],
})
export class VendorPaymentsModule {}
