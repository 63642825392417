<div *ngIf="(fetchingUsers)" class="fetching-files">
    <mat-spinner></mat-spinner>
</div>
<div class="header-bar" id="header-bar-create">
    <div class="header-text-div">
        <span class="header-text" id="header-text-create">{{ userFormTitle }}</span>
    </div>
</div>
<div class="create-user-main">
    <form (ngSubmit)="onSubmit(userForm)" [formGroup]="userForm" class="user-form" id="user-form">
        <div *ngIf="mode === CreateEditUserTypes.ADD_EXISTING_USER" class="grouping">
            <div class="info-section">
                <span class="form-primary-text">Select User</span>
                <span class="form-secondary-text">Contact information that syncs across applications</span>
            </div>
            <div class="form-fields-group">
                <div class="form-fields-group">
                    <div class="form-fields">
                        <div class="add-existing-user-fields-container">
                            <div class="text-field">
                                <mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic">
                                    <mat-label>Existing User</mat-label>
                                    <mat-select (selectionChange)="onAddExistingUserChange($event)"
                                                formControlName="existingUser">
                                        <mat-select-trigger>
                                            {{ userToEdit?.userUi.firstName }} {{ userToEdit?.userUi?.lastName }}
                                        </mat-select-trigger>
                                        <mat-option class="role-underline" [value]="CreateNewUserRecord.id">
                                          <div class="role-options">{{ CreateNewUserRecord.firstName }} {{ CreateNewUserRecord.lastName }}</div>
                                          <div class="role-description">{{ CreateNewUserRecord.email }}</div>
                                        </mat-option>
                                        <mat-divider></mat-divider>
                                        <mat-option *ngFor='let user of addExistingUserRecords;'
                                                    [value]="user.id">
                                            <div class="role-options">{{ user.firstName }} {{ user.lastName }}</div>
                                            <div class="role-description">{{ user.email }}</div>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="mode === CreateEditUserTypes.ADD_EXISTING_USER ? addExistingUserSelected : true"
             class="grouping">
            <div class="info-section">
                <span class="form-primary-text">Personal Information</span>
                <span class="form-secondary-text">Contact information that syncs across applications</span>
            </div>
            <div class="form-fields-group">
                <div class="form-fields">
                    <div class="personal-info-fields-container">
                        <div class="text-field">
                            <mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic">
                                <mat-label>First Name</mat-label>
                                <input data-testid="firstName" formControlName="firstName" matInput>
                                <mat-error *ngIf="userForm.get('firstName').invalid">
                                    First name required
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="text-field">
                            <mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic">
                                <mat-label>Last Name</mat-label>
                                <input data-testid="lastName" formControlName="lastName" matInput>
                                <mat-error *ngIf="userForm.get('lastName').invalid">
                                    Last name required
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="text-field">
                            <mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic">
                                <mat-label>Email Address</mat-label>
                                <input data-testid="email" formControlName="email" matInput>
                                <mat-error
                                    *ngIf="userForm.get('email').invalid && !userForm.controls.email.hasError('emailAlreadyExists')">
                                    Please enter a valid email address
                                </mat-error>
                                <mat-error *ngIf="userForm.controls.email.hasError('emailAlreadyExists')">
                                    User already exists within the system
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="mode === CreateEditUserTypes.ADD_EXISTING_USER ? addExistingUserSelected : true"
             class="grouping">
            <div class="info-section">
                <span class="form-primary-text">Deal Dashboard Access</span>
                <span class="form-secondary-text">Manage user roles on Deals in Deal Dashboard</span>
            </div>
            <div class="form-fields-group">
                <div class="form-fields">
                    <div class="add-access-button-container">
                        <div (click)="addDealAccessClick()" class="add-access-button">
                            <span class="form-fields-button-label">Add Deal Access</span>
                        </div>
                    </div>
                    <div class="access-fields-container" formArrayName="dealAccess">
                        <ng-container *ngFor="let dealAccessForm of dealAccess.controls; let i = index">
                            <div [formGroup]="$any(dealAccessForm)"
                                 attr.data-testid="deal-access-row-{{i}}"
                                 class="access-form">

                                <mat-form-field appearance="outline" class="access-form-field" floatLabel="always">
                                    <mat-spinner *ngIf="fetchingDealsUsers" matSuffix class="fetching-search-results" diameter="32"></mat-spinner>
                                    <mat-label>Deal Access</mat-label>
                                    <mat-chip-grid #chipGrid aria-label="Deal dashboard deal access selection">
                                        <mat-chip-row *ngFor="let deal of ddUserAccess[i].deals; trackBy:identifyDeal"
                                                      [disabled]="ddUserAccess[i].inputDisabled"
                                                      (removed)="removeDealChip(deal, i)"
                                        >
                                            <span matTooltip="{{ deal.name }}"
                                                  matTooltipShowDelay="500"
                                                  matTooltipHideDelay="500"
                                                  matTooltipPosition="above"
                                            >
                                              {{ deal.name }}
                                          </span>
                                            <button [attr.aria-label]="'remove ' + deal.name" matChipRemove>
                                                <mat-icon>cancel</mat-icon>
                                            </button>
                                        </mat-chip-row>
                                    </mat-chip-grid>
                                    <input #dealDashboardDealInput formControlName="deal" matInput
                                           (matChipInputTokenEnd)="addDealChip($event)"
                                           [disabled]="ddUserAccess[i].inputDisabled"
                                           [matAutocomplete]="autoDeal"
                                           [matChipInputFor]="chipGrid"
                                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                           attr.data-testid="deal-access-row-deal-{{i}}"
                                    >
                                    <mat-autocomplete #autoDeal="matAutocomplete"
                                                      (optionSelected)="dealSelected($event, i)">
                                        <mat-option
                                            *ngFor="let deal of filteredDealDashboardDeals | async; trackBy:identifyDeal"
                                            [value]="deal">{{ deal.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-error *ngIf="dealAccessForm.get('deal').invalid">
                                        Deal required
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" class="access-form-field" floatLabel="always">
                                    <mat-label>Role</mat-label>
                                    <mat-select (selectionChange)="onRoleChange($event, 'dealAccess', i)"
                                                data-testid="role"
                                                formControlName="role">
                                        <mat-select-trigger [class.revoke-access]="ddUserAccess[i].role?.id === dealRoleRecordRevokeAccess.id">
                                            {{ ddUserAccess[i].role?.name }}
                                        </mat-select-trigger>
                                        <mat-option *ngFor='let role of dealDashboardRoleRecords;'
                                                    [value]="role.id">
                                            <div class="role-options">{{ role.name }}</div>
                                        </mat-option>
                                        <mat-option
                                            *ngIf="ddUserAccess[i].action !== FormControlActionAdd"
                                            [value]="dealRoleRecordRevokeAccess.id">
                                            <div class="revoke-access">{{ dealRoleRecordRevokeAccess.name }}</div>
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="dealAccessForm.get('role').invalid">
                                        Role required
                                    </mat-error>
                                </mat-form-field>

                                <div class="x-dismiss-div">
                                    <span (click)="deleteDealAccessRow(i)"
                                          attr.data-testid="x-dismiss-deal-access-row-{{i}}"
                                          class="material-symbols-outlined x-dismiss"
                                          id="x-dismiss-dd"
                                    >close
                                    </span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="mode === CreateEditUserTypes.ADD_EXISTING_USER ? addExistingUserSelected : true"
             class="grouping">
            <div class="info-section">
                <span class="form-primary-text">Shareholder Portal Access</span>
                <span
                    class="form-secondary-text">Manage Shareholder user roles on Deals in Shareholder Portal</span>
            </div>
            <div class="form-fields-group">
                <div class="form-fields">
                    <div class="add-access-button-container">
                        <div (click)="addShareholderAccessClick()" class="add-access-button">
                            <span class="form-fields-button-label">Add Shareholder Access</span>
                        </div>
                    </div>
                    <div class="access-fields-container" formArrayName="shareholderAccess">
                        <ng-container
                            *ngFor="let shareholderAccessForm of shareholderAccess.controls; let i = index">
                            <div [formGroup]="$any(shareholderAccessForm)"
                                 attr.data-testid="shareholder-access-row-{{i}}"
                                 class="access-form">

                                <mat-form-field appearance="outline" class="access-form-field" floatLabel="always">
                                    <mat-spinner *ngIf="fetchingShareholders" matSuffix class="fetching-search-results" diameter="32"></mat-spinner>
                                    <mat-label>Shareholder</mat-label>
                                    <input #shareholderInput class="access-form-text-input" formControlName="shareholder"
                                           matInput
                                           matTooltip="{{ shareHolderUserAccess[i]?.shareholder?.shareholder }}"
                                           matTooltipShowDelay="500"
                                           matTooltipHideDelay="500"
                                           matTooltipPosition="above"
                                           [matTooltipDisabled]="shareHolderUserAccess[i] == null"
                                           [disabled]="shareHolderUserAccess[i].inputDisabled"
                                           [matAutocomplete]="shareholderAuto"
                                           attr.data-testid="shareholder-access-row-shareholder-{{i}}"
                                    >
                                    <mat-autocomplete #shareholderAuto="matAutocomplete"
                                                      (optionSelected)="shareholderSelected($event, i)">
                                        <mat-option
                                            *ngFor="let shareholder of filteredShareholderRecords | async; trackBy:identifyShareholder"
                                            [value]="shareholder">{{ shareholder.shareholder }}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-error *ngIf="shareholderAccessForm.get('shareholder').invalid">
                                        Shareholder required
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" class="access-form-field" floatLabel="always">
                                    <mat-spinner *ngIf="fetchingShareholderDeals" matSuffix class="fetching-search-results" diameter="32"></mat-spinner>
                                    <mat-label>Deal</mat-label>
                                    <input #shareholderDealInput class="access-form-text-input" formControlName="deal"
                                           matInput
                                           matTooltip="{{ shareHolderUserAccess[i]?.deal?.name }}"
                                           matTooltipShowDelay="500"
                                           matTooltipHideDelay="500"
                                           matTooltipPosition="above"
                                           [matTooltipDisabled]="shareHolderUserAccess[i] == null"
                                           [disabled]="shareHolderUserAccess[i].inputDisabled"
                                           [matAutocomplete]="autoShareholderDeal"
                                           attr.data-testid="shareholder-access-row-deal-{{i}}"
                                    >
                                    <mat-autocomplete #autoShareholderDeal="matAutocomplete"
                                                      (optionSelected)="shareholderDealSelected($event, i)">
                                        <mat-option
                                            *ngFor="let deal of filteredShareholderPortalDeals | async; trackBy:identifyDeal"
                                            [value]="deal">{{ deal.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-error *ngIf="shareholderAccessForm.get('deal').invalid">
                                        Deal required
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" class="access-form-field" floatLabel="always">
                                    <mat-label>Role</mat-label>
                                    <mat-select (selectionChange)="onRoleChange($event, 'shareholderAccess', i)"
                                                data-testid="role"
                                                formControlName="role">
                                        <mat-select-trigger [class.revoke-access]="shareHolderUserAccess[i].role?.id === shareholderRoleRecordRevokeAccess.id">
                                            {{ shareHolderUserAccess[i].role?.name }}
                                        </mat-select-trigger>
                                        <mat-option *ngFor='let role of shareholderRoleRecords;'
                                                    [value]="role.id">
                                            <div class="role-options">{{ role.name }}</div>
                                        </mat-option>
                                        <mat-option
                                            *ngIf="shareHolderUserAccess[i].action !== FormControlActionAdd"
                                            [value]="shareholderRoleRecordRevokeAccess.id">
                                            <div class="revoke-access">{{ shareholderRoleRecordRevokeAccess.name }}</div>
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="shareholderAccessForm.get('role').invalid">
                                        Role required
                                    </mat-error>
                                </mat-form-field>

                                <div class="x-dismiss-div">
                                    <span (click)="deleteShareholderAccessRow(i)"
                                          attr.data-testid="x-dismiss-shareholder-access-row-{{i}}"
                                          class="material-symbols-outlined x-dismiss"
                                          id="x-dismiss-sharp"
                                    >close
                                    </span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="user-form-actions">
            <div *ngIf="userForm.invalid && userForm.touched" class="user-form-alert-div">
                <mat-icon aria-hidden="false" aria-label="error icon">error_outline</mat-icon>
                <div class="user-form-alert-text">
                    Please complete all required fields
                </div>
            </div>
            <div class="user-form-actions-buttons">
                <div (click)="cancelClicked()" class="cancel-button"
                     data-testid="user-form-cancel-button"
                >
                    <span class="actions-button-label">Cancel</span>
                </div>
                <div (click)="onSubmit(userForm)" class="create-save-button"
                     data-testid="user-form-save-button">
                    <span class="actions-button-label">Save</span>
                </div>
            </div>
        </div>
    </form>
</div>

