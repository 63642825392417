import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ActivityRecord } from '../shared';

@Component({
    selector: 'app-slider-activity-details',
    templateUrl: './slider-activity-details.component.html',
    styleUrls: ['./slider-activity-details.component.scss'],
})
export class SliderActivityDetailsComponent {
    @HostListener('document:keydown.escape', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
        this.closeSlider();
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        // If this element does not contain the target element, then a click
        // happened outside the panel and should be closed. But because this is
        // listening to all document clicks, including the click to open the
        // slider, the click count needs to be reset after (re)opening. This is
        // to effectively ignore initial click to open the container.
        if (!this.eRef.nativeElement.contains(event.target) && this.clickCount > 0 && this.isOpen) {
            this.closeSlider();
        }

        this.clickCount++;
    }

    public get isOpen(): boolean {
        return this._isOpen;
    }
    @Input() set isOpen(value: boolean) {
        this._isOpen = value;
        if (this._isOpen) {
            this.clickCount = 0;
        }
    }

    @Input() activity: ActivityRecord;
    @Output() closeSliderEvent = new EventEmitter<any>();
    @Output() searchEvent = new EventEmitter<string>();

    private clickCount = 0;
    private _isOpen = false;

    constructor(private eRef: ElementRef) {}

    public closeSlider() {
        this.closeSliderEvent.next(null);
    }

    public handleUserHistoryClick() {
        this.searchEvent.next(this.activity.userName);
    }

    public capitalizeFirstLetter(string) {
        return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
    }
}
