export enum IntAdmPermissions {
    userAdd = 'intadm-user-add',
    userCreate = 'intadm-user-create',
    userEdit = 'intadm-user-edit',
    userRemove = 'intadm-user-remove',
    userBlock = 'intadm-user-block',
    userUnblock = 'intadm-user-unblock',
    userDeactivate = 'intadm-user-deactivate',
    userResetMfa = 'intadm-user-reset-mfa',
    userSendPwReset = 'intadm-user-send-pw-reset',
    userResendInv = 'intadm-user-resend-inv',
}
