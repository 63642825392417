import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigurationService } from '../../core/services/configuration.service';
import { catchError, Observable } from 'rxjs';
import { handleMissingServerError } from '../../core/utils/http-response-handlers';
import { BankValidationRequest, BankValidationResponse } from './bank-validation.types';
import { VendorRecord } from './vendor.types';

@Injectable({
    providedIn: 'root',
})
export class BankValidationService {
    private serverName: string = 'MS Bank Validation Service';

    constructor(
        private http: HttpClient,
        private configurationService: ConfigurationService,
    ) {}

    public validateBank(vendor: VendorRecord): Observable<BankValidationResponse[]> {
        const requestUrl = [this.configurationService.envConfig.getValue().bankValidationHost, 'validate-bank'].join(
            '/',
        );

        // Map incoming brokerage account to a bank validation request.
        const bankValidationRequest: BankValidationRequest = {
            accountNumber: vendor.accountNumber,
            countryCode: vendor.payeeCountry,
            nationalId: [vendor.abaSwift],
            serviceId: null,
        };

        const options = {
            headers: { 'Content-Type': 'application/json' },
            params: new HttpParams().set('OverrideCache', false),
        };

        return this.http
            .post(requestUrl, bankValidationRequest, options)
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }
}
