import { AbstractControl, FormControl } from '@angular/forms';
import { RoleRecord } from './roles.types';
import { DealPartialRecord } from './deal.types';
import { ShareholderPartialRecord } from './shareholder.types';

export interface UserRecord {
    id?: string;
    nsId?: number;
    nsLastModified?: number;
    firstName: string;
    lastName: string;
    status?: string;
    email: string;
    phoneNr?: string;
    products?: ProductsUi[];
    auth0Id: string;
    role?: string;
    mfaMethods?: { [key: string]: AuthenticationMethod };
    subsidiaries?: string[];
}

// Used by the UI to control display of the Deal Dashboard associated deals.
export type DDUserAccess = {
    action: UserAccessAction_DD;
    inputDisabled: boolean;
    deals: DealPartialRecord[];
    role: RoleRecord;
};

// Used by the UI to control display of the SHARP associated deals.
export type ShareHolderUserAccess = {
    action: UserAccessAction_SHARP;
    inputDisabled: boolean;
    shareholder: ShareholderPartialRecord;
    deal: DealPartialRecord;
    role: RoleRecord;
};

// Sent from the API to the UI
export type UserFullUi = {
    userUi: UserRecord;
    dealDashboardAccess: UserPartialDDAccessRecord[];
    shareholderPortalAccess: UserPartialSHARPAccessRecord[];
};

export type UserPartialDDAccessRecord = {
    deal: DealPartialRecord;
    role: RoleRecord; // TODO This may need to change, depending on what IDD has for roles.
};

export type UserPartialSHARPAccessRecord = {
    shareholder: ShareholderPartialRecord;
    deal: DealPartialRecord;
    role: RoleRecord; // TODO This may need to change, depending on what SHARP has for roles.
};

export enum UserAccessAction_DD {
    Add = 'a',
    Delete = 'd',
    Update = 'u', // Only the Role may be updated; otherwise it is a delete.
    NoChange = 'nc',
}

export enum UserAccessAction_SHARP {
    Add = 'a',
    Delete = 'd',
    Update = 'u', // Only the Role may be updated; otherwise it is a delete.
    NoChange = 'nc',
}

export interface DealDashboardAccessRecord {
    action: UserAccessAction_DD;
    dealIds: number[];
    roleId: number;
}

export interface ShareholderPortalAccessRecord {
    action: UserAccessAction_SHARP;
    shareholderId: number;
    dealId: number;
    roleId: number;
}

export interface UserDeal {
    user: UserRecord;
    dealDashboardAccess: DealDashboardAccessRecord[];
    shareholderPortalAccess: ShareholderPortalAccessRecord[];
    byId: string;
    byName: string;
}

export interface DealUserAccess {
    id: number;
    parent_id: number;
    parent_type: string;
    child_id: number;
    child_type: string;
    role_id: number;
}

export type ProductsUi = {
    id: number;
    name: string;
    abbr: string;
};

export const UserSearchColumns = ['email', 'firstName', 'lastName', 'status'];
export const UserSearchColumnsFromActivity = ['email', 'firstName', 'lastName'];

export enum UserStatus {
    Invited = 'invited',
    Active = 'active',
    Blocked = 'blocked',
    Deactivated = 'deactivated',
}

export enum UserActionEnum {
    activity = 'activity',
    resetMfa = 'resetMfa',
    sendPwReset = 'sendPwReset',
    deactivate = 'deactivate',
    resendInv = 'resentInv',
    revokeAccessDD = 'revokeAccessDD',
    revokeAccessSH = 'revokeAccessSH',
    revokeAccessCH = 'revokeAccessCH',
    revokeAllAccess = 'revokeAllAccess',
    block = 'block',
    unblock = 'unblock',
}

export enum ModalConfigButtonClick {
    Cancel = 'cancel',
    Confirm = 'confirm',
}

export type UserActionConfig = {
    arrayIndex: number;
    action: UserActionEnum;
};

export enum UserAuth0Actions {
    sendPwReset = 'sendPwReset',
    deactivate = 'deactivate',
    resendInv = 'resentInv',
    block = 'block',
}

export type Auth0APIConfig = {
    userId: string;
    action: UserAuth0Actions;
    byId: string;
    byName: string;
    mfaMethodId?: string;
};

export type DealAccessFormType = AbstractControl<{
    action: FormControl<string | null>;
    deal: FormControl<string | null>;
    role: FormControl<number | null>;
}>;

export type ShareholderAccessFormType = AbstractControl<{
    action: FormControl<string | null>;
    shareholder: FormControl<string | null>;
    deal: FormControl<string | null>;
    role: FormControl<number | null>;
}>;

export type AuthenticationMethod = {
    id: string;
    type: AuthenticationMethodsTypeEnum;
    confirmed?: boolean;
    phone_number?: string;
    created_at: string;
};

export enum AuthenticationMethodsTypeEnum {
    totp = 'totp',
    phone = 'phone',
}
