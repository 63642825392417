<div id="giact-import" class="file-action">
  <form [formGroup]="identityServiceForm" (ngSubmit)="submit()">
    <div class="identity-service-selection">
      <label id="identity-service-radio-group">Identity Service Requested</label>
      <mat-radio-group aria-labelledby="identity-service-radio-group" aria-label="Select an option"
                       formControlName="identityTargetService" required color="primary">
        <mat-radio-button [value]="SelectedService.ENABLE_EMAIL_VERIFICATION_PLUS_ADDRESS">Email, Address, and IP
        </mat-radio-button>
        <mat-radio-button [value]="SelectedService.ENABLE_BANK_VERIFICATION">Bank Information</mat-radio-button>
      </mat-radio-group>
    </div>
    <app-file-upload formControlName="identityCsv" [fileType]="'.csv'"></app-file-upload>
    <div class="progress-bar">
      <mat-progress-bar mode="determinate" [value]="progressStatus"></mat-progress-bar>
    </div>
    <button [disabled]="!(identityServiceForm.valid && hasReviewedDuplicatesAndErrors && hasVerifyRights())" mat-raised-button type="submit">
      Submit
    </button>
  </form>
</div>

<ng-template #importExceptionConfirmDialog>
  <h2 mat-dialog-title>Import Exceptions</h2>
  <div mat-dialog-content>
    <div *ngIf="invalidRecords.length > 0">
      <h3>Invalid Records found:</h3>
      <table mat-table [dataSource]="invalidRecords" class="mat-elevation-z8 confirm-table">
        <ng-container matColumnDef="lineNumber">
          <th mat-header-cell *matHeaderCellDef> Line Number</th>
          <td mat-cell *matCellDef="let element"> {{element.lineNumber}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="error">
          <th mat-header-cell *matHeaderCellDef> Error</th>
          <td mat-cell *matCellDef="let element"> {{element.error}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="record">
          <th mat-header-cell *matHeaderCellDef> Record</th>
          <td mat-cell *matCellDef="let element"> {{element.record}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['lineNumber', 'error']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['lineNumber', 'error'];"></tr>
      </table>
      <mat-divider class="confirm-divider"></mat-divider>
    </div>
    <div *ngIf="duplicateRecords.length > 0">
      <h3>Duplicate Records found:</h3>
      <table mat-table [dataSource]="duplicateRecords" class="mat-elevation-z8 confirm-table">
        <ng-container matColumnDef="lineNumber">
          <th mat-header-cell *matHeaderCellDef> Line Number</th>
          <td mat-cell *matCellDef="let element"> {{element.lineNumber}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="matchedLineNumber">
          <th mat-header-cell *matHeaderCellDef> Duplicate Of</th>
          <td mat-cell *matCellDef="let element"> {{element.matchedLineNumber}} </td>
        </ng-container>

        <!-- Weight Column -->
<!--        <ng-container matColumnDef="record">-->
<!--          <th mat-header-cell *matHeaderCellDef> Record</th>-->
<!--          <td mat-cell *matCellDef="let element"> {{element.record}} </td>-->
<!--        </ng-container>-->

        <tr mat-header-row *matHeaderRowDef="['lineNumber', 'matchedLineNumber']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['lineNumber', 'matchedLineNumber'];"></tr>
      </table>
      <mat-divider class="confirm-divider"></mat-divider>
    </div>
  </div>
  <div mat-dialog-actions>
    <p class="confirm-exceptions-action-items">It looks like the file you are trying to import has data that may not work as intended.</p>
    <p *ngIf="validatedRecords">Would you like to import only the valid data, <b>excluding</b> the records reported above?</p>
    <p class="confirm-exceptions-action-items" *ngIf="!validatedRecords">
      There are no valid records. Please choose a different file or change the targeted service.</p>
    <div class="confirm-exceptions-action-items">
      <button mat-raised-button (click)="onClickCancelImport()">Cancel</button>
      <button mat-raised-button (click)="onClickImport()" *ngIf="validatedRecords">Proceed</button>
    </div>
  </div>
</ng-template>

<ng-template #importWarningConfirmDialog>
  <h2 mat-dialog-title>Import Warnings</h2>
  <div mat-dialog-content>
    <div *ngIf="warningRecords.length > 0">
      <h3>Records with warnings found:</h3>
      <table mat-table [dataSource]="warningRecords" class="mat-elevation-z8 confirm-table">
        <ng-container matColumnDef="lineNumber">
          <th mat-header-cell *matHeaderCellDef> Line Number</th>
          <td mat-cell *matCellDef="let element"> {{element.lineNumber}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="error">
          <th mat-header-cell *matHeaderCellDef> Warning</th>
          <td mat-cell *matCellDef="let element"> {{element.error}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="record">
          <th mat-header-cell *matHeaderCellDef> Record</th>
          <td mat-cell *matCellDef="let element"> {{element.record}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['lineNumber', 'error']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['lineNumber', 'error'];"></tr>
      </table>
      <mat-divider class="confirm-divider"></mat-divider>
    </div>
  </div>
  <div mat-dialog-actions>
    <p class="confirm-exceptions-action-items">It looks like the file you are trying to import has data that may not
      work as
      intended. Would you like to import the data, including the records with warnings reported above?</p>
    <div class="confirm-exceptions-action-items">
      <button mat-raised-button (click)="onClickCancelImport()">Cancel</button>
      <button mat-raised-button (click)="onClickAcceptWarnings()">Proceed</button>
    </div>
  </div>
</ng-template>
