import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { ActivityRecord } from '../shared';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-activity-table',
    templateUrl: './activity-table.component.html',
    styleUrls: ['./activity-table.component.scss'],
})
export class ActivityTableComponent implements AfterViewInit, OnChanges {
    @Input() activityRecords: ActivityRecord[];
    @Input() searchText: string;
    @Input() pageIndex: number;
    @Input() pageSize: number;
    @Input() initialSort: MatSortable;
    @Input() totalRecords: number = 0;
    @Input() fetching: boolean;
    @Output() pageEvent = new EventEmitter<PageEvent>();
    @Output() sortEvent = new EventEmitter<Sort>();
    @ViewChild(MatSort) sort: MatSort;

    public dataSource: MatTableDataSource<ActivityRecord>;
    public displayedColumns: string[] = ['expand', 'username', 'usertype', 'activity', 'feature', 'timestamp'];
    public expandedElements: ActivityRecord[] = [];

    constructor(private cdr: ChangeDetectorRef) {
        // Assign the data to the data source for the table to render
        this.dataSource = new MatTableDataSource(this.activityRecords);
    }

    ngAfterViewInit() {
        this.sort?.sort({ ...this.initialSort, disableClear: false });
        this.dataSource.sort = this.sort;
        this.cdr.detectChanges();
    }

    ngOnChanges(_: SimpleChanges): void {
        this.dataSource = new MatTableDataSource(this.activityRecords);
    }

    public toggleRow(event) {
        const index = this.expandedElements.findIndex((x) => x.id == event.id);
        if (index === -1) {
            this.expandedElements.push(event);
        } else {
            this.expandedElements.splice(index, 1);
        }
    }

    public isExpanded(row): boolean {
        return this.expandedElements.findIndex((x) => x.id == row.id) !== -1;
    }

    public getFormattedDate(time: number): string {
        const date = new Date(time);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const newMinutes = minutes < 10 ? '0' + minutes : minutes.toString();
        const dtf = Intl.DateTimeFormat(undefined, { timeZoneName: 'short' });

        // Format the date to parts, and pull out the value of the time zone name
        const result = dtf.formatToParts(date).find((part) => part.type == 'timeZoneName').value;

        return (
            date.toLocaleString('default', { month: 'long' }) + // long month
            ' ' +
            date.getDate() +
            ' ' +
            date.getFullYear() +
            ' ' +
            hours +
            ':' +
            newMinutes +
            ' ' +
            (hours >= 12 ? 'PM' : 'AM') +
            ' ' +
            result
        );
    }

    public handlePageEvent(e: PageEvent) {
        this.pageEvent.emit(e);
    }

    public handleSortChange(s: Sort) {
        this.sortEvent.emit(s);
    }
}
