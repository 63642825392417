import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { IEnvConfiguration, IFlagsConfiguration } from '../configuration.model';

@Injectable({
    providedIn: 'root',
})
export class FlagsService {
    private featureFlags: { [featureName: string]: number };
    private environmentFlag: string;
    private envConfig: IEnvConfiguration;

    constructor(private configurationService: ConfigurationService) {
        configurationService.envConfig.subscribe((envConfig) => {
            this.envConfig = envConfig;
            this.featureFlags = this.envConfig.flags.feature;
            this.environmentFlag = this.envConfig.environment;
        });
    }

    public isFeatureFlagEnabled(flag: string) {
        return this.featureFlags && this.featureFlags[flag];
    }

    public fetchFlagStatus(flag: string) {
        return this.featureFlags && this.featureFlags[flag];
    }

    public fetchEnvironmentFlag() {
        return this.environmentFlag;
    }
}
