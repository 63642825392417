<div class="header">
    Admin Home
</div>
<div *ngIf="(allowedApps$ | async | json) != '{}'" class="page">
    <div class="main">
        <ng-container *ngFor="let allowedApp of allowedApps$ | async | keyvalue">
            <a
                *appFlags="allowedApp.value.flag"
                [routerLink]="allowedApp.value.route"
                class="main__app-card-link"
                (click)="appClicked(allowedApp.key)"
            >
                <mat-card appearance="outlined" class="main__app-card">
                    <mat-card-subtitle class="app-card__title">
                        {{allowedApp.value.label | uppercase}}
                    </mat-card-subtitle>
                    <mat-card-content class="app-card__content">
                        <span class="material-icons-outlined action-icon">{{allowedApp.value.icon}}</span>
                    </mat-card-content>
                </mat-card>
            </a>
        </ng-container>
    </div>
</div>
<div *ngIf="(allowedApps$ | async | json) == '{}'" class="page">
    <div class="container">
        <div class="center">
            <p class="no_authorized_app">No authorized applications</p>
        </div>
    </div>
</div>
