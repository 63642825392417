// WARNING: These are defined as Postgres enum types as well, so do not change
// these without creating a new flyway script to change the enum and existing
// database values.
export enum ReviewStatusType {
    APPROVED = 'APPROVED',
    EDIT_REVIEW = 'EDIT_REVIEW',
    DELETE_REVIEW = 'DELETE_REVIEW',
    DELETED = 'DELETED',
    NEW = 'NEW',
    REJECTED = 'REJECTED',
    REVERSAL_REVIEW = 'REVERSAL_REVIEW',
}
