export interface VendorRecord {
    id?: number;
    dealId?: number;
    exRecId?: number;

    // Payee Information
    payeeName?: string;
    email?: string;
    accountTitle?: string;
    accountNumber?: string;
    currency?: string;
    createdDate?: number;
    shrhldId?: number;

    // Banking Details
    abaSwift?: string;
    sortCode?: string;
    bankName?: string;
    bankCountry?: string;
    ffcAcct?: string;
    ffcName?: string;
    payApprovedDate?: number;

    // Payee Address
    payeeAddress1?: string;
    payeeAddress2?: string;
    payeeAddress3?: string;
    payeeCity?: string;
    payeeState?: string;
    payeePostalCode?: string;
    payeeCountry?: string;

    // Vendor Payment Screening
    vpsReq?: boolean;
    vpsStatus?: string;
    vpsDt?: number;

    // Intermediary Bank Info
    imbSwiftbic?: string;
    imbBankname?: string;

    // Status and misc.
    status?: string; // Approved, Edit-Review, Delete-Review, Deleted, New, Rejected
    reviewType?: string;
    changeSubmittedById?: string;
    inEdit?: boolean;
    dealCount?: number;

    // Reviewer / Approver Info
    reviewedBy?: string;
    reviewedDt?: number;
    approvedBy?: string;
    approvedDt?: number;

    taxpayerId?: number;
    subsidiary?: string;

    lastModified?: number;
    updatedDt?: number;
}

// { "payeeName": [ { "name": "Smith LLC", "count": 3 }, { "name": "Langosh Funds", "count": 1 } ] }
export type FieldValueCount = Record<string, { name: string; count: number }[]>;

export type ModalConfigData = {
    title?: string;
    selected?: VendorRecord;
    action: 'create' | 'edit' | 'delete';
};

export type ModalReviewConfigData = {
    title?: string;
    selected?: VendorRecord;
    action: 'review';
};

export type VendorConfigWithNote = {
    vendor: VendorRecord;
    note: VendorNote;
};

export type VendorDetailConfig = {
    title?: string;
    selected?: VendorRecord;
    notes?: VendorNote[];
    primaryButtonTitle?: string;
    secondaryButtonTitle?: string;
    tertiaryButtonTitle?: string;
    submitDisabled?: boolean;
};

export enum VendorTabNames {
    IN_REVIEW = 'In Review',
    APPROVED = 'Approved',
    REJECTED = 'Rejected/Deleted',
}

export interface VendorNote {
    id?: number;
    vendorId?: number;
    exRecId?: number;
    note: string;
    createdDt?: number;
    createdBy?: string;
}

export type ExportConfig = {
    filename?: string;
    exportas?: string;
    ids?: number[];
};

export type HideVendorConfig = {
    id: number;
};
