import { Injectable } from '@angular/core';
import { catchError, Observable, Subscriber } from 'rxjs';
import { handleMissingServerError } from '../../core/utils/http-response-handlers';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigurationService } from '../../core/services/configuration.service';
import { AdminRequestHelper } from './user-access-request-helper';
import { DealRecord, RequestDealUserRole } from './deal.types';
import { Auth0APIConfig, UserDeal, UserRecord } from './user.types';
import { ActivityRecord } from './activity.types';
import { RoleRecord } from './roles.types';
import { Permission, ShareholderUserAccess, ShareholderRecord } from './shareholder.types';

@Injectable({
    providedIn: 'root',
})
export class UserAccessService {
    private serverName: string = 'Internal Admin Service API';
    private INTERNAL_ADMIN_SERVICE_ENDPOINT = 'internal-admin';

    constructor(
        private http: HttpClient,
        private configurationService: ConfigurationService,
    ) {}

    public getDeals(helper: AdminRequestHelper): Observable<DealRecord[]> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().internalAdminHost,
            this.INTERNAL_ADMIN_SERVICE_ENDPOINT,
        ].join('/');

        return this.http
            .get(requestUrl, { params: helper.toHttpParams(), observe: 'response' })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public getUsers(helper: AdminRequestHelper): Observable<UserRecord[]> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().internalAdminHost,
            this.INTERNAL_ADMIN_SERVICE_ENDPOINT,
        ].join('/');

        return this.http
            .get(requestUrl, { params: helper.toHttpParams(), observe: 'response' })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public getActivity(helper: AdminRequestHelper): Observable<ActivityRecord[]> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().internalAdminHost,
            this.INTERNAL_ADMIN_SERVICE_ENDPOINT,
        ].join('/');

        return this.http
            .get(requestUrl, { params: helper.toHttpParams(), observe: 'response' })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public getRoles(helper: AdminRequestHelper): Observable<RoleRecord[]> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().internalAdminHost,
            this.INTERNAL_ADMIN_SERVICE_ENDPOINT,
        ].join('/');

        return this.http
            .get(requestUrl, { params: helper.toHttpParams(), observe: 'response' })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public getShareholders(helper: AdminRequestHelper): Observable<ShareholderRecord[]> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().internalAdminHost,
            this.INTERNAL_ADMIN_SERVICE_ENDPOINT,
        ].join('/');

        return this.http
            .get(requestUrl, { params: helper.toHttpParams(), observe: 'response' })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public getShareholdersUserAccessRecords(helper: AdminRequestHelper): Observable<RoleRecord[]> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().internalAdminHost,
            this.INTERNAL_ADMIN_SERVICE_ENDPOINT,
        ].join('/');

        return this.http
            .get(requestUrl, { params: helper.toHttpParams(), observe: 'response' })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public getDealUserAccessRecords(helper: AdminRequestHelper): Observable<{}> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().internalAdminHost,
            this.INTERNAL_ADMIN_SERVICE_ENDPOINT,
        ].join('/');

        return this.http
            .get(requestUrl, { params: helper.toHttpParams(), observe: 'response' })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public createUser(user: UserDeal): Observable<{}> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().internalAdminHost,
            this.INTERNAL_ADMIN_SERVICE_ENDPOINT,
            'user',
        ].join('/');

        return this.http
            .post(requestUrl, user, { headers: { 'Content-Type': 'application/json' } })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public editUser(user: UserDeal): Observable<{}> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().internalAdminHost,
            this.INTERNAL_ADMIN_SERVICE_ENDPOINT,
            'user',
        ].join('/');

        return this.http
            .put(requestUrl, user, {
                observe: 'response',
                headers: { 'Content-Type': 'application/json' },
            })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public deleteUserFromDeal(config: RequestDealUserRole): Observable<{}> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().internalAdminHost,
            this.INTERNAL_ADMIN_SERVICE_ENDPOINT,
            `dealUserAccess`,
        ].join('/');

        let params = new HttpParams()
            .set('userId', config.userId)
            .set('dealId', config.dealId)
            .set('byId', config.byId)
            .set('byName', config.byName);

        return this.http
            .delete(requestUrl, { params, observe: 'response' })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public deleteShareholderUserAccess(userAccess: ShareholderUserAccess): Observable<{}> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().internalAdminHost,
            this.INTERNAL_ADMIN_SERVICE_ENDPOINT,
            `shareholderUserAccess`,
        ].join('/');

        return this.http
            .delete(requestUrl, {
                headers: { 'Content-Type': 'application/json' },
                body: userAccess,
                observe: 'response',
            })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public addExistingUser(user: UserDeal): Observable<{}> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().internalAdminHost,
            this.INTERNAL_ADMIN_SERVICE_ENDPOINT,
            'addExistingUser',
        ].join('/');

        return this.http
            .post(requestUrl, user, { headers: { 'Content-Type': 'application/json' } })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public userAuth0(auth0Config: Auth0APIConfig): Observable<{}> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().internalAdminHost,
            this.INTERNAL_ADMIN_SERVICE_ENDPOINT,
            'auth0',
        ].join('/');

        return this.http
            .put(requestUrl, auth0Config, {
                observe: 'response',
                headers: { 'Content-Type': 'application/json' },
            })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }
}
