<div *ngIf="(fetching)" class="fetching-files">
    <mat-spinner></mat-spinner>
</div>
<div class="bank-file-container">
    <div class="header-bar" id="header-bar">
        <div class="header-text-div">
            <span class="header-text" id="header-text">Bank Files</span>
        </div>
    </div>
    <div class="bank-file-page">
        <div class="bank-table-toolbar">
            <div class="bfc-breadcrumbs">
                <div *ngFor="let part of pathParts; index as idx"
                     class="bfc-breadcrumb-group"
                >
                    <span class="bfc-breadcrumb-divider">/</span>
                    <span (click)="pathClick(part)"
                          *ngIf="idx !== pathParts.length - 1 && part !== '/'"
                          class="bfc-breadcrumb bfc-breadcrumb-not-last bfc-breadcrumb-divider"
                    >{{ part }}</span>
                    <span *ngIf="idx === pathParts.length - 1 && part !== '/'"
                          class="bfc-breadcrumb bfc-breadcrumb-last"
                    >{{ part }}</span>
                </div>
            </div>
            <div class="bfc-actions">
                <div (click)="handleDownloadClick()"
                     [class.download-in-progress]="downloadInProgress"
                     class="header-download-span"
                >
                    <span class="material-symbols-outlined header-button-icon">download</span>
                    <span
                        class="header-button-label">{{ downloadInProgress ? downloadSelectedLabel : downloadLabel }}</span>
                </div>
            </div>
        </div>
        <div class="bank-table-view-bar">
            <app-search
                [initialValue]="bfdRequest.searchText"
                (newSearchEvent)="handleSearchEvent($event)"
                data-testid="app-accounts-search"
                placeholder="Search by Name, Type"
            ></app-search>
        </div>
        <div class="bfc-table">
            <app-bank-file-table
                [bfdFiles]="bfdFiles"
                [downloadInProgress]="downloadInProgress"
                [fetching]="fetching"
                [initialSort]="{ id: bfdRequest.sortField, start: bfdRequest.sortDir }"
                [searchText]="bfdRequest.searchText"
                (sortEvent)="handleSortEvent($event)"
                (dirClickEvent)="handleDirClick($event)"
                (rowsSelected)="handleRowsSelected($event)"
                (fileToPreviewEvent)="handleFileToPreviewEvent($event)"
            ></app-bank-file-table>
        </div>
        <div class="paginator-div">
            <mat-paginator (page)="handlePageEvent($event)"
                           [length]="totalRecords"
                           [pageSizeOptions]="[10, 25, 50, 100]"
                           [pageSize]="pageSize"
                           [pageIndex]="page"
                           aria-label="Select page"
                           disabled=false
                           hidePageSize=false
                           showFirstLastButtons=true>
            </mat-paginator>
        </div>
    </div>
</div>
