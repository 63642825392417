import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { BrokerageAccount, ModalConfigData } from '../shared';

@Component({
    selector: 'app-brokerage-table',
    templateUrl: './brokerage-table.component.html',
    styleUrls: ['./brokerage-table.component.scss'],
})
export class BrokerageTableComponent implements AfterViewInit, OnChanges {
    @Input() accountRecords: BrokerageAccount[];
    @Input() searchText: any;
    @Input() initialSort: MatSortable;
    @Input() fetching: boolean;
    @ViewChild(MatSort) sort: MatSort;
    @Output() editDeleteEvent = new EventEmitter<ModalConfigData>();
    @Output() sortEvent = new EventEmitter<Sort>();

    public editSectionTitle = 'brokerage-edit';
    public deleteSectionTitle = 'brokerage-delete';

    public displayedColumns: string[] = [
        'brokerageAccountName',
        'brokerageAccountNumber',
        'bankRoutingNumber',
        'bankName',
        'countryCode',
        'details',
    ];
    public dataSource: MatTableDataSource<BrokerageAccount>;

    constructor(private cdr: ChangeDetectorRef) {
        // Assign the data to the data source for the table to render
        this.dataSource = new MatTableDataSource(this.accountRecords);
    }

    ngAfterViewInit() {
        // This was added because the Brokerage Account Number was being sorted
        // numerically instead of alphabetically.
        this.dataSource.sortingDataAccessor = (data, attribute) => data[attribute];

        if (this.sort) {
            this.sort.sort({ ...this.initialSort, disableClear: false });
            this.dataSource.sort = this.sort;
            this.cdr.detectChanges();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.dataSource.data = this.accountRecords;
    }

    public editRow(row: BrokerageAccount) {
        this.editDeleteEvent.emit({
            title: 'Edit Common Brokerage Account',
            action: 'edit',
            selected: row,
        });
    }

    public deleteRow(row: BrokerageAccount) {
        this.editDeleteEvent.emit({
            title: 'Delete Common Brokerage Account',
            action: 'delete',
            selected: row,
        });
    }

    handleSortChange(s: Sort) {
        this.sortEvent.emit(s);
    }
}
