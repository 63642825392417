<div style="height: 100%;">
    <mat-card appearance="outlined" class="mat-card" *ngIf="vendorConfig">
        <mat-card-header>
            <mat-card-title>{{vendorConfig.title}}</mat-card-title>
            <span id="x-dismiss"
                  (click)="closeSlider()"
                  class="material-symbols-outlined x-dismiss"
                  data-testid="x-dismiss"
            >close
            </span>
        </mat-card-header>
        <div *ngIf="vendorConfig.selected" class="card__container">
            <mat-card-content>
                <div style="display: block;">
                    <div class="info-section">
                        <div class="info-label">Payee Information</div>
                        <div class="bank-info">
                            <span class="field-label">Payee Name</span>
                            <span class="field-value">{{vendorConfig.selected.payeeName}}</span>
                        </div>
                        <div class="bank-info">
                            <span class="field-label">Subsidiary</span>
                            <span class="field-value">{{vendorConfig.selected.subsidiary}}</span>
                        </div>
                        <div class="bank-info">
                            <span class="field-label">Email</span>
                            <span class="field-value">{{vendorConfig.selected.email}}</span>
                        </div>
                        <div class="bank-info">
                            <span class="field-label">Shareholder ID</span>
                            <span class="field-value, as-link"
                                  (click)="openInNewTab($event, getShareholderNsUrl(vendorConfig.selected.shrhldId))"
                                  [matTooltip]="getShareholderNsUrl(vendorConfig.selected.shrhldId)"
                                  matTooltipPosition="below"
                                  matTooltipShowDelay="300"
                                  matTooltipHideDelay="200"
                            >{{vendorConfig.selected.shrhldId}}</span>
                        </div>
                        <div class="bank-info">
                            <span class="field-label">Taxpayer ID</span>
                            <span class="field-value">{{vendorConfig.selected.taxpayerId}}</span>
                        </div>
                        <div class="bank-info">
                            <span class="field-label">Shareholder Date Created</span>
                            <span class="field-value">{{vendorConfig.selected.createdDate | date: 'MM/dd/yyyy'}}</span>
                        </div>
                    </div>
                </div>
                <div style="display: block;">
                    <div class="info-section">
                        <div class="info-label">Banking Details</div>
                        <div class="bank-info">
                            <span class="field-label">Account Title</span>
                            <span class="field-value">{{vendorConfig.selected.accountTitle}}</span>
                        </div>
                        <div class="bank-info">
                            <span class="field-label">Account Number or IBAN</span>
                            <span class="field-value">{{vendorConfig.selected.accountNumber}}</span>
                        </div>
                        <div class="bank-info">
                            <span class="field-label">Currency Code</span>
                            <span class="field-value">{{vendorConfig.selected.currency}}</span>
                        </div>
                        <div class="bank-info">
                            <span class="field-label">Country Code</span>
                            <span class="field-value">{{vendorConfig.selected.bankCountry}}</span>
                        </div>
                        <div class="bank-info">
                            <span class="field-label">Routing / Swift</span>
                            <span class="field-value">{{vendorConfig.selected.abaSwift}}</span>
                        </div>
                        <div class="bank-info">
                            <span class="field-label">Sort Code</span>
                            <span class="field-value">{{vendorConfig.selected.sortCode}}</span>
                        </div>
                        <div class="bank-info">
                            <span class="field-label">Bank Name</span>
                            <span class="field-value">{{vendorConfig.selected.bankName}}</span>
                        </div>
                        <div class="bank-info">
                            <span class="field-label">FFC Account Name</span>
                            <span class="field-value">{{vendorConfig.selected.ffcName}}</span>
                        </div>
                        <div class="bank-info">
                            <span class="field-label">FFC Account Number</span>
                            <span class="field-value">{{vendorConfig.selected.ffcAcct}}</span>
                        </div>
                        <div class="bank-info">
                            <span class="field-label">Intermediary Swift/Bic</span>
                            <span class="field-value">{{vendorConfig.selected.imbSwiftbic}}</span>
                        </div>
                        <div class="bank-info">
                            <span class="field-label">Intermediary Bank Name</span>
                            <span class="field-value">{{vendorConfig.selected.imbBankname}}</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="info-section">
                        <div class="info-label">Payee Address</div>
                        <div class="bank-info">
                            <span class="field-label">Payee Address</span>
                            <span class="field-value" *ngIf="vendorConfig.selected.payeeAddress1">{{vendorConfig.selected.payeeAddress1}}</span>
                            <span class="field-value" *ngIf="vendorConfig.selected.payeeAddress2">{{vendorConfig.selected.payeeAddress2}}</span>
                            <span class="field-value" *ngIf="vendorConfig.selected.payeeAddress3">{{vendorConfig.selected.payeeAddress3}}</span>
                            <span class="field-value">{{vendorConfig.selected.payeeCity}}, {{vendorConfig.selected.payeeState}} {{vendorConfig.selected.payeePostalCode}}</span>
                            <span class="field-value">{{vendorConfig.selected.payeeCountry}}</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="info-section">
                        <div class="info-label">Reviewer / Approver</div>
                        <div class="bank-info">
                            <span class="field-label">Reviewed By</span>
                            <span class="field-value">{{vendorConfig.selected.reviewedBy}}</span>
                        </div>
                        <div class="bank-info">
                            <span class="field-label">Reviewed On</span>
                            <span class="field-value">{{vendorConfig.selected.reviewedDt | date: 'EEE, MMM dd, yyyy, h:mm aaaaa\'m\''}}</span>
                        </div>
                        <div class="bank-info">
                            <span class="field-label">Approved By</span>
                            <span class="field-value">{{vendorConfig.selected.approvedBy}}</span>
                        </div>
                        <div class="bank-info">
                            <span class="field-label">Approved On</span>
                            <span class="field-value">{{vendorConfig.selected.approvedDt | date: 'EEE, MMM dd, yyyy, h:mm aaaaa\'m\''}}</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="info-section">
                        <div class="info-section-title">
                            <div class="info-label">Notes</div>
                            <div class="add-note-div" data-testid="add-note-link" (click)="addNote()">+ Add Note</div>
                        </div>
                        <div class="info-section-notes">
                            <div *ngFor="let note of vendorConfig.notes" class="note-block">
                                <div class="note-meta">
                                    <span class="note-date">{{note.createdDt | date: 'EEE, MMM dd, yyyy, h:mm aaaaa\'m\''}}</span>
                                    <span class="note-by">by {{note.createdBy}}</span>
                                </div>
                                <div class="note-text">{{note.note}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </div>
        <mat-card-actions>
            <div class="card-actions">
                <button
                    class="modal-button"
                    mat-flat-button
                    (click)="closeSlider()"
                    data-testid="btnCancel"
                >
                    Cancel
                </button>
                <span *ngIf="vendorConfig.tertiaryButtonTitle">
                    <button
                        class="modal-button modal-button-secondary"
                        mat-flat-button
                        (click)="hideButtonSubmit()"
                        data-testid="btnHide"
                        *appAccessCheckSection="hideAccessCheck"
                    >
                        {{vendorConfig.tertiaryButtonTitle}}
                    </button>
                </span>
                <span *ngIf="vendorConfig.secondaryButtonTitle">
                    <button
                        class="modal-button modal-button-secondary"
                        mat-flat-button
                        (click)="rejectVendor()"
                        data-testid="btnReject"
                        *appAccessCheckSection="rejectAccessCheck"
                    >
                        {{vendorConfig.secondaryButtonTitle}}
                    </button>
                </span>
                <span *ngIf="vendorConfig.primaryButtonTitle">
                    <button
                        class="modal-button modal-button-primary"
                        [class.modal-button-primary-disabled]="isSubmitDisabled()"
                        type="submit"
                        (click)="submitVendor()"
                        [matTooltip]="getSubmitTooltip()"
                        [disabled]="isSubmitDisabled()"
                        mat-flat-button
                        data-testid="btnApprove"
                        *appAccessCheckSection="approveAccessCheck"
                    >
                        <span class="icon-button">
                            <span class="modal-button-label">{{vendorConfig.primaryButtonTitle}}</span>
                        </span>
                    </button>
                </span>
            </div>
        </mat-card-actions>
    </mat-card>
</div>
