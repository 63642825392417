import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
    AdminRequestHelper,
    CreateEditUserTypes,
    GetType,
    InternalAdminService,
    ModalCloseConfig,
    ModalConfigData,
    ShareholderUserAccess,
    ShareholderRecord,
    ShareholderSearchColumns,
    UserActionEnum,
    UserFullUi,
} from '../shared';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfigurationService } from '../../core/services/configuration.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AvailableColumns } from '../shareholder-deal-user-table/shareholder-deal-user-table.columns.types';
import { InternalAdminModalComponent } from '../internal-admin-modal/internal-admin-modal.component';

@Component({
    selector: 'app-shareholders',
    templateUrl: './shareholders.component.html',
    styleUrls: ['./shareholders.component.scss'],
})
export class ShareholdersComponent implements OnInit, AfterViewInit {
    public shareholderRecords: ShareholderRecord[];
    public searchValue: string;
    public page: number;
    public pageSize: number;
    public totalRecords: number;
    public fetching = false;
    public requestHelperShareholderUserAccess: AdminRequestHelper;

    // For a single shareholder is selected
    public selectedShareholderId: number;
    public selectedShareholder: ShareholderRecord;
    public shareholderDealUserRecords: ShareholderUserAccess[];
    public searchValueDealsUsers: string;
    public pageDealsUsers: number;
    public pageSizeDealsUsers: number;
    public totalRecordsDealsUsers: number;
    public fetchingDealsUsers = true;
    public showRevokeAccess: boolean;
    public displayedColumns: string[] = [
        AvailableColumns.Deal,
        AvailableColumns.User,
        AvailableColumns.Permission,
        AvailableColumns.Actions,
    ];

    // Create/Edit User Component values.
    public createEditUserMode: CreateEditUserTypes;
    public showCreateEditUser: boolean = false;
    public userToEdit: UserFullUi = null;
    public userToEditId: number = null;
    public requestHelperDealsUsers: AdminRequestHelper;
    public internalAdminModal: MatDialogRef<InternalAdminModalComponent>;

    private shareholderModalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: true,
        minWidth: '500px',
        minHeight: '200px',
        autoFocus: 'first-header',
    };

    private readonly DEALS_URL = '/internal-admin/deals';
    private readonly USERS_URL = '/internal-admin/users';
    private readonly ACTIVITY_URL = '/internal-admin/activity';

    constructor(
        private internalAdminService: InternalAdminService,
        private snackBar: MatSnackBar,
        private matDialog: MatDialog,
        private configurationService: ConfigurationService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.showRevokeAccess = true;
        this.requestHelperShareholderUserAccess = new AdminRequestHelper(GetType.shareholders);
        this.requestHelperDealsUsers = new AdminRequestHelper(GetType.shareholders);

        this.activatedRoute.queryParams.subscribe({
            next: (params: Params) => {
                const isEmpty = Object.keys(params).length === 0;

                this.searchValue =
                    this.requestHelperShareholderUserAccess.srchVal =
                    this.requestHelperDealsUsers.srchVal =
                        params['search'] ?? '';
                this.requestHelperShareholderUserAccess.srchFields = ShareholderSearchColumns;
                this.requestHelperDealsUsers.srchFields = ShareholderSearchColumns;
                this.page = this.requestHelperShareholderUserAccess.page = params['page'] ?? 0;
                this.pageDealsUsers = this.requestHelperDealsUsers.page = params['page'] ?? 0;
                this.pageSize = this.requestHelperShareholderUserAccess.size =
                    params['pageSize'] ?? this.requestHelperShareholderUserAccess.size;
                this.pageSizeDealsUsers = this.requestHelperDealsUsers.size =
                    params['pageSize'] ?? this.requestHelperDealsUsers.size;
                this.requestHelperShareholderUserAccess.sortDir = params['sortDir'] ?? '';
                this.requestHelperShareholderUserAccess.sortField = params['sortId'] ?? '';
                this.requestHelperDealsUsers.sortDir = params['sortDir'] ?? '';
                this.requestHelperDealsUsers.sortField = params['sortId'] ?? '';

                if (!params || isEmpty || params['clear']) {
                    this.selectedShareholderId = null;
                    this.selectedShareholder = null;
                    // Set the default sort order to newest shareholders first.
                    this.requestHelperShareholderUserAccess.sortField = 'shareholder';
                    this.requestHelperShareholderUserAccess.sortDir = 'asc';

                    this.fetchShareholders();
                } else if (params['shareholderId']) {
                    this.selectedShareholderId = params['shareholderId'];
                    this.fetchSingleShareholder(this.selectedShareholderId);
                }
            },
            error: (error) => this.handleError(error),
        });
    }

    ngAfterViewInit(): void {
        this.cdr.detectChanges();
    }

    public performSearch(searchVal: string) {
        this.searchValue = searchVal;

        if (this.searchValue && this.searchValue.trim().length > 0) {
            this.requestHelperShareholderUserAccess.srchVal = this.searchValue;
            this.requestHelperShareholderUserAccess.srchFields = ShareholderSearchColumns;
        } else {
            this.requestHelperShareholderUserAccess.srchVal = null;
            this.requestHelperShareholderUserAccess.srchFields = null;
        }
        // What if they had already initiated a search, paged to page N, then
        // adjusted the search value. Should it be reset to 0 again?
        this.requestHelperShareholderUserAccess.page = 0;

        this.router.navigate(['/internal-admin/shareholders'], {
            queryParams: {
                search: searchVal,
            },
            queryParamsHandling: 'merge',
        });

        this.fetchShareholders();
    }

    public performSearchDealsUsers(searchVal: string) {
        this.searchValueDealsUsers = searchVal;

        if (this.searchValueDealsUsers && this.searchValueDealsUsers.trim().length > 0) {
            this.requestHelperDealsUsers.srchVal = this.searchValueDealsUsers;
            this.requestHelperDealsUsers.srchFields = ShareholderSearchColumns;
        } else {
            this.requestHelperDealsUsers.srchVal = null;
            this.requestHelperDealsUsers.srchFields = null;
        }
        // What if they had already initiated a search, paged to page N, then
        // adjusted the search value. Should it be reset to 0 again?
        this.requestHelperDealsUsers.page = 0;

        this.router.navigate(['/internal-admin/shareholders'], {
            queryParams: {
                search: searchVal,
            },
            queryParamsHandling: 'merge',
        });

        this.fetchSingleShareholder(this.selectedShareholderId);
    }

    public handlePageEvent(e: PageEvent) {
        this.requestHelperShareholderUserAccess.size = e.pageSize;
        this.requestHelperShareholderUserAccess.page = e.pageIndex;

        this.router.navigate(['/internal-admin/shareholders'], {
            queryParams: {
                page: e.pageIndex,
                pageSize: e.pageSize,
            },
            queryParamsHandling: 'merge',
        });

        this.fetchShareholders();
    }

    public handleSortChange(s: Sort) {
        // Sort object when sorting is turned off: { active: "seller", direction: "" }
        if (s.direction) {
            this.requestHelperShareholderUserAccess.sortField = s.active;
            this.requestHelperShareholderUserAccess.sortDir = s.direction;

            this.fetchShareholders();
        } else {
            // Do not fetch if sorting has been turned off
            this.requestHelperShareholderUserAccess.sortField = null;
            this.requestHelperShareholderUserAccess.sortDir = null;
        }

        this.router.navigate(['/internal-admin/shareholders'], {
            queryParams: {
                sortId: this.requestHelperShareholderUserAccess.sortField,
                sortDir: this.requestHelperShareholderUserAccess.sortDir,
                clear: null,
            },
            queryParamsHandling: 'merge',
        });
        this.cdr.detectChanges();
    }

    public handlePageEventDealsUsers(e: PageEvent) {
        this.requestHelperDealsUsers.size = e.pageSize;
        this.requestHelperDealsUsers.page = e.pageIndex;

        this.router.navigate(['/internal-admin/shareholders'], {
            queryParams: {
                page: e.pageIndex,
                pageSize: e.pageSize,
            },
            queryParamsHandling: 'merge',
        });

        this.fetchSingleShareholder(this.selectedShareholderId);
    }

    public handleSortChangeDealsUsers(s: Sort) {
        // Sort object when sorting is turned off: { active: "seller", direction: "" }
        if (s.direction) {
            this.requestHelperDealsUsers.sortField = s.active;
            this.requestHelperDealsUsers.sortDir = s.direction;

            this.fetchSingleShareholder(this.selectedShareholderId);
        } else {
            // Do not fetch if sorting has been turned off
            this.requestHelperDealsUsers.sortField = null;
            this.requestHelperDealsUsers.sortDir = null;
        }

        this.router.navigate(['/internal-admin/shareholders'], {
            queryParams: {
                sortId: this.requestHelperDealsUsers.sortField,
                sortDir: this.requestHelperDealsUsers.sortDir,
                clear: null,
            },
            queryParamsHandling: 'merge',
        });
        this.cdr.detectChanges();
    }

    public handleShareholderSelected(sid: number) {
        this.fetchSingleShareholder(sid);
    }

    public handleEditUserEvent(userId: number) {
        this.userToEditId = userId;
        this.createEditUserMode = CreateEditUserTypes.EDIT_USER;
        this.showCreateEditUser = true;
    }

    public handleShowCreateEditUserCancelEvent() {
        this.createEditUserMode = null;
        this.userToEdit = null;
        this.userToEditId = null;
        this.showCreateEditUser = false;
    }

    public handleRevokeAccessEvent(userAccess: ShareholderUserAccess) {
        const modalConfig: MatDialogConfig<ModalConfigData> = {
            ...this.shareholderModalConfigTemplate,
            data: {
                title: 'Confirm Revoke Shareholder Access',
                action: UserActionEnum.revokeAccessSH,
                messageHTML: `This will remove this shareholder's assigned role to this deal and user.`,
                userAccessRecord: userAccess,
            },
        };
        this.openModal(modalConfig);
    }

    public shareholderActivityClick(name?: string) {
        this.router.navigate([this.ACTIVITY_URL], {
            queryParams: {
                search: name ? name : this.selectedShareholder.shareholder,
                filterBy: 'shareholder',
            },
        });
    }

    public shareholderPortalClick(sid?: number) {
        console.log('*** ShareholdersComponent.shareholderDashboardClick()');
    }

    public openInNewTab($event, url: string) {
        // This is to prevent the click event from propagating up.
        $event?.stopPropagation();
        $event?.preventDefault();
        window.open(url, '_blank').focus();
    }

    public getShareholderNsUrl(nsId: number): string {
        const nsCustomerUrl = this.configurationService.envConfig.getValue().nsCustomerUrl;

        return nsCustomerUrl && nsId ? nsCustomerUrl.replace(/INSERTIDHERE/, nsId.toString()) : '';
    }

    public handleUserClickedEvent(uid: number) {
        this.router.navigate([this.USERS_URL], {
            queryParams: { userId: uid },
            queryParamsHandling: 'merge',
        });
    }

    public handleDealClickedEvent(uid: number) {
        this.router.navigate([this.DEALS_URL], {
            queryParams: { dealId: uid },
            queryParamsHandling: 'merge',
        });
    }

    private openModal(modalConfig: MatDialogConfig) {
        this.internalAdminModal = this.matDialog.open(InternalAdminModalComponent, modalConfig);
        this.internalAdminModal.componentInstance.confirmButtonEvent.subscribe((emittedValue: ModalCloseConfig) => {
            if (emittedValue) {
                this.internalAdminModal.close();

                switch (emittedValue.action) {
                    case UserActionEnum.revokeAccessSH: {
                        this.internalAdminService
                            .deleteShareholderUserAccess(modalConfig.data.userAccessRecord)
                            .subscribe({
                                next: (res: any) => {
                                    if (res.status >= 200 && res.status <= 204) {
                                        this.snackBar.open('Shareholder access revoked.', undefined, {
                                            duration: 6000,
                                            panelClass: ['swatch-teal'],
                                        });
                                    } else {
                                        const msg = `Revoke access failed`;
                                        console.error(msg);
                                        this.snackBar.open(msg, undefined, {
                                            duration: 10000,
                                        });
                                    }
                                },
                                error: (error) => this.handleError(error),
                            });

                        break;
                    }
                }
            }
        });

        this.internalAdminModal.componentInstance.cancelButtonEvent.subscribe((emittedValue) => {
            if (emittedValue) {
                this.internalAdminModal.close();
            }
        });
    }

    /**
     * The "single shareholder" comes from the original list of shareholders,
     * so this search is to retrieve the linked deals and users, I.e. ShareholderDataAccess data.
     * @param sid
     * @private
     */
    private fetchSingleShareholder(sid: number) {
        this.fetchingDealsUsers = true;
        this.fetching = true;
        this.selectedShareholderId = sid;
        // Using '==' instead of '===' as the sid has come in as a string, whereas nsId is a number.
        this.selectedShareholder = this.shareholderRecords?.find((shareholder) => shareholder.nsId == sid);

        this.requestHelperDealsUsers.sid = sid;
        this.internalAdminService.getShareholders(this.requestHelperDealsUsers).subscribe({
            next: (res: any) => {
                this.fetchingDealsUsers = false;
                this.fetching = false;
                const contentRange = res.headers.get('content-range'); // "1-3/3"
                this.totalRecordsDealsUsers = +contentRange.substring(contentRange.indexOf('/') + 1);
                this.shareholderDealUserRecords = res.body;
            },
            error: (error) => this.handleError(error),
        });
    }

    private fetchShareholders() {
        this.fetching = true;
        this.internalAdminService.getShareholders(this.requestHelperShareholderUserAccess).subscribe({
            next: (res: any) => {
                this.fetching = false;
                const contentRange = res.headers.get('content-range'); // "1-3/3"
                this.totalRecords = +contentRange.substring(contentRange.indexOf('/') + 1);
                this.shareholderRecords = res.body;
            },
            error: (error) => this.handleError(error),
        });
    }

    private handleError(error: HttpErrorResponse): void {
        this.fetching = false;
        this.fetchingDealsUsers = false;
        error['stack'] ? console.error(error['stack']) : console.error(error);

        let msg: string;
        if (error?.status >= 400 && error?.status < 500) {
            msg = error?.error?.message ? error.error.message : 'Server error';
        } else {
            msg = 'Server error';
        }

        this.snackBar.open(msg, undefined, {
            duration: 10000,
        });
    }
}
