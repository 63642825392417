import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/guards/auth-guard.service';
import { AllowedRoles } from '../core/guards/allowed-roles.guard';
import { OneLoginRoles } from '../core/services/auth.service';
import { BankFileExplorerComponent } from './bank-file-explorer.component';

const routes: Routes = [
    {
        path: 'bank-file-explorer',
        component: BankFileExplorerComponent,
        canActivate: [AuthGuard, AllowedRoles],
        data: {
            AllowedRolesData: {
                oneOfRoles: [OneLoginRoles.bankFileExplorerStandard],
            },
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class BankFileExplorerRoutingModule {}
