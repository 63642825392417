import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { BankRecord, BankValidationService, ModalConfigData, SimpleModalResult } from './shared';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from './modal/modal.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Sort } from '@angular/material/sort';

@Component({
    selector: 'app-cache',
    templateUrl: './bank-codes.component.html',
    styleUrls: ['./bank-codes.component.scss'],
})
export class BankCodesComponent implements OnInit {
    public cachedBankCodesTitle = 'Bank Codes';
    public cachedBankCodesSubtitle = 'Stored Routing Numbers';
    public cachedBankCodesCountText = 'total routing numbers in this directory';
    public get cachedBankCodesCount(): number {
        return this.cacheRecords ? this.cacheRecords.length : 0;
    }
    public cacheRecords: BankRecord[];
    public fullList: BankRecord[];
    public searchValue: string;
    public sortDir: string;
    public sortId: string;
    private modalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: true,
        minWidth: '500px',
        maxWidth: '500px',
        minHeight: '223px',
    };
    public cacheModal: MatDialogRef<ModalComponent>;
    public exportDefault = 'Export';
    public exporting = 'Exporting';
    public exportLabel = this.exportDefault;
    public exportInProgress = false;
    public fetching = true;

    private modalConfigTemplateExport: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: false,
        minWidth: '550px',
        maxWidth: '550px',
        minHeight: '259px',
        maxHeight: '300px',
    };

    constructor(
        public matDialog: MatDialog,
        private snackBar: MatSnackBar,
        private bankValidationService: BankValidationService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe({
            next: (params: Params) => {
                this.sortDir = params['sortDir'] ?? 'asc';
                this.sortId = params['sortId'] ?? 'bankName';
                this.searchValue = params['search'] ?? '';
            },
        });

        this.fetch();
    }

    public performSearch(searchVal: string) {
        this.searchValue = searchVal;
        this.router.navigate(['/bank-codes'], {
            queryParams: {
                search: this.searchValue,
            },
            queryParamsHandling: 'merge',
        });
        if (!searchVal || searchVal.trim() == '') {
            // No search value, return all records
            this.cacheRecords = this.fullList;
        } else {
            this.cacheRecords = this.fullList.filter((val) => {
                if (searchVal.trim().toLowerCase() === 'direct' || searchVal.trim().toLowerCase() === 'indirect') {
                    return (
                        val.fedach.connectivity?.toLowerCase() === searchVal.trim().toLowerCase() ||
                        val.fedwire.connectivity?.toLowerCase() === searchVal.trim().toLowerCase()
                    );
                } else {
                    return (
                        val.bankName.toLowerCase().indexOf(searchVal.trim().toLowerCase()) >= 0 ||
                        val.nationalId.toLowerCase().indexOf(searchVal.trim().toLowerCase()) >= 0
                    );
                }
            });
        }
    }

    public export() {
        this.exportInProgress = true;
        const newNoteConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplateExport,
            data: {
                title: 'Export Bank Codes',
                action: 'export',
            },
        };

        this.openModal(newNoteConfig);
    }

    public handleSortChange(s: Sort) {
        this.sortId = s.active;
        this.sortDir = s.direction;

        this.router.navigate(['/bank-codes'], {
            queryParams: {
                sortId: this.sortId,
                sortDir: this.sortDir,
                clear: null,
            },
            queryParamsHandling: 'merge',
        });
        this.cdr.detectChanges();
    }

    public handleDelete($event: ModalConfigData) {
        const deleteConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplate,
            data: $event,
        };
        this.openModal(deleteConfig);
    }

    private openModal(modalConfig: MatDialogConfig) {
        this.cacheModal = this.matDialog.open(ModalComponent, modalConfig);
        this.cacheModal.afterClosed().subscribe((res: SimpleModalResult) => {
            this.exportInProgress = false;

            if (res?.updateData) {
                let message: string;
                if (modalConfig.data.action === 'delete') {
                    message = modalConfig.data.selected.bankName + ' has been successfully removed from your list.';
                }

                this.snackBar.open(message, undefined, {
                    duration: 6000,
                });

                setTimeout(() => {
                    this.snackBar.open('Fetched cached bank codes after most recent changes', undefined, {
                        duration: 6000,
                    });
                }, 6000);

                this.fetch();
            } else if (res?.exported) {
                this.snackBar.open(`Success!`, undefined, {
                    duration: 6000,
                });
            }
        });
    }

    private fetch() {
        this.fetching = true;
        this.bankValidationService.get().subscribe({
            next: (res) => {
                this.fetching = false;
                this.cacheRecords = this.fullList = res;

                if (this.searchValue) {
                    this.performSearch(this.searchValue);
                }
            },
            error: (error: HttpErrorResponse) => {
                this.fetching = false;
                console.error(error);
                this.snackBar.open('Server error', undefined, {
                    duration: 10000,
                });
            },
        });
    }
}
