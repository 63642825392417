import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
    ActivityRecord,
    ActivityTranslated,
    AdminRequestHelper,
    DealRecord,
    DealSearchColumnsFromActivity,
    GetType,
    UserAccessService,
    UserRecord,
    UserSearchColumnsFromActivity,
} from '../shared';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UserService } from '../../core/services/user.service';
import { MatSelectChange } from '@angular/material/select';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { MatSelectionListChange } from '@angular/material/list';

enum Activities {
    'All Activity' = 'all',
    'Deals' = 'deals',
    'Users' = 'users',
    'Admin' = 'admin',
}

enum Actions {
    'All Actions' = 'all',
    'Created' = 'created',
    'Invited' = 'invited',
    'Added' = 'added',
    'Edited' = 'edited',
    'Deactivated' = 'deactivated',
    'Updated' = 'updated',
}

@Component({
    selector: 'app-activity',
    templateUrl: './activity.component.html',
    styleUrls: ['./activity.component.scss'],
})
export class ActivityComponent implements OnInit {
    @HostListener('document:keydown.escape', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
        this.hideMenu();
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        // If this element does not contain the target element, then a click
        // happened outside the panel and should be closed. But because this is
        // listening to all document clicks, including the click to open the
        // slider, the click count needs to be reset after (re)opening. This is
        // to effectively ignore initial click to open the container.
        if (!this.eRef.nativeElement.contains(event.target) && this.clickCount > 0 && this.selectedIndex >= 0) {
            this.hideMenu();
        }

        this.clickCount++;
    }

    // The static:false makes that Angular check if is under a *ngIf.
    // The read:ElementRef makes that the input is seen as ElementRef, not MatInput.
    @ViewChild('activityFilterByInput', { static: false, read: ElementRef }) activityFilterByInput: ElementRef;

    public activityRecords: ActivityRecord[] = [];
    public selectedActivity: ActivityRecord;
    public activities: ActivityTranslated[] = [];
    public form: FormGroup = new FormGroup({
        searchValue: new FormControl<string>(''),
        activity: new FormControl<string>(''),
        activityFilterBySearchInput: new FormControl<string>(''),
        action: new FormControl<string>(''),
        startdate: new FormControl<Date>(null),
        enddate: new FormControl<Date>(null),
        excludeme: new FormControl<boolean>(false),
    });
    private requestHelper: AdminRequestHelper;

    public dataSource: MatTableDataSource<ActivityTranslated>;
    public displayedColumns = ['initials', 'text', 'activityDt', 'actions'];
    public page: number;
    public pageSize: number;
    public totalRecords: number = 0;
    public ActivityEnum = Activities;
    public ActivityEnumKeys = Object.keys(Activities);
    public ActionEnum = Actions;
    public ActionEnumKeys = Object.keys(Actions);
    public iconWasClicked = false;
    public selectedIndex = null;
    public showDetails: boolean = false;
    public filterBySearchVisible = false;
    public filterBySearchLabel = '';
    public filterBySearchSelectedName = '';
    public activitySearchResDeals: DealRecord[];
    public activitySearchResDealSelected: DealRecord;
    public activitySearchResUsers: UserRecord[];
    public activitySearchResUserSelected: UserRecord;
    public activitySearchResAdmins: string[];
    public activitySearchResAdminSelected: string;
    public fetching = false;

    private clickCount = 0;

    constructor(
        private userAccessService: UserAccessService,
        private snackBar: MatSnackBar,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private eRef: ElementRef,
        private userService: UserService,
    ) {
        this.dataSource = new MatTableDataSource<ActivityTranslated>(this.activities);
    }

    ngOnInit() {
        this.requestHelper = new AdminRequestHelper(GetType.activity);
        this.pageSize = this.requestHelper.size;
        this.filterBySearchVisible = false;

        this.activatedRoute.queryParams.subscribe({
            next: (params: Params) => {
                if (!params) {
                    return;
                } else if (params['clear'] === 'true') {
                    this.clearFilters();
                    return;
                }

                this.form.controls.searchValue.setValue(params['search']);
                this.page = this.requestHelper.page = params['page'] ?? 0;
                this.pageSize = this.requestHelper.size = params['pageSize'] ?? this.requestHelper.size;
                this.form.controls.activity.setValue(params['filterBy']);
                this.filterBySearchVisible =
                    this.form.controls.activity.value &&
                    this.form.controls.activity.value !== Activities['All Activity'];
                this.form.controls.activityFilterBySearchInput.setValue(params['name']);
                this.filterBySearchLabel = this.getEnumKeyByEnumValue(Activities, this.form.controls.activity.value);
                this.form.controls.action.setValue(params['show']);
                this.form.controls.startdate.setValue(params['startDate'] ? new Date(params['startDate']) : null);
                this.form.controls.enddate.setValue(params['endDate'] ? new Date(params['endDate']) : null);
                this.form.controls.excludeme.setValue(params['excludeme'] === 'true');

                this.form.markAsDirty();
                this.requestHelper.page = 0;

                this.fetch();
            },
            error: (error) => this.handleError(error),
        });

        this.form.controls.activityFilterBySearchInput.valueChanges
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe((filterValue) => {
                // If there was a selected value but the user changed it then
                // remove the selection and any prior search results.
                if (this.filterBySearchSelectedName !== filterValue) {
                    this.clearActivitySearchValues();

                    if (filterValue?.length > 1) {
                        let helper: AdminRequestHelper;
                        switch (this.form.controls.activity.value) {
                            case Activities.Users: {
                                helper = new AdminRequestHelper(GetType.users);
                                helper.srchVal = filterValue;
                                helper.srchFields = UserSearchColumnsFromActivity;

                                this.fetching = true;
                                this.userAccessService.getUsers(helper).subscribe({
                                    next: (res: any) => {
                                        this.fetching = false;
                                        if (res && Array.isArray(res.body) && res.body.length > 0) {
                                            this.activitySearchResUsers = res.body;
                                        } else {
                                            this.activitySearchResUsers = null;
                                        }
                                    },
                                    error: (error) => this.handleError(error),
                                });

                                break;
                            }
                            case Activities.Deals: {
                                helper = new AdminRequestHelper(GetType.deals);
                                helper.srchVal = filterValue;
                                helper.srchFields = DealSearchColumnsFromActivity;

                                this.fetching = true;
                                this.userAccessService.getDeals(helper).subscribe({
                                    next: (res: any) => {
                                        this.fetching = false;
                                        if (res && Array.isArray(res.body) && res.body.length > 0) {
                                            this.activitySearchResDeals = res.body;
                                        } else {
                                            this.activitySearchResDeals = null;
                                        }
                                    },
                                    error: (error) => this.handleError(error),
                                });

                                break;
                            }
                            case Activities.Admin: {
                                helper = new AdminRequestHelper(GetType.activity);
                                helper.activityRequestHelper = {
                                    srchval: filterValue,
                                    activity: this.form.controls.activity?.value,
                                    bynameunique: true,
                                };

                                this.fetching = true;
                                this.userAccessService.getActivity(helper).subscribe({
                                    next: (res: any) => {
                                        this.fetching = false;
                                        if (res && Array.isArray(res.body) && res.body.length > 0) {
                                            const tempAdmins: string[] = [];

                                            res.body.forEach((activity) => {
                                                if (tempAdmins.indexOf(activity.byName) < 0) {
                                                    tempAdmins.push(activity.byName);
                                                }
                                            });

                                            this.activitySearchResAdmins = tempAdmins;
                                        } else {
                                            this.activitySearchResAdmins = null;
                                        }
                                    },
                                    error: (error) => this.handleError(error),
                                });

                                break;
                            }
                        }
                    }
                }
            });
    }

    public handlePageEvent(e: PageEvent) {
        this.requestHelper.size = e.pageSize;
        this.requestHelper.page = e.pageIndex;

        this.fetch();
    }

    public fetch() {
        const activity = this.form.controls.activity?.value;
        let srchval;

        if (activity && this.filterBySearchSelectedName) {
            srchval = this.filterBySearchSelectedName;
        } else {
            srchval = this.form.controls.searchValue?.value;
        }

        this.requestHelper.activityRequestHelper = {
            srchval,
            activity,
            action: this.form.controls.action?.value,
            startdt: this.form.controls.startdate?.value
                ? (this.form.controls.startdate.value as Date).getTime()
                : null,
            enddt: this.form.controls.enddate?.value ? (this.form.controls.enddate.value as Date).getTime() : null,
        };

        if (this.form.controls.excludeme?.value === true) {
            this.requestHelper.activityRequestHelper.excludename = this.userService.user.name;
            // Not including By ID as part of the search as it is redundant.
            // this.requestHelper.activityRequestHelper.excludeid = this.userService.user.sub;
        }

        this.fetching = true;
        this.userAccessService.getActivity(this.requestHelper).subscribe({
            next: (res: any) => {
                this.fetching = false;
                if (res && Array.isArray(res.body) && res.body.length > 0) {
                    const contentRange = res.headers.get('content-range'); // "1-3/3"
                    this.totalRecords = +contentRange.substring(contentRange.indexOf('/') + 1);

                    this.activityRecords = res.body;
                    this.activities = this.translateActivities(res.body);
                    this.dataSource = new MatTableDataSource(this.activities);
                } else {
                    this.totalRecords = 0;

                    this.activityRecords = [];
                    this.activities = [];
                    this.dataSource = new MatTableDataSource(this.activities);
                }
            },
            error: (error) => this.handleError(error),
        });

        this.router.navigate(['/user-access/activity'], {
            queryParams: {
                page: this.requestHelper.page,
                pageSize: this.requestHelper.size,
                search: srchval || null,
                filterBy: activity || null,
                name: this.form.controls.activityFilterBySearchInput?.value || null,
                show: this.form.controls.action?.value || null,
                startDate: this.form.controls.startdate?.value?.toISOString() || null,
                endDate: this.form.controls.enddate?.value?.toISOString() || null,
                excludeme: this.form.controls.excludeme?.value || null,
                clear: null,
            },
            queryParamsHandling: 'merge',
        });
    }

    public haveResults(): boolean {
        return !!(this.activities && this.activities?.length > 0);
    }

    public clearFilters() {
        this.form.reset();
        this.filterBySearchVisible = false;
        this.clearActivitySearchValues();

        this.router.navigate(['/user-access/activity'], {
            queryParams: {
                search: null,
                filterBy: null,
                name: null,
                show: null,
                startDate: null,
                endDate: null,
                excludeme: null,
                clear: null,
            },
            queryParamsHandling: 'merge',
        });
    }

    private clearActivitySearchValues() {
        this.filterBySearchSelectedName = null;

        this.activitySearchResDeals = null;
        this.activitySearchResDealSelected = null;

        this.activitySearchResUsers = null;
        this.activitySearchResUserSelected = null;

        this.activitySearchResAdmins = null;
        this.activitySearchResAdminSelected = null;
    }

    public areFiltersDirty(): boolean {
        return this.form.dirty;
    }

    public showFilterBySelectionList(): boolean {
        return (
            !this.filterBySearchSelectedName &&
            (this.activitySearchResUsers != null ||
                this.activitySearchResDeals != null ||
                this.activitySearchResAdmins != null)
        );
    }

    public filterBySelectionChange($event: MatSelectChange) {
        this.clearActivitySearchValues();
        this.form.controls.activityFilterBySearchInput.setValue(null);

        if (
            $event.value === Activities.Deals ||
            $event.value === Activities.Users ||
            $event.value === Activities.Admin
        ) {
            this.filterBySearchVisible = true;
            this.filterBySearchLabel = this.getEnumKeyByEnumValue(Activities, $event.value);

            // The delay parameter is omitted, meaning execute "immediately", or more accurately, the next event cycle.
            setTimeout(() => {
                // This fails with "this.activityFilterByInput is undefined" if not within setTimeout().
                this.activityFilterByInput.nativeElement.focus();
            });
        } else {
            this.filterBySearchVisible = false;
        }
    }

    public filterBySelectionMade($event: MatSelectionListChange) {
        switch (this.form.controls.activity.value) {
            case Activities.Deals: {
                this.activitySearchResDealSelected = this.activitySearchResDeals.find(
                    (deal) => deal.id === $event.options[0].value,
                );
                this.filterBySearchSelectedName = this.activitySearchResDealSelected.name;
                this.form.controls.activityFilterBySearchInput.setValue(this.filterBySearchSelectedName);

                break;
            }
            case Activities.Users: {
                this.activitySearchResUserSelected = this.activitySearchResUsers.find(
                    (user) => user.id === $event.options[0].value,
                );
                this.filterBySearchSelectedName =
                    this.activitySearchResUserSelected.firstName + ' ' + this.activitySearchResUserSelected.lastName;
                this.form.controls.activityFilterBySearchInput.setValue(
                    this.activitySearchResUserSelected.firstName + ' ' + this.activitySearchResUserSelected.lastName,
                );

                break;
            }
            case Activities.Admin: {
                this.activitySearchResAdminSelected = $event.options[0].value;
                this.filterBySearchSelectedName = $event.options[0].value;
                this.form.controls.activityFilterBySearchInput.setValue($event.options[0].value);

                break;
            }
            case Activities['All Activity']: {
                // Clear fields
                this.clearActivitySearchValues();

                break;
            }
        }
    }

    public openMenu($event: MouseEvent, index: number) {
        this.iconWasClicked = true;
        this.selectedIndex = index;
        $event?.stopPropagation();
        $event?.preventDefault();
    }

    public hideMenu() {
        this.iconWasClicked = false;
        this.selectedIndex = -1;
        this.clickCount = 0;
    }

    public handleViewDetailsClick(index: number) {
        this.selectedActivity = this.activityRecords[index];
        this.openSlideoutPanel(this.selectedActivity);
        this.hideMenu();
    }

    public handleFilterByUserClick(index: number) {
        this.hideMenu();
    }

    public handleFilterByDealClick(index: number) {
        this.hideMenu();
    }

    public handleFilterByAdminClick(index: number) {
        this.hideMenu();
    }

    public openSlideoutPanel($event: ActivityRecord) {
        this.showDetails = true;
    }

    public closeSliderPanel() {
        this.showDetails = false;
    }

    public handleSearchEvent(searchValue: string) {
        this.closeSliderPanel();
        this.form.reset();

        this.form.controls.searchValue.setValue(searchValue);
        this.form.markAsDirty();

        this.requestHelper.page = 0; // Start at page 0.

        this.fetch();
    }

    private getEnumKeyByEnumValue = (myEnum, enumValue): string => {
        const keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
        return keys.length > 0 ? keys[0] : null;
    };

    private translateActivities(dbdata: ActivityRecord[]): ActivityTranslated[] {
        return dbdata.map((row) => {
            const temp: ActivityTranslated = {
                initials: '',
                text: '',
                activityDt: row.activityDt,
            };

            // Ignore Jr, Sr, Ms, other titles?
            temp.initials = row.userName
                .split(' ')
                .map((word) => {
                    if (
                        word.toLowerCase().indexOf('jr') < 0 &&
                        word.toLowerCase().indexOf('sr') < 0 &&
                        word.toLowerCase().indexOf('ms') < 0
                    ) {
                        return word.substring(0, 1);
                    } else {
                        return '';
                    }
                })
                .join('');

            // Translate into text.
            // Examples:
            // Curtis Weaver’s phone number was updated by Dan Anuka
            // Curtis Weaver was added to Deal Dashboard by Dan Anuka
            // Curtis Weaver was removed from Deal Dashboard by Alyssa Vuong within Project Galaxy (Gunder Luh) 2022
            // There are many other actions possible
            temp.text += '<b>' + row.userName + '</b>';

            // NOTE: &nbsp; is required both before and after embedded HTML.
            if (row.properties) {
                const idx = row.properties.lastIndexOf(',');
                let properties = '';

                if (idx >= 0) {
                    const commaCount = (row.properties.match(/,/g) || []).length;
                    // If only one comma then replace it with 'and', otherwise keep them all.
                    properties =
                        row.properties.substring(0, commaCount === 1 ? idx : idx + 1) +
                        ' and' +
                        row.properties.substring(idx + 1);
                } else {
                    properties = row.properties;
                }

                temp.text += `<b>'s</b>&nbsp;${properties} was ${row.action} by&nbsp;<b>${row.byName}</b>&nbsp;`;

                if (row.deals) {
                    temp.text += `within&nbsp;<b>${row.deals}</b>&nbsp;`;
                }
            } else {
                temp.text += '&nbsp;was';
                switch (row.action) {
                    case 'invited':
                    case 'added':
                        temp.text += ` ${row.action} to`;
                        break;
                    case 'removed':
                        temp.text += ` removed from`;
                        break;
                    default:
                        temp.text += ` ${row.action}`;
                        break;
                }

                if (row.products) {
                    temp.text += `&nbsp;<b>${row.products}</b>`; // &nbsp; after HTML
                }

                // By (admin)
                temp.text += `&nbsp;by&nbsp;<b>${row.byName}</b>&nbsp;`; // No beginning space, but trailing &ngsp;

                if (row.deals) {
                    temp.text += `within&nbsp;<b>${row.deals}</b>&nbsp;`; // beginning space added by previous text
                }
            }

            return temp;
        });
    }

    private handleError(error: HttpErrorResponse): void {
        this.fetching = false;
        error['stack'] ? console.error(error['stack']) : console.error(error);

        let msg: string;
        if (error?.status >= 400 && error?.status < 500) {
            msg = error?.error?.message ? error.error.message : 'Server error';
        } else {
            msg = 'Server error';
        }

        this.snackBar.open(msg, undefined, {
            duration: 10000,
        });
    }
}
