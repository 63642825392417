<mat-card appearance="outlined" class="mat-card">
    <div id="title-bar" class="title-bar">
        <mat-card-title>
            {{ modalData.title }}
        </mat-card-title>
        <div class="dismiss-div">
            <span id="x-dismiss"
                  (click)="closeModal()"
                  class="material-symbols-outlined x-dismiss"
                  data-testid="x-dismiss">
                close
            </span>
        </div>
    </div>
    <div class="card__container">
        <ng-container
            *ngTemplateOutlet="createEditVendorSection"
        ></ng-container>
    </div>
</mat-card>

<ng-template #createEditVendorSection>
    <mat-card-content>
        <div id="content-area" class="content-area">
            <mat-dialog-content>
                <form class="vendor-form" [formGroup]="form" (ngSubmit)="submit()" (keydown.enter)="submit()">
                    <div class="info-section">
                        <div class="label-group">
                            <div class="info-label">Payee Information</div>
                            <div class="info-description">Please provide details about the payee.</div>
                        </div>
                        <div class="field-group">
                            <div class="vendor-info">
                                <mat-form-field class="vendor-input-field" data-testid="payeeNameMFF">
                                    <mat-label>Payee Name</mat-label>
                                    <input id="payeeNameInput" matInput type="text" formControlName="payeeName" data-testid="payeeName"/>
                                    <mat-error *ngIf="form.controls.payeeName.hasError('required')">
                                        Payee Name is <strong>required</strong>
                                    </mat-error>
                                    <mat-error *ngIf="form.controls.payeeName.hasError('maxlength')">
                                        Payee Name max length is 150 characters
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="vendor-info">
                                <mat-form-field class="vendor-input-field" data-testid="emailMFF">
                                    <mat-label>Email</mat-label>
                                    <input id="emailInput" matInput type="text" formControlName="email" data-testid="email"/>
                                    <mat-error *ngIf="form.controls.email.hasError('maxlength')">
                                        Email max length is 150 characters
                                    </mat-error>
                                    <mat-error *ngIf="form.controls.email.hasError('pattern')">
                                        Email must have "@" and "." with characters before and after
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="vendor-info">
                                <mat-form-field class="vendor-input-field" data-testid="shrhldIdMFF">
                                    <mat-label>Shareholder Id</mat-label>
                                    <input id="shrhldId" matInput readonly type="text" formControlName="shrhldId" data-testid="shrhldId"/>
                                </mat-form-field>
                            </div>
                            <div class="vendor-info">
                                <mat-form-field class="vendor-input-field" data-testid="createdDateMFF">
                                    <mat-label>Shareholder Date Created</mat-label>
                                    <input id="createdDate" matInput readonly type="text" formControlName="createdDate" data-testid="createdDate"/>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="info-section">
                        <div class="label-group">
                            <div class="info-label">Banking Details</div>
                        </div>
                        <div class="field-group">
                            <div class="vendor-info">
                                <mat-form-field class="vendor-input-field" data-testid="accountTitleMFF">
                                    <mat-label>Account Title</mat-label>
                                    <input id="accountTitleInput" matInput type="text" formControlName="accountTitle" data-testid="accountTitle"/>
                                    <mat-error *ngIf="form.controls.accountTitle.hasError('required')">
                                        Account Title is <strong>required</strong>
                                    </mat-error>
                                    <mat-error *ngIf="form.controls.accountTitle.hasError('maxlength')">
                                        Account Title max length is 150 characters
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="vendor-info">
                                <mat-form-field class="vendor-input-field" data-testid="accountNumberMFF">
                                    <mat-label>Account Number or IBAN</mat-label>
                                    <input id="accountNumberInput" matInput readonly type="text" formControlName="accountNumber" data-testid="accountNumber"/>
                                </mat-form-field>
                            </div>
                            <div class="vendor-info">
                                <mat-form-field class="vendor-input-field" data-testid="currencyMFF">
                                    <mat-label>Currency Code</mat-label>
                                    <input id="currencyInput" matInput type="text" formControlName="currency" data-testid="currency"/>
                                    <mat-error *ngIf="form.controls.currency.hasError('minlength')">
                                        Currency must be 3 characters
                                    </mat-error>
                                    <mat-error *ngIf="form.controls.currency.hasError('maxlength')">
                                        Currency must be 3 characters
                                    </mat-error>
                                    <mat-error *ngIf="form.controls.currency.hasError('pattern')">
                                        Currency must be alphabetic
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="vendor-info">
                                <mat-form-field class="vendor-input-field" data-testid="bankCountryMFF">
                                    <mat-label>Country Code</mat-label>
                                    <input id="bankCountryInput" matInput type="text" formControlName="bankCountry" data-testid="bankCountry"/>
                                    <mat-error *ngIf="form.controls.bankCountry.hasError('required')">
                                        Country Code is <strong>required</strong>
                                    </mat-error>
                                    <mat-error *ngIf="form.controls.bankCountry.hasError('minlength')">
                                        Country Code must be 3 characters
                                    </mat-error>
                                    <mat-error *ngIf="form.controls.bankCountry.hasError('maxlength')">
                                        Country Code must be 3 characters
                                    </mat-error>
                                    <mat-error *ngIf="form.controls.bankCountry.hasError('pattern')">
                                        Country Code must be alphabetic
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="vendor-info">
                                <mat-form-field class="vendor-input-field" data-testid="abaSwiftMFF">
                                    <mat-label>Routing / SWIFT</mat-label>
                                    <input id="abaSwiftInput" matInput type="text" formControlName="abaSwift" data-testid="abaSwift"/>
                                    <mat-error *ngIf="form.controls.abaSwift.hasError('required')">
                                        Routing / SWIFT is <strong>required</strong>
                                    </mat-error>
                                    <mat-error *ngIf="form.controls.abaSwift.hasError('maxlength')">
                                        Routing / SWIFT max length is 50 characters
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="vendor-info">
                                <mat-form-field class="vendor-input-field" data-testid="sortCodeMFF">
                                    <mat-label>Sort Code</mat-label>
                                    <input id="sortCodeInput" matInput type="text" formControlName="sortCode" data-testid="sortCode"/>
                                    <mat-error *ngIf="form.controls.sortCode.hasError('maxlength')">
                                        Sort Code max length is 35 characters
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="vendor-info">
                                <mat-form-field class="vendor-input-field" data-testid="bankNameMFF">
                                    <mat-label>Bank Name</mat-label>
                                    <input id="bankNameInput" matInput type="text" formControlName="bankName" data-testid="bankName"/>
                                    <mat-error *ngIf="form.controls.bankName.hasError('required')">
                                        Bank Name is <strong>required</strong>
                                    </mat-error>
                                    <mat-error *ngIf="form.controls.bankName.hasError('maxlength')">
                                        Bank Name max length is 150 characters
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="info-section">
                        <div class="label-group">
                            <div class="info-label">FFC Details</div>
                        </div>
                        <div class="field-group">
                            <div class="vendor-info">
                                <mat-form-field class="vendor-input-field" data-testid="ffcNameMFF">
                                    <mat-label>FFC Account Name</mat-label>
                                    <input id="ffcNameInput" matInput type="text" formControlName="ffcName" data-testid="ffcName"/>
                                    <mat-error *ngIf="form.controls.ffcName.hasError('maxlength')">
                                        FFC Account Name max length is 150 characters
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="vendor-info">
                                <mat-form-field class="vendor-input-field" data-testid="ffcAcctMFF">
                                    <mat-label>FFC Account Number</mat-label>
                                    <input id="ffcAcctInput" matInput type="text" formControlName="ffcAcct" data-testid="ffcAcct"/>
                                    <mat-error *ngIf="form.controls.ffcAcct.hasError('maxlength')">
                                        FFC Account Number max length is 50 characters
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="info-section">
                        <div class="label-group">
                            <div class="info-label">Intermediary Details</div>
                        </div>
                        <div class="field-group">
                            <div class="vendor-info">
                                <mat-form-field class="vendor-input-field" data-testid="imbSwiftbicMFF">
                                    <mat-label>Intermediary Swift/BIC</mat-label>
                                    <input id="imbSwiftbicInput" matInput type="text" formControlName="imbSwiftbic" data-testid="imbSwiftbic"/>
                                    <mat-error *ngIf="form.controls.imbSwiftbic.hasError('maxlength')">
                                        Intermediary Swift/BIC max length is 150 characters
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="vendor-info">
                                <mat-form-field class="vendor-input-field" data-testid="imbBanknameMFF">
                                    <mat-label>Intermediary Bank Name</mat-label>
                                    <input id="imbBanknameInput" matInput type="text" formControlName="imbBankname" data-testid="imbBankname"/>
                                    <mat-error *ngIf="form.controls.imbBankname.hasError('maxlength')">
                                        Intermediary Bank Name max length is 150 characters
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="info-section">
                        <div class="label-group">
                            <div class="info-label">Payee Details (Optional)</div>
                            <div class="info-description">
                                Please provide the address of the payee, if available.<br>
                                This information is optional and can be left blank if not applicable.
                            </div>
                        </div>
                        <div class="field-group">
                            <div class="vendor-info">
                                <mat-form-field class="vendor-input-field" data-testid="payeeAddress1MFF">
                                    <mat-label>Address Line 1</mat-label>
                                    <input id="payeeAddress1Input" matInput type="text" formControlName="payeeAddress1" data-testid="payeeAddress1"/>
                                    <mat-error *ngIf="form.controls.payeeAddress1.hasError('maxlength')">
                                        Payee Address Line 1 max length is 100 characters
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="vendor-info">
                                <mat-form-field class="vendor-input-field" data-testid="payeeAddress2MFF">
                                    <mat-label>Address Line 2</mat-label>
                                    <input id="payeeAddress2Input" matInput type="text" formControlName="payeeAddress2" data-testid="payeeAddress2"/>
                                    <mat-error *ngIf="form.controls.payeeAddress2.hasError('maxlength')">
                                        Payee Address Line 2 max length is 100 characters
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="vendor-info">
                                <mat-form-field class="vendor-input-field" data-testid="payeeAddress3MFF">
                                    <mat-label>Address Line 3</mat-label>
                                    <input id="payeeAddress3Input" matInput type="text" formControlName="payeeAddress3" data-testid="payeeAddress3"/>
                                    <mat-error *ngIf="form.controls.payeeAddress3.hasError('maxlength')">
                                        Payee Address Line 3 max length is 100 characters
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="vendor-info">
                                <mat-form-field class="vendor-input-field" data-testid="payeeCityMFF">
                                    <mat-label>City</mat-label>
                                    <input id="payeeCityInput" matInput type="text" formControlName="payeeCity" data-testid="payeeCity"/>
                                    <mat-error *ngIf="form.controls.payeeCity.hasError('maxlength')">
                                        Payee City max length is 100 characters
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="vendor-info">
                                <mat-form-field class="vendor-input-field" data-testid="payeeStateMFF">
                                    <mat-label>State</mat-label>
                                    <input id="payeeStateInput" matInput type="text" formControlName="payeeState" data-testid="payeeState"/>
                                    <mat-error *ngIf="form.controls.payeeState.hasError('pattern')">
                                        Payee State length is 2 characters if entered
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="vendor-info">
                                <mat-form-field class="vendor-input-field" data-testid="payeePostalCodeMFF">
                                    <mat-label>Postal Code</mat-label>
                                    <input id="postalCodeInput" matInput type="text" formControlName="payeePostalCode" data-testid="payeePostalCode"/>
                                    <mat-error *ngIf="form.controls.payeePostalCode.hasError('maxlength')">
                                        Postal Code max length is 10 characters
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="vendor-info">
                                <mat-form-field class="vendor-input-field" data-testid="payeeCountryMFF">
                                    <mat-label>Country</mat-label>
                                    <input id="payeeCountryInput" matInput type="text" formControlName="payeeCountry" data-testid="payeeCountry"/>
                                    <mat-error *ngIf="form.controls.payeeCountry.hasError('maxlength')">
                                        Country max length is 100 characters
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="info-section">
                        <div class="label-group">
                            <div class="info-label">Notes (Required)</div>
                            <div class="info-description">
                                Please provide a brief summary of the changes you made and your reasoning for the update/edit.
                            </div>
                        </div>
                        <div class="field-group">
                            <div class="vendor-info">
                                <mat-form-field class="vendor-input-field" data-testid="vendorNoteMFF">
                                    <mat-label>Note (Required)</mat-label>
                                    <textarea id="vendorNoteInput"
                                              matInput
                                              formControlName="vendorNote"
                                              rows="4"
                                              cols="50"
                                              data-testid="vendorNote"
                                              placeholder="Please provide a brief summary of the changes made..."
                                    ></textarea>
                                    <mat-error *ngIf="form.controls.vendorNote.hasError('maxlength')">
                                        Note max length is 1000 characters
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </form>
            </mat-dialog-content>
        </div>
    </mat-card-content>
    <mat-card-actions align="end">
        <button
            class="modal-button"
            mat-flat-button
            (click)="closeModal()"
            matTooltipPosition="before"
            data-testid="btnCancel"
        >
            Cancel
        </button>
        <button
            class="modal-button modal-button-primary"
            [class.modal-button-primary-disabled] = "isSubmitDisabled()"
            [disabled]="isSubmitDisabled()"
            type="submit"
            (click)="submit()"
            mat-flat-button
            data-testid="btnSave"
        >
            <span class="icon-button">
                <span class="modal-button-label">{{submitButtonText}}</span>
            </span>
        </button>
    </mat-card-actions>
</ng-template>
