<div>
    <div class="base-heading-1 component-title">Badges</div>
    <div class="badges component-demo">
        <div class="srsa-badge xl-placeholder profile-placeholder">
            <div class="badge-content badge-progress">999+</div>
        </div>
        <div class="srsa-badge xl-placeholder profile-placeholder">
            <div class="badge-content badge-success"></div>
        </div>
        <div class="srsa-badge xl-placeholder profile-placeholder">
            <div class="badge-content badge-warning badge-single-digit">1</div>
        </div>
        <div class="srsa-badge xl-placeholder profile-placeholder">
            <div class="badge-content badge-error">123</div>
        </div>
        <div class="srsa-badge xl-placeholder profile-placeholder">
            <div class="badge-content">86</div>
        </div>
        <div class="srsa-badge m-placeholder profile-placeholder">
            <div class="badge-content badge-warning badge-small"></div>
        </div>
        <div class="srsa-badge xl-placeholder profile-placeholder">
            <div class="badge-content badge-progress shimmer"></div>
        </div>
        <button class="srsa-badge">
            Button text
            <span class="badge-content badge-inline">12</span>
        </button>
    </div>
    <hr />
    <div class="base-heading-1 component-title">srsa-chip</div>
    <div class="component-demo">
        <srsa-chip internal-class="none" internal-label="chip" is-clickable="true"></srsa-chip>
        <srsa-chip internal-class="success" internal-label="success"></srsa-chip>
        <srsa-chip
            internal-class="in-progress"
            internal-label="in progress"
            is-clickable="true"
            internal-id="this-interupts-numbering"
            button-icon="check_box_fill_on"
            internal-aria-label="remove in progress"
        ></srsa-chip>
        <srsa-chip internal-class="error" internal-label="error"></srsa-chip>
        <srsa-chip
            internal-class="in-review"
            internal-label="in review"
            is-clickable="true"
            button-icon="indeterminate_check_box_fill_on"
            internal-aria-label="remove in review"
        ></srsa-chip>
        <srsa-chip internal-class="disabled" internal-label="disabled"></srsa-chip>
        <srsa-chip
            internal-class="disabled"
            internal-label="disabled clickable"
            is-clickable="true"
            disabled="true"
        ></srsa-chip>
        <srsa-chip internal-class="none" internal-label="chip" is-loading="true"></srsa-chip>
    </div>
    <hr />
    <div class="base-heading-1 component-title">srsa-button</div>
    <div class="component-demo srsa-buttons">
        <srsa-button></srsa-button>
        <srsa-button leading-icon="download"></srsa-button>
        <srsa-button trailing-icon="upload"></srsa-button>
        <srsa-button internal-label="Default" internal-id="someId"></srsa-button>
        <srsa-button internal-class="primary" internal-label="Download" leading-icon="download"></srsa-button>
        <srsa-button internal-class="secondary" internal-label="Upload" trailing-icon="expand_more"></srsa-button>
        <srsa-button
            internal-class="secondary"
            internal-label="BothIcons"
            leading-icon="download"
            trailing-icon="expand_more"
        ></srsa-button>
        <srsa-button internal-class="tertiary" internal-label="Close"></srsa-button>
        <srsa-button internal-class="destructive" internal-label="Destructive"></srsa-button>
        <srsa-button internal-class="quaternary" internal-label="Option"></srsa-button>
        <srsa-button internal-label="Disabled" disabled></srsa-button>
        <srsa-button internal-label="Button" badge-label="#"></srsa-button>
        <srsa-button internal-class="secondary" internal-label="isLoading" is-loading></srsa-button>
        <srsa-button internal-class="tertiary" internal-label="anchor" href="https://srsacquiom.com"></srsa-button>
    </div>
    <hr />
    <div class="base-heading-1 component-title">srsa-accordion</div>
    <div class="component-demo">
        <div class="accordion-demo">
            <div class="accordion-demo-section accordion-demo-dropdowns">
                <srsa-accordion is-expanded="false" absolute="true" children-width="10rem">
                    <button type="button" slot="parent" (click)="toggleAccordion($event)">
                        Dropdown 1 <span class="icon material-symbols-sharp srsa-icon-rotation">expand_more</span>
                    </button>
                    <div slot="children">
                        <button>bard</button><button>warlock</button><button>barbarian</button><button>cleric</button>
                    </div>
                </srsa-accordion>
                <srsa-accordion is-expanded="false" absolute="true" children-width="10rem">
                    <button type="button" slot="parent" (click)="toggleAccordion($event)">
                        Dropdown 2 <span class="icon material-symbols-sharp srsa-icon-rotation">expand_more</span>
                    </button>
                    <div slot="children">
                        <button>burger</button><button>pad thai</button><button>omelette</button><button>salad</button>
                    </div>
                </srsa-accordion>
            </div>
            <div class="accordion-demo-section test-buttons">
                <button class="accordion-demo-button add" (click)="accordionDemoAddContent();">
                    Add content to open accordion(s)
                </button>
                <button class="accordion-demo-button remove" (click)="accordionDemoRemoveContent();">
                    Remove content from open accordion(s)
                </button>
            </div>
            <div class="accordion-demo-section accordion-demo-textblock">
                <srsa-accordion is-expanded="true" internal-class="textblock-demo">
                    <button type="button" slot="parent" (click)="toggleAccordion($event)">
                        <span class="base-body-paragrah">Accordion 1</span>
                        <span class="base-heading-4">$123,456,789.00</span>
                        <span class="icon material-symbols-sharp srsa-icon-rotation rotate">expand_more</span>
                    </button>
                    <div slot="children">
                        <div>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua.
                        </div>
                    </div>
                </srsa-accordion>
                <srsa-accordion is-expanded="false" internal-class="textblock-demo">
                    <button type="button" slot="parent" (click)="toggleAccordion($event)">
                        <span class="base-body-paragrah">Accordion 2</span>
                        <span class="base-heading-4">$112,358.00</span>
                        <span class="icon material-symbols-sharp srsa-icon-rotation">expand_more</span>
                    </button>
                    <div slot="children">
                        <div>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua.
                        </div>
                    </div>
                </srsa-accordion>
            </div>
        </div>
    </div>
    <hr />
    <div class="base-heading-1 component-title">srsa-text-field</div>
    <div class="component-demo">
        <div class="srsa-text-field-demo">
            <srsa-text-field
                type="text"
                label="Label"
                internal-class="medium-text-field"
                leading-icon="search"
                is-clear-button="true"
                helper="Additional helper text."
            ></srsa-text-field>
            <srsa-text-field
                type="text"
                label="Zip Code"
                internal-class="short-text-field"
                internal-id="zip-validator"
                min-length="5"
                max-length="10"
            ></srsa-text-field>
            <srsa-text-field
                type="text"
                label="Address"
                internal-class="long-text-field"
                internal-id="address-validator"
                leading-icon="apps"
                trailing-icon="auto_fix"
                helper="Required field. Needs a z and a y. Checks every change."
                is-clear-button="true"
                required="true"
                should-show-errors="true"
            ></srsa-text-field>
        </div>
        <div class="srsa-text-field-demo">
            <srsa-text-field
                type="password"
                label="Password"
                internal-class="medium-text-field"
                trailing-icon="delete"
                min-length="5"
                max-length="30"
                required="true"
                is-clear-button="true"
                helper="Required field. 5 to 30 characters."
            ></srsa-text-field>
            <srsa-text-field
                type="text"
                label="disabled"
                internal-class="short-text-field"
                disabled="true"
            ></srsa-text-field>
            <srsa-text-field
                type="number"
                label="Number"
                min="1"
                max="100"
                internal-class="medium-text-field"
                helper="Number must be between 1 and 100."
            ></srsa-text-field>
            <srsa-text-field type="text" label="Loading" is-loading="true"></srsa-text-field>
        </div>
    </div>
    <hr />
    <div class="base-heading-1 component-title">srsa-checkbox</div>
    <div class="component-demo">
        <table>
            <thead>
            <tr>
                <th></th>
                <th>Default</th>
                <th>Disabled</th>
                <th>Loading</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>Unchecked</td>
                <td>
                    <srsa-checkbox></srsa-checkbox>
                </td>
                <td>
                    <srsa-checkbox disabled></srsa-checkbox>
                </td>
                <td>
                    <srsa-checkbox is-loading></srsa-checkbox>
                </td>
            </tr>
            <tr>
                <td>Checked</td>
                <td>
                    <srsa-checkbox value="true"></srsa-checkbox>
                </td>
                <td>
                    <srsa-checkbox value="true" disabled></srsa-checkbox>
                </td>
                <td>
                    <srsa-checkbox value="true" is-loading></srsa-checkbox>
                </td>
            </tr>
            <tr>
                <td>Indeterminate</td>
                <td>
                    <srsa-checkbox indeterminate></srsa-checkbox>
                </td>
                <td>
                    <srsa-checkbox indeterminate disabled></srsa-checkbox>
                </td>
                <td>
                    <srsa-checkbox indeterminate is-loading></srsa-checkbox>
                </td>
            </tr>
            <tr>
                <td>Tooltip</td>
                <td>
                    <srsa-checkbox tooltip="I'm a really helpful tooltip!"></srsa-checkbox>
                </td>
                <td>
                    <srsa-checkbox tooltip="I'm a really helpful tooltip!" disabled></srsa-checkbox>
                </td>
                <td>
                    <srsa-checkbox tooltip="I'm a really helpful tooltip!" is-loading></srsa-checkbox>
                </td>
            </tr>
            <tr>
                <td>Label</td>
                <td>
                    <srsa-checkbox label="I'm a label!"></srsa-checkbox>
                </td>
                <td>
                    <srsa-checkbox label="I'm a label!" disabled></srsa-checkbox>
                </td>
                <td>
                    <srsa-checkbox label="I'm a label!" is-loading></srsa-checkbox>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <hr />
    <div class="base-heading-1 component-title">srsa-profile-photo</div>
    <div class="flex flex-row items-start">
        <div class="component-demo flex flex-col items-center">
            <h2>Image</h2>
            <srsa-profile-photo
                size="s"
                src="https://srsacquiom.atlassian.net/rest/api/2/universal_avatar/view/type/project/avatar/11903?size=medium"
            ></srsa-profile-photo>
            <srsa-profile-photo
                src="https://srsacquiom.atlassian.net/rest/api/2/universal_avatar/view/type/project/avatar/11903?size=medium"
            ></srsa-profile-photo>
            <srsa-profile-photo
                size="m"
                src="https://srsacquiom.atlassian.net/rest/api/2/universal_avatar/view/type/project/avatar/11903?size=medium"
            ></srsa-profile-photo>
            <srsa-profile-photo
                size="l"
                src="https://srsacquiom.atlassian.net/rest/api/2/universal_avatar/view/type/project/avatar/11903?size=medium"
            ></srsa-profile-photo>
            <srsa-profile-photo
                size="xl"
                src="https://srsacquiom.atlassian.net/rest/api/2/universal_avatar/view/type/project/avatar/11903?size=medium"
            ></srsa-profile-photo>
        </div>
        <div class="component-demo flex flex-col items-center">
            <h2>Monogram: single letter</h2>
            <srsa-profile-photo size="s" internal-id="4" text="should-display-S"></srsa-profile-photo>
            <srsa-profile-photo text="4-should-display-4"></srsa-profile-photo>
            <srsa-profile-photo size="m" internal-aria-label="aria!" text="m-hould-display-M"></srsa-profile-photo>
            <srsa-profile-photo size="l" text="l-should-display-L"></srsa-profile-photo>
            <srsa-profile-photo size="xl" text="x-should-display-X"></srsa-profile-photo>
        </div>
        <div class="component-demo flex flex-col items-center">
            <h2>Monogram: two letters</h2>
            <srsa-profile-photo size="s" text="should-display-SM m-is-my-second-word"></srsa-profile-photo>
            <srsa-profile-photo disabled text="4-should-display-42 2-is-my-second-number"></srsa-profile-photo>
            <srsa-profile-photo size="m" text="m-should-display-ME e-is-my-second-word"></srsa-profile-photo>
            <srsa-profile-photo
                size="l"
                text="l-should-display-LG  t-is-my-second-word u-is-third, v-is-fourth g-is-my-nth-word"
            ></srsa-profile-photo>
            <srsa-profile-photo size="xl" disabled text="x-should-display-42 l-is-my-second-number"></srsa-profile-photo>
        </div>
        <div class="component-demo flex flex-col items-center">
            <h2>Loading</h2>
            <srsa-profile-photo
                is-loading="true"
                size="s"
                src="https://srsacquiom.atlassian.net/rest/api/2/universal_avatar/view/type/project/avatar/11903?size=medium"
            ></srsa-profile-photo>
            <srsa-profile-photo
                is-loading="true"
                src="https://srsacquiom.atlassian.net/rest/api/2/universal_avatar/view/type/project/avatar/11903?size=medium"
            ></srsa-profile-photo>
            <srsa-profile-photo
                is-loading="true"
                size="m"
                src="https://srsacquiom.atlassian.net/rest/api/2/universal_avatar/view/type/project/avatar/11903?size=medium"
            ></srsa-profile-photo>
            <srsa-profile-photo size="l" is-loading="true" text="should-display-S"></srsa-profile-photo>
            <srsa-profile-photo size="xl" is-loading="true" text="should-display-S"></srsa-profile-photo>
        </div>
        <div class="component-demo flex flex-col items-center">
            <h2>Blank</h2>
            <srsa-profile-photo size="s"></srsa-profile-photo>
            <srsa-profile-photo></srsa-profile-photo>
            <srsa-profile-photo size="m"></srsa-profile-photo>
            <srsa-profile-photo size="l"></srsa-profile-photo>
            <srsa-profile-photo size="xl"></srsa-profile-photo>
        </div>
        <div class="component-demo flex flex-col items-center">
            <h2>Text & Image Props</h2>
            <srsa-profile-photo
                text="Alien Avatar"
                size="xl"
                src="https://srsacquiom.atlassian.net/rest/api/2/universal_avatar/view/type/project/avatar/11903?size=medium"
            ></srsa-profile-photo>
            <!-- bad link, for alt text -->
            <srsa-profile-photo text="Alien Avatar" size="xl" src="some.bad.link.com"></srsa-profile-photo>
        </div>
        <div class="component-demo flex flex-col items-center">
            <h2>Clickable</h2>
            <srsa-profile-photo
                text="Alien Avatar"
                is-clickable="true"
                size="xl"
                src="https://srsacquiom.atlassian.net/rest/api/2/universal_avatar/view/type/project/avatar/11903?size=medium"
            ></srsa-profile-photo>
            <srsa-profile-photo text="Alien Avatar" is-clickable="true" size="xl"></srsa-profile-photo>
        </div>
        <div class="component-demo flex flex-col items-center">
            <h2>Clickable & disabled</h2>
            <srsa-profile-photo
                text="Alien Avatar"
                is-clickable="true"
                disabled
                size="xl"
                src="https://srsacquiom.atlassian.net/rest/api/2/universal_avatar/view/type/project/avatar/11903?size=medium"
            ></srsa-profile-photo>
            <srsa-profile-photo text="Alien Avatar" is-clickable="true" disabled size="xl"></srsa-profile-photo>
        </div>
        <div class="component-demo flex flex-col items-center">
            <h2>Other</h2>
            <srsa-profile-photo size="s" internal-id="4" text="should-display-S"></srsa-profile-photo>
            <srsa-profile-photo size="m" internal-aria-label="aria!" text="m-hould-display-M"></srsa-profile-photo>
            <srsa-profile-photo size="m" text="no tooltip" tooltip="false"></srsa-profile-photo>
        </div>
    </div>
    <hr />
    <div class="base-heading-1 component-title">srsa-menu</div>
    <div class="component-demo srsa-menu-demo">
        <div class="srsa-menu-demo-column">
            <srsa-menu internal-id="menu-demo-1"></srsa-menu>
            <srsa-menu internal-id="menu-demo-2"></srsa-menu>
            <srsa-menu internal-id="menu-demo-3"></srsa-menu>
        </div>
        <div class="srsa-menu-demo-column">
            <srsa-menu internal-id="menu-demo-4" disabled="true"></srsa-menu>
            <srsa-menu internal-id="menu-demo-5" internal-aria-label="custom activation button aria"></srsa-menu>
            <srsa-menu internal-id="menu-demo-6" is-loading="true"></srsa-menu>
        </div>
    </div>
    <hr />
    <div class="base-heading-1 component-title">srsa-sort</div>
    <div class="component-demo srsa-sort-demo">
        <srsa-sort internal-id="sort-demo-1"></srsa-sort>
        <srsa-sort internal-id="sort-demo-2"></srsa-sort>
        <srsa-sort internal-id="sort-demo-3" disabled="true"></srsa-sort>
        <srsa-sort internal-id="sort-demo-4" is-loading="true"></srsa-sort>
        <srsa-sort internal-id="sort-demo-5" single-sort="true"></srsa-sort>
    </div>
    <hr />
    <div class="base-heading-1 component-title">srsa-grid</div>
    <div class="component-demo srsa-grid-demo">
        <div class="srsa-grid-1">
            <srsa-grid internal-id="srsa-grid-1" is-row-button-behavior="true" should-multi-sort="true"></srsa-grid>
        </div>
        <div class="srsa-double-grid">
            <div class="srsa-grid-2">
                <srsa-grid internal-id="srsa-grid-2"></srsa-grid>
            </div>
            <div class="srsa-grid-3">
                <srsa-grid internal-id="srsa-grid-3" is-row-button-behavior="true"></srsa-grid>
            </div>
        </div>
    </div>
    <hr />
    <div class="base-heading-1 component-title">srsa-select</div>
    <div class="component-demo">
        <div class="select-demo">
            <div class="select-demo-section select-demo-dropdowns">
                <srsa-select label="" leading-icon="close" trailing-icon="home"></srsa-select>
                <srsa-select
                    label="Shareholders and other information"
                    helper="Choose a Shareholder to filter by"
                    overflow-y-scroll
                    should-filter
                >
                </srsa-select>
                <srsa-select
                    label="Shareholders"
                    helper="Choose a Shareholder to filter by"
                    overflow-y-scroll
                    should-filter
                    required
                    disabled
                >
                </srsa-select>
                <srsa-select label="Shareholders" helper="Choose a Shareholder to filter by" value="2"></srsa-select>
                <srsa-select
                    label="Permission Level"
                    required="true"
                    internal-class="my-test-class"
                    should-filter
                ></srsa-select>
                <srsa-select label="" is-loading="true"></srsa-select>
            </div>
        </div>
    </div>
    <hr />
    <div class="base-heading-1 component-title">Tooltip</div>
    <div class="component-demo tooltip-demo">
        <table>
            <thead>
            <tr>
                <th>Description</th>
                <th>Demo</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>Short Label (Hover)</td>
                <td>
                    <span id="tooltip-target-1" class="material-symbols-sharp">help</span>
                </td>
            </tr>
            <tr>
                <td>Long Label (Hover)</td>
                <td>
                    <span id="tooltip-target-2" class="material-symbols-sharp">help</span>
                </td>
            </tr>
            <tr>
                <td>Short Label (Click)</td>
                <td>
                    <span id="tooltip-target-3" class="material-symbols-sharp">help</span>
                </td>
            </tr>
            <tr>
                <td>Long Label (Click, 2000ms delay)</td>
                <td>
                    <span id="tooltip-target-4" class="material-symbols-sharp">help</span>
                </td>
            </tr>
            <tr>
                <td>Dynamic</td>
                <td>
                    <span id="dynamic-tooltip-target" class="material-symbols-sharp">help</span>
                </td>
                <td>
                    <fieldset id="dynamic-tooltip-options">
                        <div>
                            <input
                                type="text"
                                id="dynamic-tooltip-value"
                                placeholder="Tooltip Label"
                                style="border: 1px solid black"
                            />
                        </div>
                        <div>
                            <input type="checkbox" id="dynamic-tooltip-clickable" />
                            <label for="dynamic-tooltip-clickable">Clickable?</label>
                        </div>
                        <div>
                            <input type="checkbox" id="dynamic-tooltip-has-arrow" />
                            <label for="dynamic-tooltip-has-arrow">Has Arrow?</label>
                        </div>
                        <div>
                            <input type="radio" id="dynamic-tooltip-arrow-up" name="arrow-direction" value="up" />
                            <label for="dynamic-tooltip-arrow-up">Up</label>
                        </div>
                        <div>
                            <input type="radio" id="dynamic-tooltip-arrow-down" name="arrow-direction" value="down" />
                            <label for="dynamic-tooltip-arrow-down">Down</label>
                        </div>
                        <div>
                            <input type="radio" id="dynamic-tooltip-arrow-left" name="arrow-direction" value="left" />
                            <label for="dynamic-tooltip-arrow-left">Left</label>
                        </div>
                        <div>
                            <input type="radio" id="dynamic-tooltip-arrow-right" name="arrow-direction" value="right" />
                            <label for="dynamic-tooltip-arrow-right">Right</label>
                        </div>
                    </fieldset>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <hr />
    <div class="base-heading-1 component-title">srsa-navbar</div>
    <div class="component-demo navbar-demos">
        <srsa-navbar internal-id="nav1" is-primary="true"></srsa-navbar>
        <srsa-navbar internal-id="nav2"></srsa-navbar>
        <div>
            <srsa-navbar disabled="true"></srsa-navbar>
            <srsa-navbar is-loading="true"></srsa-navbar>
        </div>
    </div>
    <hr />
    <div class="base-heading-1 component-title">srsa-modal</div>
    <div class="component-demo modal-demos">
        <srsa-modal header-text="Modal Example (No Background Scroll)" prevent-background-scroll="true">
            <div slot="modal-body">
                <srsa-button internal-class="primary" internal-label="Here's a button"></srsa-button>
            </div>
        </srsa-modal>
        <button class="open-modal-button srsa-button primary" (click)="openModal('srsa-modal')">Open Modal</button>
        <div class="modal2">
            <srsa-modal
                header-text="Absolutely Positioned (Relative to Button)"
                absolute="true"
                show-close-button="false"
                is-dialog="true"
            >
                <div slot="modal-body">
                    <srsa-button
                        internal-class="primary"
                        internal-label="No X button for this dialog, use Escape"
                    ></srsa-button>
                    <srsa-profile-photo
                        size="m"
                        src="https://srsacquiom.atlassian.net/rest/api/2/universal_avatar/view/type/project/avatar/11903?size=medium"
                    ></srsa-profile-photo>
                    <srsa-checkbox label="I'm inside a dialog!"></srsa-checkbox>
                </div>
            </srsa-modal>
            <button class="open-modal-button srsa-button secondary" (click)="openModal('.modal2 srsa-modal')">
                Open Absolutely Positioned Dialog
            </button>
        </div>
        <div class="modal3">
            <srsa-modal
                header-text="Modal without Horizontal Rule and Generated Content"
                horizontal-rule="false"
            ></srsa-modal>
            <button class="open-modal-button srsa-button primary" (click)="openModal('.modal3 srsa-modal', true)">
                Open Modal with Generated Content (Minty version!)
            </button>
        </div>
        <div class="modal4">
            <srsa-modal
                header-text="Dialog Positioned Relative to Viewport"
                absolute="true"
                custom-class-string="positioned-relative-modal"
                is-dialog="true"
            >
                <div slot="modal-body">
                    <p>Lorem ipsum dolor sit amet</p>
                </div>
            </srsa-modal>
            <button class="open-modal-button srsa-button secondary" (click)="openModal('.modal4 srsa-modal')">
                Open Dialog Positioned to Viewport
            </button>
        </div>
    </div>
    <hr />
    <div class="base-heading-1 component-title">srsa-alert</div>
    <div class="component-demo alert-demo">
        <button
            class="srsa-button primary"
            (click)="openAlert({
        'type':'default',
        'description-text': 'You\'re doing a great job.',
        'position': 'top-left',
      })"
        >
            Open Default (Top-Left)
        </button>
        <button
            class="srsa-button secondary"
            (click)="openAlert({
        'type':'info',
        'description-text': 'It\'s easier to add than to take away.',
        'position': 'top-right'
      })"
        >
            Open Info (Top-Right)
        </button>
        <button
            class="srsa-button primary"
            (click)="openAlert({
        'type':'success',
        'description-text': 'Your file was successfully updated.',
        'position': 'bottom-left'
      })"
        >
            Open Success (Bottom-Left)
        </button>
        <button
            class="srsa-button secondary"
            (click)="openAlert({
        'type':'error',
        'description-text': 'One or more fields have an error.',
        'position': 'bottom-right'
      })"
        >
            Open Error (Bottom-Right)
        </button>
        <button
            class="srsa-button primary"
            (click)="openAlert({
        'type':'default',
        'title-text': 'Chrome on OS X 10.15.7',
        'description-text': 'February 06, 2023 12:34 PM',
        'action-text': 'revoke',
        'position': 'top-right',
        'leading-icon': 'computer',
        'auto-hide': false
      })"
        >
            Open Alert (Top-Right)
        </button>
    </div>
    <!-- Insert new component here -->
    <hr />
    <div class="base-heading-1 component-title">Colors</div>
    <div class="component-demo" id="demo-colors"></div>
    <hr />
    <div class="base-heading-1 component-title">Typography</div>
    <div class="component-demo" id="demo-typography">
        <div *ngFor="let typo of typographyClasses" class="typography-demo-item card">
            <div class="base-heading-3 label">{{typo}}</div>
            <div class="example" [class]="typo">{{loremIpsum}}</div>
        </div>
    </div>
</div>
