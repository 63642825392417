import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

export function handleMissingServerError(
    serverName: string = '',
): (err: any, caught: Observable<any>) => Observable<never> {
    return (error: HttpErrorResponse, caught) => {
        if (error.status === 0) {
            return throwError(() => {
                return {
                    ...error,
                    error: { message: `${serverName} is unavailable`, details: error.message },
                };
            });
        } else {
            return throwError(() => error);
        }
    };
}
