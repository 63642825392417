import { Component } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-callback',
    styles: [],
    template: ``,
})
export class CallbackComponent {
    constructor(
        private authService: AuthService,
        private router: Router,
    ) {
        this.authService.loginCallback().then(() => {
            this.router.navigate(['']);
        });
    }
}
