<div *ngIf="(fetching)" class="fetching-files">
    <mat-spinner></mat-spinner>
</div>
<div class="host-div">
    <div id="vendor-payments-container" class="vendor-payments-container">
        <mat-tab-group class="tab-group" mat-align-tabs="start" animationDuration="0ms" mat-stretch-tabs="false"
                       [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChanged();">
            <mat-tab label="{{VendorTabNames.IN_REVIEW}}" class="tab-template">
                <ng-container
                    *ngTemplateOutlet="inReviewTabTemplate"
                ></ng-container>
            </mat-tab>
            <mat-tab label="{{VendorTabNames.APPROVED}}" class="tab-template">
                <ng-container
                    *ngTemplateOutlet="approvedTabTemplate"
                ></ng-container>
            </mat-tab>
            <mat-tab label="{{VendorTabNames.REJECTED}}" class="tab-template">
                <ng-container
                    *ngTemplateOutlet="rejectedTabTemplate"
                ></ng-container>
            </mat-tab>
        </mat-tab-group>
        <div id="action-area" class="action-area">
            <div *ngIf="isApprovedTab()" id="upload-div" class="upload-div"
                 [class.export-label-inprogress]="exportVendorsInProgress"
                 (click)="exportVendors()"
            >
                <span id="export-span"
                      class="material-symbols-outlined action-icon action-icon-margin"
                >download</span>
                <span class="export-label" data-testid="export-button">{{exportVendorsInProgress ? exportVendorsSelected : exportVendorsLabel}}</span>
            </div>
        </div>
    </div>
</div>
<div id="slideout" class="slideout" [class.show-details]="showDetails" data-testid="details-slideout">
    <app-slider-panel
        [isOpen]="showDetails"
        [vendorConfig]="vendorConfig"
        (closeSliderEvent)="closeSliderPanel()"
        (rejectVendorEvent)="rejectInReviewVendor($event)"
        (submitVendorEvent)="submitVendorDetails($event)"
        (addNoteEvent)="addVendorNote($event)"
        (hideVendorEvent)="hideInReviewVendor($event)"
    ></app-slider-panel>
</div>

<ng-template matTabContent #inReviewTabTemplate>
    <div id="in-review-table" class="approved-table-template" data-testid="approved-table-template">
        <div style="height: 20px;">&nbsp;</div>
        <div id="review-header-bar" class="header-bar">
            <div class="header-text-div">
                <span id="review-header-text" class="header-text">{{vendorTitleInReview}}</span>
                <span class="material-icons-outlined info-icon tooltip-review">info</span>
            </div>
        </div>
        <div id="review-count-bar" class="count-bar" data-testid="count-bar-div">
            You have <span class="count-bar-count">{{vendorRecords && vendorRecords.length > 0 ? vendorRecords.length : 'no'}}</span> Vendor Accounts in this list.
        </div>
        <div id="review-search-bar" class="search-bar">
            <app-search
                placeholder="Search By Number, Title, Name, etc."
                data-testid="app-search"
                (newSearchEvent)="performSearch($event)"
            ></app-search>
            <app-filter
                [filterConfig]="filterConfig"
                data-testid="app-filter"
                (filterEvent)="filterTable($event)"
            ></app-filter>
            <div *ngIf="selected?.viewValue === dealCountLabel" class="deal-count-div">
                <div id="deal-input-div" class="deal-input-div">
                    <span class="deal-count-label">Deal Count</span>
                    <div class="deal-count-wrapper">
                        <form class="vendor-form" [formGroup]="form">
                            <mat-form-field>
                                <input id="deal-count-filter-input"
                                       class="deal-count-input"
                                       matInput
                                       formControlName="dealCount"
                                       #dealCountInput
                                       type="text"
                                       (keyup)="onDealCountChange($event.key)"
                                       data-testid="deal-count-filter-input"
                                />
                            </mat-form-field>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-container">
            <app-vendor-table id="inreview-table"
                              class="vendor-table"
                              data-testid="app-vendor-table"
                              [selectedTabIndex]="selectedTabIndex"
                              [vendorRecords]="vendorRecords"
                              [displayedColumns]="inReviewColumns"
                              [searchText]="searchValue"
                              [fetching]="fetching"
                              (showDetailsEvent)="openSlideoutPanel($event)"
                              (reviewEvent)="handleReviewEvent($event)"
            ></app-vendor-table>
        </div>
    </div>
</ng-template>

<ng-template matTabContent #approvedTabTemplate>
    <div id="approved-table-div" class="approved-table-template" data-testid="approved-table-template">
        <div style="height: 20px;">&nbsp;</div>
        <div id="approved-header-bar" class="header-bar">
            <div class="header-text-div">
                <span id="approved-header-text" class="header-text">{{vendorTitleApproved}}</span>
                <span class="material-icons-outlined info-icon">info</span>
            </div>
            <button mat-flat-button
                    id="add-new-button"
                    data-testid="add-new-button"
                    class="add-new-button"
                    (click)="addRow()"
                    *appAccessCheckSection="addSectionTitle"
            >Add New Vendor</button>
        </div>
        <div id="approved-count-bar" class="count-bar" data-testid="count-bar-div">
            You have <span class="count-bar-count">{{vendorRecords && vendorRecords.length > 0 ? vendorRecords.length : 'no'}}</span> Vendor Accounts in this list.
        </div>
        <div id="approved-search-bar" class="search-bar">
            <app-search
                data-testid="app-search"
                (newSearchEvent)="performSearch($event)"></app-search>
        </div>
        <div class="table-container">
            <app-vendor-table id="approved-table"
                              class="vendor-table"
                              data-testid="app-vendor-table"
                              [selectedTabIndex]="selectedTabIndex"
                              [vendorRecords]="vendorRecords"
                              [displayedColumns]="approvedColumns"
                              [searchText]="searchValue"
                              [fetching]="fetching"
                              (editDeleteEvent)="handleEditDelete($event)"
                              (showDetailsEvent)="openSlideoutPanel($event)"
                              (selectedIdsEvent)="idsForExport($event)"
            ></app-vendor-table>
        </div>
    </div>
</ng-template>

<ng-template matTabContent #rejectedTabTemplate>
    <div id="rejected-table-div" class="rejected-table-template" data-testid="rejected-table-template">
        <div style="height: 20px;">&nbsp;</div>
        <div id="rejected-header-bar" class="header-bar">
            <div class="header-text-div">
                <span id="rejected-header-text" class="header-text">{{vendorTitleRejected}}</span>
                <span class="material-icons-outlined info-icon">info</span>
            </div>
        </div>
        <div id="rejected-count-bar" class="count-bar" data-testid="count-bar-div">
            You have <span class="count-bar-count">{{vendorRecords && vendorRecords.length > 0 ? vendorRecords.length : 'no'}}</span> Vendor Accounts in this list.
        </div>
        <div id="rejected-search-bar" class="search-bar">
            <app-search
                data-testid="app-search"
                (newSearchEvent)="performSearch($event)"></app-search>
        </div>
        <div class="table-container">
            <app-vendor-table id="rejected-table"
                              class="vendor-table"
                              data-testid="app-vendor-table"
                              [selectedTabIndex]="selectedTabIndex"
                              [vendorRecords]="vendorRecords"
                              [displayedColumns]="rejectedColumns"
                              [searchText]="searchValue"
                              [fetching]="fetching"
                              (showDetailsEvent)="openSlideoutPanel($event)"
            ></app-vendor-table>
        </div>
    </div>
</ng-template>
