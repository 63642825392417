import {
    Component,
    ElementRef,
    EventEmitter,
    OnInit,
    HostListener,
    Input,
    Output,
    SimpleChanges,
    ViewChild,
    OnChanges,
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import {
    AdminRequestHelper,
    InternalAdminService,
    GetType,
    UserDeal,
    SingleFullDealRecord,
    ModalConfigData,
    UserRecord,
    DealRecord,
    RequestDealUserRole,
    IntAdmPermissions,
} from '../shared';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { RoleRecord } from '../shared/roles.types';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { InternalAdminModalComponent } from '../internal-admin-modal/internal-admin-modal.component';
import { InternalAdminSnackbarComponent } from '../internal-admin-snackbar/internal-admin-snackbar.component';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { MatAutocompleteSelectedEvent, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { map, startWith } from 'rxjs/operators';
import { UserService } from '../../core/services/user.service';
import { AccessTypes, AuthService } from '../../core/services/auth.service';

@Component({
    selector: 'app-user-slider-panel',
    templateUrl: './user-slider-panel.component.html',
    styleUrls: ['./user-slider-panel.component.scss'],
})
export class UserSliderPanelComponent implements OnInit, OnChanges {
    @HostListener('document:keydown.escape', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
        this.closeSlider();
    }

    //   trouble implementing since the select options are outside of the element and when clicked, calls closeSlider()
    //   @HostListener('document:click', ['$event'])
    //   clickout(event) {
    //       // If this element does not contain the target element, then a click
    //       // happened outside the panel and should be closed. But because this is
    //       // listening to all document clicks, including the click to open the
    //       // slider, the click count needs to be reset after (re)opening. This is
    //       // to effectively ignore initial click to open the container.
    //       if (!this.eRef.nativeElement.contains(event.target) && this.clickCount > 0 && this.isOpen) {
    //           this.closeSlider();
    //       }

    //       this.clickCount++;
    //   }

    public get isOpen(): boolean {
        return this._isOpen;
    }

    @Input() set isOpen(value: boolean) {
        this._isOpen = value;
        if (this._isOpen) {
            this.clickCount = 0;
            // The selected deal may not be set by the time isOpen() is called.
            this.addUserForm.controls.deal.setValue(this.selectedDeal?.name);
            this.isDisabled = true;
        }
    }

    @Input() public selectedDeal: SingleFullDealRecord;
    @Input() editMode: boolean;
    @Input() allUsersEditMode: boolean;
    @Input() singleUserEditMode: boolean;
    @Input() addExistingUserMode: boolean;
    @Input() userToEdit: UserRecord;
    @Input() dealRecords: DealRecord[];
    @Input() userRecords: UserRecord[];
    @Input() addExistingUserRecords: UserRecord[];
    @Input() allUsersEditDealRecords: DealRecord[];
    @Input() singleUserEditDealRecords: DealRecord[];
    @Input() addExistingUserDealRecords: DealRecord[];

    @Output() closeSliderEvent = new EventEmitter<any>();
    @Output() selectionChange = new EventEmitter<MatSelectChange>();
    @Output() singleUserEditEvent = new EventEmitter<UserRecord>();
    @Output() addExistingUserSelectedUserIdEvent = new EventEmitter<number>();
    @Output() addExistingUserButtonClickEvent = new EventEmitter<any>();
    @Output() newUserButtonClickEvent = new EventEmitter<any>();

    @ViewChild('dealInput') dealInput: ElementRef<HTMLInputElement>;

    public roleRecords: RoleRecord[];
    public addUserForm = new FormGroup({
        firstName: new FormControl<String>(null, [Validators.required]),
        lastName: new FormControl<String>(null, [Validators.required]),
        existingUser: new FormControl<number>(null, [Validators.required]),
        email: new FormControl<String>(null, [Validators.required, Validators.email]),
        phoneNumber: new FormControl<String>(null),
        role: new FormControl<number>(null, [Validators.required]),
        deal: new FormControl<String>(null),
    });
    public isDisabled: boolean = false;
    public disableSelect = new FormControl(false);
    public currentRole: RoleRecord;
    public selectedExistingUser: UserRecord;
    public userDealRelRoleId: number;
    public userId: number;
    public dealId: number;
    public dealIds: number[] = [];
    public previousDealIds: number[] = [];
    public userDealIds: number[] = [];
    public dealsToAdd: string[];
    public removeCalled: boolean = false;
    public selectedCalled: boolean = false;
    public removedDeals: string[] = [];
    public addedDeals: string[] = [];
    public permissionAddUser = IntAdmPermissions.userAdd;
    public permissionRemoveUser = IntAdmPermissions.userRemove;
    public cardTitle: string;

    private requestHelper: AdminRequestHelper;
    private _isOpen = false;
    private clickCount = 0;
    private modalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: true,
        minWidth: '500px',
        minHeight: '200px',
    };

    public internalAdminModal: MatDialogRef<InternalAdminModalComponent>;

    public separatorKeysCodes: number[] = [ENTER, COMMA];
    public filteredDeals: Observable<string[] | DealRecord[]>;
    public deals: string[] = [];
    public allDeals: string[] = [];
    identify(index, deal) {
        return deal;
    }

    constructor(
        private internalAdminService: InternalAdminService,
        private snackBar: MatSnackBar,
        private eRef: ElementRef,
        private matDialog: MatDialog,
        private userService: UserService,
        private authService: AuthService,
    ) {
        this.filteredDeals = this.addUserForm.controls.deal.valueChanges.pipe(
            startWith(null),
            map((deal: string | null) => (deal ? this._filter(deal) : this.allDeals.slice())),
        );
    }

    public remove(dealName: string): void {
        if (this.addExistingUserMode && this.dealsToAdd.includes(dealName)) {
            return;
        }
        const index = this.deals.indexOf(dealName);
        if (index >= 0) {
            this.deals.splice(index, 1);
            this.allDeals.push(dealName);
        }
        this.removeCalled = true;
    }

    public selected(event: MatAutocompleteSelectedEvent): void {
        const dealToAdd = event.option.viewValue;
        if (this.deals.find((element) => element == dealToAdd)) {
            this.dealInput.nativeElement.value = '';
            this.addUserForm.controls.deal.setValue(null);
            this.selectedCalled = true;
            return;
        } else {
            const index = this.allDeals.indexOf(dealToAdd);
            this.deals.push(dealToAdd);
            this.allDeals.splice(index, 1);
            this.dealInput.nativeElement.value = '';
            this.addUserForm.controls.deal.setValue(null);
            this.selectedCalled = true;
        }
    }

    public _filter(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.allDeals.filter((dealName) => dealName.toLowerCase().includes(filterValue));
    }

    public closeSlider() {
        const closeSliderConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Are you sure?',
            },
        };
        // close slider if there are no values entered in form,
        // or if user is in edit mode and form is not dirty, else open modal
        if (
            !this.editMode &&
            !this.addUserForm.controls.firstName.value &&
            !this.addUserForm.controls.lastName.value &&
            !this.addUserForm.controls.email.value &&
            !this.addUserForm.controls.phoneNumber.value &&
            !this.addUserForm.controls.role.value &&
            this.deals.length === 0
        ) {
            if (!this.selectedDeal) {
                this.dealInput.nativeElement.value = '';
            }
            this.closeSliderEvent.next(null);
            this.addUserForm.reset();
            this.userDealIds = [];
        } else if (this.editMode && !this.addUserForm.dirty) {
            this.closeSliderEvent.next(null);
            this.addUserForm.reset();
            this.dealInput.nativeElement.value = '';
            this.userDealIds = [];
            for (let i = 0; i < this.allUsersEditDealRecords.length; i++) {
                this.remove(this.dealsToAdd[i]);
            }
        } else {
            this.openModal(closeSliderConfig);
        }
    }

    ngOnInit(): void {
        this.requestHelper = new AdminRequestHelper(GetType.roles);
        this.fetchRoles();
    }

    ngOnChanges(_: SimpleChanges): void {
        if (_.isOpen) {
            if (this.addExistingUserMode) {
                this.cardTitle = 'Add Deal Dasbhoard User';
            } else if (this.editMode) {
                this.cardTitle = 'Edit Deal Dashboard User';
                this.addUserForm.controls.existingUser.setValue(0);
            } else {
                this.cardTitle = 'Create Deal Dasbhoard User';
                this.addUserForm.controls.existingUser.setValue(0);
            }
        }
        if (_.dealRecords?.currentValue && !_.dealRecords?.previousValue) {
            this.allDeals = _.dealRecords.currentValue.map((deal) => deal.name);
        }
        if (this.addExistingUserMode && _.addExistingUserDealRecords) {
            this.allDeals = this.dealRecords.map((deal) => deal.name);
            this.removeCalled = false;
            this.selectedCalled = false;
            this.deals = [];
            this.previousDealIds = _.addExistingUserDealRecords.currentValue.map((deal) => deal.id);
            this.dealsToAdd = _.addExistingUserDealRecords.currentValue.map((deal) => deal.name);
            for (let i = 0; i < _.addExistingUserDealRecords.currentValue.length; i++) {
                const index = this.allDeals.indexOf(this.dealsToAdd[i]);
                this.deals.push(this.dealsToAdd[i]);
                this.allDeals.splice(index, 1);
            }
        }

        if (this.allUsersEditMode && _.allUsersEditDealRecords) {
            this.removeCalled = false;
            this.deals = [];
            this.previousDealIds = _.allUsersEditDealRecords.currentValue.map((deal) => deal.id);
            this.dealsToAdd = _.allUsersEditDealRecords.currentValue.map((deal) => deal.name);
            for (let i = 0; i < _.allUsersEditDealRecords.currentValue.length; i++) {
                const index = this.allDeals.indexOf(this.dealsToAdd[i]);
                this.deals.push(this.dealsToAdd[i]);
                this.allDeals.splice(index, 1);
            }
            const userIds = [this.userId];
            this.fetchUserDealRel(userIds, this.previousDealIds);
        } else if (this.singleUserEditMode && _.singleUserEditDealRecords) {
            this.removeCalled = false;
            this.deals = [];
            this.previousDealIds = _.singleUserEditDealRecords.currentValue.map((deal) => deal.id);
            this.dealsToAdd = _.singleUserEditDealRecords.currentValue.map((deal) => deal.name);
            for (let i = 0; i < _.singleUserEditDealRecords.currentValue.length; i++) {
                const index = this.allDeals.indexOf(this.dealsToAdd[i]);
                if (index === -1) {
                    this.deals.push(this.dealsToAdd[i]);
                } else {
                    this.deals.push(this.dealsToAdd[i]);
                    this.allDeals.splice(index, 1);
                }
            }
            const userIds = [this.userId];
            this.fetchUserDealRel(userIds, this.previousDealIds);
        }

        if (this.editMode && (_.editMode || _.userToEdit || _.singleUserEditDealRecords)) {
            this.userId = this.userToEdit.id;
            if (this.selectedDeal) {
                this.dealId = this.selectedDeal.id;
                this.dealIds = [];
                this.dealIds.push(this.dealId);
                const userIds = [this.userId];
                this.fetchUserDealRel(userIds, this.dealIds);
            }
            this.addUserForm.controls.firstName.setValue(this.userToEdit.firstName);
            this.addUserForm.controls.lastName.setValue(this.userToEdit.lastName);
            this.addUserForm.controls.email.setValue(this.userToEdit.email);
            this.addUserForm.controls.phoneNumber.setValue(this.userToEdit.phoneNr);
        }
    }

    public onRoleChange(selectionChange) {
        this.currentRole = this.roleRecords.find((role) => role.id === selectionChange.value);
    }

    public onAddExistingUserChange(selectionChange) {
        this.addUserForm.controls.firstName.setValue('test');
        this.addUserForm.controls.lastName.setValue('test');
        this.addUserForm.controls.email.setValue('z@z');
        this.selectedExistingUser = this.addExistingUserRecords.find((user) => user.id === selectionChange.value);
        this.addExistingUserSelectedUserIdEvent.emit(this.selectedExistingUser.id);
    }

    public onAddExistingUserClick() {
        this.addExistingUserButtonClickEvent.next(null);
        this.cardTitle = 'Add Deal Dashboard User';
        this.addUserForm.reset();
        if (!this.selectedDeal) {
            this.dealInput.nativeElement.value = '';
            this.deals = [];
            this.userDealIds = [];
            this.allDeals = this.dealRecords.map((deal) => deal.name);
            this.addUserForm.controls.deal.setValue(null);
        } else {
            this.userDealIds = [];
            this.addUserForm.controls.deal.setValue(this.selectedDeal.name);
        }
    }

    public onNewUserClick() {
        this.newUserButtonClickEvent.next(null);
        this.cardTitle = 'Create Deal Dashboard User';
        this.addUserForm.reset();
        this.addUserForm.controls.existingUser.setValue(0);
        if (!this.selectedDeal) {
            this.dealInput.nativeElement.value = '';
            this.deals = [];
            this.userDealIds = [];
            this.allDeals = this.dealRecords.map((deal) => deal.name);
            this.addUserForm.controls.deal.setValue(null);
        } else {
            this.userDealIds = [];
            this.addUserForm.controls.deal.setValue(this.selectedDeal.name);
        }
    }

    public onSubmit(form: FormGroup, action?: string) {
        if (this.selectedDeal) {
            this.userDealIds.push(this.selectedDeal.id);
        } else {
            for (let i = 0; i < this.deals.length; i++) {
                this.userDealIds.push(this.dealRecords.find((element) => element.projectName === this.deals[i]).id);
            }
        }
        const newUser: UserDeal = {
            user: {
                id: this.userToEdit ? this.userToEdit.id : 0,
                firstName: form.value.firstName,
                lastName: form.value.lastName,
                email: form.value.email,
                phoneNr: form.value.phoneNumber,
                auth0Id: this.userToEdit ? this.userToEdit.auth0Id : null,
            },
            previousDealIds:
                this.allUsersEditMode || this.singleUserEditMode || this.addExistingUserMode
                    ? this.previousDealIds
                    : null,
            dealIds: this.userDealIds,
            roleId: form.value.role,
            byId: this.userService.user.sub,
            byName: this.userService.user.name,
        };
        if (this.editMode) {
            this.editUser(newUser);
        } else if (this.addExistingUserMode) {
            newUser.user = { ...this.selectedExistingUser };
            this.createUser(newUser, action);
        } else {
            this.createUser(newUser, action);
        }
        for (let name in form.controls) {
            form.controls[name].setErrors(null);
        }
        this.userDealIds = [];
    }

    public removeUserFromDeal() {
        const removeUserFromDealConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Remove user from deal?',
                userName: `${this.addUserForm.controls.firstName.value} ${this.addUserForm.controls.lastName.value}`,
                dealName: `${this.addUserForm.controls.deal.value}`,
            },
        };
        this.openModal(removeUserFromDealConfig);
    }

    public handleAllUsersSave(form: FormGroup) {
        this.removedDeals = [];
        this.addedDeals = [];
        // figure out what deals the user will be removed from, based on editDealRecords

        if (this.allUsersEditMode) {
            for (let i = 0; i < this.allUsersEditDealRecords.length; i++) {
                if (!this.deals.includes(this.allUsersEditDealRecords[i].name)) {
                    this.removedDeals.push(this.allUsersEditDealRecords[i].name);
                }
            }
        }

        if (this.singleUserEditMode) {
            for (let i = 0; i < this.singleUserEditDealRecords.length; i++) {
                if (!this.deals.includes(this.singleUserEditDealRecords[i].name)) {
                    this.removedDeals.push(this.singleUserEditDealRecords[i].name);
                }
            }
        }
        // determine what deals the user will be added to, based on dealsToAdd
        for (let i = 0; i < this.deals.length; i++) {
            if (!this.dealsToAdd.includes(this.deals[i])) {
                this.addedDeals.push(this.deals[i]);
            }
        }

        if (this.removedDeals.length > 0) {
            let modalTitle: string;
            if (this.removedDeals.length === 1) {
                modalTitle = 'Remove user from deal?';
            } else {
                modalTitle = 'Remove user from deals?';
            }
            const removeUserFromDealsConfig: MatDialogConfig<ModalConfigData> = {
                ...this.modalConfigTemplate,
                data: {
                    title: modalTitle,
                    userName: `${this.addUserForm.controls.firstName.value} ${this.addUserForm.controls.lastName.value}`,
                    dealName: this.removedDeals.join(', and '),
                },
            };
            this.openModal(removeUserFromDealsConfig);
        } else {
            this.onSubmit(form);
        }
    }

    public canAddUser(): boolean {
        return this.authService.checkAccess(this.permissionAddUser) === AccessTypes.FULL;
    }

    private fetchRoles() {
        this.internalAdminService.getRoles(this.requestHelper).subscribe({
            next: (res: any) => {
                this.roleRecords = res.body;
            },
            error: (error) => this.handleError(error),
        });
    }

    private createUser(user: UserDeal, action?: string) {
        if (action === 'save') {
            if (!this.addExistingUserMode) {
                this.internalAdminService.createUser(user).subscribe({
                    next: (res: any) => {
                        this.closeSliderEvent.next(null);
                        if (this.selectedDeal) {
                            this.snackBar.openFromComponent(InternalAdminSnackbarComponent, {
                                data: `You've added ${user.user.firstName} ${user.user.lastName} to ${this.addUserForm.controls.deal.value}`,
                                duration: 10000,
                            });
                        } else {
                            this.snackBar.openFromComponent(InternalAdminSnackbarComponent, {
                                data: `You've added ${user.user.firstName} ${user.user.lastName} to
                                       ${this.deals ? this.deals.length : 0} projects`,
                                duration: 10000,
                            });
                            this.deals = [];
                        }
                        this.addUserForm.reset();
                        this.dealInput.nativeElement.value = '';
                        this.userDealIds = [];
                    },
                    error: (error) => this.handleError(error),
                });
            } else {
                // need to only add new dealIds to dealIds array to prevent duplicate records getting created
                for (let i = 0; i < user.previousDealIds.length; i++) {
                    if (user.dealIds.includes(user.previousDealIds[i])) {
                        const index = user.dealIds.findIndex((dealId) => dealId === user.previousDealIds[i]);
                        user.dealIds.splice(index, 1);
                    }
                }

                this.internalAdminService.addExistingUser(user).subscribe({
                    next: (res: any) => {
                        this.closeSliderEvent.next(null);
                        if (this.selectedDeal) {
                            this.snackBar.openFromComponent(InternalAdminSnackbarComponent, {
                                data: `You've added ${user.user.firstName} ${user.user.lastName} to ${this.addUserForm.controls.deal.value}`,
                                duration: 10000,
                            });
                        } else {
                            this.snackBar.openFromComponent(InternalAdminSnackbarComponent, {
                                data: `You've added ${user.user.firstName} ${user.user.lastName} to
                                       ${
                                           this.deals ? this.deals.length - this.addExistingUserDealRecords.length : 0
                                       } projects`,
                                duration: 10000,
                            });
                            this.deals = [];
                        }
                        this.addUserForm.reset();
                        this.dealInput.nativeElement.value = '';
                        this.userDealIds = [];
                    },
                    error: (error) => this.handleError(error),
                });
            }
        } else if (action === 'save and add') {
            if (!this.addExistingUserMode) {
                this.internalAdminService.createUser(user).subscribe({
                    next: (res: any) => {
                        if (this.selectedDeal) {
                            this.snackBar.openFromComponent(InternalAdminSnackbarComponent, {
                                data: `You've added ${user.user.firstName} ${user.user.lastName} to ${this.addUserForm.controls.deal.value}`,
                                duration: 10000,
                            });
                            this.addUserForm.reset();
                            this.userDealIds = [];
                            this.addUserForm.controls.deal.setValue(this.selectedDeal.name);
                            this.addUserForm.controls.existingUser.setValue(0);
                        } else {
                            this.snackBar.openFromComponent(InternalAdminSnackbarComponent, {
                                data: `You've added ${user.user.firstName} ${user.user.lastName} to
                                       ${this.deals ? this.deals.length : 0} projects`,
                                duration: 10000,
                            });
                            this.addUserForm.reset();
                            this.dealInput.nativeElement.value = '';
                            this.deals = [];
                            this.userDealIds = [];
                            this.allDeals = this.dealRecords.map((deal) => deal.name);
                            this.addUserForm.controls.existingUser.setValue(0);
                        }
                    },
                    error: (error) => this.handleError(error),
                });
            } else {
                for (let i = 0; i < user.previousDealIds.length; i++) {
                    if (user.dealIds.includes(user.previousDealIds[i])) {
                        const index = user.dealIds.findIndex((dealId) => dealId === user.previousDealIds[i]);
                        user.dealIds.splice(index, 1);
                    }
                }
                this.internalAdminService.addExistingUser(user).subscribe({
                    next: (res: any) => {
                        if (this.selectedDeal) {
                            this.snackBar.openFromComponent(InternalAdminSnackbarComponent, {
                                data: `You've added ${user.user.firstName} ${user.user.lastName} to ${this.addUserForm.controls.deal.value}`,
                                duration: 10000,
                            });
                            this.addUserForm.reset();
                            this.userDealIds = [];
                            this.addUserForm.controls.deal.setValue(this.selectedDeal.name);
                            //remove user from addExistingUserRecords
                            const index = this.addExistingUserRecords.findIndex(
                                (existingUser) => existingUser.id === user.user.id,
                            );
                            this.addExistingUserRecords.splice(index, 1);
                        } else {
                            this.snackBar.openFromComponent(InternalAdminSnackbarComponent, {
                                data: `You've added ${user.user.firstName} ${user.user.lastName} to
                                       ${
                                           this.deals ? this.deals.length - this.addExistingUserDealRecords.length : 0
                                       } projects`,
                                duration: 10000,
                            });
                            this.addUserForm.reset();
                            this.dealInput.nativeElement.value = '';
                            this.deals = [];
                            this.userDealIds = [];
                            this.allDeals = this.dealRecords.map((deal) => deal.name);
                        }
                    },
                    error: (error) => this.handleError(error),
                });
            }
        }
    }

    private editUser(user: UserDeal) {
        if (!this.allUsersEditMode && !this.singleUserEditMode) {
            this.internalAdminService.editUser(user).subscribe({
                next: (res: any) => {
                    this.closeSliderEvent.next(null);
                    this.snackBar.openFromComponent(InternalAdminSnackbarComponent, {
                        data: `You've edited ${user.user.firstName} ${user.user.lastName}`,
                        duration: 10000,
                    });
                    this.addUserForm.reset();
                    this.dealInput.nativeElement.value = '';
                    this.userDealIds = [];
                },
                error: (error) => this.handleError(error),
            });
        } else {
            let modalString = `You've edited ${user.user.firstName} ${user.user.lastName}`;
            if (this.removedDeals.length > 0) {
                modalString += ` and removed them from ${this.removedDeals.length}
                    ${this.removedDeals.length === 1 ? 'deal' : 'deals'}`;
            }
            if (this.addedDeals.length > 0) {
                modalString += ` and added them to ${this.addedDeals.length}
                    ${this.addedDeals.length === 1 ? 'deal' : 'deals'}`;
            }
            this.internalAdminService.editUser(user).subscribe({
                next: (res: any) => {
                    this.closeSliderEvent.next(null);
                    if (this.singleUserEditMode) {
                        this.singleUserEditEvent.next(user.user);
                    }
                    this.snackBar.openFromComponent(InternalAdminSnackbarComponent, {
                        data: modalString + '.',
                        duration: 10000,
                    });
                    this.addUserForm.reset();
                    this.dealInput.nativeElement.value = '';
                    this.userDealIds = [];
                },
                error: (error) => this.handleError(error),
            });
        }
    }

    private fetchUserDealRel(userIds: number[], dealIds: number[]) {
        this.internalAdminService.getUserDealRel(userIds, dealIds).subscribe({
            next: (res: any) => {
                if (res.body && Array.isArray(res.body) && res.body.length > 0) {
                    this.userDealRelRoleId = res.body[0].role_id;
                    this.currentRole = this.roleRecords.find((role) => role.id === this.userDealRelRoleId);
                    this.addUserForm.controls.role.setValue(this.userDealRelRoleId);
                }
            },
            error: (error) => this.handleError(error),
        });
    }

    private deleteUserFromDeal(user: UserRecord, userId: number, dealId: number) {
        const config: RequestDealUserRole = {
            dealId,
            userId,
            byId: this.userService.user.sub,
            byName: this.userService.user.name,
        };

        this.internalAdminService.deleteUserFromDeal(config).subscribe({
            next: (res: any) => {
                this.closeSliderEvent.next(null);
                this.snackBar.openFromComponent(InternalAdminSnackbarComponent, {
                    data: `You've updated ${user.firstName} ${user.lastName} within ${this.selectedDeal.name}`,
                    duration: 10000,
                });
            },
        });
    }

    private handleError(error: HttpErrorResponse): void {
        error['stack'] ? console.error(error['stack']) : console.error(error);

        let msg: string;
        if (error.status === 400 && error.error.message.includes('User with provided email address already exists.')) {
            msg = 'User with provided email address already exists.';
            this.addUserForm.controls.email.setErrors({
                emailAlreadyExists: true,
                invalid: true,
            });
        } else if (error?.status >= 400 && error?.status < 500) {
            msg = error?.error?.message ? error.error.message : 'Server error';
        } else {
            msg = 'Server error';
        }

        this.snackBar.open(msg, undefined, {
            duration: 10000,
        });
    }

    private openModal(modalConfig: MatDialogConfig) {
        this.internalAdminModal = this.matDialog.open(InternalAdminModalComponent, modalConfig);
        this.internalAdminModal.componentInstance.confirmButtonEvent.subscribe((emmitedValue) => {
            if (
                (emmitedValue && !this.editMode) ||
                (this.editMode && emmitedValue && !modalConfig.data.userName && !modalConfig.data.dealName)
            ) {
                this.closeSliderEvent.next(null);
                this.internalAdminModal.close();
                this.addUserForm.reset();
                this.dealInput.nativeElement.value = '';
                this.deals = [];
            } else if (
                this.editMode &&
                !this.allUsersEditMode &&
                !this.singleUserEditMode &&
                emmitedValue &&
                modalConfig.data.userName &&
                modalConfig.data.dealName
            ) {
                this.internalAdminModal.close();
                this.addUserForm.reset();
                this.deleteUserFromDeal(this.userToEdit, this.userId, this.dealId);
            } else if (
                this.editMode &&
                (this.allUsersEditMode || this.singleUserEditMode) &&
                emmitedValue &&
                modalConfig.data.userName &&
                modalConfig.data.dealName
            ) {
                this.onSubmit(this.addUserForm);
                this.internalAdminModal.close();
            }
        });
        this.internalAdminModal.componentInstance.cancelButtonEvent.subscribe((emmitedValue) => {
            if (emmitedValue) {
                this.internalAdminModal.close();
            }
        });
    }
}
