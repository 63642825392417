import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import {
    ModalConfigData,
    ModalReviewConfigData,
    ReviewStatusType,
    VendorDetailConfig,
    VendorRecord,
    VendorService,
    VendorTabNames,
} from './shared';
import { ModalComponent } from './modal/modal.component';
import { FilterConfig, FilterOption } from '../components/filter/filter.model.types';
import { AvailableColumns } from './table/table.columns.types';
import { ModalSimpleComponent } from './modal-simple/modal-simple.component';
import { SimpleModalConfigData, SimpleModalResult } from './modal-simple/modal-simple.config.type';
import { cloneDeep } from 'lodash-es';
import { UserService } from '../core/services/user.service';
import { ModalReviewComponent } from './modal-review/modal-review.component';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-vendor-payments',
    templateUrl: './vendor-payments.component.html',
    styleUrls: ['./vendor-payments.component.scss'],
})
export class VendorPaymentsComponent implements OnInit {
    // The static:false makes that Angular check if is under a *ngIf.
    // The read:ElementRef makes that the input is seen as ElementRef, not MatInput.
    @ViewChild('dealCountInput', { static: false, read: ElementRef }) dealCountInput: ElementRef;

    public vendorTitleInReview = 'Vendor Review List';
    public vendorTitleApproved = 'Approved Vendors';
    public vendorTitleRejected = 'Rejected / Deleted Vendors';
    public vendorRecords: VendorRecord[];
    public fullList: VendorRecord[];
    public searchValue: string;
    public addSectionTitle = 'Add';
    public selectedTabIndex = 0;
    private tabInReview = 0;
    private tabApproved = 1;
    private tabRejected = 2;

    public showDetails: boolean = false;
    public vendorModal: MatDialogRef<ModalComponent>;
    public vendorReviewModal: MatDialogRef<ModalReviewComponent>;
    public vendorSimpleModal: MatDialogRef<ModalSimpleComponent>;
    public result: boolean = false;
    public vendorConfig: VendorDetailConfig;
    public exportVendorsDefault = 'Export';
    public exportVendorsSelected = 'Export Selected';
    public exportVendorsLabel = this.exportVendorsDefault;
    public exportVendorsInProgress = false;
    public fetching = true;

    public inReviewColumns: AvailableColumns[] = [
        AvailableColumns.PayeeName,
        AvailableColumns.AccountTitle,
        AvailableColumns.AccountNumber,
        AvailableColumns.AbaSwift,
        AvailableColumns.Subsidiary,
        AvailableColumns.TaxpayerId,
        AvailableColumns.ReviewStatusInReview,
        AvailableColumns.ReviewType,
        AvailableColumns.DealCount,
        AvailableColumns.Details,
    ];
    public approvedColumns: AvailableColumns[] = [
        AvailableColumns.PayeeName,
        AvailableColumns.AccountTitle,
        AvailableColumns.AccountNumber,
        AvailableColumns.AbaSwift,
        AvailableColumns.Subsidiary,
        AvailableColumns.TaxpayerId,
        AvailableColumns.BankCountry,
        AvailableColumns.Currency,
        AvailableColumns.SortCode,
        AvailableColumns.UpdatedDt,
        AvailableColumns.Details,
    ];
    public rejectedColumns: AvailableColumns[] = [
        AvailableColumns.PayeeName,
        AvailableColumns.AccountTitle,
        AvailableColumns.AccountNumber,
        AvailableColumns.AbaSwift,
        AvailableColumns.Subsidiary,
        AvailableColumns.TaxpayerId,
        AvailableColumns.ReviewStatus,
        AvailableColumns.RejectionDate,
        AvailableColumns.Details,
    ];

    public form: FormGroup = new FormGroup({
        dealCount: new FormControl<number>(null),
    });
    public selected: FilterOption;
    private default = 'None';
    public dealCountLabel = 'Deal Count';

    private dealCountFilter: FilterOption = {
        value: this.dealCountLabel,
        viewValue: this.dealCountLabel,
    };
    public filterConfig: FilterConfig = {
        label: 'Filter by',
        filterOptions: [
            { value: this.default, viewValue: this.default },
            { value: 'new', viewValue: 'New Eligible Vendor' },
            { value: 'edit-review', viewValue: 'Edit to Approved Vendor' },
            { value: 'delete-review', viewValue: 'Delete Approved Vendor' },
            { value: 'reversal-review', viewValue: 'Reverse Rejected / Deleted Vendor' },
            { value: 'reviewed', viewValue: 'Reviewed Vendors' },
            this.dealCountFilter,
        ],
    };

    protected readonly VendorTabNames = VendorTabNames;

    private selectedIds: number[];
    private filterValue: string;
    private modalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: false,
        minWidth: '600px',
        minHeight: '400px',
    };

    private modalReviewConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: false,
        maxWidth: '90vw',
        maxHeight: '90vh',
    };

    private rejectModalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: false,
        minWidth: '560px',
        minHeight: '530px',
    };

    private reverseModalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: false,
        minWidth: '570px',
        minHeight: '600px',
    };

    private deleteModalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: false,
        minWidth: '575px',
        minHeight: '530px',
    };

    private newNoteModalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: false,
        minWidth: '500px',
        minHeight: '320px',
    };

    private exportVendorsModalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: false,
        minWidth: '500px',
        minHeight: '270px',
    };

    private hideVendorModalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: false,
        minWidth: '570px',
        minHeight: '300px',
    };

    constructor(
        public matDialog: MatDialog,
        private snackBar: MatSnackBar,
        private vendorPaymentsService: VendorService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        this.fetch();
    }

    private fetch() {
        this.fetching = true;
        switch (this.selectedTabIndex) {
            case 0: {
                this.vendorPaymentsService.getInReviewVendors().subscribe({
                    next: (res) => {
                        this.fetching = false;
                        if (res && res.length > 0) {
                            res.forEach((row) => {
                                if (row.reviewedBy && row.reviewedDt && !row.approvedBy && !row.approvedDt) {
                                    row.reviewType = 'Reviewed';
                                } else {
                                    switch (row.status) {
                                        case ReviewStatusType.NEW: {
                                            row.reviewType = 'New Eligible Vendor';
                                            break;
                                        }
                                        case ReviewStatusType.EDIT_REVIEW: {
                                            row.reviewType = 'Edit to Approved Vendor';
                                            break;
                                        }
                                        case ReviewStatusType.DELETE_REVIEW: {
                                            row.reviewType = 'Delete Approved Vendor';
                                            break;
                                        }
                                        case ReviewStatusType.REVERSAL_REVIEW: {
                                            row.reviewType = 'Reverse Rejected / Deleted Vendor';
                                            break;
                                        }
                                        default: {
                                            row.reviewType = '';
                                        }
                                    }
                                }
                            });
                            this.vendorRecords = this.fullList = res;
                        } else {
                            this.vendorRecords = this.fullList = [];
                        }
                    },
                    error: (error) => this.handleError(error),
                });

                break;
            }
            case 1: {
                this.vendorPaymentsService.getApprovedVendors().subscribe({
                    next: (res) => {
                        this.fetching = false;
                        this.vendorRecords = this.fullList = res;
                    },
                    error: (error) => this.handleError(error),
                });

                break;
            }
            case 2: {
                this.vendorPaymentsService.getRejectedVendors().subscribe({
                    next: (res) => {
                        this.fetching = false;
                        this.vendorRecords = this.fullList = res;
                    },
                    error: (error) => this.handleError(error),
                });
            }
        }
    }

    public onTabChanged() {
        this.fetch();
    }

    public rejectInReviewVendor($event: VendorRecord) {
        // Each vendor status is rejected differently
        switch ($event.status) {
            case ReviewStatusType.NEW: {
                // New eligible vendors in review get a modal to confirm the
                // rejection and enter a reason why.
                const rejectVendorConfig: MatDialogConfig<SimpleModalConfigData> = {
                    ...this.rejectModalConfigTemplate,
                    data: {
                        title: 'Reject This Vendor Account?',
                        action: 'reject',
                        selected: $event,
                    },
                };

                this.openSimpleModal(rejectVendorConfig);

                break;
            }
            case ReviewStatusType.DELETE_REVIEW:
            case ReviewStatusType.EDIT_REVIEW:
            case ReviewStatusType.REVERSAL_REVIEW: {
                this.vendorPaymentsService.rejectInReviewVendorEditDelete($event).subscribe({
                    next: () => {},
                    error: (error) => this.handleError(error),
                });

                this.closeSliderPanel();

                this.fetch();

                break;
            }
        }
    }

    public hideInReviewVendor($event: VendorRecord) {
        // Each vendor status is rejected differently
        switch ($event.status) {
            case ReviewStatusType.NEW: {
                // New eligible vendors in review get a modal to confirm the
                // hide request.
                const rejectVendorConfig: MatDialogConfig<SimpleModalConfigData> = {
                    ...this.hideVendorModalConfigTemplate,
                    data: {
                        title: 'Hide Vendor',
                        action: 'hide',
                        selected: $event,
                    },
                };

                this.openSimpleModal(rejectVendorConfig);

                break;
            }
        }
    }

    public submitVendorDetails($event: VendorRecord) {
        // In this case the action is only for when it has been reviewed but not approved,
        // because other status types are possible than those originating from the In Review tab.
        if ($event.reviewedBy && $event.reviewedDt && !$event.approvedBy && !$event.approvedDt) {
            this.vendorPaymentsService.approveInReviewVendor($event).subscribe({
                next: () => {
                    this.closeSliderPanel();

                    this.fetch();

                    this.snackBar.open(
                        'Success! The vendor has been approved and moved to the list of Approved Vendors.',
                        undefined,
                        {
                            duration: 6000,
                        },
                    );
                },
                error: (error) => this.handleError(error),
            });
        } else {
            switch ($event.status) {
                case ReviewStatusType.NEW:
                case ReviewStatusType.DELETE_REVIEW:
                case ReviewStatusType.EDIT_REVIEW:
                case ReviewStatusType.REVERSAL_REVIEW: {
                    this.vendorPaymentsService.markInReviewVendorAsReviewed($event).subscribe({
                        next: () => {
                            this.closeSliderPanel();

                            this.fetch();

                            this.snackBar.open('Vendor successfully marked as reviewed.', undefined, {
                                duration: 6000,
                            });
                        },
                        error: (error) => this.handleError(error),
                    });

                    break;
                }
                case ReviewStatusType.REJECTED:
                case ReviewStatusType.DELETED: {
                    // Rejected or deleted vendors on the Rejected / Deleted tab need
                    // confirmation before moving them back to In Review.
                    const rejectVendorConfig: MatDialogConfig<SimpleModalConfigData> = {
                        ...this.reverseModalConfigTemplate,
                        data: {
                            title: 'Confirm Reversal?',
                            action: 'reverse',
                            selected: $event,
                        },
                    };

                    this.openSimpleModal(rejectVendorConfig);

                    break;
                }
            }
        }
    }

    public addRow() {
        const addRowConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Add New Vendor',
                action: 'create',
            },
        };
        this.openModal(addRowConfig);
    }

    public handleEditDelete($event: ModalConfigData) {
        switch ($event.action) {
            case 'edit': {
                // Cloning this vendor record to avoid altering the underlying approved vendor record.
                $event.selected = cloneDeep($event.selected);

                const editRowConfig: MatDialogConfig<ModalConfigData> = {
                    ...this.modalConfigTemplate,
                    data: $event,
                };
                this.openModal(editRowConfig);

                break;
            }
            case 'delete': {
                const deleteVendorConfig: MatDialogConfig<SimpleModalConfigData> = {
                    ...this.deleteModalConfigTemplate,
                    data: {
                        title: 'Delete Vendor Account?',
                        action: 'delete',
                        selected: $event.selected,
                    },
                };

                this.openSimpleModal(deleteVendorConfig);

                break;
            }
        }
    }

    public handleReviewEvent($event: ModalReviewConfigData) {
        switch ($event.action) {
            case 'review': {
                // Cloning this vendor record to avoid altering the underlying approved vendor record.
                $event.selected = cloneDeep($event.selected);

                const reviewConfig: MatDialogConfig<ModalReviewConfigData> = {
                    ...this.modalReviewConfigTemplate,
                    data: $event,
                };
                this.openReviewModal(reviewConfig);

                break;
            }
        }
    }

    public openSlideoutPanel($event: VendorRecord) {
        this.vendorConfig = {
            selected: $event,
            // Disable the submit button if the reviewer is the same person who
            // submitted the edit/delete/reversal, or if the approver is the same
            // person who marked the vendor as reviewed.

            // Note that change submitted by is an ID while reviewed by is a NAME.
            submitDisabled:
                $event?.changeSubmittedById === this.userService.user.sub ||
                $event.reviewedBy === this.userService.user.name,
        };

        // Get All notes for this vendor record
        this.vendorPaymentsService
            .getNotesForVendorByVendorId($event.id)
            .subscribe((notes) => (this.vendorConfig.notes = notes));

        // In this case the button titles are only for when it has been reviewed but not approved,
        // because other status types are possible than those originating from the In Review tab.
        if ($event.reviewedBy && $event.reviewedDt && !$event.approvedBy && !$event.approvedDt) {
            this.vendorConfig.title = 'Account Details';
            this.vendorConfig.primaryButtonTitle = 'Approve';
            this.vendorConfig.secondaryButtonTitle = 'Reject';
        } else {
            switch ($event.status) {
                case ReviewStatusType.NEW: {
                    this.vendorConfig.title = 'New Vendor Details';
                    this.vendorConfig.primaryButtonTitle = 'Mark as Reviewed';
                    this.vendorConfig.secondaryButtonTitle = 'Reject';
                    this.vendorConfig.tertiaryButtonTitle = 'Hide';

                    break;
                }
                case ReviewStatusType.EDIT_REVIEW: {
                    this.vendorConfig.title = 'Account Details';
                    this.vendorConfig.primaryButtonTitle = 'Mark as Reviewed';
                    this.vendorConfig.secondaryButtonTitle = 'Reject Edit';

                    break;
                }
                case ReviewStatusType.DELETE_REVIEW: {
                    this.vendorConfig.title = 'Account Details';
                    this.vendorConfig.primaryButtonTitle = 'Mark as Reviewed';
                    this.vendorConfig.secondaryButtonTitle = 'Reject Delete';

                    break;
                }
                case ReviewStatusType.REVERSAL_REVIEW: {
                    this.vendorConfig.title = 'Account Details';
                    this.vendorConfig.primaryButtonTitle = 'Mark as Reviewed';
                    this.vendorConfig.secondaryButtonTitle = 'Reject Reversal';

                    break;
                }
                case ReviewStatusType.DELETED:
                case ReviewStatusType.REJECTED: {
                    this.vendorConfig.title = 'Account Details';
                    this.vendorConfig.primaryButtonTitle = 'Reverse Rejection';

                    break;
                }
                default: {
                    this.vendorConfig.title = 'Account Details';
                    this.vendorConfig.primaryButtonTitle = null;
                    this.vendorConfig.secondaryButtonTitle = null;

                    break;
                }
            }
        }

        this.showDetails = true;
    }

    public closeSliderPanel() {
        this.showDetails = false;
    }

    public performSearch(searchVal: string) {
        this.searchValue = searchVal;

        this.vendorRecords = this.refreshTable(this.fullList, this.filterValue, this.searchValue);
    }

    public filterTable($event: FilterOption) {
        this.selected = $event;
        if ($event?.viewValue.startsWith(this.dealCountLabel)) {
            this.form.controls.dealCount.setValue(1);

            this.dealCountFilter.value = `${this.dealCountLabel} ${this.form.controls.dealCount.value}`;

            // The delay parameter is omitted, meaning execute "immediately", or more accurately, the next event cycle.
            setTimeout(() => {
                // This fails with "this.dealCountInput is undefined" if not within setTimeout().
                this.dealCountInput.nativeElement.focus();
            });
        }

        if (!$event || $event.value === 'None') {
            this.filterValue = null;
        } else {
            this.filterValue = $event.value;
        }

        this.vendorRecords = this.refreshTable(this.fullList, this.filterValue, this.searchValue);
    }

    public onDealCountChange(value) {
        if (value && value.length === 1 && isNaN(value)) {
            this.form.controls.dealCount.setValue(
                this.form.controls.dealCount.value
                    .toString()
                    .substring(0, this.form.controls.dealCount.value.length - 1),
            );
        } else if (!value) {
            this.form.controls.dealCount.setValue(1);
        }

        if (this.form.controls?.dealCount?.value !== '') {
            this.dealCountFilter.value = `${this.dealCountLabel} ${this.form.controls.dealCount.value}`;
            this.filterValue = this.dealCountFilter.value;
        }

        this.vendorRecords = this.refreshTable(this.fullList, this.filterValue, this.searchValue);
    }

    public refreshTable(vendors: VendorRecord[], filterValue: string, searchVal: string): VendorRecord[] {
        let tempArray: VendorRecord[] = this.applyFilter(vendors, filterValue);

        if (searchVal) {
            tempArray = this.applySearch(tempArray, searchVal);
        }

        return tempArray;
    }

    public addVendorNote($event: VendorRecord) {
        const newNoteConfig: MatDialogConfig<SimpleModalConfigData> = {
            ...this.newNoteModalConfigTemplate,
            data: {
                title: 'New Note',
                action: 'newNote',
                selected: $event,
            },
        };

        this.openSimpleModal(newNoteConfig);
    }

    public isApprovedTab(): boolean {
        return Object.values(VendorTabNames)[this.selectedTabIndex] === VendorTabNames.APPROVED;
    }

    public exportVendors() {
        if (this.exportVendorsInProgress) {
            const newNoteConfig: MatDialogConfig<SimpleModalConfigData> = {
                ...this.exportVendorsModalConfigTemplate,
                data: {
                    title: 'Export Approved Vendors',
                    action: 'export',
                    selected: null,
                    exportIds: this.selectedIds,
                },
            };

            this.openSimpleModal(newNoteConfig);
        } else {
            this.approvedColumns.splice(0, 0, AvailableColumns.ExportVendor);
            this.exportVendorsInProgress = true;
        }
    }

    public idsForExport($event: any) {
        this.selectedIds = $event;
    }

    private applyFilter(array: VendorRecord[], value: string): VendorRecord[] {
        let tempVendors: VendorRecord[] = [];

        if (array && array.length > 0) {
            switch (value) {
                case 'None': {
                    tempVendors = [...array];

                    break;
                }
                case 'new': {
                    tempVendors = array.filter((vendor) => vendor.status?.toUpperCase() === ReviewStatusType.NEW);
                    break;
                }
                case 'edit-review': {
                    tempVendors = array.filter(
                        (vendor) => vendor.status?.toUpperCase() === ReviewStatusType.EDIT_REVIEW,
                    );

                    break;
                }
                case 'delete-review': {
                    tempVendors = array.filter(
                        (vendor) => vendor.status?.toUpperCase() === ReviewStatusType.DELETE_REVIEW,
                    );

                    break;
                }
                case 'reversal-review': {
                    tempVendors = array.filter(
                        (vendor) => vendor.status?.toUpperCase() === ReviewStatusType.REVERSAL_REVIEW,
                    );

                    break;
                }
                case 'reviewed': {
                    tempVendors = array.filter(
                        (vendor) => vendor.reviewedBy && vendor.reviewedDt && !vendor.approvedBy && !vendor.approvedDt,
                    );

                    break;
                }
                case 'sort-newer':
                case 'sort-older': {
                    tempVendors = array.sort(
                        (a: VendorRecord, b: VendorRecord) =>
                            (a.createdDate - b.createdDate) * (value === 'sort-newer' ? 1 : -1),
                    );

                    break;
                }
                case value?.startsWith('Deal Count') ? value : '': {
                    let temp = value.substring(10)?.trim();

                    const dealCount = temp ? +temp : 2;
                    tempVendors = array.filter((vendor) => vendor.dealCount >= dealCount);

                    break;
                }
                default: {
                    tempVendors = [...array];

                    break;
                }
            }
        }

        return tempVendors;
    }

    private applySearch(array: VendorRecord[], searchVal: string): VendorRecord[] {
        let tempVendors: VendorRecord[] = [];

        if (!searchVal || searchVal.trim() == '') {
            // No search value, return all records
            tempVendors = array;
        } else if (array && array.length > 0) {
            const temp = searchVal.toLowerCase();
            if (this.selectedTabIndex === this.tabInReview) {
                tempVendors = array.filter((val) => {
                    return (
                        val.payeeName.toLowerCase().indexOf(temp) >= 0 ||
                        val.accountNumber.toLowerCase().indexOf(temp) >= 0 ||
                        (val.accountTitle && val.accountTitle.toLowerCase().indexOf(temp) >= 0) ||
                        (val.abaSwift && val.abaSwift.toLowerCase().indexOf(temp) >= 0) ||
                        (val.taxpayerId && val.taxpayerId.toString().indexOf(temp) >= 0)
                    );
                });
            } else if (this.selectedTabIndex === this.tabApproved || this.selectedTabIndex === this.tabRejected) {
                tempVendors = array.filter((val) => {
                    return (
                        val.payeeName.toLowerCase().indexOf(temp) >= 0 ||
                        val.accountNumber.toLowerCase().indexOf(temp) >= 0 ||
                        (val.accountTitle && val.accountTitle.toLowerCase().indexOf(temp) >= 0) ||
                        (val.abaSwift && val.abaSwift.toLowerCase().indexOf(temp) >= 0) ||
                        (val.bankCountry && val.bankCountry.toLowerCase().indexOf(temp) >= 0) ||
                        (val.currency && val.currency.toLowerCase().indexOf(temp) >= 0) ||
                        (val.sortCode && val.sortCode.toLowerCase().indexOf(temp) >= 0) ||
                        (val.taxpayerId && val.taxpayerId.toString().indexOf(temp) >= 0)
                    );
                });
            }
        }

        return tempVendors;
    }

    private openModal(modalConfig: MatDialogConfig) {
        this.vendorModal = this.matDialog.open(ModalComponent, modalConfig);
        this.vendorModal.afterClosed().subscribe((res: { updateData: boolean; areChangesSaved: boolean }) => {
            if (!res || !res.areChangesSaved) {
                this.snackBar.open('Last changes were not saved and have been discarded', undefined, {
                    duration: 6000,
                });
            }
            if (res?.updateData) {
                this.snackBar.open(
                    'Your account is currently In Review. Please note that certain actions may be limited or require additional verification during this time.',
                    undefined,
                    {
                        duration: 6000,
                    },
                );

                this.fetch();
            }
        });
    }

    private openReviewModal(modalConfig: MatDialogConfig) {
        this.vendorReviewModal = this.matDialog.open(ModalReviewComponent, modalConfig);
        this.vendorReviewModal.afterClosed().subscribe((res: { updateData: boolean; areChangesSaved: boolean }) => {
            if (!res || !res.areChangesSaved) {
                this.snackBar.open('Last changes were not saved and have been discarded', undefined, {
                    duration: 6000,
                });
            }
            if (res?.updateData) {
                this.snackBar.open('Your changes have been saved.', undefined, {
                    duration: 6000,
                });

                this.fetch();
            }
        });
    }

    private openSimpleModal(modalConfig: MatDialogConfig) {
        this.vendorSimpleModal = this.matDialog.open(ModalSimpleComponent, modalConfig);
        this.vendorSimpleModal.afterClosed().subscribe((res: SimpleModalResult) => {
            if (res?.isRejected) {
                this.snackBar.open(
                    'Your account has been successfully been added to the Rejected & Deleted section.',
                    undefined,
                    {
                        duration: 6000,
                    },
                );

                this.fetch();
            } else if (res?.isDeleted) {
                this.snackBar.open(
                    'Your account is currently In Review. Please note that certain actions may be limited or require additional verification during this time.',
                    undefined,
                    {
                        duration: 6000,
                    },
                );

                this.fetch();
            } else if (res?.isNoteCreated) {
                // Get All notes for this vendor record
                this.vendorPaymentsService
                    .getNotesForVendorByVendorId(modalConfig.data.selected.id)
                    .subscribe((notes) => {
                        this.vendorConfig.notes = notes;
                    });
            } else if (res?.exported) {
                this.approvedColumns = this.approvedColumns.filter(
                    (column) => column !== AvailableColumns.ExportVendor,
                );
                this.exportVendorsInProgress = false;

                this.snackBar.open('Your vendor details have been successfully exported.', undefined, {
                    duration: 6000,
                });
            } else if (res?.reversed) {
                this.snackBar.open(
                    'The vendor is now moved to the "In Review" tab as a "Reverse Rejected / Deleted Vendor."',
                    undefined,
                    {
                        duration: 6000,
                    },
                );
            } else if (res?.hidden) {
                this.snackBar.open(
                    'The vendor is now hidden from the In Review List. It will reappear after another payment is processed.',
                    undefined,
                    {
                        duration: 6000,
                    },
                );

                this.fetch();
            }
        });
    }

    private handleError(error: HttpErrorResponse): void {
        this.fetching = false;
        console.error(error);
        this.snackBar.open('Server error', undefined, {
            duration: 10000,
        });
    }
}
