/**
 * Convert what the user typed to upper case, except for articles.
 * @param name
 * @private
 */
export function convertToUpper(name: string): string {
    const skipWords = ['a', 'and', 'or', 'the', 'of', 'to'];
    const parts = name?.trim().split(' ');
    if (parts) {
        for (let i = 0; i < parts.length; i++) {
            if (skipWords.indexOf(parts[i].toLowerCase()) < 0) {
                parts[i] = parts[i].charAt(0).toUpperCase() + (parts[i].length > 1 ? parts[i].substr(1) : '');
            }
        }
        return parts.join(' ').trim();
    }
    return null;
}
