import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../core/services/auth.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'app-giact-export',
    templateUrl: './giact-export.component.html',
    styleUrls: ['./giact-export.component.scss'],
})
export class GiactExportComponent implements OnInit {
    public progressMessage = '';
    public progressStatus = 0;

    datePickerForm: UntypedFormGroup;
    minDate = new Date(1970, 0, 1); // dates on backend are unix timpestamps, so earliest date you can pick is 0 or 1/1/1970
    maxDate = new Date();

    constructor(
        private http: HttpClient,
        private snackBar: MatSnackBar,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        let startDate = new Date();
        startDate.setHours(0, 0, 0, 0);

        let endDate = new Date();
        endDate.setHours(23, 59, 59, 0);

        this.datePickerForm = new UntypedFormGroup({
            startDate: new UntypedFormControl(startDate),
            endDate: new UntypedFormControl(endDate),
        });
    }

    public downloadFile() {
        const selectedStartDate = this.normalizeStartDate(this.startDate);
        const selectedEndDate = this.normalizeEndDate(this.endDate);

        this.progressMessage = 'Preparing Data Export...';
        this.progressStatus = 20;
        this.http
            .get(`/api/v1/giact-inquiry/export`, {
                responseType: 'blob',
                reportProgress: true,
                params: new HttpParams()
                    .set('startDate', selectedStartDate.toString())
                    .set('endDate', selectedEndDate.toString()),
            })
            .subscribe((response: any) => {
                this.parseBlobToText(response).then((value) => {
                    const lines = value.split(/\r\n|\r|\n/g);
                    this.progressMessage = `Complete`;
                    this.progressStatus = 100;
                    this.openSnackBar(
                        `Success! Generated export for ${lines.length - 1} records. Your download will begin shortly.`,
                    );
                });
                window.location.href = window.URL.createObjectURL(response);
                this.progressMessage = 'Preparing Records for Download...';
                this.progressStatus = 80;
            });
    }

    private async parseBlobToText(blob: Blob): Promise<string> {
        return await new Response(blob).text();
    }

    get startDate() {
        return this.datePickerForm.get('startDate').value;
    }

    get endDate() {
        return this.datePickerForm.get('endDate').value;
    }

    private normalizeStartDate(startDate: string) {
        // this helper sets a Date's timestamp as 12:00:00 that day
        return new Date(startDate).setHours(0, 0, 0, 0) / 1000;
    }

    private normalizeEndDate(endDate: string) {
        // this helper sets a Date's timestamp as 11:59:59 that day
        return new Date(endDate).setHours(23, 59, 59, 0) / 1000;
    }

    private openSnackBar(message: string) {
        this.snackBar.open(message, null, { duration: 5000 });
    }
}
