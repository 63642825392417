import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import userRoleAccessData from '../../../assets/access/userRoleAccess.json';
import * as oauth from 'oauth4webapi';
import jwtDecode from 'jwt-decode';
import { UserService } from './user.service';
import { IConfiguration } from '../configuration.model';
import { TimerHandle } from 'rxjs/internal/scheduler/timerHandle';
import { ConfigurationService } from './configuration.service';
import { Router } from '@angular/router';

export interface IAuthApps {
    [appName: string]: {
        route: string;
        icon: string;
        flag: string;
        roles: string[];
        label: string;
        hasSubmenu?: boolean;
    };
}

export interface IUserRoleAccess {
    role: string;
    section: string;
    access: string;
}

export enum OneLoginRoles {
    activity = 'Static Group - Eng Activity',
    armoryAuditor = 'Static Group - Armory - Auditor',
    bankFileExplorerStandard = 'Static Group - Eng Bank File Explorer - Standard',
    cache = 'Static Group - Cache', // Read only
    cacheAdmin = 'Static Group - Cache - Admin',
    cloak = 'Static Group - Brokerage Accounts', // Read only
    cloakAdmin = 'Static Group - Brokerage Accounts - Admin',
    internalAdmin = 'Static Group - Eng Internal Admin', // Read only
    internalAdminStandard = 'Static Group - Eng Internal Admin - Standard',
    shield = 'static group - shield',
    vendor = 'Static Group - Vendor Payments', // Read only
    vendorApprove = 'Static Group - Vendor Payments - Approve',
    vendorEdit = 'Static Group - Vendor Payments - Edit',
}

export enum AccessTypes {
    VIEW = 'View',
    FULL = 'Full',
}

export enum AccessFeatures {
    LANCE_PAYMENTS = 'Payments',
    LANCE_ISSUERS = 'Issuers',
    LANCE_PORTFOLIOS = 'Portfolio',
    SHIELD_VERIFY = 'Shield',
}

enum OauthStorageItems {
    ACCESS_TOKEN = 'accessToken',
    IDENTITY_TOKEN = 'identityToken',
    REFRESH_TOKEN = 'refreshToken',
}

export enum LocalStorageItems {
    LOGOUT_CLICKED = 'logoutClicked',
    LOGIN_STARTED = 'loginStarted',
}

type AccessToken = {
    jti: string;
    sub: string;
    iss: string;
    iat: number;
    exp: number;
    scope: string;
    aud: string;
};

type IdentityToken = {
    sub: string;
    email: string;
    preferred_username: string;
    name: string;
    groups: string[];
    at_hash: string;
    sid: string;
    aud: string;
    exp: number;
    iat: number;
    iss: string;
};

type RefreshToken = {
    sub: string;
    iss: string;
    iat: number;
    exp: number;
    scope: string;
    aud: string;
};

export const AppRegistry: IAuthApps = {
    shield: {
        route: '/identity-verification',
        icon: 'verified_user',
        flag: 'shield',
        roles: [OneLoginRoles.shield, OneLoginRoles.armoryAuditor],
        label: 'Payee Identity Verification',
    },
    cloak: {
        route: '/brokerage-accounts',
        icon: 'manage_accounts',
        flag: 'cloak',
        roles: [OneLoginRoles.cloak, OneLoginRoles.cloakAdmin],
        label: 'Common Brokerage Accounts',
    },
    guild: {
        route: '/vendor-payments',
        icon: 'account_balance',
        flag: 'guild',
        roles: [OneLoginRoles.vendor, OneLoginRoles.vendorEdit, OneLoginRoles.vendorApprove],
        label: 'Pre-Approved Vendors',
    },
    cache: {
        route: '/cached-bank-codes',
        icon: 'cached',
        flag: 'cache',
        roles: [OneLoginRoles.cache, OneLoginRoles.cacheAdmin],
        label: 'Cached Bank Codes',
    },
    internalAdmin: {
        route: '/internal-admin',
        icon: 'group',
        flag: 'internalAdmin',
        roles: [OneLoginRoles.internalAdmin, OneLoginRoles.internalAdminStandard],
        label: 'Internal Admin',
        hasSubmenu: true,
    },
    dsComponentsShowcase: {
        route: '/ds-components-showcase',
        icon: 'flag',
        flag: 'dsComponentsShowcase',
        roles: [OneLoginRoles.internalAdmin, OneLoginRoles.internalAdminStandard],
        label: 'Design Systems Component Showcase',
    },
    bankFileExplorer: {
        route: '/bank-file-explorer',
        icon: 'folder_open',
        flag: 'bankFileExplorer',
        roles: [OneLoginRoles.bankFileExplorerStandard],
        label: 'Bank File Explorer',
    },
    activity: {
        route: '/activity',
        icon: 'view_list',
        flag: 'activity',
        roles: [OneLoginRoles.activity, OneLoginRoles.bankFileExplorerStandard],
        label: 'Activity',
    },
};

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private _isAuthDoneLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public get isAuthDoneLoading$(): BehaviorSubject<boolean> {
        return this._isAuthDoneLoading$;
    }

    private userRoleAccess: IUserRoleAccess[] = userRoleAccessData;

    public isTokenExpiring: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public sessionTime: BehaviorSubject<string> = new BehaviorSubject('00:00');
    public refreshLoginFailed: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private _accessToken: string = '';
    private _identityToken: string = '';
    private _refreshToken: string = '';
    private authURL: URL;
    private authorizationServer: oauth.AuthorizationServer;
    private client: oauth.Client;
    private codeVerifier: string;
    private redirectURI = window.location.origin + '/login/callback';
    private tokenExpiringTimer: TimerHandle;
    private logoutTimer: TimerHandle;
    private sessionInterval: TimerHandle;
    private configService: ConfigurationService;

    constructor(
        private userService: UserService,
        configService: ConfigurationService,
        private router: Router,
    ) {
        this.configService = configService;

        window.addEventListener('storage', (event: StorageEvent) => {
            const storageArea = event.storageArea;

            // The user has been logged out somewhere... log them out here.
            if (storageArea && storageArea.getItem(OauthStorageItems.ACCESS_TOKEN) === null) {
                window.location.assign(window.location.origin);
            } else {
                // update access token when changed
                if (event.key === OauthStorageItems.ACCESS_TOKEN) {
                    this._accessToken = localStorage.getItem(OauthStorageItems.ACCESS_TOKEN) as string;
                    const decodedAccessToken = jwtDecode<AccessToken>(this._accessToken);
                    this.setTokenExpiringTimer(decodedAccessToken.exp);
                }
                // update identity token when changed (propagate changes to user store)
                if (event.key === OauthStorageItems.IDENTITY_TOKEN) {
                    this._identityToken = localStorage.getItem(OauthStorageItems.IDENTITY_TOKEN) as string;
                    const parsedIDToken = jwtDecode<IdentityToken>(this._identityToken);
                    this.userService.user = {
                        name: parsedIDToken.name,
                        roles: parsedIDToken.groups,
                        sub: parsedIDToken.sub,
                    };
                }
                // update refresh token when changed
                if (event.key === OauthStorageItems.REFRESH_TOKEN) {
                    this._refreshToken = localStorage.getItem(OauthStorageItems.REFRESH_TOKEN) as string;
                }

                // if user is on login tab in another window, navigate them to home tab
                if (window.location.pathname.includes('login')) {
                    window.location.assign(window.location.origin);
                }
            }
        });
    }

    public checkAccess(sectionName) {
        let access = '';

        // console.log('*** AuthService.checkAccess() -----------------------------------------------------------------------------------------------------------');
        // console.log('*** AuthService.checkAccess() sectionName =', sectionName);
        // console.log('*** AuthService.checkAccess() this.userRoleAccess =', JSON.stringify(this.userRoleAccess));
        // console.log('*** AuthService.checkAccess() this.userService.user.roles =', JSON.stringify(this.userService.user.roles));

        const accessRows = this.userRoleAccess.filter((accessRow) => {
            return accessRow.section === sectionName && this.userService.user.roles.includes(accessRow.role);
        });

        if (accessRows && accessRows.length > 0) {
            access = accessRows[0].access;
        }

        // console.log('*** AuthService.checkAccess() access =', access);
        return access;
    }

    public get accessToken(): string {
        return this._accessToken;
    }

    public get identityToken(): string {
        return this._identityToken;
    }

    /**
     * This function should only be called during the app initialization.
     * Sets up the authorization server that the rest of the Auth service uses for mechanisms like logging in, logging out
     * or refreshing a session.
     *
     * This expects the config to have been error checked before coming in.
     * @param config a configuration object containing necessary Identity Provider details, like the issuer URL and clientId
     * @param force_login Whether to force the user to login. Only used if initial attempt without username/pw failed.
     */
    public async establishAuthorizationServer(config: IConfiguration, force_login: boolean = false) {
        const oidcIssuer = new URL(config.oidcConfig.issuer);
        this.client = {
            client_id: config.oidcConfig.clientId,
            token_endpoint_auth_method: 'none',
        };

        const discoveryResponse = await oauth.discoveryRequest(oidcIssuer);
        this.authorizationServer = await oauth.processDiscoveryResponse(oidcIssuer, discoveryResponse);

        if (this.authorizationServer.code_challenge_methods_supported?.includes('S256') !== true) {
            // This example assumes S256 PKCE support is signalled
            // If it isn't supported, random `nonce` must be used for CSRF protection.
            throw new Error();
        }

        this.codeVerifier = localStorage.getItem('codeVerifier') || oauth.generateRandomCodeVerifier();
        const code_challenge = await oauth.calculatePKCECodeChallenge(this.codeVerifier);
        const code_challenge_method = 'S256';

        this.authURL = new URL(this.authorizationServer.authorization_endpoint || '');
        this.authURL.searchParams.set('client_id', this.client.client_id);
        this.authURL.searchParams.set('code_challenge', code_challenge);
        this.authURL.searchParams.set('code_challenge_method', code_challenge_method);
        this.authURL.searchParams.set('redirect_uri', this.redirectURI);
        this.authURL.searchParams.set('response_type', 'code');
        this.authURL.searchParams.set('scope', 'openid profile groups');
        this.authURL.searchParams.set('grant_type', 'none');
        this.authURL.searchParams.set('prompt', force_login ? 'login' : 'none');

        localStorage.setItem('codeVerifier', this.codeVerifier);
    }

    /**
     * This function should only be called during app initialization.
     * Initializes the user session.
     * Checks to see if the user is already authenticated in another browser window by checking local storage.
     * If the user is already authenticated, allows the user to use the app in the new window.
     */
    public initializeSession(): boolean {
        const storedAccessToken = localStorage.getItem(OauthStorageItems.ACCESS_TOKEN);

        let isAuthenticated = false;

        if (storedAccessToken !== null) {
            const decodedToken = jwtDecode<AccessToken>(storedAccessToken);
            const now = Math.floor(new Date().getTime() / 1000);

            if (now > decodedToken.exp) {
                this.removeOauthStorageItems();
            } else {
                this._accessToken = localStorage.getItem(OauthStorageItems.ACCESS_TOKEN) as string;
                this._identityToken = localStorage.getItem(OauthStorageItems.IDENTITY_TOKEN) as string;
                this._refreshToken = localStorage.getItem(OauthStorageItems.REFRESH_TOKEN) as string;
                const storedIDToken = localStorage.getItem(OauthStorageItems.IDENTITY_TOKEN);

                if (storedIDToken === null) {
                    console.error(`error: missing ${OauthStorageItems.IDENTITY_TOKEN} from local storage`);
                } else {
                    const decodedIDToken = jwtDecode<IdentityToken>(storedIDToken);
                    this.userService.user = {
                        name: decodedIDToken.name,
                        roles: decodedIDToken.groups,
                        sub: decodedIDToken.sub,
                    };
                    this.userService.isAuthenticated$.next(true);
                    isAuthenticated = true;
                }
                this.setTokenExpiringTimer(decodedToken.exp);
            }
        }

        this._isAuthDoneLoading$.next(true);

        return isAuthenticated;
    }

    public login() {
        this._isAuthDoneLoading$.next(false);
        window.location.assign(this.authURL.toString());
    }

    public async loginCallback() {
        this._isAuthDoneLoading$.next(false);
        const currentUrl: URL = new URL(window.location.href);

        const params: URLSearchParams | oauth.OAuth2Error = oauth.validateAuthResponse(
            this.authorizationServer,
            this.client,
            currentUrl,
            oauth.expectNoState,
        );

        if (oauth.isOAuth2Error(params)) {
            console.error('error', params);
            if (params?.error === 'login_required') {
                await this.establishAuthorizationServer(this.configService.config, true);
                this.login();
                return; // Unreachable
            } else {
                throw new Error(); // Handle OAuth 2.0 redirect error
            }
        }

        const response = await oauth.authorizationCodeGrantRequest(
            this.authorizationServer,
            this.client,
            params,
            this.redirectURI,
            this.codeVerifier,
        );

        let challenges: oauth.WWWAuthenticateChallenge[] | undefined;
        if ((challenges = oauth.parseWwwAuthenticateChallenges(response))) {
            for (const challenge of challenges) {
                console.error('challenge', challenge);
            }
            throw new Error(); // Handle www-authenticate challenges authorizationServer needed
        }
        const processedAuthenticationResponse = await oauth.processAuthorizationCodeOpenIDResponse(
            this.authorizationServer,
            this.client,
            response,
        );

        if (oauth.isOAuth2Error(processedAuthenticationResponse)) {
            console.error('error', processedAuthenticationResponse);
            throw new Error(); // Handle OAuth 2.0 response body error
        }

        this._accessToken = processedAuthenticationResponse.access_token as string;
        this._identityToken = processedAuthenticationResponse.id_token as string;
        this._refreshToken = processedAuthenticationResponse.refresh_token as string;

        this.setOauthStorageItems();
        const decodedAccessToken = jwtDecode<AccessToken>(this._accessToken);
        this.setTokenExpiringTimer(decodedAccessToken.exp);

        const parsedIdToken = jwtDecode<IdentityToken>(this._identityToken);

        this.userService.user = { name: parsedIdToken.name, roles: parsedIdToken.groups, sub: parsedIdToken.sub };
        this.userService.isAuthenticated$.next(true);
        this._isAuthDoneLoading$.next(true);
        localStorage.removeItem('codeVerifier');
        // Clearing the logout_clicked from local storage as the user is logging in.
        localStorage.removeItem(LocalStorageItems.LOGOUT_CLICKED);
        localStorage.removeItem(LocalStorageItems.LOGIN_STARTED);
    }

    public async logout(timedOut: boolean = false) {
        this._isAuthDoneLoading$.next(false);
        this.userService.user = { name: '', roles: [], sub: '' };
        this.userService.isAuthenticated$.next(false);

        // We revoke the refresh token because OneLogin revokes ALL tokens associated with the refresh token
        // https://developers.onelogin.com/openid-connect/api/revoke-token
        const logoutURLParams = new URLSearchParams();
        logoutURLParams.append('token_type_hint', 'refresh_token');
        logoutURLParams.append('client_id', this.client.client_id);
        const revocationResponse = await oauth.revocationRequest(
            this.authorizationServer,
            this.client,
            this._accessToken,
            {
                additionalParameters: logoutURLParams,
            },
        );
        const processedRevocationResponse = await oauth.processRevocationResponse(revocationResponse);
        if (oauth.isOAuth2Error(processedRevocationResponse)) {
            console.error('error', processedRevocationResponse);
            throw new Error();
        }
        this.clearTimers();
        // localStorage.clear(); // Need to keep some local storage items.
        this.removeOauthStorageItems();
        this._isAuthDoneLoading$.next(true);
        if (timedOut) {
            window.location.assign(this.configService.envConfig.getValue().sessionTimeoutDestinationUrl);
        } else {
            window.location.assign(window.location.origin);
        }
    }

    public async refreshLogin() {
        this.refreshLoginFailed.next(false);
        const refreshURLParams = new URLSearchParams();
        refreshURLParams.append('grant_type', 'refresh_token');
        refreshURLParams.append('refresh_token', this._refreshToken);
        refreshURLParams.append('client_id', this.client.client_id);
        const refreshResponse = await oauth.refreshTokenGrantRequest(
            this.authorizationServer,
            this.client,
            this._refreshToken,
            {
                additionalParameters: refreshURLParams,
            },
        );
        const processedRefreshResponse = await oauth.processRefreshTokenResponse(
            this.authorizationServer,
            this.client,
            refreshResponse,
        );

        if (oauth.isOAuth2Error(processedRefreshResponse)) {
            if (processedRefreshResponse?.error === 'invalid_grant') {
                // Refreshing the user's access token failed, so they will need
                // to log out and then back in.
                this.refreshLoginFailed.next(true);
                return;
            } else {
                console.error('error', processedRefreshResponse);
                throw new Error(); // Handle OAuth 2.0 redirect error
            }
        }

        this._accessToken = processedRefreshResponse.access_token;
        this._identityToken = processedRefreshResponse.id_token;
        this._refreshToken = processedRefreshResponse.refresh_token;

        this.setOauthStorageItems();

        const decodedAccessToken = jwtDecode<AccessToken>(this._accessToken);
        this.setTokenExpiringTimer(decodedAccessToken.exp);
    }

    public routeToHomePage() {
        this.router.navigate(['']);
    }

    private removeOauthStorageItems() {
        localStorage.removeItem(OauthStorageItems.ACCESS_TOKEN);
        localStorage.removeItem(OauthStorageItems.IDENTITY_TOKEN);
        localStorage.removeItem(OauthStorageItems.REFRESH_TOKEN);
    }

    private setOauthStorageItems() {
        localStorage.setItem(OauthStorageItems.ACCESS_TOKEN, this._accessToken);
        localStorage.setItem(OauthStorageItems.IDENTITY_TOKEN, this._identityToken);
        localStorage.setItem(OauthStorageItems.REFRESH_TOKEN, this._refreshToken);
    }

    private setTokenExpiringTimer(tokenExpiry: number) {
        this.clearTimers();
        this.isTokenExpiring.next(false);
        this.refreshLoginFailed.next(false);
        const now = Math.floor(new Date().getTime() / 1000);

        const sessionLength = tokenExpiry - now;

        this.sessionTime.next(
            `${String(Math.floor(sessionLength / 60)).padStart(2, '0')}:${String(
                Math.floor(sessionLength % 60),
            ).padStart(2, '0')}`,
        );

        let timeRemaining = sessionLength;
        this.sessionInterval = setInterval(() => {
            timeRemaining = timeRemaining - 1;
            this.sessionTime.next(
                `${String(Math.floor(timeRemaining / 60)).padStart(2, '0')}:${String(
                    Math.floor(timeRemaining % 60),
                ).padStart(2, '0')}`,
            );
        }, 1000);

        const decodedRefreshToken = jwtDecode<RefreshToken>(this._refreshToken);

        let timeWindow: number;
        // If tokenExpiry > refreshToken expiry, they won't be able to refresh...
        if (tokenExpiry > decodedRefreshToken.exp) {
            timeWindow = 300;
        } else {
            timeWindow = 60;
        }

        const secondsUntilLogoutWarning = tokenExpiry - timeWindow - now;
        const logoutWarningDurationInSeconds = timeWindow;
        this.tokenExpiringTimer = setTimeout(() => {
            this.setLogoutTimer(logoutWarningDurationInSeconds);
        }, secondsUntilLogoutWarning * 1000);
    }

    private setLogoutTimer(logoutWarningDurationInSeconds: number) {
        this.isTokenExpiring.next(true);
        this.logoutTimer = setTimeout(async () => {
            await this.logout(true);
        }, logoutWarningDurationInSeconds * 1000);
    }

    private clearTimers() {
        clearTimeout(this.tokenExpiringTimer);
        clearTimeout(this.logoutTimer);
        clearInterval(this.sessionInterval);
    }
}
