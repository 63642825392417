export interface IParsedCsvObject<T> {
    validRows: { lineNumber: number; record: T }[];
    invalidRows?: { lineNumber: number; record: any }[];
    duplicateRows?: { line: number; matchedLine: number }[];
}

export enum CsvParseFailures {
    INVALID_RECORD_ROW = 'Invalid record: the record must contain values for each column of the file, even if the value is empty.',
    MALFORMED_HEADER = 'The headers of the submitted file seem to be malformed and cannot be parsed. Please make sure to wrap fields with special characters in a pair of quote marks.',
    MALFORMED_ROW = 'This row of the submitted file seem to be malformed and cannot be parsed. Please make sure to wrap fields with special characters in a pair of quote marks.',
}
