<div *ngIf="(fetching)" class="fetching-files">
    <mat-spinner></mat-spinner>
</div>
<div class="activity-container">
    <span class="title-span">Activity</span>
    <div class="frame">
        <div class="search">
            <form [formGroup]="form" (ngSubmit)="fetch()" (keydown.enter)="fetch()">
                <div class="fields">
                    <div class="field-input">
                        <mat-form-field class="search-field" data-testid="searchMFF">
                            <input id="searchInput" matInput type="text" formControlName="searchValue" data-testid="searchValue" placeholder="Deal name, User name, ..."/>
                        </mat-form-field>
                    </div>

                    <div class="field-input">
                        <mat-form-field class="search-field" data-testid="activitySelectMFF">
                            <mat-label>Filter by</mat-label>
                            <mat-select formControlName="activity" (selectionChange)="filterBySelectionChange($event)">
                                <mat-option *ngFor="let key of ActivityEnumKeys" [value]="ActivityEnum[key]">{{key}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div *ngIf="filterBySearchVisible" class="field-input element-wrapper">
                        <mat-form-field class="search-field" data-testid="activityByNameSelectMFF">
                            <mat-label>{{filterBySearchLabel}}</mat-label>
                            <input #activityFilterByInput
                                   matInput
                                   type="text"
                                   formControlName="activityFilterBySearchInput"
                                   placeholder="name..."
                                   data-testid="filterBySearchInput"
                            />
                        </mat-form-field>
                        <mat-selection-list *ngIf="showFilterBySelectionList()" class="search-field search-list-results"
                                            multiple="false"
                                            hideSingleSelectionIndicator="true"
                                            (selectionChange)="filterBySelectionMade($event)"
                        >
                            <mat-list-option *ngFor="let deal of activitySearchResDeals" class="list-option" [value]="deal.id">
                                <h3 matListItemTitle>{{deal.name}}</h3>
                                <p matListItemLine>
                                    <span>{{deal.projectName}}</span>
                                </p>
                            </mat-list-option>
                            <mat-list-option *ngFor="let user of activitySearchResUsers" class="list-option" [value]="user.id">
                                <h3 matListItemTitle>{{user.firstName + ' ' + user.lastName}}</h3>
                                <p matListItemLine>
                                    <span>{{user.email}}</span>
                                </p>
                            </mat-list-option>
                            <mat-list-option *ngFor="let name of activitySearchResAdmins" class="list-option" [value]="name">
                                <h3 matListItemTitle>{{name}}</h3>
                            </mat-list-option>
                        </mat-selection-list>
                    </div>

                    <div class="field-input">
                        <mat-form-field class="search-field" data-testid="actionSelectMFF">
                            <mat-label>Show</mat-label>
                            <mat-select formControlName="action">
                                <mat-option *ngFor="let key of ActionEnumKeys" [value]="ActionEnum[key]">{{key}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="field-input">
                        <mat-form-field class="search-field" data-testid="startDateMFF">
                            <mat-label>Start Date</mat-label>
                            <input matInput [matDatepicker]="pickerStart" formControlName="startdate">
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="pickerStart"></mat-datepicker-toggle>
                            <mat-datepicker #pickerStart></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="field-input">
                        <mat-form-field class="search-field" data-testid="endDateMFF">
                            <mat-label>End Date</mat-label>
                            <input matInput [matDatepicker]="pickerEnd" formControlName="enddate">
                            <mat-datepicker-toggle matIconSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                            <mat-datepicker #pickerEnd></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="field-input">
                        <mat-checkbox formControlName="excludeme" [disableRipple]="true">Exclude my activity</mat-checkbox>
                    </div>
                </div>
            </form>
            <div class="activity-buttons">
                <div *ngIf="areFiltersDirty()"
                     class="clear-filters-button"
                     (click)="clearFilters()">
                    <span class="search-label">Clear Filters</span>
                </div>
                <div class="search-button" (click)="fetch()">
                    <span class="search-label">Search</span>
                </div>
            </div>
        </div>
        <div *ngIf="haveResults()" class="activity-results">
            <ng-container
                *ngTemplateOutlet="activityResults"
            ></ng-container>
        </div>
        <div *ngIf="!haveResults()" class="empty-state">
            <ng-container
                *ngTemplateOutlet="emptyState"
            ></ng-container>
        </div>
    </div>
</div>

<ng-template #activityResults>
    <div class="activity-table">
        <table
            mat-table
            matSort
            class="table-div"
            [dataSource]="dataSource"
        >
            <ng-container matColumnDef="initials">
                <td mat-cell *matCellDef="let element">
                    <div class="cell-initials">
                        <span class="initials-span">{{element.initials}}</span>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="text">
                <td mat-cell class="cell-text" *matCellDef="let element">
                    <div class="cell-text">
                        <span class="text-span" [innerHTML]="element.text"></span>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="activityDt">
                <td mat-cell class="cell-activity" *matCellDef="let element">
                    {{element.activityDt | date: "MMM dd, yyyy 'at' hh:mm a"}}
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell
                    *matCellDef="let element; index as idx;"
                >
                    <div class="action-cell"
                         [class.action-cell-clicked]="idx === selectedIndex"
                         [matMenuTriggerFor]="activityMenu"
                         aria-label="Activity menu"
                         (click)="openMenu($event, idx)"
                    >
                        <div class="action-div">
                            <span class="material-symbols-outlined"
                            >more_horiz</span>
                        </div>
                    </div>
                    <mat-menu #activityMenu="matMenu" xPosition="before" yPosition="below">
                        <div mat-menu-item class="menu-option"
                             (click)="handleViewDetailsClick(idx)">View Details</div>
                        <div mat-menu-item class="menu-option"
                             (click)="handleFilterByUserClick(idx)">Filter by User</div>
                        <div mat-menu-item class="menu-option"
                             (click)="handleFilterByDealClick(idx)">Filter by Deal</div>
                        <div mat-menu-item class="menu-option"
                             (click)="handleFilterByAdminClick(idx)">Filter by Admin</div>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="intadm-table-row"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell no-data" colspan="10"><span *ngIf="!fetching">No data</span></td>
            </tr>
        </table>
    </div>
    <div class="paginator-div">
        <mat-paginator [length]="totalRecords"
                       [pageIndex]="page"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="[10, 25, 50, 100]"
                       aria-label="Select page"
                       showFirstLastButtons=true
                       disabled=false
                       hidePageSize=false
                       (page)="handlePageEvent($event)">
        </mat-paginator>
    </div>
    <div id="slideout" class="slideout" [class.show-details]="showDetails" data-testid="details-slideout">
        <app-slider-activity-details
            [isOpen]="showDetails"
            [activity]="selectedActivity"
            (closeSliderEvent)="closeSliderPanel()"
            (searchEvent)="handleSearchEvent($event)"
        ></app-slider-activity-details>
    </div>
</ng-template>

<ng-template #emptyState>
    <div class="empty-icon">
        <span class="material-symbols-outlined blank-page-icon"
        >search</span>
    </div>
    <div class="text-grouping">
        <span class="empty-label">Activity Results</span>
        <span class="narrow">Narrow your search by using the activity filters</span>
    </div>
</ng-template>
