import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
    DuplicateGiactRecord,
    GiactImportEntity,
    GiactRecord,
    InvalidGiactEntityErrors,
    ValidGiactRecord,
} from '../types/giact.types';
import { GiactImportService } from '../services/giact-import.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { requiredFileType } from '../../core/components/file-upload/requiredFileType';

import { SelectedService } from '../types/giact.types';
import { UtilsService } from '../../core/services/utils.service';
import { CsvParseFailures } from '../../core/utils.types';
import { Subscription } from 'rxjs';
import { AccessFeatures, AccessTypes, AuthService } from '../../core/services/auth.service';

@Component({
    selector: 'app-giact-import',
    templateUrl: './giact-import.component.html',
    styleUrls: ['./giact-import.component.scss'],
})
export class GiactImportComponent implements OnDestroy, OnInit {
    public progressStatus = 0;
    public progressMessage = '';
    public duplicateRecords: DuplicateGiactRecord[] = [];
    public invalidRecords: GiactRecord[] = [];
    public warningRecords: GiactRecord[] = [];
    public validatedRecords: ValidGiactRecord;

    public SelectedService = SelectedService;

    public hasReviewedDuplicatesAndErrors: boolean = true;
    public hasReviewedWarnings: boolean = true;

    public identityServiceForm = new UntypedFormGroup({
        identityTargetService: new UntypedFormControl(null, Validators.required),
        identityCsv: new UntypedFormControl(null, [Validators.required, requiredFileType('csv')]),
    });

    private dialogRef: MatDialogRef<ElementRef>;
    private warningDialogRef: MatDialogRef<ElementRef>;
    private formChangesSubscription: Subscription;

    @ViewChild('importExceptionConfirmDialog') importExceptionConfirmDialog: TemplateRef<ElementRef>;
    @ViewChild('importWarningConfirmDialog') importWarningConfirmDialog: TemplateRef<ElementRef>;
    @ViewChild('fileUpload') fileUpload: HTMLInputElement;

    constructor(
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private giactImportService: GiactImportService,
        private authService: AuthService,
        private utilsService: UtilsService,
    ) {}

    ngOnInit(): void {
        this.formChangesSubscription = this.identityServiceForm.valueChanges.subscribe(
            this.identityServiceFormValueChanges.bind(this),
        );
    }

    ngOnDestroy() {
        this.formChangesSubscription.unsubscribe();
    }

    public onClickCancelImport() {
        if (this.warningDialogRef !== undefined) {
            this.warningDialogRef.close();
        }
        if (this.dialogRef !== undefined) {
            this.dialogRef.close();
        }
        this.initializeImportSettings();
        this.identityServiceForm.reset();
    }

    public onClickAcceptWarnings() {
        this.hasReviewedWarnings = true;
        this.warningDialogRef.close();
    }

    public onClickImport() {
        this.hasReviewedDuplicatesAndErrors = true;
        this.dialogRef.close();
    }

    public openImportExceptionsDialog() {
        this.dialogRef = this.dialog.open(this.importExceptionConfirmDialog, {
            minWidth: '300px',
            minHeight: '300px',
            maxWidth: '90%',
            maxHeight: '90%',
        });
    }

    public openImportWarningsDialog() {
        this.warningDialogRef = this.dialog.open(this.importWarningConfirmDialog, {
            minWidth: '300px',
            minHeight: '300px',
            maxWidth: '90%',
            maxHeight: '90%',
        });
    }

    public initializeImportSettings() {
        this.progressStatus = 0;
        this.progressMessage = '';
        this.duplicateRecords = [];
        this.invalidRecords = [];
        this.validatedRecords = undefined;
        this.hasReviewedDuplicatesAndErrors = true;
        this.hasReviewedWarnings = true;
    }

    public handleResponse(response) {
        if (response !== undefined) {
            if (response['Giact Error']) {
                this.openSnackBar(`Failure!
        Records have failed to upload! Error: ${response['Giact Error'].data.message}`);
                this.progressStatus = 0;
                this.progressMessage = 'Import Failed';
            }
            if (!response['Giact Error']) {
                this.openSnackBar(`Success!
        ${response.length} record${response.length > 1 ? 's' : ''} ha${response.length > 1 ? 've' : 's'}
        been processed and uploaded.`);
                this.progressStatus = 100;
                this.progressMessage = 'Import Complete';
            }
            this.initializeImportSettings();
            this.identityServiceForm.reset();
        }
    }

    public handleErr(err: Error) {
        this.openSnackBar('Something went wrong. Please check your CSV headers and try again.');
        console.error(err);
        this.initializeImportSettings();
        this.identityServiceForm.reset();
    }

    private openSnackBar(message: string) {
        this.snackBar.open(message, null, { duration: 5000 });
    }

    public async identityServiceFormValueChanges(data: { identityTargetService: string; identityCsv: File }) {
        if (data.identityCsv && data.identityTargetService) {
            // If file change or service change occurs, we want to start from fresh slate
            this.initializeImportSettings();

            const textFromFile = await this.giactImportService.parseBlobToText(data.identityCsv);
            const csvObject = this.utilsService.parseCsvToObject<GiactImportEntity>(textFromFile);
            // separate out invalidRecords that failed during csv to object parsing.
            if (csvObject.invalidRows && csvObject.invalidRows.length > 0) {
                csvObject.invalidRows.forEach((record) => {
                    if (record.record === 'nullHeader') {
                        this.addRecordToInvalidRecords({
                            lineNumber: record.lineNumber,
                            error: CsvParseFailures.MALFORMED_HEADER,
                            record: null,
                        });
                    } else if (record.record === null) {
                        this.addRecordToInvalidRecords({
                            lineNumber: record.lineNumber,
                            error: CsvParseFailures.MALFORMED_ROW,
                            record: null,
                        });
                    } else {
                        this.addRecordToInvalidRecords({
                            lineNumber: record.lineNumber,
                            error: InvalidGiactEntityErrors.INVALID_RECORD_ROW,
                            record: record.record,
                        });
                    }
                });
            }
            if (csvObject.duplicateRows && csvObject.duplicateRows.length > 0) {
                csvObject.duplicateRows.forEach((duplicateRecord) => {
                    this.addRecordToDuplicateRecords({
                        lineNumber: duplicateRecord.line,
                        matchedLineNumber: duplicateRecord.matchedLine,
                    });
                });
            }

            csvObject.validRows.forEach((record) => {
                const validationResult = this.giactImportService.validateGiactRecord(
                    record,
                    data.identityTargetService,
                );
                switch (validationResult.status) {
                    case 'error':
                        this.addRecordToInvalidRecords(validationResult.validationResult);
                        break;
                    case 'warning':
                        // Here, we are assuming that the user can only accept all records with warnings as valid
                        // or be forced to cancel the import, thus all warning records are valid records
                        this.addRecordToWarningRecords(validationResult.validationResult);
                        this.checkForDuplicate(record);
                        break;
                    case 'success':
                        // If "successful" we must check for duplicates
                        this.checkForDuplicate(record);
                        break;
                    default:
                        console.error(
                            new Error(
                                `Record didn't return with expected status. Unexpected status: ${validationResult.status}`,
                            ),
                        );
                }
            });
            if (this.warningRecords.length > 0) {
                this.hasReviewedWarnings = false;
                this.openImportWarningsDialog();
            }
            if (this.invalidRecords.length > 0 || this.duplicateRecords.length > 0) {
                this.hasReviewedDuplicatesAndErrors = false;
                this.openImportExceptionsDialog();
            }
        }
    }

    public submit(): void {
        if (this.identityServiceForm.valid && this.hasReviewedDuplicatesAndErrors && this.hasReviewedWarnings) {
            this.progressMessage = 'Preparing records for import...';
            this.progressStatus = 20;
            const recordsToImport = Object.keys(this.validatedRecords).reduce(
                (acc: GiactImportEntity[], curVal: string) => {
                    const records = this.validatedRecords[curVal].map((giactRecord) => giactRecord.record);
                    acc.push(...records);
                    return acc;
                },
                [],
            );
            this.giactImportService
                .postGiactRequest(recordsToImport, this.identityServiceForm.controls.identityTargetService.value)
                .subscribe({
                    next: this.handleResponse.bind(this),
                    error: this.handleErr.bind(this),
                });
        }
    }

    public addRecordToInvalidRecords(invalidRecord: GiactRecord): void {
        this.invalidRecords.push({
            lineNumber: invalidRecord.lineNumber,
            record: invalidRecord.record,
            error: invalidRecord.error,
        });
    }

    public addRecordToWarningRecords(invalidRecord: GiactRecord): void {
        this.warningRecords.push({
            lineNumber: invalidRecord.lineNumber,
            record: invalidRecord.record,
            error: invalidRecord.error,
        });
    }

    public addRecordToDuplicateRecords(duplicateRecord: DuplicateGiactRecord): void {
        this.duplicateRecords.push({
            lineNumber: duplicateRecord.lineNumber,
            matchedLineNumber: duplicateRecord.matchedLineNumber,
        });
    }

    /**
     * TODO: revisit this as more is understood about the use of this service...
     * Too many side effects, this function is not ideal.
     * @param giactRecord: GiactRecord the record to check against existing validated records
     */
    public checkForDuplicate(giactRecord: GiactRecord): void {
        // const [isDuplicated, matchedLineNumber] = this.giactImportService.checkForDuplicateEntity(giactRecord.record, this.validatedRecords);
        // if (isDuplicated) {
        //   this.addRecordToDuplicateRecords({
        //     record: giactRecord.record,
        //     lineNumber: giactRecord.lineNumber,
        //     matchedLineNumber
        //   });
        // } else {
        if (this.validatedRecords === undefined) {
            this.validatedRecords = {} as ValidGiactRecord;
        }
        if (this.validatedRecords[giactRecord.record.Name]) {
            this.validatedRecords[giactRecord.record.Name].push(giactRecord);
        } else {
            this.validatedRecords[giactRecord.record.Name] = [giactRecord];
        }
        // }
    }

    public hasVerifyRights(): boolean {
        const accessLevel = this.authService.checkAccess(AccessFeatures.SHIELD_VERIFY);
        return accessLevel === AccessTypes.FULL;
    }
}
