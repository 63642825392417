import { Component } from '@angular/core';
import { AuthService, LocalStorageItems } from '../../core/services/auth.service';
import { UserService } from '../../core/services/user.service';
import { NavigationHelperService } from '../../core/services/navigation-helper.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-armory-header',
    templateUrl: './armory-header.component.html',
    styleUrls: ['./armory-header.component.scss'],
})
export class ArmoryHeaderComponent {
    constructor(
        private authService: AuthService,
        private userService: UserService,
        private navigationHelperService: NavigationHelperService,
        private router: Router,
    ) {}

    public async logout() {
        // Save the fact that the user physically clicked "Log Out" to prevent
        // them from being automatically logging back in.
        localStorage.setItem(LocalStorageItems.LOGOUT_CLICKED, 'true');
        await this.authService.logout();
    }

    public get isDoneLoading$() {
        return this.authService.isAuthDoneLoading$;
    }

    public get isAuthenticated$() {
        return this.userService.isAuthenticated$;
    }

    public goHome() {
        this.router.navigate(['']);
    }

    public login() {
        this.authService.login();
    }

    public toggleSidebar() {
        this.navigationHelperService.toggleSidebar();
    }
}
