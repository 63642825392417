export type ActivityRecord = {
    id: number; // If there is no id in the source data then assign a number in increasing order.
    username: string;
    usertype: string;
    activity: string;
    feature: string;
    timestamp: number;
    auditDetails?: object;
};

export type ActivityRequest = {
    userType: string;
    userSearch: string;
    feature: string;
    startDate: number;
    endDate: number;
    page: number;
    size: number;
    sortField: string;
    sortDir: string;
};
