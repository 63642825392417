import { BankRecord } from './bank-record.types';

export type ModalConfigData = {
    title?: string;
    selected?: BankRecord;
    action: 'delete' | 'export';
};

export type SimpleModalResult = {
    updateData?: boolean;
    areChangesSaved?: boolean;
    exported?: boolean;
};

export type ExportConfig = {
    filename?: string;
    exportas?: string;
};
