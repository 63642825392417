import { Component } from '@angular/core';

@Component({
    selector: 'app-activity-modal',
    templateUrl: './activity-modal.component.html',
    styleUrls: ['./activity-modal.component.scss'],
})
export class ActivityModalComponent {
    constructor() {}
}
