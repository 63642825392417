import { ModalConfigButtonClick, UserActionEnum } from './user.types';

export type ShareholderRecord = {
    nsId: number;
    shareholder: string;
    email?: string;
    address1: string;
    address2?: string;
    address3?: string;
    address4?: string;
    subsidiaries?: string[];
};

export type ShareholderPartialRecord = {
    nsId: number;
    shareholder: string;
};

export const ShareholderSearchColumns = ['shareholder'];

export enum Permission {
    Administrator = 'administrator',
    Inviter = 'inviter',
    'No Access' = 'noaccess',
    Owner = 'owner',
    Viewer = 'viewer',
}

export type ShareholderUserAccess = {
    shareholder: string;
    shareholderId: number;
    deal: string;
    dealId: number;
    user: string;
    userId: number;
    permission: Permission;
};
