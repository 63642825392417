import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { BankRecord } from '../shared';

@Component({
    selector: 'app-slider-panel',
    templateUrl: './slider-panel.component.html',
    styleUrls: ['./slider-panel.component.scss'],
})
export class SliderPanelComponent {
    public get isOpen(): boolean {
        return this._isOpen;
    }
    @Input() set isOpen(value: boolean) {
        this._isOpen = value;
        if (this._isOpen) {
            this.clickCount = 0;
        }
    }

    public get bank(): BankRecord {
        return this._bank;
    }
    @Input() set bank(value: BankRecord) {
        this._bank = value;
    }

    @Output() closeEvent = new EventEmitter<any>();

    private clickCount = 0;
    private _isOpen = false;
    private _bank: BankRecord;

    @HostListener('document:click', ['$event'])
    clickout(event) {
        // If this element does not contain the target element, then a click
        // happened outside the panel and should be closed. But because this is
        // listening to all document clicks, including the click to open the
        // slider, the click count needs to be reset after (re)opening. This is
        // to effectively ignore initial click to open the container.
        if (!this.eRef.nativeElement.contains(event.target) && this.clickCount > 0 && this.isOpen) {
            this.closeSlider();
        }

        this.clickCount++;
    }

    constructor(private eRef: ElementRef) {}

    public closeSlider() {
        this.closeEvent.next(null);
    }
}
