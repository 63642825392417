import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BfdFile, DownloadList } from '../shared';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';

type TableElement = {
    selected?: boolean;
    file: BfdFile;
};

@Component({
    selector: 'app-bank-file-table',
    templateUrl: './bank-file-table.component.html',
    styleUrls: ['./bank-file-table.component.scss'],
})
export class BankFileTableComponent implements OnChanges {
    @Input() bfdFiles: BfdFile[];
    @Input() searchText: string;
    @Input() downloadInProgress: boolean;
    @Input() fetching: boolean;
    @ViewChild(MatSort) sort: MatSort;
    @Output() sortEvent = new EventEmitter<Sort>();
    @Output() dirClickEvent = new EventEmitter<BfdFile>();
    @Output() rowsSelected = new EventEmitter<DownloadList>();

    public form: FormGroup = new FormGroup({
        filterBy: new FormControl<string>(''),
    });
    public displayedColumns: string[] = ['bfdName', 'bfdExt', 'bfdLastModified', 'bfdSize'];
    public dataSource: MatTableDataSource<TableElement>;
    public tableData: TableElement[] = [];

    public allChecked = false;
    private downloadList: DownloadList = {};

    constructor() {
        // Assign the data to the data source for the table to render
        this.dataSource = new MatTableDataSource(this.tableData);
        this.dataSource.sort = this.sort;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['downloadInProgress']) {
            // {"downloadInProgress":{"previousValue":false,"currentValue":true,"firstChange":false}}
            if (changes['downloadInProgress'].currentValue === true) {
                this.displayedColumns.splice(0, 0, 'downloadFile');
            } else {
                if (this.displayedColumns[0] === 'downloadFile') {
                    this.displayedColumns.splice(0, 1);
                }
                this.downloadList = {};
                this.tableData.forEach((row) => (row.selected = false));
                this.allChecked = false;
            }
        } else if (changes['bfdFiles']) {
            if (this.bfdFiles && Array.isArray(this.bfdFiles) && this.bfdFiles.length > 0) {
                this.tableData = this.bfdFiles.map((row) => {
                    return {
                        selected: !!this.downloadList[row.name],
                        file: row,
                    };
                });
            } else {
                this.tableData = [];
            }

            // Assign the data to the data source for the table to render
            this.dataSource = new MatTableDataSource(this.tableData);
        }
    }

    public getLastModifiedUTC(lm: number): string {
        if (lm) {
            return new Date(lm).toUTCString();
        } else {
            return '';
        }
    }

    public handleNameClick(element: BfdFile) {
        if (element.isDir) {
            this.dirClickEvent.emit(element);
        }
    }

    handleSortChange(s: Sort) {
        this.sortEvent.emit(s);
    }

    public someComplete(): boolean {
        const hasChecked = this.tableData.find((row) => !row.file.isDir && row.selected === true);
        const hasUnchecked = this.tableData.find((row) => !row.file.isDir && row.selected === false);

        setTimeout(() => {
            // Avoiding this error: Expression has changed after it was checked
            this.allChecked = hasChecked != null && hasUnchecked == null;
        });

        return hasChecked != null && hasUnchecked != null;
    }

    public setAll($event: boolean) {
        this.tableData.forEach((row) => {
            if (!row.file.isDir) {
                row.selected = $event;

                this.downloadList[row.file.name] = {
                    name: row.file.name,
                    selected: $event,
                };
            } else {
                row.selected = false;
            }
        });

        this.allChecked = $event;

        this.emitSelectedNames();
    }

    public selectedChange($event: boolean, tableItem: TableElement) {
        tableItem.selected = $event;

        this.downloadList[tableItem.file.name] = {
            name: tableItem.file.name,
            selected: $event,
        };

        this.emitSelectedNames();
    }

    private emitSelectedNames() {
        this.rowsSelected.emit(this.downloadList);
    }
}
