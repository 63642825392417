<div id="main-div" class="main-div">
    <table
        mat-table
        matSort
        class="table-div"
        [dataSource]="dataSource"
        (matSortChange)="handleSortChange($event)"
    >
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="email">Email</th>
            <td mat-cell
                *matCellDef="let element"
                appHighlight
                [searchedWords]="searchText"
                [text]="element.email"
                [ignoreCase]="true"
                classToApply="selected"
            >{{element.email}}</td>
        </ng-container>

        <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="user">User</th>
            <td mat-cell
                *matCellDef="let element"
            >
                <span class="actionable-item"
                      (click)="handleUserSelected(element)"
                      appHighlight
                      [searchedWords]="searchText"
                      [text]="element.user ? element.user : element.firstName + ' ' + element.lastName"
                      [ignoreCase]="true"
                      classToApply="selected"
                >{{element.user ? element.user : element.firstName + ' ' + element.lastName}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="role">Role</th>
            <td mat-cell
                *matCellDef="let element"
                appHighlight
                [searchedWords]="searchText"
                [text]="element.role"
                [ignoreCase]="true"
                classToApply="selected"
            >{{element.role}}</td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="status">Auth0</th>
            <!-- Do not use appHighlight here as it removes the styling around the text -->
            <td mat-cell
                *matCellDef="let element"
            ><div class="auth0-div">
                <span class="auth0-chip"
                    [class.auth0-invited]="element.status?.toLowerCase() === 'invited'"
                    [class.auth0-active]="element.status?.toLowerCase() === 'active'"
                    [class.auth0-blocked]="element.status?.toLowerCase() === 'blocked'"
                    [class.auth0-deactivated]="element.status?.toLowerCase() === 'deactivated'"
                >
                    <span class="auth0-label">{{element.status}}</span>
                </span>
            </div></td>
        </ng-container>

        <ng-container matColumnDef="products">
            <th mat-header-cell *matHeaderCellDef>Products</th>
            <td mat-cell
                *matCellDef="let element"
            ><div class="products-div">
                <div class="chips-div">
                    <span *ngFor="let product of element.products" class="chip">
                        <span class="chip-label">
                            <span class="text-span">
                                <span class="text-label auth0-active"
                                >{{product.abbr}}</span>
                            </span>
                        </span>
                    </span>
                </div>
            </div></td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell
                *matCellDef="let element; index as idx;"
            >
                <div class="action-cell"
                     [class.action-cell-clicked]="idx === selectedIndex"
                     [matMenuTriggerFor]="menu"
                     aria-label="User action menu"
                     (click)="openMenu($event, idx)"
                     (menuClosed)="handleMenuClosedEvent($event, idx)"
                >
                    <div class="action-div">
                        <span class="material-symbols-outlined"
                        >more_horiz</span>
                    </div>
                </div>
                <mat-menu #menu="matMenu" xPosition="before" yPosition="below">
                    <div *ngIf="canEditUser(element.status)"
                         mat-menu-item
                         class="menu-option"
                         (click)="handleEditUserClick(element)">Edit User</div>
                    <div *ngIf="canViewUserActivity(element.status)"
                         mat-menu-item
                         class="menu-option"
                         (click)="handleUserActivityClick(idx)">User Activity</div>
                    <div *ngIf="canResetMFA(element.status)"
                         mat-menu-item
                         class="menu-option"
                         (click)="handleResetMFAClick(idx)">Reset MFA Method</div>
                    <div *ngIf="canResendInvitation(element.status)"
                         mat-menu-item
                         class="menu-option"
                         (click)="handleResendInvitation(idx)">Resend Invitation</div>
                    <div *ngIf="canSendPwReset(element.status)"
                         mat-menu-item
                         class="menu-option"
                         (click)="handleSendPwResetClick(idx)">Send Password Reset</div>
                    <div *ngIf="canBlock(element.status)"
                         mat-menu-item
                         class="menu-option menu-option-red"
                         (click)="handleBlockClick(idx)">Block Authentication</div>
                    <div *ngIf="canUnblock(element.status)"
                         mat-menu-item
                         class="menu-option menu-option-red"
                         (click)="handleUnblockClick(idx)">Unblock Authentication</div>
                    <div *ngIf="canDeactivate(element.status)"
                         mat-menu-item
                         class="menu-option menu-option-red"
                         (click)="handleDeactivateClick(idx)">Deactivate Account</div>
                    <div *ngIf="showRevokeAccess"
                         mat-menu-item
                         class="menu-option menu-option-red"
                         (click)="handleRevokeAccessClick(element)">Revoke Access</div>
                </mat-menu>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: !showCreateUser"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="intadm-table-row"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell no-data" colspan="10"><span *ngIf="!fetching">No data</span></td>
        </tr>
    </table>
</div>
<div class="paginator-div">
    <mat-paginator [length]="totalRecords"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="[10, 25, 50, 100]"
                   aria-label="Select page"
                   showFirstLastButtons=true
                   disabled=false
                   hidePageSize=false
                   (page)="handlePageEvent($event)">
    </mat-paginator>
</div>
