import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BankRecord, ModalConfigData } from '../shared';

@Component({
    selector: 'app-cache-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent implements AfterContentInit, AfterViewInit, OnChanges {
    @Input() cacheRecords: BankRecord[];
    @Input() searchText;
    @Input() initialSort: MatSortable;
    @Input() fetching: boolean;
    @ViewChild(MatSort) sort: MatSort;
    @Output() deleteEvent = new EventEmitter<ModalConfigData>();
    @Output() sortEvent = new EventEmitter<Sort>();

    @HostListener('document:keydown.escape', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
        if (this.showDetails) {
            this.closeSliderPanel();
        }
    }

    // This is used to check the users access; if they do not have access the
    // icons are not displayed.
    public deleteSectionTitle = 'cache-delete';

    public displayedColumns: string[] = ['bankName', 'nationalId', 'fedAch', 'fedWire', 'expirationDate', 'actions'];

    public dataSource: MatTableDataSource<BankRecord>;
    public showDetails: boolean = false;
    public selectedRow: BankRecord;

    constructor(private cdr: ChangeDetectorRef) {
        // Assign the data to the data source for the table to render
        this.dataSource = new MatTableDataSource<BankRecord>(this.cacheRecords);
    }

    ngAfterContentInit(): void {
        this.dataSource.data = this.cacheRecords;
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (data, property) => {
            if (property === 'fedach') {
                return data.fedach.connectivity;
            } else if (property === 'fedwire') {
                return data.fedwire.connectivity;
            } else {
                // This was added because the Account Number was being sorted
                // numerically instead of alphabetically.
                return data[property];
            }
        };

        if (this.sort) {
            this.sort.sort({ ...this.initialSort, disableClear: false });
            this.sort.sortChange.subscribe((sort) => {
                this.sortEvent.emit(sort);
            });
            this.dataSource.sort = this.sort;
            this.cdr.detectChanges();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.dataSource.data = this.cacheRecords;
    }

    public displayDetails(row: BankRecord) {
        this.selectedRow = row;
        this.showDetails = true;
    }

    public deleteRow(row: BankRecord) {
        this.deleteEvent.emit({
            title: 'Delete Routing Number Information',
            action: 'delete',
            selected: row,
        });
    }

    public closeSliderPanel() {
        this.showDetails = false;
    }
}
