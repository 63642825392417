import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './guards/auth-guard.service';
import { ConfigurationService } from './services/configuration.service';
import { ConfigurationInterceptor } from './interceptors/configuration.interceptor';
import { initConfigAndAuthServer } from './core.init';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { FlagsService } from './services/flags.service';
import { FlagsDirective } from './directives/flags.directive';
import { AccessDirective } from './directives/access.directive';
import { HighlightDirective } from './directives/highlight.directive';
import { FeatureToggleGuard } from './guards/feature-toggle.guard';

@NgModule({
    declarations: [FileUploadComponent, FlagsDirective, AccessDirective, HighlightDirective],
    imports: [CommonModule, HttpClientModule, MatProgressBarModule, MatButtonModule],
    exports: [FileUploadComponent, FlagsDirective, AccessDirective, HighlightDirective],
    providers: [AuthService, AuthGuard, FlagsService, FeatureToggleGuard],
})
export class CoreModule {
    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                ConfigurationService,
                {
                    provide: APP_INITIALIZER,
                    useFactory: initConfigAndAuthServer,
                    deps: [ConfigurationService, AuthService],
                    multi: true,
                },
                { provide: HTTP_INTERCEPTORS, useClass: ConfigurationInterceptor, multi: true },
            ],
        };
    }

    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }
    }
}
