import { Component, OnInit } from '@angular/core';
import { BrokerageAccount, ModalConfigData, ModalResult } from './shared';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { BrokerageAccountModalComponent } from './brokerage-account-modal/brokerage-account-modal.component';
import { BrokerageAccountsService } from './shared/brokerage-accounts.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AvailableColumns } from '../vendor-payments/table/table.columns.types';

@Component({
    selector: 'app-brokerage-accounts',
    templateUrl: './brokerage-accounts.component.html',
    styleUrls: ['./brokerage-accounts.component.scss'],
})
export class BrokerageAccountsComponent implements OnInit {
    public brokerageTitle = 'Common Brokerage Account List';
    public accountRecords: BrokerageAccount[];
    public fullList: BrokerageAccount[];
    public searchValue: string;
    public addSectionTitle = 'brokerage-add';
    public fetching = true;

    private modalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: true,
        minWidth: '700px',
        minHeight: '250px',
    };
    public brokerageModal: MatDialogRef<BrokerageAccountModalComponent>;
    public result: boolean = false;

    constructor(
        public matDialog: MatDialog,
        private snackBar: MatSnackBar,
        private brokerageAccountsService: BrokerageAccountsService,
    ) {}

    public ngOnInit(): void {
        this.fetch();
    }

    public performSearch(searchVal: string) {
        this.searchValue = searchVal;
        if (!searchVal || searchVal.trim() == '') {
            // No search value, return all records
            this.accountRecords = this.fullList;
        } else {
            this.accountRecords = this.fullList.filter((val) => {
                return (
                    val.brokerageAccountName.toLowerCase().indexOf(searchVal.trim().toLowerCase()) >= 0 ||
                    val.brokerageAccountNumber.toLowerCase().indexOf(searchVal.trim().toLowerCase()) >= 0 ||
                    val.bankRoutingNumber.toLowerCase().indexOf(searchVal.trim().toLowerCase()) >= 0 ||
                    val.bankName.toLowerCase().indexOf(searchVal.trim().toLowerCase()) >= 0
                );
            });
        }
    }

    public addRow() {
        const addRowConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Add New Account',
                action: 'create',
            },
        };
        this.openModal(addRowConfig);
    }

    public handleEditDelete($event: ModalConfigData) {
        const addRowConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplate,
            data: $event,
        };
        this.openModal(addRowConfig);
    }

    public export() {
        const newNoteConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Export Common Brokerage Accounts',
                action: 'export',
            },
        };

        this.openModal(newNoteConfig);
    }

    private openModal(modalConfig: MatDialogConfig) {
        this.brokerageModal = this.matDialog.open(BrokerageAccountModalComponent, modalConfig);
        this.brokerageModal.afterClosed().subscribe((res: ModalResult) => {
            if (!res || !res.areChangesSaved) {
                this.snackBar.open('Last changes were not saved and have been discarded', undefined, {
                    duration: 4000,
                });
            }

            if (res?.updateData) {
                let message;
                if (modalConfig.data.action === 'create') {
                    message = 'Brokerage Account has been created successfully';
                } else if (modalConfig.data.action === 'edit') {
                    message = 'Brokerage Account has been updated successfully';
                } else if (modalConfig.data.action === 'delete') {
                    message = 'Brokerage Account has been deleted successfully';
                }

                this.snackBar.open(message, undefined, {
                    duration: 4000,
                });

                setTimeout(() => {
                    this.snackBar.open('Fetched brokerage accounts after most recent edits', undefined, {
                        duration: 4000,
                    });
                }, 5000);

                this.fetch();
            } else if (res?.exported) {
                this.snackBar.open('Brokerage Accounts have been successfully exported', undefined, {
                    duration: 6000,
                });
            }
        });
    }

    private fetch() {
        this.fetching = true;
        this.brokerageAccountsService.get().subscribe({
            next: (res) => {
                this.fetching = false;
                this.accountRecords = this.fullList = res;
            },
            error: (error) => this.handleError(error),
        });
    }

    private handleError(error: HttpErrorResponse): void {
        this.fetching = false;
        console.error(error);
        this.snackBar.open('Server error', undefined, {
            duration: 10000,
        });
    }
}
