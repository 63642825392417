import { Directive, Input, SimpleChanges, Renderer2, ElementRef, OnChanges } from '@angular/core';

@Directive({
    selector: '[appHighlight]',
})
export class HighlightDirective implements OnChanges {
    @Input() searchedWords: string;
    @Input() text: string;
    @Input() classToApply: string;
    @Input() ignoreCase: boolean;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
    ) {}

    ngOnChanges(_: SimpleChanges): void {
        if (!this.searchedWords || !this.classToApply) {
            this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.text);
            return;
        }

        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.getFormattedText());
    }

    getFormattedText() {
        const escapedWords = this.searchedWords.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
        const re = new RegExp(`(${escapedWords})`, this.ignoreCase ? 'gi' : 'g');
        if (typeof this.text == 'number') {
            this.text = '' + this.text;
        }

        return this.text ? this.text.replace(re, `<span class="${this.classToApply}">$1</span>`) : this.text;
    }
}
