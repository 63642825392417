<mat-card appearance="outlined" class="mat-card">
    <div id="title-bar" class="title-bar">
        <mat-card-title>
            {{ modalData.title }}
        </mat-card-title>
        <span id="x-dismiss"
              (click)="closeModal()"
              class="material-symbols-outlined x-dismiss"
              data-testid="x-dismiss">
            close
        </span>
    </div>
    <div class="card__container" *ngIf="modalData.action === 'delete'">
        <ng-container
            *ngTemplateOutlet="deleteSection"
        ></ng-container>
    </div>
    <div class="card__container" *ngIf="modalData.action === 'reject'">
        <ng-container
            *ngTemplateOutlet="rejectSection"
        ></ng-container>
    </div>
    <div class="card__container" *ngIf="modalData.action === 'newNote'">
        <ng-container
            *ngTemplateOutlet="notesSection"
        ></ng-container>
    </div>
    <div class="card__container" *ngIf="modalData.action === 'export'">
        <ng-container
            *ngTemplateOutlet="exportSection"
        ></ng-container>
    </div>
    <div class="card__container" *ngIf="modalData.action === 'reverse'">
        <ng-container
            *ngTemplateOutlet="reverseSection"
        ></ng-container>
    </div>
    <div class="card__container" *ngIf="modalData.action === 'hide'">
        <ng-container
            *ngTemplateOutlet="hideSection"
        ></ng-container>
    </div>
</mat-card>

<ng-template #deleteSection>
    <form [formGroup]="deleteForm">
        <mat-card-content class="delete-card-content">
            <div class="delete-details">
                <span>Are you sure you want to delete </span>
                <span class="delete-selected-bank-name">{{this.modalData.selected.payeeName}}</span>
                <span> from your list?</span>
            </div>
            <div class="reason-frame">
                <div class="reason-label">Reason for deletion?</div>
                <div class="reason-note">Please give a brief explanation for deleting this account.</div>
            </div>
            <div class="textarea-frame">
                        <textarea class="reason-div" id="deleteNote"
                                  matInput
                                  formControlName="deleteReason"
                                  placeholder="You must provide a reason for the deletion..."></textarea>
                <mat-error *ngIf="deleteForm.controls.deleteReason.hasError('required')">
                    Delete reason is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="deleteForm.controls.deleteReason.hasError('maxLength')">
                    Maximum length is 1000 characters.
                </mat-error>
            </div>
            <div class="note-frame">
                <span class="note-msg-span"><b>Note:</b> This vendor's profile will be permanently removed from your Approved Vendor list and will no longer be included for future payments.</span>
            </div>
            <div class="confirm-checkbox">
                    <mat-checkbox id="delete-checkbox"
                                  class="delete-checkbox"
                                  formControlName="confirmDelete"
                    ></mat-checkbox>
                <span class="delete-checkbox-label">I understand the implications of deleting vendors.</span>
            </div>
        </mat-card-content>
    </form>
    <mat-card-actions align="end">
        <button
            class="modal-button modal-button-cancel"
            mat-flat-button
            (click)="closeModal()"
            data-testid="btnCancel"
        >
            Cancel
        </button>
        <button
            class="modal-button]"
            [class.modal-button-primary-delete]="!isDeleteDisabled()"
            [class.mat-button-disabled]="isDeleteDisabled()"
            type="submit"
            [matTooltip]="modalTooltip"
            matTooltipPosition="after"
            [disabled]="isDeleteDisabled()"
            (click)="submit()"
            mat-flat-button
            data-testid="btnDeleteVendor"
        >
            <span class="icon-button">
                <span class="modal-button-label">Delete</span>
            </span>
        </button>
    </mat-card-actions>
</ng-template>

<ng-template #rejectSection>
    <form [formGroup]="rejectForm">
        <mat-card-content>
            <div class="reject-title">
                Vendor Account Information
            </div>
            <div *ngIf="modalData.selected" class="data-div">
                <div class="data-frame">
                    <div class="data-element">
                        <span class="data-label">Payee Name</span>
                        <span class="data-value">{{modalData.selected.payeeName}}</span>
                    </div>
                    <div class="data-element">
                        <span class="data-label">Account Title</span>
                        <span class="data-value">{{modalData.selected.accountTitle}}</span>
                    </div>
                </div>
                <div class="data-frame">
                    <div class="data-element">
                        <span class="data-label">Account Number / IBAN</span>
                        <span class="data-value">{{modalData.selected.accountNumber}}</span>
                    </div>
                    <div class="data-element">
                        <span class="data-label">Routing / Swift</span>
                        <span class="data-value">{{modalData.selected.abaSwift}}</span>
                    </div>
                </div>
            </div>
            <div class="rejection-reason">Reason for rejection?</div>
            <div>
                <div class="reason-div">
                    <textarea id="rejectionNote"
                              matInput
                              formControlName="rejectReason"
                              placeholder="Please provide a brief explanation for the rejection of this account..."></textarea>
                </div>
                <mat-error *ngIf="rejectForm.controls.rejectReason.hasError('required')">
                    Reject reason is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="rejectForm.controls.rejectReason.hasError('maxLength')">
                    Maximum length is 1000 characters.
                </mat-error>
            </div>
        </mat-card-content>
    </form>
    <mat-card-actions align="end">
        <button
            class="modal-button modal-button-cancel"
            mat-flat-button
            (click)="closeModal()"
            data-testid="btnCancel"
        >
            Cancel
        </button>
        <button
            class="modal-button modal-button-primary"
            type="submit"
            [matTooltip]="modalTooltip"
            [disabled]="isRejectDisabled()"
            matTooltipPosition="after"
            (click)="submit()"
            mat-flat-button
            data-testid="btnRejectVendor"
        >
            <span class="icon-button">
                <span class="modal-button-label">Reject Vendor</span>
            </span>
        </button>
    </mat-card-actions>
</ng-template>

<ng-template #notesSection>
    <form [formGroup]="noteForm">
        <mat-card-content class="note-card-content">
            <div *ngIf="modalData.selected" class="note-data-div">
                    <textarea class="textarea-div" id="newNote"
                              matInput
                              formControlName="noteText"
                              rows="4"
                              cols="50"
                              placeholder="Add a note"></textarea>
                <mat-error *ngIf="noteForm.controls.noteText.hasError('required')">
                    Note is <strong>required</strong>
                </mat-error>
            </div>
        </mat-card-content>
    </form>
    <mat-card-actions align="end">
        <button
            class="modal-button modal-button-cancel"
            mat-flat-button
            (click)="closeModal()"
            data-testid="btnCancel"
        >
            Cancel
        </button>
        <button
            class="modal-button modal-button-primary"
            type="submit"
            [disabled]="isNoteDisabled()"
            (click)="submit()"
            mat-flat-button
            data-testid="btnSaveNote"
        >
            <span class="icon-button">
                <span class="modal-button-label">Save Note</span>
            </span>
        </button>
    </mat-card-actions>
</ng-template>

<ng-template #exportSection>
    <form [formGroup]="exportForm">
        <mat-card-content class="exportas-card-content">
            <div class="exportas-label">Export as:</div>
            <div class="exportas-data-div">
                <div class="filename-frame">
                    <div class="filename-outer-div">
                        <div class="filename-inner-div">
                            <div class="filename-input">
                                <input class="filename-value" id="filenameInput" matInput type="text" formControlName="filename" data-testid="filename"/>
                                <span class="filename-affordance">X</span>
                            </div>
                        </div>
                    </div>
                    <div class="filename-extension">
                        <div class="extension-text-field">
                            <span class="extension-input">.xlsx</span>
                        </div>
                    </div>
                </div>
                <mat-error *ngIf="exportForm.controls.filename.hasError('required')">
                    Filename is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="exportForm.controls.filename.hasError('maxLength')">
                    Maximum length is 150 characters.
                </mat-error>
            </div>
        </mat-card-content>
    </form>
    <mat-card-actions align="end">
        <button
            class="modal-button modal-button-cancel"
            mat-flat-button
            (click)="closeModal()"
            data-testid="btnCancel"
        >
            Cancel
        </button>
        <button
            class="modal-button modal-button-primary"
            type="submit"
            [disabled]="isExportDisabled()"
            (click)="submit()"
            mat-flat-button
            data-testid="btnExport"
        >
            <span class="icon-button">
                <span class="modal-button-label">Export</span>
            </span>
        </button>
    </mat-card-actions>
</ng-template>

<ng-template #reverseSection>
    <form [formGroup]="noteForm">
        <mat-card-content>
            <div class="reject-title">
                Are you sure you want to reverse the rejection of this vendor?
            </div>
            <div *ngIf="modalData.selected" class="data-div">
                <div class="data-frame">
                    <div class="data-element">
                        <span class="data-label">Payee Name</span>
                        <span class="data-value">{{modalData.selected.payeeName}}</span>
                    </div>
                    <div class="data-element">
                        <span class="data-label">Account Title</span>
                        <span class="data-value">{{modalData.selected.accountTitle}}</span>
                    </div>
                </div>
                <div class="data-frame">
                    <div class="data-element">
                        <span class="data-label">Account Number / IBAN</span>
                        <span class="data-value">{{modalData.selected.accountNumber}}</span>
                    </div>
                    <div class="data-element">
                        <span class="data-label">Routing / Swift</span>
                        <span class="data-value">{{modalData.selected.abaSwift}}</span>
                    </div>
                </div>
            </div>
            <div class="rejection-reason">Reason for rejection?</div>
            <div *ngIf="modalData.selected" class="note-data-div">
                    <textarea class="textarea-div" id="reversal-note"
                              matInput
                              formControlName="noteText"
                              rows="4"
                              cols="50"
                              placeholder="A note is required"></textarea>
            </div>
            <div class="note-frame">
                <span class="note-msg-span"><b>Note:</b> If the rejection is reversed, this vendor will be moved back to the 'In Review' section as a new eligible vendor.</span>
            </div>
        </mat-card-content>
    </form>
    <mat-card-actions align="end">
        <button
            class="modal-button modal-button-cancel"
            mat-flat-button
            (click)="closeModal()"
            data-testid="btnCancel"
        >
            Cancel
        </button>
        <button
            class="modal-button modal-button-primary"
            type="submit"
            [disabled]="isReversalDisabled()"
            (click)="submit()"
            mat-flat-button
            data-testid="btnReverseRejection"
        >
            <span class="icon-button">
                <span class="modal-button-label">Yes, Reverse Rejection</span>
            </span>
        </button>
    </mat-card-actions>
</ng-template>

<ng-template #hideSection>
        <mat-card-content>
            <div class="reject-title">
                Are you sure you want to hide this vendor from In Review?
            </div>
            <div class="note-frame">
                <span class="note-msg-span">Hiding the vendor means it <b>will not</b> be considered for approval until another payment is made. The <b>vendor will not be rejected</b>, but you won't see it in the review queue until a future payment is processed.</span>
            </div>
        </mat-card-content>
    <mat-card-actions align="end">
        <button
            class="modal-button modal-button-cancel"
            mat-flat-button
            (click)="closeModal()"
            data-testid="btnCancel"
        >
            Cancel
        </button>
        <button
            class="modal-button modal-button-primary"
            type="submit"
            (click)="submit()"
            mat-flat-button
            data-testid="btnHideVendor"
        >
            <span class="icon-button">
                <span class="modal-button-label">Yes, Hide Vendor</span>
            </span>
        </button>
    </mat-card-actions>
</ng-template>
