import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FieldValueCount, ModalConfigData, VendorRecord, VendorService } from '../shared';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { AvailableColumns, AllTableColumns, ColumnHeaders } from './modal-review.columns.types';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfigurationService } from '../../core/services/configuration.service';

@Component({
    selector: 'app-modal-review',
    templateUrl: './modal-review.component.html',
    styleUrls: ['./modal-review.component.scss'],
})
export class ModalReviewComponent implements OnInit {
    public areChangesSaved: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public submitButtonText = 'Close';

    public bankingDetailsColumns = [
        AvailableColumns.AccountTitle,
        AvailableColumns.AccountNumber,
        AvailableColumns.AbaSwift,
        AllTableColumns.Subsidiary,
        AvailableColumns.Currency,
        AvailableColumns.BankCountry,
        AvailableColumns.SortCode,
        AllTableColumns.BankName,
        AllTableColumns.FfcName,
        AllTableColumns.FfcAcct,
        AllTableColumns.ImbSwiftBic,
        AllTableColumns.ImbBankName,
    ];

    public payeeDetailsColumns = [
        AvailableColumns.PayeeName,
        AllTableColumns.Email,
        AllTableColumns.ShrhldId,
        AllTableColumns.TaxpayerId,
        AllTableColumns.PayeeAddress1,
        AllTableColumns.PayeeAddress2,
        AllTableColumns.PayeeAddress3,
        AllTableColumns.PayeeCity,
        AllTableColumns.PayeeState,
        AllTableColumns.PayeePostalCode,
        AllTableColumns.PayeeCountry,
    ];

    public fieldValueCount: FieldValueCount;
    public bankingDetailsDataSource: MatTableDataSource<VendorRecord>;
    public payeeDetailsDataSource: MatTableDataSource<VendorRecord>;
    public inEditMode = false;
    public primaryLabelDefault = 'Edit';
    public primaryLabel = this.primaryLabelDefault;
    public primaryLabelSave = 'Save Changes';

    public readOnlyFields = [AllTableColumns.AccountNumber, AllTableColumns.AbaSwift, AllTableColumns.Subsidiary];
    public customFields = [AllTableColumns.ShrhldId];
    public validShrHldrIds: Set<number>;
    public form: FormGroup = new FormGroup({
        // Banking Details
        accountTitle: new FormControl<string>('', [Validators.required, Validators.maxLength(150)]),
        accountNumber: new FormControl<string>(''), // Read only
        abaSwift: new FormControl<string>(''), // Read only
        subsidiary: new FormControl<string>(''), // Read only
        currency: new FormControl<string>('', [
            Validators.minLength(3),
            Validators.maxLength(3),
            Validators.pattern('[a-zA-Z]{3}'),
        ]),
        bankCountry: new FormControl<string>('', [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(3),
            Validators.pattern('[a-zA-Z]{3}'),
        ]),
        sortCode: new FormControl<string>('', [Validators.maxLength(35)]),
        bankName: new FormControl<string>('', [Validators.required, Validators.maxLength(150)]),
        ffcName: new FormControl<string>('', [Validators.maxLength(150)]),
        ffcAcct: new FormControl<string>('', [Validators.maxLength(50)]),
        imbSwiftbic: new FormControl<string>('', [Validators.maxLength(100)]),
        imbBankname: new FormControl<string>('', [Validators.maxLength(150)]),

        // Payee Details
        payeeName: new FormControl<string>('', [Validators.required, Validators.maxLength(150)]),
        email: new FormControl<string>('', [Validators.maxLength(150), Validators.pattern(/^.*@.*\..*$/)]),
        shrhldId: new FormControl<string>('', [Validators.required]),
        taxpayerId: new FormControl<number>(null),
        payeeAddress1: new FormControl<string>('', [Validators.maxLength(100)]),
        payeeAddress2: new FormControl<string>('', [Validators.maxLength(100)]),
        payeeAddress3: new FormControl<string>('', [Validators.maxLength(100)]),
        payeeCity: new FormControl<string>('', [Validators.maxLength(100)]),
        payeeState: new FormControl<string>('', [
            // payeeState is not required, but if entered must be 2 characters.
            Validators.pattern('^$|[a-zA-Z]{2}'),
        ]),
        payeePostalCode: new FormControl<string>('', [Validators.maxLength(10)]),
        payeeCountry: new FormControl<string>('', [Validators.maxLength(100)]),
    });

    private updateData: boolean = false;
    private originalVendorCopy: VendorRecord;
    private bankingVendors: VendorRecord[];
    private payeeVendors: VendorRecord[];
    private lastUpdatedVendor: VendorRecord;

    constructor(
        @Optional() public dialogRef: MatDialogRef<ModalReviewComponent>,
        @Inject(MAT_DIALOG_DATA) public modalData: ModalConfigData,
        private cd: ChangeDetectorRef,
        private snackBar: MatSnackBar,
        private vendorService: VendorService,
        private configurationService: ConfigurationService,
    ) {}

    ngOnInit(): void {
        this.form.reset();
        this.lastUpdatedVendor = null;

        // Close the dialog if the user presses the Escape key.
        this.dialogRef.keydownEvents().subscribe((event) => {
            if (event?.key === 'Escape') {
                this.closeModal();
            }
        });

        // Close the dialog if the user clicks outside the dialog.
        this.dialogRef.backdropClick().subscribe((e) => {
            if (e != null) {
                // null is getting passed during unit tests; this avoids testing problems.
                this.closeModal();
            }
        });

        // Keep a shallow copy of the original object so it does not get updated.
        this.originalVendorCopy = { ...this.modalData.selected };

        this.fetchRelatedVendors(this.originalVendorCopy);

        if (this.originalVendorCopy) {
            this.updateFormFields(this.originalVendorCopy);
        }
    }

    public closeModal() {
        this.dialogRef.close({ updateData: this.updateData, areChangesSaved: this.areChangesSaved.getValue() });
    }

    public getRelatedValueCount(column: string, value: string): string | undefined {
        const tip = this.fieldValueCount[column]?.find((val) => val.name === value);
        return tip ? tip.count.toString() : undefined;
    }

    public submit() {
        if (this.inEditMode) {
            const toSubmit = this.lastUpdatedVendor ? { ...this.lastUpdatedVendor } : { ...this.originalVendorCopy };
            toSubmit.accountTitle = this.form.controls?.accountTitle?.value;
            toSubmit.currency = this.form.controls?.currency?.value;
            toSubmit.bankCountry = this.form.controls?.bankCountry?.value;
            toSubmit.sortCode = this.form.controls?.sortCode?.value;
            toSubmit.bankName = this.form.controls?.bankName?.value;
            toSubmit.ffcName = this.form.controls?.ffcName?.value;
            toSubmit.ffcAcct = this.form.controls?.ffcAcct?.value;
            toSubmit.imbSwiftbic = this.form.controls?.imbSwiftbic?.value;
            toSubmit.imbBankname = this.form.controls?.imbBankname?.value;

            // Payee Details
            toSubmit.payeeName = this.form.controls?.payeeName?.value;
            toSubmit.email = this.form.controls?.email?.value;
            toSubmit.payeeAddress1 = this.form.controls?.payeeAddress1?.value;
            toSubmit.payeeAddress2 = this.form.controls?.payeeAddress2?.value;
            toSubmit.payeeAddress3 = this.form.controls?.payeeAddress3?.value;
            toSubmit.payeeCity = this.form.controls?.payeeCity?.value;
            toSubmit.payeeState = this.form.controls?.payeeState?.value;
            toSubmit.payeePostalCode = this.form.controls?.payeePostalCode?.value;
            toSubmit.payeeCountry = this.form.controls?.payeeCountry?.value;
            toSubmit.shrhldId = this.form.controls?.shrhldId?.value;

            toSubmit.taxpayerId = this.form.controls?.taxpayerId?.value;

            this.vendorService.saveInReviewNewVendorEdits(toSubmit).subscribe({
                next: (res: any) => {
                    const errorMessage = `${res?.message}`;

                    switch (res.status) {
                        case 201: {
                            this.lastUpdatedVendor = res.body;

                            if (this.lastUpdatedVendor) {
                                this.updateData = true;
                                this.areChangesSaved.next(true);

                                // Update the first row in each of the tables.
                                this.bankingVendors.splice(0, 1, this.lastUpdatedVendor);
                                this.payeeVendors.splice(0, 1, this.lastUpdatedVendor);

                                this.bankingDetailsDataSource = new MatTableDataSource(this.bankingVendors);
                                this.payeeDetailsDataSource = new MatTableDataSource(this.payeeVendors);

                                this.cd.detectChanges();

                                this.updateFormFields(this.lastUpdatedVendor);
                            }

                            this.resetButtonState();
                            break;
                        }
                        case 404:
                        case 500:
                        default: {
                            console.error('Server error:', errorMessage);
                            this.snackBar.open('Server error', undefined, {
                                duration: 10000,
                                panelClass: ['swatch-red'],
                            });
                        }
                    }
                },
                error: (error) => this.handleError(error),
            });
        } else {
            this.inEditMode = true;
            this.primaryLabel = this.primaryLabelSave;
        }
    }

    public cancelEdit() {
        this.form.reset();
        this.updateFormFields(this.lastUpdatedVendor ? this.lastUpdatedVendor : this.originalVendorCopy);
        this.resetButtonState();
    }

    /**
     * Returns a string array of the object keys of the errors for this field.
     * @param fieldName
     */
    public checkField(fieldName: string | number): string[] {
        const myControl = this.form.controls[fieldName];

        return myControl?.errors ? Object.keys(myControl.errors) : [];
    }

    public isPrimaryDisabled(): boolean {
        return this.inEditMode && !this.form.valid;
    }

    protected readonly ColumnHeaders = ColumnHeaders;

    private resetButtonState() {
        this.inEditMode = false;
        this.primaryLabel = this.primaryLabelDefault;
    }

    private updateFormFields(vendor: VendorRecord) {
        // Banking Details
        this.form.controls.accountTitle.setValue(vendor.accountTitle);
        this.form.controls.accountNumber.setValue(vendor.accountNumber);
        this.form.controls.abaSwift.setValue(vendor.abaSwift);
        this.form.controls.subsidiary.setValue(vendor.subsidiary);
        this.form.controls.currency.setValue(vendor.currency);
        this.form.controls.bankCountry.setValue(vendor.bankCountry);
        this.form.controls.sortCode.setValue(vendor.sortCode);
        this.form.controls.bankName.setValue(vendor.bankName);
        this.form.controls.ffcName.setValue(vendor.ffcName);
        this.form.controls.ffcAcct.setValue(vendor.ffcAcct);
        this.form.controls.imbSwiftbic.setValue(vendor.imbSwiftbic);
        this.form.controls.imbBankname.setValue(vendor.imbBankname);

        // Payee Details
        this.form.controls.payeeName.setValue(vendor.payeeName);
        this.form.controls.email.setValue(vendor.email);
        this.form.controls.shrhldId.setValue(vendor.shrhldId);
        this.form.controls.taxpayerId.setValue(vendor.taxpayerId);
        this.form.controls.payeeAddress1.setValue(vendor.payeeAddress1);
        this.form.controls.payeeAddress2.setValue(vendor.payeeAddress2);
        this.form.controls.payeeAddress3.setValue(vendor.payeeAddress3);
        this.form.controls.payeeCity.setValue(vendor.payeeCity);
        this.form.controls.payeeState.setValue(vendor.payeeState);
        this.form.controls.payeePostalCode.setValue(vendor.payeePostalCode);
        this.form.controls.payeeCountry.setValue(vendor.payeeCountry);

        this.form.markAllAsTouched();
    }

    private fetchRelatedVendors(vendor: VendorRecord) {
        this.vendorService.getRelatedVendors(vendor).subscribe({
            next: (res) => {
                this.fieldValueCount = res;

                // These "VendorRecords" only holds unique values and are not valid VendorRecords!
                // Many fields are missing, like 'id', dealId, and exRecId, because they are only
                // intended to show unique values.
                this.bankingVendors = this.createVendorRows(this.fieldValueCount, this.bankingDetailsColumns);
                this.payeeVendors = this.createVendorRows(this.fieldValueCount, this.payeeDetailsColumns);

                // Put as the first row the original vendor record copy
                this.bankingVendors.splice(0, 0, this.originalVendorCopy);
                this.payeeVendors.splice(0, 0, this.originalVendorCopy);

                this.bankingDetailsDataSource = new MatTableDataSource(this.bankingVendors);
                this.payeeDetailsDataSource = new MatTableDataSource(this.payeeVendors);

                this.validShrHldrIds = new Set(this.payeeVendors.map((vendor) => vendor.shrhldId));

                this.cd.detectChanges();
            },
            error: (error) => this.handleError(error),
        });
    }

    private createVendorRows(apiResult: FieldValueCount, supportedColumns: string[]): VendorRecord[] {
        // These "VendorRecords" only holds unique values and are not valid VendorRecords!
        // Many fields are missing, like 'id', dealId, and exRecId, because they are only
        // intended to show unique values.
        let tempRelated: VendorRecord[] = [];
        let rowCount = 0;
        const fields = Object.keys(apiResult);

        if (apiResult && fields && fields.length > 0) {
            fields.forEach((field) => {
                if (
                    supportedColumns &&
                    supportedColumns.includes(field) && // Keep only those columns we care about
                    apiResult[field] &&
                    Array.isArray(apiResult[field])
                ) {
                    // Sort arrays descending so the most used values are on top.
                    apiResult[field].sort((a, b) => (a.count < b.count ? 1 : a.count > b.count ? -1 : 0));

                    if (apiResult[field].length > rowCount) {
                        // How many rows are required? Get the max array length.
                        rowCount = apiResult[field].length;
                    }
                }
            });

            // Create rowCount number of objects to be populated with the unique values.
            [...Array(rowCount)].forEach((_) => tempRelated.push({}));

            fields.forEach((field) => {
                if (supportedColumns && supportedColumns.includes(field)) {
                    apiResult[field].forEach((val, idx) => {
                        tempRelated[idx][field] = val.name;
                    });
                }
            });

            return tempRelated;
        }
    }

    private handleError(error: HttpErrorResponse): void {
        console.error(error);
        this.snackBar.open('Server error', undefined, {
            duration: 10000,
            panelClass: ['swatch-red'],
        });
    }

    public isShareholderIdColumn(innerColumn: string): boolean {
        return innerColumn === AllTableColumns.ShrhldId;
    }

    public openInNewTab($event, url: string) {
        // This is to prevent the click event from propagating up.
        $event?.stopPropagation();
        $event?.preventDefault();
        window.open(url, '_blank').focus();
    }

    public getShareholderNsUrl(nsId: number): string {
        const nsCustomerUrl = this.configurationService.envConfig.getValue().nsCustomerUrl;

        return nsCustomerUrl && nsId ? nsCustomerUrl.replace(/INSERTIDHERE/, nsId.toString()) : '';
    }
}
