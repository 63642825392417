import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigurationService } from '../../core/services/configuration.service';
import { catchError, Observable } from 'rxjs';
import { handleMissingServerError } from '../../core/utils/http-response-handlers';
import { cloneDeep } from 'lodash-es';

import {
    ExportConfig,
    FieldValueCount,
    HideVendorConfig,
    VendorConfigWithNote,
    VendorNote,
    VendorRecord,
} from './vendor.types';
import { ReviewStatusType } from './review_status_type.type';
import { UserService } from '../../core/services/user.service';

@Injectable({
    providedIn: 'root',
})
export class VendorService {
    private serverName: string = 'VendorRecord Payments API';
    private VENDOR_SERVICE_ENDPOINT = 'vendor-payments';
    private VENDOR_NOTES_ENDPOINT = 'vendor-notes';

    constructor(
        private http: HttpClient,
        private configurationService: ConfigurationService,
        private userService: UserService,
    ) {}

    public addNote(vendor: VendorRecord, comment: string): Observable<{}> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().vendorPaymentsHost,
            this.VENDOR_NOTES_ENDPOINT,
        ].join('/');

        const note: VendorNote = {
            vendorId: vendor.id,
            exRecId: vendor.exRecId,
            note: comment,
            createdBy: this.userService.user.name,
        };

        return this.http
            .post(requestUrl, note, { headers: { 'Content-Type': 'application/json' } })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public getNoteForVendorById(id: number): Observable<VendorNote> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().vendorPaymentsHost,
            this.VENDOR_NOTES_ENDPOINT,
            'by_id',
            id,
        ].join('/');

        return this.http
            .get(requestUrl, { headers: { 'Content-Type': 'application/json' } })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public getNotesForVendorByVendorId(vendorId: number): Observable<VendorNote[]> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().vendorPaymentsHost,
            this.VENDOR_NOTES_ENDPOINT,
            'by_vendorid',
            vendorId,
        ].join('/');

        return this.http
            .get(requestUrl, { headers: { 'Content-Type': 'application/json' } })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public getApprovedVendors(): Observable<VendorRecord[]> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().vendorPaymentsHost,
            this.VENDOR_SERVICE_ENDPOINT,
            'approved',
        ].join('/');

        return this.http.get(requestUrl).pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public getInReviewVendors(): Observable<VendorRecord[]> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().vendorPaymentsHost,
            this.VENDOR_SERVICE_ENDPOINT,
            'inreview',
        ].join('/');

        return this.http.get(requestUrl).pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public getRejectedVendors(): Observable<VendorRecord[]> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().vendorPaymentsHost,
            this.VENDOR_SERVICE_ENDPOINT,
            'rejected',
        ].join('/');

        return this.http.get(requestUrl).pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public rejectInReviewNewVendor(vendor: VendorRecord, comment: string): Observable<VendorRecord> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().vendorPaymentsHost,
            this.VENDOR_SERVICE_ENDPOINT,
            'reject',
        ].join('/');

        const tempNote: VendorNote = {
            note: comment,
            createdBy: this.userService.user.name,
        };

        const vendorConfigWithNote: VendorConfigWithNote = {
            vendor: vendor,
            note: tempNote,
        };

        return this.http
            .put(requestUrl, vendorConfigWithNote, { headers: { 'Content-Type': 'application/json' } })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public rejectInReviewVendorEditDelete(vendor: VendorRecord): Observable<{}> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().vendorPaymentsHost,
            this.VENDOR_SERVICE_ENDPOINT,
            'reject',
        ].join('/');

        const vendorConfigWithNote: VendorConfigWithNote = {
            vendor: vendor,
            note: null,
        };

        return this.http
            .put(requestUrl, vendorConfigWithNote, { headers: { 'Content-Type': 'application/json' } })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public approveInReviewVendor(vendor: VendorRecord): Observable<VendorRecord> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().vendorPaymentsHost,
            this.VENDOR_SERVICE_ENDPOINT,
            'approve',
        ].join('/');

        vendor.approvedBy = this.userService.user.name;
        vendor.approvedDt = new Date().getTime();

        return this.http
            .put(requestUrl, vendor, { headers: { 'Content-Type': 'application/json' } })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public submitEditsForApproval(vendor: VendorRecord, comment: string): Observable<VendorRecord> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().vendorPaymentsHost,
            this.VENDOR_SERVICE_ENDPOINT,
            'edit',
        ].join('/');

        const tempNote: VendorNote = {
            note: comment,
            createdBy: this.userService.user.name,
        };

        vendor.status = ReviewStatusType.EDIT_REVIEW;
        vendor.reviewType = 'Edit to Approved Vendor';
        vendor.changeSubmittedById = this.userService.user.sub;

        const editConfig: VendorConfigWithNote = {
            vendor: vendor,
            note: tempNote,
        };

        return this.http
            .put(requestUrl, editConfig, { headers: { 'Content-Type': 'application/json' } })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public submitDeleteForApproval(vendor: VendorRecord, comment: string): Observable<{}> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().vendorPaymentsHost,
            this.VENDOR_SERVICE_ENDPOINT,
            'delete',
        ].join('/');

        const tempNote: VendorNote = {
            exRecId: vendor.exRecId,
            note: comment,
            createdBy: this.userService.user.name,
        };

        // WHEN I confirm my delete request
        // THEN I should still see the approved vendor in the Approved Vendor list with it’s original values
        // AND I should see the approved vendor in the Review list as a delete request
        const deletedVendor = cloneDeep(vendor);
        deletedVendor.reviewType = 'Delete Approved Vendor';
        deletedVendor.changeSubmittedById = this.userService.user.sub;

        const deleteConfig: VendorConfigWithNote = {
            vendor: deletedVendor,
            note: tempNote,
        };

        return this.http
            .put(requestUrl, deleteConfig, { headers: { 'Content-Type': 'application/json' } })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public reverseRejection(vendor: VendorRecord, comment: string): Observable<{}> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().vendorPaymentsHost,
            this.VENDOR_SERVICE_ENDPOINT,
            'reverseRejection',
        ].join('/');

        vendor.changeSubmittedById = this.userService.user.sub;

        const tempNote: VendorNote = {
            note: comment,
            createdBy: this.userService.user.name,
        };

        const reverseConfig: VendorConfigWithNote = {
            vendor: vendor,
            note: tempNote,
        };

        return this.http
            .put(requestUrl, reverseConfig, { headers: { 'Content-Type': 'application/json' } })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public export(exportConfig: ExportConfig): Observable<Response> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().vendorPaymentsHost,
            this.VENDOR_SERVICE_ENDPOINT,
            'exportApproved',
        ].join('/');

        return this.http
            .post(requestUrl, exportConfig, {
                headers: { 'Content-Type': 'application/json' },
                responseType: 'blob',
                observe: 'response',
            })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public hideVendor(vendor: VendorRecord): Observable<Response> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().vendorPaymentsHost,
            this.VENDOR_SERVICE_ENDPOINT,
            'hide',
        ].join('/');

        const hideConfig: HideVendorConfig = {
            id: vendor.id,
        };

        return this.http
            .put(requestUrl, hideConfig, { headers: { 'Content-Type': 'application/json' } })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public getRelatedVendors(vendor: VendorRecord): Observable<FieldValueCount> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().vendorPaymentsHost,
            this.VENDOR_SERVICE_ENDPOINT,
            'related',
        ].join('/');

        let params = new HttpParams()
            .set('accountNumber', vendor.accountNumber)
            .set('abaSwift', vendor.abaSwift)
            .set('id', vendor.id);

        return this.http
            .get(requestUrl, { params: params })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public saveInReviewNewVendorEdits(vendor: VendorRecord): Observable<VendorRecord> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().vendorPaymentsHost,
            this.VENDOR_SERVICE_ENDPOINT,
            'updateNew',
        ].join('/');

        return this.http
            .put(requestUrl, vendor, {
                headers: { 'Content-Type': 'application/json' },
                responseType: 'json',
                observe: 'response',
            })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public markInReviewVendorAsReviewed(vendor: VendorRecord): Observable<{}> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().vendorPaymentsHost,
            this.VENDOR_SERVICE_ENDPOINT,
            'reviewed',
        ].join('/');

        vendor.reviewedBy = this.userService.user.name;
        vendor.reviewedDt = new Date().getTime();

        return this.http
            .put(requestUrl, vendor, { headers: { 'Content-Type': 'application/json' } })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }
}
