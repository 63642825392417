<div *ngIf="(fetching)" class="fetching-files">
    <mat-spinner></mat-spinner>
</div>
<div id="deals-container" class="deals-container">
    <ng-container
        *ngTemplateOutlet="selectedDealId ? singleDeal : multipleDeals"
    ></ng-container>
</div>

<ng-template #singleDeal>
    <ng-container *ngTemplateOutlet="showCreateEditUser ? createUserForm : null">
    </ng-container>
    <div *ngIf="!showCreateEditUser" class="wrapper-div">
        <div class="page-title-actions">
            <div class="title-actions">
                 <span class="title">{{selectedDeal?.name}}</span>
                <div class="actions">
                    <div class="action-icon action-icon-border deal-activity-button"
                         (click)="dealActivityClick()"
                         data-testid="deal-activity-button"
                    >
                        <span class="action-label">Deal Activity</span>
                    </div>
                    <div class="action-icon view-button-div">
                        <div [matMenuTriggerFor]="viewMenu" class="view-button-elem">
                            <span>View</span>
                            <mat-icon>keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                    <mat-menu #viewMenu="matMenu" xPosition="before" yPosition="below">
                        <div mat-menu-item class="menu-option" (click)="tabClickSingleDeal(0)">Deal Dashboard</div>
                        <div mat-menu-item class="menu-option" (click)="tabClickSingleDeal(1)">Shareholder Portal</div>
                    </mat-menu>
                </div>
            </div>
        </div>
        <div class="frame-div">
            <div class="deal-details-div">
                <div class="deal-details-scroll">
                    <div class="event-div">
                        <div class="event-date-div">
                            <span class="event-month">{{selectedDeal?.closingDt | date: 'MMM' : 'UTC'}}</span>
                            <span class="event-day">{{selectedDeal?.closingDt | date: 'dd' : 'UTC'}}</span>
                        </div>
                        <div class="event-date-event">
                            <span class="event-label">Closing Date</span>
                            <span class="event-date-full">{{selectedDeal?.closingDt | date: 'MMM dd, yyyy' : 'UTC'}}</span>
                        </div>
                    </div>
                    <div class="info-div">
                        <div class="info-label">
                            {{ 'Deal Info' | uppercase }}
                        </div>
                        <div class="info-data">
                            <span class="info-data-label">Project Name</span>
                            <span class="info-data-value">{{selectedDeal?.projectName}}</span>
                        </div>
                        <div class="info-data">
                            <span class="info-data-label">Selling Company</span>
                            <span class="info-data-value">{{selectedDeal?.seller}}</span>
                        </div>
                        <div class="info-data">
                            <span class="info-data-label">Acquiring Company</span>
                            <span class="info-data-value">{{selectedDeal?.buyer}}</span>
                        </div>
                        <div class="info-data">
                            <span class="info-data-label">Salesforce ID</span>
                            <span class="as-link"
                                  (click)="openInNewTab($event, getDealSfUrl(selectedDeal?.sfId))"
                                  [matTooltip]="getDealSfUrl(selectedDeal?.sfId)"
                                  matTooltipPosition="below"
                                  matTooltipShowDelay="300"
                                  matTooltipHideDelay="200"
                            >{{selectedDeal?.sfId}}</span>
                        </div>
                        <div class="info-data">
                            <span class="info-data-label">NetSuite ID</span>
                            <span class="as-link"
                                  (click)="openInNewTab($event, getDealNsUrl(selectedDeal?.nsId))"
                                  [matTooltip]="getDealNsUrl(selectedDeal?.nsId)"
                                  matTooltipPosition="below"
                                  matTooltipShowDelay="300"
                                  matTooltipHideDelay="200"
                            >{{selectedDeal?.nsId}}
                            </span>
                        </div>
                        <div class="info-data">
                            <span class="info-data-label">Subsidiary</span>
                            <span class="info-data-value" *ngFor="let sub of selectedDeal?.subsidiaries">{{ sub }}</span>
                        </div>
                    </div>
                    <div class="team-div">
                        <div class="team-label">
                            {{ 'Team' | uppercase }}
                        </div>
                        <div class="team-list">
                            <div class="team-data">
                                <span class="team-data-label">Relationship Manager</span>
                                <span class="team-data-value">{{selectedDeal?.rmName}}</span>
                            </div>
                            <div class="team-data">
                                <span class="team-data-label">Relationship Associate</span>
                                <span class="team-data-value">{{selectedDeal?.raName}}</span>
                            </div>
                            <div class="team-data">
                                <span class="team-data-label">Business Development</span>
                                <span class="team-data-value">{{selectedDeal?.bdName}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="services-div">
                        <div class="services-label">
                            {{ 'Services' | uppercase }}
                        </div>
                        <div class="services-column">
                            <span class="service-label" *ngFor="let service of selectedDeal?.services">{{service}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="users-section-div">
                <mat-tab-group mat-align-tabs="start" animationDuration="0ms" mat-stretch-tabs="false"
                               [disableRipple]="true"
                               [(selectedIndex)]="selectedIndexSingleDeal"
                               (selectedTabChange)="tabChangeSingleDeal($event);"
                               fitInkBarToContent="true"
                               class="tool-bar"
                >
                    <mat-tab label="Deal Dashboard">
                        <ng-container
                            *ngTemplateOutlet="dealDashboardUserTableTemplate"
                        ></ng-container>
                    </mat-tab>
                    <mat-tab label="Shareholder Portal">
                        <ng-container
                            *ngTemplateOutlet="shareholderPortalUserTableTemplate"
                        ></ng-container>
                    </mat-tab>
                </mat-tab-group>
                <div *ngIf="showAddUserMenu()" class="button-div add-user-button-container">
                    <div [matMenuTriggerFor]="menu" class="button-elem action-icon-border">
                        <span class="button-label">Add User</span>
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </div>
                </div>
                <mat-menu #menu="matMenu" xPosition="before" yPosition="below">
                    <div *appAccessCheckSection="permissionCreateUser" mat-menu-item class="menu-option" (click)="createUser()">Create User</div>
                    <div *appAccessCheckSection="permissionAddUser" mat-menu-item class="menu-option" (click)="addExistingUser()">Add Existing User</div>
                </mat-menu>
            </div>
        </div>
    </div>
</ng-template>

<ng-template matTabContent #dealDashboardUserTableTemplate>
    <div class="users-frame-div">
        <div class="users-search-bar">
            <app-intadm-search
                [initialValue]="searchValue"
                data-testid="app-accounts-search-users"
                placeholder="Filter by name, email, or role..."
                (newSearchEvent)="performSearchUsers($event)">
            </app-intadm-search>
        </div>
    </div>
    <div class="table-container">
        <app-user-table
            class="users-table"
            data-testid="app-user-table"
            [displayedColumns]="userDisplayedColumns"
            [showCreateUser]="showCreateEditUser"
            (userToEditEvent)="handleUserToEditEvent($event)"
            [userRecords]="userRecords"
            [initialSort]="{ id: requestHelperUsers.sortField, start: requestHelperUsers.sortDir }"
            [searchText]="searchValueUsers"
            [page]="pageUsers"
            [pageSize]="pageSizeUsers"
            [totalRecords]="totalRecordsUsers"
            (pageEvent)="handleUsersPageEvent($event)"
            (sortEvent)="handleUsersSortChange($event)"
            (userActionNav)="handleUserAction($event)"
            (revokeAccessEvent)="handleRevokeAccessEventDD($event)"
            [showRevokeAccess]="showRevokeAccess"
        ></app-user-table>
    </div>
</ng-template>

<ng-template matTabContent #shareholderPortalUserTableTemplate>
    <div class="users-frame-div">
        <div class="users-search-bar">
            <app-intadm-search
                [initialValue]="searchValue"
                data-testid="app-accounts-search-users"
                placeholder="Filter by name, permission, or shareholder..."
                (newSearchEvent)="performSearchUsers($event)">
            </app-intadm-search>
        </div>
    </div>
    <div class="table-container">
        <app-shareholder-deal-user-table
            class="shareholder-deal-user-table"
            [displayedColumns]="shareholderDealUserDisplayedColumns"
            [records]="shareholderDealUserRecords"
            [initialSort]="{ id: requestHelperShareholderUserAccess.sortField, start: requestHelperShareholderUserAccess.sortDir }"
            [searchText]="searchValueDealsUsers"
            [page]="page"
            [pageSize]="pageSize"
            [totalRecords]="totalRecordsDealsUsers"
            [fetching]="fetchingDealsUsers"
            [showRevokeAccess]="showRevokeAccess"
            (userClickedEvent)="handleUserClickedEventDealsUsers($event)"
            (shareholderClickedEvent)="handleShareholderClickedEventDealsUsers($event)"
            (pageEvent)="handlePageEventDealsUsers($event)"
            (sortEvent)="handleSortChangeDealsUsers($event)"
            (revokeAccessEvent)="handleRevokeAccessEventSH($event)"
        ></app-shareholder-deal-user-table>
    </div>
</ng-template>

<ng-template #multipleDeals>
    <div id="header-bar" class="header-bar">
        <div class="header-text-div">
            <span id="header-text" class="header-text">Deals</span>
        </div>
        <div class="toggle-group">
            <span class="subtab"
                  (click)="tabClickMultipleDeals(tabAll)"
                  [class.subtab-selected]="tabSelectedMultipleDeals === tabAll"
            >All Deals</span>
            <span class="subtab"
                  (click)="tabClickMultipleDeals(tabMine)"
                  [class.subtab-selected]="tabSelectedMultipleDeals === tabMine"
            >My Deals</span>
        </div>
    </div>
    <div class="main">
        <div id="search-bar" class="search-bar">
            <app-intadm-search
                [initialValue]="searchValue"
                data-testid="app-accounts-search"
                placeholder="Filter Deals"
                (newSearchEvent)="performSearch($event)"></app-intadm-search>
        </div>
        <div class="table-container">
            <app-deal-table
                class="deals-table"
                data-testid="app-deals-table"
                [displayedColumns]="multipleDealsDisplayedColumns"
                [dealRecords]="dealRecords"
                [initialSort]="{ id: requestHelper.sortField, start: requestHelper.sortDir }"
                [searchText]="searchValue"
                [page]="page"
                [pageSize]="pageSize"
                [totalRecords]="totalRecords"
                (pageEvent)="handlePageEvent($event)"
                (sortEvent)="handleSortChange($event)"
                [fetching]="fetching"
                (dealActivityNav)="handleDealActivityNavigation($event)"
                (dealDashboardNav)="handleDealDashboardNavigation($event)"
            ></app-deal-table>
        </div>
    </div>
</ng-template>

<ng-template #createUserForm>
    <app-create-edit-user id="create-edit-user"
                          [mode]="createEditUserMode"
                          [userToEditId]="userToEditId"
                          (showCreateEditUserCloseEvent)="handleShowCreateEditUserCancelEvent()"
    ></app-create-edit-user>
</ng-template>
