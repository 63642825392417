import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from './core/services/user.service';
import { TimerHandle } from 'rxjs/internal/scheduler/timerHandle';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
    public error: Error;
    public showTokenExpiringWarning: boolean = false;
    public sessionTime: string = '00:00';
    private sessionInterval: TimerHandle;

    constructor(
        private authService: AuthService,
        private snackBar: MatSnackBar,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        this.authService.tokenExpiringWarningDuration$.subscribe((timeRemaining) => {
            if (timeRemaining <= 0) {
                this.showTokenExpiringWarning = false;
                return;
            }
            this.updateSessionTime(timeRemaining);
            this.showTokenExpiringWarning = true;

            this.sessionInterval = setInterval(() => {
                if (--timeRemaining >= 0) {
                    this.updateSessionTime(timeRemaining);
                }
            }, 1000);
        });
    }

    ngOnDestroy(): void {
        this.clearTimers();
    }

    public openSnackBar(message: string, action?: string) {
        this.snackBar.open(message, action, { verticalPosition: 'top', horizontalPosition: 'center' });
    }

    public get isDoneLoading$() {
        return this.authService.isAuthDoneLoading$;
    }

    public get refreshLoginFailed() {
        return this.authService.refreshLoginFailed$;
    }

    public get isAuthenticated$() {
        return this.userService.isAuthenticated$;
    }

    public logout() {
        this.clearTimers();
        this.authService.logout();
    }

    public refreshLogin() {
        this.clearTimers();
        this.authService.refreshLogin();
    }

    private updateSessionTime(timeRemaining: number) {
        this.sessionTime = `${String(Math.floor(timeRemaining / 60)).padStart(2, '0')}:${String(
            Math.floor(timeRemaining % 60),
        ).padStart(2, '0')}`;
    }

    private clearTimers() {
        if (this.sessionInterval) {
            clearInterval(this.sessionInterval);
        }
    }
}
