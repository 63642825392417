<div style="height: 100%;">
    <mat-card appearance="outlined" class="mat-card" *ngIf="activity">
        <mat-card-header>
            <mat-card-title>Details</mat-card-title>
            <span id="x-dismiss"
                  (click)="closeSlider()"
                  class="material-symbols-outlined x-dismiss"
                  data-testid="x-dismiss"
            >close
            </span>
        </mat-card-header>
        <div class="card__container">
            <mat-card-content>
                <div class="accordian-list">
                    <div class="text-grouping">
                        <div class="grouping-label">
                            User{{activity.action ? '&nbsp;(' + capitalizeFirstLetter(activity.action) + ')' : ''}}
                        </div>
                        <div class="grouping-value-name">
                            {{activity.userName}}
                        </div>
                    </div>
                    <div class="text-grouping">
                        <div class="grouping-label">
                            Status
                        </div>
                        <div class="grouping-value-action">
                            <span class="grouping-value-chip"
                                  [class.grouping-value-status-invited]="activity.userStatus?.toLowerCase() === 'invited'"
                                  [class.grouping-value-status-active]="activity.userStatus?.toLowerCase() === 'active'"
                                  [class.grouping-value-status-locked]="activity.userStatus?.toLowerCase() === 'locked'"
                                  [class.grouping-value-status-deactivated]="activity.userStatus?.toLowerCase() === 'deactivated'"
                            >
                                <span class="grouping-value-label">{{capitalizeFirstLetter(activity.userStatus)}}</span>
                            </span>
                        </div>
                    </div>
                    <div *ngIf="activity.products" class="text-grouping">
                        <div class="grouping-label">
                            Product
                        </div>
                        <div class="grouping-value-product">
                            {{activity.products}}
                        </div>
                    </div>
                    <div *ngIf="activity.properties" class="text-grouping">
                        <div class="grouping-label">
                            Properties
                        </div>
                        <div class="grouping-value-product">
                            {{activity.properties}}
                        </div>
                    </div>
                    <div *ngIf="activity.dealUserRole" class="text-grouping">
                        <div class="grouping-label">
                            Deal Dashboard Role
                        </div>
                        <div class="grouping-value-deal-role">
                            {{activity.dealUserRole}}
                        </div>
                    </div>
                    <div *ngIf="activity.deals" class="text-grouping">
                        <div class="grouping-label">
                            Deal
                        </div>
                        <div class="grouping-value-deal">
                            {{activity.deals}}
                        </div>
                    </div>
                    <div class="text-grouping">
                        <div class="grouping-label">
                            Admin
                        </div>
                        <div class="grouping-value-byname">
                            {{activity.byName}}
                        </div>
                    </div>
                    <div class="text-grouping">
                        <div class="grouping-label">
                            When
                        </div>
                        <div class="grouping-value-byname">
                            {{activity.activityDt | date: "MMM dd, yyyy 'at' hh:mm a"}}
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </div>
        <div class="card-actions">
            <button
                class="modal-button modal-button-secondary"
                mat-flat-button
                (click)="handleUserHistoryClick()"
            >
                <span class="icon-button">
                    <span class="modal-button-label">User History</span>
                </span>
            </button>
            <button
                class="modal-button modal-button-primary"
                (click)="closeSlider()"
                mat-flat-button
                data-testid="btnDone"
            >
                    <span class="icon-button">
                        <span class="modal-button-label">Done</span>
                    </span>
            </button>

        </div>
    </mat-card>
</div>
