<div id="main-div" class="main-div" data-testid="main-div">
    <table mat-table matSort [dataSource]="dataSource" class="table-div">
        <ng-container matColumnDef="bankName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="bankName"> Bank Name</th>
            <td mat-cell
                *matCellDef="let element"
                appHighlight
                [searchedWords]="searchText"
                [text]="element.bankName"
                [ignoreCase]="true"
                classToApply="selected"
            ></td>
        </ng-container>

        <ng-container matColumnDef="nationalId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="nationalId"> National ID</th>
            <td mat-cell
                *matCellDef="let element"
                appHighlight
                [searchedWords]="searchText"
                [text]="element.nationalId"
                [ignoreCase]="true"
                classToApply="selected"
            ></td>
        </ng-container>

        <ng-container matColumnDef="fedAch">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="fedach"> Fed ACH</th>
            <td mat-cell
                *matCellDef="let element"
            >
                <div
                    [class.connectivity-direct] = "element.fedach.connectivity.toLowerCase() === 'direct'"
                    [class.connectivity-indirect] = "element.fedach.connectivity.toLowerCase() === 'indirect'"
                >{{element.fedach.connectivity.toUpperCase()}}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="fedWire">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="fedwire"> Fed Wire</th>
            <td mat-cell
                *matCellDef="let element"
            >
                <div
                    [class.connectivity-direct] = "element.fedwire.connectivity.toLowerCase() === 'direct'"
                    [class.connectivity-indirect] = "element.fedwire.connectivity.toLowerCase() === 'indirect'"
                >{{element.fedwire.connectivity.toUpperCase()}}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="expirationDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="expirationDate"> Expiration</th>
            <td mat-cell
                *matCellDef="let element"
            >{{element.expirationDate}}</td>
        </ng-container>

        <ng-container matColumnDef="actions" class="mat-header-cell" style="vertical-align: middle;">
            <th mat-header-cell *matHeaderCellDef> Actions</th>
            <td mat-cell *matCellDef="let element; index as i;" class="action-column">
                <span id="visibility-span-{{i}}"
                      class="material-symbols-outlined action-icon action-icon-margin"
                      (click)="displayDetails(element)"
                >visibility</span>
                <span id="delete-span-{{i}}"
                      class="material-symbols-outlined action-icon action-icon-margin"
                      (click)="deleteRow(element)"
                      *appAccessCheckSection="deleteSectionTitle"
                >delete</span>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: !showDetails"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell no-data" colspan="6"><span *ngIf="!fetching">No data</span></td>
        </tr>
    </table>
</div>
<div id="slideout" class="slideout" [class.show-details]="showDetails" data-testid="details-slideout">
    <app-slider-panel
        [isOpen]="showDetails"
        [bank]="selectedRow"
        (closeEvent)="closeSliderPanel()"></app-slider-panel>
</div>
