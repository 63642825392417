import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { InputTextModule } from 'primeng/inputtext';
import { BrokerageAccountsRoutingModule } from './brokerage-accounts.routing.module';
import { BrokerageAccountsComponent } from './brokerage-accounts.component';
import { BrokerageTableComponent } from './brokerage-table/brokerage-table.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import { BrokerageAccountModalComponent } from './brokerage-account-modal/brokerage-account-modal.component';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonModule } from 'primeng/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SharedModule } from '../components/shared.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    declarations: [BrokerageAccountsComponent, BrokerageTableComponent, BrokerageAccountModalComponent],
    imports: [
        BrokerageAccountsRoutingModule,
        CommonModule,
        InputTextModule,
        MatButtonModule,
        MatIconModule,
        MatSortModule,
        MatTableModule,
        ReactiveFormsModule,
        CoreModule,
        MatCardModule,
        MatCheckboxModule,
        MatDividerModule,
        MatFormFieldModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        MatSnackBarModule,
        MatTooltipModule,
        ButtonModule,
        SharedModule,
        MatProgressSpinnerModule,
    ],
})
export class BrokerageAccountsModule {}
