import { Injectable } from '@angular/core';
import {
    emailAndAddressAllowedFields,
    EntityType,
    GiactImportEntity,
    GiactRecord,
    InvalidGiactEntityErrors,
    ValidGiactRecord,
} from '../types/giact.types';
import { HttpClient, HttpParams } from '@angular/common/http';
import { isEqual } from 'lodash-es';
import { SelectedService } from '../types/giact.types';
import { UtilsService } from '../../core/services/utils.service';

@Injectable({
    providedIn: 'root',
})
export class GiactImportService {
    constructor(
        private http: HttpClient,
        private utilsService: UtilsService,
    ) {}

    public postGiactRequest(validRecords: GiactImportEntity[], serviceFlag: string) {
        const params: HttpParams = new HttpParams({
            fromObject: {
                [serviceFlag]: 'true',
                Source: 'manual',
            },
        });
        return this.http.post<any>(`/api/v1/giact-inquiry/bulk`, JSON.stringify(validRecords), {
            params,
            headers: { 'Content-Type': 'application/json' },
            reportProgress: true,
        });
    }

    /**
     * Converts csv blob to text
     * @param blob: Blob csv blob
     */
    public async parseBlobToText(blob: Blob): Promise<string> {
        return new Response(blob).text();
    }

    public validateGiactRecord(
        recordToValidate: { lineNumber: number; record: GiactImportEntity },
        selectedService,
    ): {
        status: string;
        validationResult: {
            lineNumber: number;
            record: GiactImportEntity;
            error?: string;
        };
    } {
        const [isValid, error, warning] =
            selectedService === SelectedService.ENABLE_EMAIL_VERIFICATION_PLUS_ADDRESS
                ? this.validateEmailAndAddress(recordToValidate.record)
                : this.validateBankInformation(recordToValidate.record);
        if (!isValid) {
            if (warning) {
                return {
                    status: 'warning',
                    validationResult: {
                        lineNumber: recordToValidate.lineNumber,
                        record: recordToValidate.record,
                        error: warning,
                    },
                };
            } else {
                return {
                    status: 'error',
                    validationResult: {
                        lineNumber: recordToValidate.lineNumber,
                        record: recordToValidate.record,
                        error,
                    },
                };
            }
        }
        return {
            status: 'success',
            validationResult: {
                lineNumber: recordToValidate.lineNumber,
                record: recordToValidate.record,
            },
        };
    }

    /**
     *
     * @param giactEntity: GiactImportEntity => the entity being validated
     * @param existingRecords: {[Name: string]: GiactImportEntity[]} => the existing records so we can check against it
     */
    public checkForDuplicateEntity(
        giactEntity: GiactImportEntity,
        existingRecords: ValidGiactRecord,
    ): [boolean, number?] {
        // If the name already exists in validGiactRecords, we need to perform deep equality check
        if (existingRecords && existingRecords[giactEntity.Name]) {
            let matchedLineNumber = -1;
            const isDuplicate = existingRecords[giactEntity.Name].some((existingRecord: GiactRecord) => {
                if (isEqual(giactEntity, existingRecord.record)) {
                    matchedLineNumber = existingRecord.lineNumber;
                    return true;
                }
            });
            return isDuplicate ? [isDuplicate, matchedLineNumber] : [isDuplicate];
        }
        // Pass
        return [false];
    }

    /**
     * Validates the import entity against the required fields for the Email and Address Verification services from our
     * identity verifying service. Creates an array of error message from our various validation functions.
     * If the array has no error message, the entity has passed validation. If the array contains messages,
     * it fails validation and will return [false, errorMessages] to be handled by the calling function.
     * @param giactEntity: GiactImportEntity the entity against which validation is performed
     */
    public validateEmailAndAddress(giactEntity: GiactImportEntity): [boolean, string?, string?] {
        const validationResults: string[] = [];
        /**
         * Check for extra fields that are explicitly disallowed (AIO-72)
         */
        validationResults.push(this.utilsService.checkAllowedKeys(giactEntity, emailAndAddressAllowedFields));
        /**
         * Required Fields
         */
        // Validate EntityType
        validationResults.push(this.validateIdentityServiceEntityType(giactEntity.EntityType));

        // Validate Name
        validationResults.push(this.validateIdentityServiceName(giactEntity.Name));

        validationResults.push(this.validateIdentityServiceUniqueId(giactEntity.UniqueId));

        /**
         * Optional Fields
         */
        // Validate email if present
        if (giactEntity.EmailAddress) {
            validationResults.push(this.utilsService.validateEmailAddress(giactEntity.EmailAddress));
        }

        // Validate ip address if present
        if (giactEntity.CurrentIpAddress) {
            validationResults.push(this.utilsService.validateIpAddress(giactEntity.CurrentIpAddress));
        }

        if (giactEntity.TaxId) {
            validationResults.push(this.utilsService.validateTaxId(giactEntity.TaxId));
        }

        if (giactEntity.PhoneNumber) {
            validationResults.push(this.utilsService.validatePhoneNumber(giactEntity.PhoneNumber));
        }

        // Validate address fields if Address 1 is filled out
        const address = {
            addressLine1: giactEntity.AddressLine1,
            addressLine2: giactEntity.AddressLine2,
            city: giactEntity.City,
            state: giactEntity.State,
            zipCode: giactEntity.ZipCode,
            country: giactEntity.Country,
        };
        const addressValidations = this.utilsService.validateAddress(address);
        validationResults.push(...addressValidations);

        const errorMessages = validationResults.filter((value: string | undefined) => value !== undefined).join('\n');
        if (errorMessages.length > 0) {
            return [false, errorMessages];
        }

        // Pass
        return [true];
    }

    // TODO: Revisit during refactor
    /**
     * Validates the import entity against the required fields for the Bank Verification services from our
     * identity verifying service. Creates an array of error message from our various validation functions.
     * If the array has no error message, the entity has passed validation. If the array contains messages,
     * it fails validation and will return [false, errorMessages] to be handled by the calling function.
     * @param giactEntity: GiactImportEntity the entity against which validation is performed
     */
    public validateBankInformation(giactEntity: GiactImportEntity): [boolean, string?, string?] {
        const validationResults: string[] = [];
        const validationWarnings: string[] = [];

        validationResults.push(this.validateIdentityServiceEntityType(giactEntity.EntityType));

        validationResults.push(this.validateIdentityServiceName(giactEntity.Name));

        validationResults.push(this.validateIdentityServiceUniqueId(giactEntity.UniqueId));

        validationResults.push(this.utilsService.validateBankRoutingNumber(giactEntity.RoutingNumber));

        validationResults.push(this.utilsService.validateBankAccountNumber(giactEntity.AccountNumber));

        if (giactEntity.TaxId) {
            validationResults.push(this.utilsService.validateTaxId(giactEntity.TaxId));
        } else {
            validationWarnings.push('Missing TIN.');
        }

        const errorMessages = validationResults.filter((value: string | undefined) => value !== undefined).join('\n');
        if (errorMessages.length > 0) {
            return [false, errorMessages];
        } else if (validationWarnings.length > 0) {
            const warningMessages = validationWarnings
                .filter((value: string | undefined) => value !== undefined)
                .join('\n');
            return [false, undefined, warningMessages];
        }

        // Pass
        return [true];
    }

    public validateIdentityServiceEntityType(entityType: string): string | undefined {
        if (
            !entityType ||
            (entityType.toUpperCase() !== EntityType.PERSON && entityType.toUpperCase() !== EntityType.BUSINESS)
        ) {
            return `${InvalidGiactEntityErrors.INVALID_ENTITY_TYPE} EntityType: ${entityType}`;
        }
    }

    public validateIdentityServiceName(name: string): string | undefined {
        if (!name) {
            return `${InvalidGiactEntityErrors.INVALID_NAME} Name: ${name}`;
        }
    }

    public validateIdentityServiceUniqueId(uniqueId: string): string | undefined {
        if (!uniqueId || uniqueId.length > 50) {
            return `${InvalidGiactEntityErrors.INVALID_UNIQUE_ID} UniqueId: ${uniqueId}`;
        }
    }
}
