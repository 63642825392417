import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { catchError, map, Observable } from 'rxjs';

import { ConfigurationService } from '../../core/services/configuration.service';
import { handleMissingServerError } from '../../core/utils/http-response-handlers';

import { BankRecord } from './bank-record.types';
import { CachedBankValidationResponse, PayServiceDetail } from './bank-validation-response.types';
import { ExportConfig } from './cache.types';

@Injectable({
    providedIn: 'root',
})
export class BankValidationService {
    private serverName: string = 'BankValidation Service API';
    private SERVICE_ENDPOINT_CACHED = 'cached-bank-codes';
    private SERVICE_ENDPOINT_EXPORT = 'export';

    constructor(
        private http: HttpClient,
        private configurationService: ConfigurationService,
    ) {}

    public get(): Observable<BankRecord[]> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().bankValidationHost,
            this.SERVICE_ENDPOINT_CACHED,
        ].join('/');

        return this.http.get(requestUrl).pipe(
            map((serviceArray: CachedBankValidationResponse[]) => {
                let lnRecords = this.mapServiceToUI(serviceArray);

                if (lnRecords && lnRecords.length > 1) {
                    lnRecords = lnRecords.sort((a: BankRecord, b: BankRecord) => a.bankName.localeCompare(b.bankName));
                }

                return lnRecords;
            }),
            catchError(handleMissingServerError(this.serverName)),
        );
    }

    public delete(countryCode: string, nationalId: string): Observable<{}> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().bankValidationHost,
            this.SERVICE_ENDPOINT_CACHED,
            countryCode,
            nationalId,
        ].join('/');

        return this.http.delete(requestUrl).pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public export(exportConfig: ExportConfig): Observable<{}> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().bankValidationHost,
            this.SERVICE_ENDPOINT_EXPORT,
        ].join('/');

        return this.http
            .post(requestUrl, exportConfig, {
                headers: { 'Content-Type': 'application/json' },
                responseType: 'blob',
                observe: 'response',
            })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    private mapServiceToUI(cachedResponses: CachedBankValidationResponse[]): BankRecord[] {
        const bankRecords: BankRecord[] = [];

        if (cachedResponses && Array.isArray(cachedResponses) && cachedResponses.length > 0) {
            for (let i = 0; i < cachedResponses.length; i++) {
                const cachedResponse: CachedBankValidationResponse = cachedResponses[i];
                let bankInfoSet = false;

                const expDate = new Date(cachedResponse.created);
                // Expiration date is 6 months from the current date.
                expDate.setMonth(6 + expDate.getMonth());

                let year = expDate.getFullYear();
                // Months are 0-based.
                let month = (1 + expDate.getMonth()).toString().padStart(2, '0');
                let day = expDate.getDate().toString().padStart(2, '0');

                const bankRecord: BankRecord = {
                    bankName: null,
                    nationalId: cachedResponse.nationalId,
                    expirationDate: month + '/' + day + '/' + year,
                    fedach: null,
                    fedwire: null,
                };

                bankRecords.push(bankRecord);

                const serviceResponse = JSON.parse(`${cachedResponse?.serviceResponse}`);
                const payServiceDetails: PayServiceDetail[] = serviceResponse?.payServiceDetails;

                if (payServiceDetails && Array.isArray(payServiceDetails) && payServiceDetails.length > 0) {
                    for (let j = 0; j < payServiceDetails.length; j++) {
                        const payServiceDetail: PayServiceDetail = payServiceDetails[j];

                        if (payServiceDetail?.serviceId?.toUpperCase() === 'FEDACH') {
                            if (!bankInfoSet) {
                                bankRecord.bankName = payServiceDetail.participantName;
                                bankRecord.countryCode = payServiceDetail.countryCode;
                                bankInfoSet = true;
                            }

                            bankRecord.fedach = {
                                bankName: payServiceDetail.participantName,
                                nationalId: payServiceDetail.participantId,
                                connectivity:
                                    payServiceDetail.directIndirect === 'D'
                                        ? 'Direct'
                                        : payServiceDetail.directIndirect === 'I'
                                          ? 'Indirect'
                                          : payServiceDetail.directIndirect,
                                statePostalCode: payServiceDetail.attributeValue1,
                                phone: payServiceDetail.attributeValue2,
                                statusCode: payServiceDetail.attributeValue3,
                            };
                        } else if (
                            payServiceDetail?.serviceId?.toUpperCase() === 'FEDWIRE' &&
                            payServiceDetail?.scheme?.toUpperCase() === 'FUNDS'
                        ) {
                            if (!bankInfoSet) {
                                bankRecord.bankName = payServiceDetail.participantName;
                                bankRecord.countryCode = payServiceDetail.countryCode;
                                bankInfoSet = true;
                            }

                            bankRecord.fedwire = {
                                bankName: payServiceDetail.participantName,
                                nationalId: payServiceDetail.participantId,
                                connectivity:
                                    payServiceDetail.directIndirect === 'D'
                                        ? 'Direct'
                                        : payServiceDetail.directIndirect === 'I'
                                          ? 'Indirect'
                                          : payServiceDetail.directIndirect,
                                fedwireFundStatus:
                                    payServiceDetail.attributeValue1 === 'D'
                                        ? 'Direct'
                                        : payServiceDetail.attributeValue1 === 'I'
                                          ? 'Indirect'
                                          : payServiceDetail.attributeValue1,
                                wireTransactCode: payServiceDetail.attributeValue2,
                                fedwireCorr: payServiceDetail.attributeValue3,
                                intermediaryId: payServiceDetail.intermediaryId,
                            };
                        }

                        if (!bankRecord.fedach) {
                            // Create an empty object so the HTML doesn't throw errors
                            bankRecord.fedach = {
                                bankName: '',
                                nationalId: '',
                                connectivity: '',
                                statePostalCode: '',
                                phone: '',
                                statusCode: '',
                            };
                        }

                        if (!bankRecord.fedwire) {
                            // Create an empty object so the HTML doesn't throw errors
                            bankRecord.fedwire = {
                                bankName: '',
                                nationalId: '',
                                connectivity: '',
                                fedwireFundStatus: '',
                                wireTransactCode: '',
                                fedwireCorr: '',
                                intermediaryId: '',
                            };
                        }
                    }
                }
            }
        }

        return bankRecords;
    }
}
