export type BfdRequest = {
    fullPath: string;
    searchText: string;
    page?: number;
    pageSize?: number;
    sortField?: string;
    sortDir?: string;
};

export type BfdResponse = {
    s3FilesBucket: string;
    fullPath: string;
    files: ApiFile[];
};

export type ApiFile = {
    name: string;
    isDir: boolean;
    lastModified: number;
    size: string;
};

export type BfdFile = {
    name: string;
    displayName: string;
    ext: string;
    isDir: boolean;
    path: string;
    lastModified: number;
    size?: string;
};

export type DownloadList = Record<string, DownloadSelection>;

export type DownloadSelection = {
    name: string;
    selected: boolean;
};

export enum FileTypeEnum {
    text = 'text',
    pdf = 'pdf',
}

export type ApiFileForPreviewRequest = {
    fullPath: string;
    fileType: FileTypeEnum;
};

export type ModalConfigData = {
    title?: string;
    selected: BfdFile;
    action: 'viewText';
    contents: any;
    fullPath: string;
};
