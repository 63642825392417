import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DIRECTIVES } from './angular-component-library';
import { defineCustomElements } from './ds-components-intapps-angular/dist/loader';

@NgModule({
    declarations: [ ...DIRECTIVES ],
    exports: [ ...DIRECTIVES ],
    imports: [
        CommonModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: () => defineCustomElements,
            multi: true
        },
    ],
})
export class DsComponentsModule { }

export * from './ds-components.module';
export { DIRECTIVES } from './angular-component-library';
export * from './angular-component-library/components';
