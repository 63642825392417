import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DsComponentsShowcaseRoutingModule } from './ds-components-showcase-routing.module';
import { DsComponentsModule } from '../ds-components/ds-components.module';
import { CoreModule } from '../core/core.module';
import { DsComponentsShowcaseComponent } from './ds-components-showcase.component';

@NgModule({
    declarations: [DsComponentsShowcaseComponent],
    imports: [CoreModule, DsComponentsShowcaseRoutingModule, CommonModule, DsComponentsModule],
})
export class DsComponentsShowcaseModule {}

export * from '../ds-components/angular-component-library/components';
