import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: FileUploadComponent,
            multi: true,
        },
    ],
})
export class FileUploadComponent implements ControlValueAccessor {
    @Input() fileType: string;
    @Input() buttonText: string = 'Choose File';
    @ViewChild('fileUpload') fileUpload: ElementRef;
    onChange: Function;
    public file: File;
    @Input() tempFilename: string;
    @Input() isDisabledFlag: boolean = false;

    @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
        const file = event && event.item(0);
        this.onChange(file);
        this.file = file;
    }

    constructor() {}

    writeValue(value: null) {
        // clear file input
        if (this.fileUpload) {
            const inputEl: HTMLInputElement = this.fileUpload.nativeElement as HTMLInputElement;
            inputEl.value = '';
        }
        this.file = null;
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {}
}
