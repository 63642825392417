import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/guards/auth-guard.service';
import { AllowedRoles } from '../core/guards/allowed-roles.guard';
import { OneLoginRoles } from '../core/services/auth.service';
import { InternalAdminComponent } from './internal-admin.component';
import { DealsComponent } from './deals/deals.component';
import { UsersComponent } from './users/users.component';
import { ActivityComponent } from './activity/activity.component';
import { ShareholdersComponent } from './shareholders/shareholders.component';

const routes: Routes = [
    {
        path: 'internal-admin',
        component: InternalAdminComponent,
        canActivate: [AuthGuard, AllowedRoles],
        data: {
            AllowedRolesData: {
                oneOfRoles: [OneLoginRoles.internalAdmin, OneLoginRoles.internalAdminStandard],
            },
        },
        children: [
            {
                path: '',
                redirectTo: 'deals',
                pathMatch: 'full',
            },
            {
                path: 'deals',
                component: DealsComponent,
                pathMatch: 'prefix',
            },
            {
                path: 'users',
                component: UsersComponent,
                pathMatch: 'prefix',
            },
            {
                path: 'shareholders',
                component: ShareholdersComponent,
                pathMatch: 'prefix',
            },
            {
                path: 'activity',
                component: ActivityComponent,
                pathMatch: 'prefix',
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class InternalAdminRoutingModule {}
