import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Permission, ShareholderDealUser, UserRecord } from '../shared';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AvailableColumns } from './shareholder-deal-user-table.columns.types';

@Component({
    selector: 'app-shareholder-deal-user-table',
    templateUrl: './shareholder-deal-user-table.component.html',
    styleUrls: ['./shareholder-deal-user-table.component.scss'],
})
export class ShareholderDealUserTableComponent implements AfterViewInit, OnChanges {
    @HostListener('document:keydown.escape', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
        this.hideMenu();
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        // If this element does not contain the target element, then a click
        // happened outside the panel and should be closed. But because this is
        // listening to all document clicks, including the click to open the
        // slider, the click count needs to be reset after (re)opening. This is
        // to effectively ignore initial click to open the container.
        if (!this.eRef.nativeElement.contains(event.target) && this.clickCount > 0 && this.selectedIndex >= 0) {
            this.hideMenu();
        }

        this.clickCount++;
    }

    @Input() records: ShareholderDealUser[];
    @Input() searchText: string;
    public get displayedColumns(): string[] {
        return this._displayedColumns;
    }
    @Input() set displayedColumns(value: string[]) {
        if (value && value.length > 0) {
            this._displayedColumns = value;
        } else {
            this._displayedColumns = this.defaultColumns;
        }
    }
    @Input() page: number;
    @Input() pageSize: number;
    @Input() totalRecords: number = 0;
    @Input() fetching: boolean;
    @Input() showRevokeAccess: boolean;
    @Output() pageEvent = new EventEmitter<PageEvent>();
    @Output() sortEvent = new EventEmitter<Sort>();
    @Output() revokeAccessEvent = new EventEmitter<number>();
    @Output() editUserEvent = new EventEmitter<void>();
    @Output() userClickedEvent = new EventEmitter<number>();
    @Output() shareholderClickedEvent = new EventEmitter<number>();
    @Output() dealClickedEvent = new EventEmitter<number>();

    @ViewChild(MatSort) sort: MatSort;

    public dataSource: MatTableDataSource<ShareholderDealUser>;
    public iconWasClicked = false;
    public selectedIndex = null;
    private clickCount = 0;

    private _displayedColumns: string[];
    private defaultColumns: string[] = [
        AvailableColumns.Deal,
        AvailableColumns.User,
        AvailableColumns.Permission,
        AvailableColumns.Actions,
    ];

    constructor(private eRef: ElementRef) {
        // Assign the data to the data source for the table to render
        this.dataSource = new MatTableDataSource(this.records);
    }

    public ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    public ngOnChanges(_: SimpleChanges): void {
        this.dataSource = new MatTableDataSource(this.records);
    }

    public handlePageEvent(e: PageEvent) {
        this.pageEvent.emit(e);
    }

    public handleSortChange(s: Sort) {
        this.sortEvent.emit(s);
    }

    public handleRevokeAccessClick(element) {
        this.hideMenu();
        this.revokeAccessEvent.emit(element);
    }

    public getPermission(perm: string): string {
        return this.getEnumKeyByEnumValue(Permission, perm);
    }

    public openMenu($event: MouseEvent, index: number) {
        this.iconWasClicked = true;
        this.selectedIndex = index;
        $event?.stopPropagation();
        $event?.preventDefault();
    }

    public handleMenuClosedEvent($event, index: number) {
        this.iconWasClicked = false;
        this.selectedIndex = -1;
        $event?.stopPropagation();
        $event?.preventDefault();
    }

    public handleEditUserClick(element) {
        this.editUserEvent.emit(element);
        this.hideMenu();
    }

    public handleDealClicked(dealId: number) {
        this.dealClickedEvent.emit(dealId);
    }

    public handleUserClicked(userId: number) {
        this.userClickedEvent.emit(userId);
    }

    public handleShareholderClicked(shareholderId: number) {
        this.shareholderClickedEvent.emit(shareholderId);
    }

    public hideMenu() {
        this.iconWasClicked = false;
        this.selectedIndex = -1;
        this.clickCount = 0;
    }

    private getEnumKeyByEnumValue = (myEnum, enumValue): string => {
        const keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
        return keys.length > 0 ? keys[0] : '';
    };
}
