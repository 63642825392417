<div *ngIf="(fetching)" class="fetching-files">
    <mat-spinner></mat-spinner>
</div>
<div class="users-container" id="users-container">
    <ng-container
        *ngTemplateOutlet="selectedUser ? singleUser : multipleUsers"
    ></ng-container>
</div>

<ng-template #singleUser>
    <div class="wrapper-div">
        <div class="page-title-actions">
            <div class="title-actions">
                <span class="title">{{ selectedUser?.firstName }} {{ selectedUser?.lastName }}</span>
                <div class="actions">
                    <div [matMenuTriggerFor]="userMenu" class="button-div" data-testid="user-actions">
                        <div class="button-elem">
                            <span class="button-label">User Actions</span>
                            <mat-icon>keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                    <mat-menu #userMenu="matMenu" xPosition="before" yPosition="below">
                        <div (click)="handleEditUserClick()"
                             *ngIf="canEditUser(selectedUser.status)"
                             class="menu-option"
                             data-testid="single-user-edit-user-option"
                             mat-menu-item>Edit User
                        </div>
                        <div (click)="handleUserActivityClick()"
                             *ngIf="canViewUserActivity(selectedUser.status)"
                             class="menu-option"
                             mat-menu-item>User Activity
                        </div>
                        <div (click)="handleResetMFAClick()"
                             *ngIf="canResetMFA(selectedUser.status)"
                             class="menu-option"
                             mat-menu-item>Reset MFA Method
                        </div>
                        <div (click)="handleResendInvitation()"
                             *ngIf="canResendInvitation(selectedUser.status)"
                             class="menu-option"
                             mat-menu-item>Resend Invitation
                        </div>
                        <div (click)="handleSendPwResetClick()"
                             *ngIf="canSendPwReset(selectedUser.status)"
                             class="menu-option"
                             mat-menu-item>Send Password Reset
                        </div>
                        <div (click)="handleRevokeAllAccessClick()"
                             *ngIf="showRevokeAccess && dealRecords?.length > 0"
                             class="menu-option menu-option-red"
                             mat-menu-item>Revoke All Access
                        </div>
                        <div (click)="handleBlockClick()"
                             *ngIf="canBlock(selectedUser.status)"
                             class="menu-option menu-option-red"
                             mat-menu-item>Block Authentication
                        </div>
                        <div (click)="handleUnblockClick()"
                             *ngIf="canUnblock(selectedUser.status)"
                             class="menu-option menu-option-red"
                             mat-menu-item>Unblock Authentication
                        </div>
                        <div (click)="handleDeactivateClick()"
                             *ngIf="canDeactivate(selectedUser.status)"
                             class="menu-option menu-option-red"
                             mat-menu-item>Deactivate User
                        </div>
                    </mat-menu>
                </div>
            </div>
        </div>
        <div class="user-frame-div">
            <div class="user-details-div">
                <div class="info-div">
                    <div class="info-label">
                        {{ 'User Info' | uppercase }}
                    </div>
                    <div class="info-data">
                        <span class="info-data-label">Email</span>
                        <span class="info-data-value">{{ selectedUser?.email }}</span>
                    </div>
                    <div class="info-data">
                        <span class="info-data-label">Netsuite ID</span>
                        <!--                            <a class="info-data-value" [href]="getUserNsUrl(selectedUser?.nsId)" (click)="openInNewTab(selectedUser?.nsId)">{{selectedUser?.nsId > 0 ? selectedUser?.nsId : ''}}</a>-->
                        <span (click)="openInNewTab(getUserNsUrl(selectedUser?.nsId))"
                              [matTooltip]="getUserNsUrl(selectedUser?.nsId)"
                              class="as-link"
                              matTooltipHideDelay="200"
                              matTooltipPosition="below"
                              matTooltipShowDelay="300"
                        >{{ selectedUser?.nsId > 0 ? selectedUser?.nsId : '' }}</span>
                    </div>
                    <div class="info-data">
                        <span class="info-data-label">Products</span>
                        <span *ngFor="let product of selectedUser?.products"
                              class="product-data-value">{{ product.name }}</span>
                    </div>
                    <div class="auth0-info-data">
                        <span class="info-data-label">Auth0 Status</span>
                        <span [class.auth0-active]="selectedUser?.status?.toLowerCase() === 'active'"
                              [class.auth0-blocked]="selectedUser?.status?.toLowerCase() === 'blocked'"
                              [class.auth0-deactivated]="selectedUser?.status?.toLowerCase() === 'deactivated'"
                              [class.auth0-invited]="selectedUser?.status?.toLowerCase() === 'invited'"
                              class="auth0-chip"
                        >
                            <span class="auth0-label">{{ selectedUser?.status }}</span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="deals-section-div">
                <mat-tab-group (selectedTabChange)="tabChangeSingleUser($event);" [(selectedIndex)]="selectedIndexSingleUser" [disableRipple]="true"
                               animationDuration="0ms"
                               class="tool-bar"
                               fitInkBarToContent="true"
                               mat-align-tabs="start"
                               mat-stretch-tabs="false"
                >
                    <mat-tab label="Deal Dashboard">
                        <ng-container
                            *ngTemplateOutlet="dealDashboardDealTableTemplate"
                        ></ng-container>
                    </mat-tab>
                    <mat-tab label="Shareholder Portal">
                        <ng-container
                            *ngTemplateOutlet="shareholderPortalUserTableTemplate"
                        ></ng-container>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #dealDashboardDealTableTemplate matTabContent>
    <div class="search-bar-deals" id="search-bar-deals">
        <app-intadm-search
            (newSearchEvent)="performSearchDeals($event)"
            data-testid="app-deals-search"
            placeholder="Filter Deals"></app-intadm-search>
    </div>
    <div class="table-container">
        <app-deal-table
            (dealActivityNav)="handleDealActivityNavigation($event)"
            (dealDashboardNav)="handleDealDashboardNavigation($event)"
            (pageEvent)="handlePageEventDeals($event)"
            (revokeAccessEvent)="handleRevokeAccessEvent($event)"
            (sortEvent)="handleSortChangeDeals($event)"
            [dealRecords]="dealRecords"
            [displayedColumns]="dealDisplayedColumns"
            [pageSize]="pageSizeDeals"
            [page]="pageDeals"
            [searchText]="searchValueDeals"
            [showCreateUser]="showCreateUser"
            [showRevokeAccess]="showRevokeAccess"
            [totalRecords]="totalRecordsDeals"
            class="deals-table"
            data-testid="app-deals-table"
        ></app-deal-table>
    </div>
</ng-template>

<ng-template #shareholderPortalUserTableTemplate matTabContent>
    <div class="users-frame-div">
        <div class="users-search-bar">
            <app-intadm-search
                (newSearchEvent)="performSearchDealsUsers($event)"
                data-testid="app-accounts-search-users"
                placeholder="Filter by deal, role, or shareholder...">
            </app-intadm-search>
        </div>
    </div>
    <div class="table-container">
        <app-shareholder-deal-user-table
            (pageEvent)="handlePageEventDealsUsers($event)"
            (revokeAccessEvent)="handleRevokeAccessEvent($event)"
            (shareholderClickedEvent)="handleShareholderClickedEventDealsUsers($event)"
            (sortEvent)="handleSortChangeDealsUsers($event)"
            (userClickedEvent)="handleUserClickedEventDealsUsers($event)"
            [displayedColumns]="shareholderDealUserDisplayedColumns"
            [fetching]="fetchingDealsUsers"
            [pageSize]="pageSize"
            [page]="page"
            [records]="shareholderDealUserRecords"
            [searchText]="searchValueDealsUsers"
            [showRevokeAccess]="showRevokeAccess"
            [totalRecords]="totalRecordsDealsUsers"
            class="shareholder-deal-user-table"
        ></app-shareholder-deal-user-table>
    </div>
</ng-template>

<ng-template #multipleUsers>
    <ng-container *ngTemplateOutlet="showCreateUser ? createUserForm : null">
    </ng-container>
    <div *ngIf="!showCreateUser" class="multiple-users-container">
        <div class="header-bar" id="header-bar">
            <div class="header-text-div">
                <span class="header-text" id="header-text">Users</span>
            </div>
        </div>
        <div class="main">
            <div class="frame-div">
                <div class="search-bar" id="search-bar">
                    <app-intadm-search
                        (newSearchEvent)="performSearch($event)"
                        data-testid="app-accounts-search"
                        placeholder="Filter by name, email...">
                    </app-intadm-search>
                </div>
                <div *ngIf="showAddUserMenu()" class="button-div">
                    <div [matMenuTriggerFor]="addUserMenu" class="button-elem" data-testid="add-user">
                        <span class="button-label">Add User</span>
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </div>
                </div>
                <mat-menu #addUserMenu="matMenu" xPosition="before" yPosition="below">
                    <div (click)="createUserClick()" *appAccessCheckSection="permissionCreateUser" class="menu-option"
                         data-testid="create-user-option" mat-menu-item>Create User
                    </div>
                    <div (click)="addExistingUser()" *appAccessCheckSection="permissionAddUser" class="menu-option"
                         data-testid="add-existing-user-option" mat-menu-item>Add Existing User
                    </div>
                </mat-menu>
            </div>
            <div class="table-container">
                <app-user-table
                    (editModeEvent)="handleEditModeEvent($event)"
                    (pageEvent)="handlePageEvent($event)"
                    (sortEvent)="handleSortChange($event)"
                    (userActionNav)="handleUserAction($event)"
                    (userToEditEvent)="handleUserToEditEvent($event)"
                    [displayedColumns]="userDisplayedColumns"
                    [fetching]="fetching"
                    [pageSize]="pageSize"
                    [page]="page"
                    [searchText]="searchValue"
                    [showCreateUser]="showCreateUser"
                    [totalRecords]="totalRecords"
                    [userRecords]="userRecords"
                    class="users-table"
                    data-testid="app-user-table"
                ></app-user-table>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #createUserForm>
    <div class="header-bar" id="header-bar-create">
        <div class="header-text-div">
            <span class="header-text" id="header-text-create">{{ userFormTitle }}</span>
        </div>
    </div>
    <div class="create-user-main">
        <form (ngSubmit)="onSubmit(userForm)" [formGroup]="userForm" class="user-form" id="user-form">
            <div *ngIf="addExistingUserMode" class="grouping">
                <div class="info-section">
                    <span class="form-primary-text">Select User</span>
                    <span class="form-secondary-text">Contact information that syncs across applications</span>
                </div>
                <div class="form-fields-group">
                    <div class="form-fields-group">
                        <div class="form-fields">
                            <div class="add-existing-user-fields-container">
                                <div class="text-field">
                                    <mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic">
                                        <mat-label>Existing User</mat-label>
                                        <mat-select (selectionChange)="onAddExistingUserChange($event)"
                                                    formControlName="existingUser">
                                            <mat-select-trigger>
                                                {{ selectedExistingUser?.firstName }} {{ selectedExistingUser?.lastName }}
                                            </mat-select-trigger>
                                            <mat-option *ngFor='let user of addExistingUserRecords;'
                                                        [class.role-underline]="user.id === 987654321"
                                                        [value]="user.id">
                                                <div class="role-options">{{ user.firstName }} {{ user.lastName }}</div>
                                                <div class="role-description">{{ user.email }}</div>
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="addExistingUserMode ? showCreateUser && addExistingUserSelected : showCreateUser"
                 class="grouping">
                <div class="info-section">
                    <span class="form-primary-text">Personal Information</span>
                    <span class="form-secondary-text">Contact information that syncs across applications</span>
                </div>
                <div class="form-fields-group">
                    <div class="form-fields">
                        <div class="personal-info-fields-container">
                            <div class="text-field">
                                <mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic">
                                    <mat-label>First Name</mat-label>
                                    <input data-testid="firstName" formControlName="firstName" matInput>
                                    <mat-error *ngIf="userForm.get('firstName').invalid">
                                        First name required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="text-field">
                                <mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic">
                                    <mat-label>Last Name</mat-label>
                                    <input data-testid="lastName" formControlName="lastName" matInput>
                                    <mat-error *ngIf="userForm.get('lastName').invalid">
                                        Last name required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="text-field">
                                <mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic">
                                    <mat-label>Email Address</mat-label>
                                    <input data-testid="email" formControlName="email" matInput>
                                    <mat-error
                                        *ngIf="userForm.get('email').invalid && !userForm.controls.email.hasError('emailAlreadyExists')">
                                        Please enter a valid email address
                                    </mat-error>
                                    <mat-error *ngIf="userForm.controls.email.hasError('emailAlreadyExists')">
                                        User already exists within the system
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="addExistingUserMode ? showCreateUser && addExistingUserSelected : showCreateUser"
                 class="grouping">
                <div class="info-section">
                    <span class="form-primary-text">Deal Dashboard Access</span>
                    <span class="form-secondary-text">Manage user roles on Deals in Deal Dashboard</span>
                </div>
                <div class="form-fields-group">
                    <div class="form-fields">
                        <div class="add-access-button-container">
                            <div (click)="addDealAccessClick()" class="add-access-button">
                                <span class="form-fields-button-label">Add Deal Access</span>
                            </div>
                        </div>
                        <div class="access-fields-container" formArrayName="dealAccess">
                            <ng-container *ngFor="let dealAccessForm of dealAccess.controls; let i = index">
                                <div [formGroup]="dealAccessForm" attr.data-testid="deal-access-row-{{i}}"
                                     class="access-form">
                                    <mat-form-field appearance="outline" class="access-form-field" floatLabel="always">
                                        <mat-label>Deal Access</mat-label>
                                        <mat-chip-grid #chipGrid aria-label="Deal dashboard deal access selection">
                                            <mat-chip-row (removed)="removeDealChip(deal, i)"
                                                          *ngFor="let deal of dealDashboardDealsRow[i]; trackBy:identifyDeal"
                                                          [disabled]="isDealDashboardDealInputDisabled[i]">
                                                {{ deal }}
                                                <button [attr.aria-label]="'remove ' + deal" matChipRemove>
                                                    <mat-icon>cancel</mat-icon>
                                                </button>
                                            </mat-chip-row>
                                        </mat-chip-grid>
                                        <input #dealDashboardDealInput (matChipInputTokenEnd)="addDealChip($event)" [disabled]="isDealDashboardDealInputDisabled[i]"
                                               [matAutocomplete]="autoDeal"
                                               [matChipInputFor]="chipGrid"
                                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                               formControlName="deal"
                                               matInput>
                                        <mat-autocomplete #autoDeal="matAutocomplete"
                                                          (optionSelected)="dealSelected($event, i)">
                                            <mat-option
                                                *ngFor="let deal of filteredDealDashboardDeals | async; trackBy:identifyDeal"
                                                [value]="deal">{{ deal }}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-error *ngIf="dealAccessForm.get('deal').invalid">
                                            Deal required
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="access-form-field" floatLabel="always">
                                        <mat-label>Role</mat-label>
                                        <mat-select (selectionChange)="onRoleChange($event, 'dealAccess', i)"
                                                    data-testid="role"
                                                    formControlName="role">
                                            <mat-select-trigger>
                                                {{ dealDashboardCurrentRoles[i]?.name }}
                                            </mat-select-trigger>
                                            <mat-option *ngFor='let role of dealDashboardRoleRecords;'
                                                        [value]="role.id">
                                                <div
                                                    [ngClass]="role.id !== 99 ? 'role-options' : 'revoke-access'">{{ role.name }}
                                                </div>
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="dealAccessForm.get('role').invalid">
                                            Role required
                                        </mat-error>
                                    </mat-form-field>
                                    <div class="x-dismiss-div">
                                            <span (click)="deleteDealAccessRow(i)"
                                                  attr.data-testid="x-dismiss-deal-access-row-{{i}}"
                                                  class="material-symbols-outlined x-dismiss"
                                                  id="x-dismiss"
                                            >close
                                            </span>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="addExistingUserMode ? showCreateUser && addExistingUserSelected : showCreateUser"
                 class="grouping">
                <div class="info-section">
                    <span class="form-primary-text">Shareholder Portal Access</span>
                    <span
                        class="form-secondary-text">Manage Shareholder user roles on Deals in Shareholder Portal</span>
                </div>
                <div class="form-fields-group">
                    <div class="form-fields">
                        <div class="add-access-button-container">
                            <div (click)="addShareholderAccessClick()" class="add-access-button">
                                <span class="form-fields-button-label">Add Shareholder Access</span>
                            </div>
                        </div>
                        <div class="access-fields-container" formArrayName="shareholderAccess">
                            <ng-container
                                *ngFor="let shareholderAccessForm of shareholderAccess.controls; let i = index">
                                <div [formGroup]="shareholderAccessForm" attr.data-testid="shareholder-access-row-{{i}}"
                                     class="access-form">
                                    <mat-form-field appearance="outline" class="access-form-field" floatLabel="always">
                                        <mat-label>Shareholder</mat-label>
                                        <input #shareholderInput [disabled]="isShareholderAccessInputDisabled[i]" [matAutocomplete]="shareholderAuto"
                                               formControlName="shareholder"
                                               matInput>
                                        <mat-autocomplete #shareholderAuto="matAutocomplete"
                                                          (optionSelected)="shareholderSelected($event)">
                                            <mat-option
                                                *ngFor="let shareholder of filteredShareholderRecords | async; trackBy:identifyShareholder"
                                                [value]="shareholder">{{ shareholder }}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-error *ngIf="shareholderAccessForm.get('shareholder').invalid">
                                            Shareholder required
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="access-form-field" floatLabel="always">
                                        <mat-label>Deal</mat-label>
                                        <input #shareholderDealInput [disabled]="isShareholderAccessInputDisabled[i]" [matAutocomplete]="autoShareholderDeal"
                                               formControlName="deal"
                                               matInput>
                                        <mat-autocomplete #autoShareholderDeal="matAutocomplete"
                                                          (optionSelected)="shareholderDealSelected($event)">
                                            <mat-option
                                                *ngFor="let deal of filteredShareholderPortalDeals | async; trackBy:identifyDeal"
                                                [value]="deal">{{ deal }}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-error *ngIf="shareholderAccessForm.get('deal').invalid">
                                            Deal required
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="access-form-field" floatLabel="always">
                                        <mat-label>Role</mat-label>
                                        <mat-select (selectionChange)="onRoleChange($event, 'shareholderAccess', i)"
                                                    data-testid="role"
                                                    formControlName="role">
                                            <mat-select-trigger>
                                                {{ shareholderPortalCurrentRoles[i]?.name }}
                                            </mat-select-trigger>
                                            <mat-option *ngFor='let role of shareholderRoleRecords;'
                                                        [value]="role.id">
                                                <div class="role-options">{{ role.name }}</div>
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="shareholderAccessForm.get('role').invalid">
                                            Role required
                                        </mat-error>
                                    </mat-form-field>
                                    <div class="x-dismiss-div">
                                        <span (click)="deleteShareholderAccessRow(i)"
                                              attr.data-testid="x-dismiss-shareholder-access-row-{{i}}"
                                              class="material-symbols-outlined x-dismiss"
                                              id="x-dismiss"
                                        >close
                                        </span>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="addExistingUserMode ? showCreateUser && addExistingUserSelected : showCreateUser"
                 class="user-form-actions">
                <div *ngIf="userForm.invalid && userForm.touched" class="user-form-alert-div">
                    <mat-icon aria-hidden="false" aria-label="error icon">error_outline</mat-icon>
                    <div class="user-form-alert-text">
                        Please complete all required fields
                    </div>
                </div>
                <div class="user-form-actions-buttons">
                    <div (click)="cancelClicked(userForm)" class="cancel-button">
                        <span class="actions-button-label">Cancel</span>
                    </div>
                    <div (click)="onSubmit(userForm, 'save')" class="create-save-button"
                         data-testid="user-form-save-button">
                        <span class="actions-button-label">Save</span>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>
