<div id="giact-export" class="file-action">

  <form [formGroup]="datePickerForm">
    <mat-form-field appearance="fill">
      <mat-label>Select a range of dates</mat-label>
      <mat-date-range-input [rangePicker]="datePickerFormPicker" [min]="minDate" [max]="maxDate">
        <input matStartDate formControlName="startDate" placeholder="startDate">
        <input matEndDate formControlName="endDate" placeholder="endDate">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="datePickerFormPicker"></mat-datepicker-toggle>
      <mat-date-range-picker #datePickerFormPicker></mat-date-range-picker>
    </mat-form-field>
  </form>

  <button [disabled]="!(datePickerForm.valid)" mat-raised-button color="primary" (click)="downloadFile()">Export Data</button>
  <div class="progress-bar">
    <mat-progress-bar mode="determinate" [value]="progressStatus"></mat-progress-bar>
    <p>{{progressMessage}}</p>
  </div>
</div>
