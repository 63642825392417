import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterConfig, FilterOption } from './filter.model.types';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
})
export class FilterComponent {
    @Input() filterConfig: FilterConfig;
    @Output() filterEvent = new EventEmitter<FilterOption>();

    public selected: FilterOption;

    constructor() {}

    onChange($event) {
        this.filterEvent.emit(this.selected);
    }
}
