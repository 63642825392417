// These are the defined columns in file table.component.html.

export enum AvailableColumns {
    ExportVendor = 'exportVendor',
    PayeeName = 'payeeName',
    AccountTitle = 'accountTitle',
    AccountNumber = 'accountNumber',
    AbaSwift = 'abaSwift',
    BankCountry = 'bankCountry',
    Currency = 'currency',
    SortCode = 'sortCode',
    UpdatedDt = 'updatedDt',
    ReviewStatus = 'reviewStatus',
    ReviewStatusInReview = 'reviewStatusInReview',
    ReviewType = 'reviewType',
    RejectionDate = 'rejectionDt',
    DealCount = 'dealCount',
    Details = 'details',
}

export enum AllTableColumns {
    PayeeName = 'payeeName',
    Email = 'email',
    AccountTitle = 'accountTitle',
    AccountNumber = 'accountNumber',
    AbaSwift = 'abaSwift',
    Subsidiary = 'subsidiary',
    BankCountry = 'bankCountry',
    Currency = 'currency',
    SortCode = 'sortCode',
    UpdatedDt = 'updatedDt',
    ReviewType = 'reviewType',
    CreatedDate = 'createdDate',
    ShrhldId = 'shrhldId',
    TaxpayerId = 'taxpayerId',
    BankName = 'bankName',
    FfcAcct = 'ffcAcct',
    FfcName = 'ffcName',
    PayApprovedDate = 'payApprovedDate',
    PayeeAddress1 = 'payeeAddress1',
    PayeeAddress2 = 'payeeAddress2',
    PayeeAddress3 = 'payeeAddress3',
    PayeeCity = 'payeeCity',
    PayeeState = 'payeeState',
    PayeePostalCode = 'payeePostalCode',
    PayeeCountry = 'payeeCountry',
    ImbSwiftBic = 'imbSwiftbic',
    ImbBankName = 'imbBankname',
}

export enum ColumnHeaders {
    payeeName = 'Payee Name',
    email = 'Email',
    accountTitle = 'Account Title',
    accountNumber = 'Account Number',
    abaSwift = 'Routing / Swift',
    subsidiary = 'Subsidiary',
    bankCountry = 'Bank Country',
    currency = 'Currency',
    sortCode = 'Sort Code',
    updatedDt = 'Updated Date',
    reviewType = 'Review Type',
    createdDate = 'Created Date',
    shrhldId = 'Shareholder Id',
    taxpayerId = 'Taxpayer Id',
    bankName = 'Bank Name',
    ffcAcct = 'FFC Account',
    ffcName = 'FFC Name',
    payApprovedDate = 'Pay Approved Date',
    payeeAddress1 = 'Address 1',
    payeeAddress2 = 'Address 2',
    payeeAddress3 = 'Address 3',
    payeeCity = 'City',
    payeeState = 'State',
    payeePostalCode = 'Postal Code',
    payeeCountry = 'Country',
    imbSwiftbic = 'Int. Swift/BIC',
    imbBankname = 'Int. Bank Name',
}
