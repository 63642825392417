export interface DealRecord {
    id: number;
    nsId: number;
    nsLastModified: number;
    sfId: string;
    name: string;
    projectName: string;
    buyer: string;
    seller: string;
    closingDt: number;
    role?: string;
}

export const DealSearchColumns = ['name', 'projectName', 'buyer', 'seller', 'nsId'];
export const DealSearchColumnsFromActivity = ['name', 'projectName'];

export interface SingleFullDealRecord extends DealRecord {
    rmName: string;
    raName: string;
    bdName: string;
    services: string[];
}

export type RequestDealUserRole = {
    userId: number;
    dealId: number;
    byId: string;
    byName: string;
};
