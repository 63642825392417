import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BfdFileExplorerService } from './shared/bfd-files.service';
import {
    ApiFile,
    ApiFileForPreviewRequest,
    BfdFile,
    BfdRequest,
    BfdResponse,
    DownloadList,
    FileTypeEnum,
    ModalConfigData,
} from './shared';
import { BfpModalComponent } from './bfp-modal/bfp-modal.component';
import { ModalResult } from '../brokerage-accounts/shared';

@Component({
    selector: 'app-bank-files',
    templateUrl: './bank-files.component.html',
    styleUrls: ['./bank-files.component.scss'],
})
export class BankFilesComponent implements OnInit, AfterViewInit {
    public bfdFiles: BfdFile[] = [];
    public bfdResponse: BfdResponse;
    public bfdRequest: BfdRequest;
    public pathParts: string[] = [];
    public pathHome = '';
    public pathRoot = '/';
    public totalRecords = 0;
    public page = 0;
    public pageSize = 100;
    public downloadLabelDefault = 'Download';
    public downloadLabel = this.downloadLabelDefault;
    public downloadSelectedLabel = 'Download Selected';
    public downloadInProgress = false;
    public fetching = false;
    public firstPageLoad = true;

    private fileToPreview: string;
    private pathDisplayRoot = 'S3';
    private selectedFiles: string[] = [];

    private modalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: true,
        minWidth: '700px',
        minHeight: '600px',
    };
    private bfpModal: MatDialogRef<BfpModalComponent>;

    constructor(
        private bfdFileExplorerService: BfdFileExplorerService,
        private snackBar: MatSnackBar,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        public matDialog: MatDialog,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.bfdRequest = {
            fullPath: '/',
            searchText: '',
            page: this.page,
            pageSize: this.pageSize,
        };
        this.pathParts = [this.pathDisplayRoot];

        this.activatedRoute.queryParams.subscribe({
            next: (params: Params) => {
                if (!params) {
                    return;
                }

                const fpParam = params['fp'];
                const paramParts = fpParam !== this.pathRoot && fpParam != '' ? fpParam?.split('/') : [];

                if (paramParts?.length > 0) {
                    this.bfdRequest.fullPath = paramParts.join('/');
                    if (this.pathParts?.length > 2) {
                        this.pathParts.splice(2, this.pathParts.length - 2);
                    }

                    this.pathParts = this.pathParts.concat(paramParts);
                } else {
                    this.bfdRequest.fullPath = this.pathRoot;
                    if (this.pathParts?.length >= 2) {
                        this.pathParts.splice(2, this.pathParts.length - 2);
                    }
                }

                this.bfdRequest.searchText = params['search'] ?? '';
                this.page = this.bfdRequest.page = params['page'] ?? this.page;
                this.pageSize = this.bfdRequest.pageSize = params['pageSize'] ?? this.pageSize;
                this.bfdRequest.sortField = params['sortField'] ?? 'ext';
                this.bfdRequest.sortDir = params['sortDir'] ?? 'asc';

                // This logic prevents performSearch() from being called when the 'file' param changes when previewing a file.
                if (this.firstPageLoad || params['file'] === this.fileToPreview) {
                    this.performSearch(this.bfdRequest.searchText, params['file']);
                }
                this.fileToPreview = params['file'];
            },
            error: (error) => this.handleError(error),
        });
    }

    ngAfterViewInit(): void {
        this.cdr.detectChanges();
    }

    public pathClick(dirName: string) {
        if (dirName === this.pathHome || dirName === this.pathDisplayRoot) {
            this.bfdRequest.fullPath = this.pathRoot;
            this.pathParts = [this.pathDisplayRoot, this.pathHome];
        } else {
            const idx = this.pathParts.indexOf(dirName);
            this.pathParts = this.pathParts.slice(0, idx + 1);
            this.bfdRequest.fullPath = this.pathParts.slice(2, idx + 1).join('/');
        }

        // All path changes are done in the activatedRoute subscriber
        // in order to use query parameters to drive navigation & searches, as it
        // will enable the browser back button, which was the requirement.
        this.router.navigate(['/bank-files'], {
            queryParams: { fp: this.bfdRequest.fullPath },
            queryParamsHandling: 'merge',
        });
    }

    public handleDirClick(row: BfdFile) {
        if (this.bfdRequest.fullPath === this.pathRoot) {
            const rowPath = row.path.endsWith('/') ? row.path.slice(0, -1) : row.path;
            this.bfdRequest.fullPath = rowPath;
        } else {
            const rowName = row.name.endsWith('/') ? row.name.slice(0, -1) : row.name;
            this.bfdRequest.fullPath += '/' + rowName;
        }

        this.pathParts.concat(row.path);

        // All path change retrievals are done in the activatedRoute subscriber
        // in order to use query parameters to drive navigation & searches, as it
        // will enable the browser back button, which was the requirement.
        this.router.navigate(['/bank-files'], {
            queryParams: { fp: this.bfdRequest.fullPath },
            queryParamsHandling: 'merge',
        });
    }

    public handleFileToPreviewEvent(row: BfdFile) {
        let documentPath: string;
        let viewPath: string;
        if (this.bfdResponse.fullPath === this.pathRoot) {
            // this file is at the root level so do not pre-pend the full path.
            documentPath = row.path;
            viewPath = row.path;
        } else {
            documentPath = this.bfdResponse.fullPath + '/' + row.name;
            viewPath = this.bfdResponse.fullPath + '/' + row.displayName;
        }

        let fileType: FileTypeEnum;
        switch (row.ext) {
            case 'pdf': {
                fileType = FileTypeEnum.pdf;
                break;
            }
            case 'txt':
            default: {
                fileType = FileTypeEnum.text;
                break;
            }
        }

        const apiRequest: ApiFileForPreviewRequest = {
            fullPath: documentPath,
            fileType,
        };

        this.bfdFileExplorerService.getContents(apiRequest).subscribe({
            next: async (res: HttpResponse<Blob>) => {
                if (fileType === FileTypeEnum.pdf) {
                    const fileURL = URL.createObjectURL(res.body);
                    window.open(fileURL, '_blank');
                    // This did not work - displays "undefined".
                    // https://stackoverflow.com/questions/41947735/custom-name-for-blob-url
                } else {
                    const viewFileConfig: MatDialogConfig<ModalConfigData> = {
                        ...this.modalConfigTemplate,
                        data: {
                            title: 'View',
                            action: 'viewText',
                            selected: row,
                            // The response body is of type Blob, so we need to convert it to text.
                            contents: await res.body.text(),
                            fullPath: viewPath,
                        },
                    };
                    this.openModal(viewFileConfig);

                    // When the PDF is opened in a new tab, keep it out of the original URL.
                    this.router.navigate(['/bank-files'], {
                        queryParams: { file: row.name },
                        queryParamsHandling: 'merge',
                    });
                }
            },
            error: (error) => this.handleError(error),
        });
    }

    public handlePageEvent(e: PageEvent) {
        this.pageSize = e.pageSize;
        this.page = e.pageIndex;

        this.router.navigate(['/bank-files'], {
            queryParams: { page: this.page, pageSize: this.pageSize },
            queryParamsHandling: 'merge',
        });
    }

    public handleSortEvent(s: Sort) {
        // Sort object when sorting is turned off: { active: "name", direction: "" }

        if (s.direction) {
            this.bfdRequest.sortField = s.active;
            this.bfdRequest.sortDir = s.direction;
            // Reset the page back to 0
            this.bfdRequest.page = 0;

            if (!this.firstPageLoad) {
                this.router.navigate(['/bank-files'], {
                    queryParams: { sortId: this.bfdRequest.sortField, sortDir: this.bfdRequest.sortDir },
                    queryParamsHandling: 'merge',
                });
            }
            this.firstPageLoad = false;
        } else {
            // Do not fetch if sorting has been turned off
            this.bfdRequest.sortField = null;
            this.bfdRequest.sortDir = null;
        }
    }

    public handleSearchEvent($event: string) {
        this.page = 0;

        this.router.navigate(['/bank-files'], {
            queryParams: {
                search: $event,
                page: this.page,
            },
            queryParamsHandling: 'merge',
        });
    }

    public performSearch(searchText: string, fileToPreview?: string) {
        this.bfdRequest.searchText = searchText;
        this.bfdRequest.page = this.page;
        this.bfdRequest.pageSize = this.pageSize;
        this.bfdFiles = [];

        this.fetching = true;
        this.bfdFileExplorerService.get(this.bfdRequest).subscribe({
            next: (res: any) => {
                // setTimeout() fixes this error: Expression has changed after it was checked.
                setTimeout(() => {
                    this.fetching = false;
                });
                if (!res) {
                    return;
                }
                this.bfdResponse = res.body;
                if (!this.pathHome) {
                    this.pathHome = this.bfdResponse.s3FilesBucket;
                }

                this.pathParts = [this.pathDisplayRoot, this.pathHome];

                if (this.bfdRequest.fullPath != this.pathRoot) {
                    this.pathParts = this.pathParts.concat(this.bfdRequest.fullPath.split('/'));
                }

                const temp: BfdFile[] = [];
                let name: string;
                let displayName: string;
                const contentRange = res.headers.get('content-range'); // "1-3/3"
                this.totalRecords = +contentRange.substring(contentRange.indexOf('/') + 1);

                if (this.bfdResponse.files.length > 0) {
                    this.bfdResponse.files.forEach((file: ApiFile) => {
                        let ext =
                            file.name?.lastIndexOf('.') >= 0
                                ? file.name?.substring(file.name?.lastIndexOf('.') + 1)
                                : '-';
                        // if the extension is numeric or more than 5 characters,
                        // then it is a bad extension and just display the default.
                        if (ext.length >= 5 || +ext >= 0) {
                            ext = '-';
                        }

                        if (this.bfdRequest.searchText) {
                            if (this.bfdRequest.fullPath.indexOf(this.pathRoot) === 0) {
                                name = file.name;
                            } else {
                                name = file.name.substring(this.bfdRequest.fullPath.length);
                                name = name.indexOf('/') === 0 ? name.substring(1) : name;
                            }
                        } else {
                            name =
                                file.name?.lastIndexOf('/') >= 0
                                    ? file.name?.substring(file.name?.lastIndexOf('/') + 1)
                                    : file.name;
                        }

                        if (name.trim().length === 0) {
                            return;
                        }

                        if (file.isDir || ext !== '-') {
                            displayName = name;
                        } else if (ext === '-') {
                            // If not a directory and there is no valid extension,
                            // then default it to .txt.
                            ext = 'txt';
                            displayName = name + '.txt';
                        }

                        temp.push({
                            name,
                            displayName,
                            ext,
                            path: file.name,
                            isDir: file.isDir,
                            lastModified: file.lastModified,
                            size: file.size,
                        });
                    });
                }

                this.bfdFiles = temp;

                if (fileToPreview && this.bfdFiles.length > 0) {
                    const file = this.bfdFiles.find((f) => f.name === fileToPreview);
                    if (file) {
                        this.handleFileToPreviewEvent(file);
                    }
                }
            },
            error: (error) => this.handleError(error),
        });
    }

    public handleRowsSelected(downloadList: DownloadList) {
        let name: string;
        if (!downloadList || Object.keys(downloadList).length === 0) {
            this.selectedFiles = [];
            return;
        }

        Object.keys(downloadList).forEach((filename) => {
            if (this.bfdResponse.fullPath === this.pathRoot) {
                // this file is at the root level so do not pre-pend the full path.
                name = filename;
            } else {
                name = this.bfdResponse.fullPath + '/' + filename;
            }

            // Make sure the name does not already exist from the root level or with
            // the full path prepended to avoid adding a filename that does not exist.
            if (
                downloadList[filename].selected &&
                this.selectedFiles.indexOf(name) < 0 &&
                this.selectedFiles.indexOf(filename) < 0
            ) {
                this.selectedFiles.push(name);
            } else if (!downloadList[filename].selected && this.selectedFiles.indexOf(name) >= 0) {
                this.selectedFiles.splice(this.selectedFiles.indexOf(name), 1);
            }
        });
    }

    public handleDownloadClick() {
        if (!this.downloadInProgress) {
            this.downloadInProgress = true;
        } else if (this.selectedFiles.length > 0) {
            let filename = 'temp.xlsx';
            this.bfdFileExplorerService.download(this.selectedFiles).subscribe({
                next: (res: any) => {
                    const errorMessage = `${res?.statusText}`;

                    switch (res.status) {
                        case 200:
                        case 204:
                            filename = res.headers.get('Content-Disposition')?.split('filename=')[1].split(';')[0];
                            if (!filename) {
                                filename = 'default filename ' + new Date().toISOString();
                            }

                            break;
                        case 400:
                        case 401:
                        case 403:
                        default:
                            throw new Error(errorMessage);
                    }

                    let binaryData = [];
                    binaryData.push(res.body);
                    let downloadLink = document.createElement('a');
                    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: res.type }));
                    if (filename) {
                        downloadLink.setAttribute('download', filename);
                    }
                    document.body.appendChild(downloadLink);
                    downloadLink.click();

                    this.selectedFiles = [];
                    this.downloadInProgress = false;
                },
                error: (error) => this.handleError(error),
            });
        } else {
            this.downloadInProgress = false;
        }
    }

    private openModal(modalConfig: MatDialogConfig) {
        this.bfpModal = this.matDialog.open(BfpModalComponent, modalConfig);
        // Remove query param 'file' when the modal is closed.
        this.bfpModal.afterClosed().subscribe((res: ModalResult) => {
            this.router.navigate(['/bank-files'], {
                queryParams: { file: null },
                queryParamsHandling: 'merge',
            });
        });
    }

    private handleError(error: HttpErrorResponse): void {
        this.fetching = false;
        this.selectedFiles = [];
        this.downloadInProgress = false;

        console.error(error);
        this.snackBar.open('Server error', undefined, {
            duration: 10000,
        });
    }
}
