export interface BankRecord {
    bankName?: string;
    nationalId?: string;
    countryCode?: string;
    expirationDate?: string;
    fedach?: BankDetailsFedach;
    fedwire?: BankDetailsFedwire;
}

export interface BankDetailsCommon {
    bankName?: string;
    nationalId?: string;
    connectivity?: string;
}

export interface BankDetailsFedach extends BankDetailsCommon {
    statePostalCode?: string;
    phone?: string;
    statusCode?: string;
}

export interface BankDetailsFedwire extends BankDetailsCommon {
    fedwireFundStatus?: string;
    wireTransactCode?: string;
    fedwireCorr?: string;
    intermediaryId?: string;
}
