<div>
    <mat-card appearance="outlined" class="mat-card">
        <div id="title-bar" class="title-bar">
            <div id="title-content" class="title-content">
                <mat-card-title>Routing Details</mat-card-title>
                <span id="x-dismiss"
                      (click)="closeSlider()"
                      class="material-symbols-outlined x-dismiss"
                      data-testid="x-dismiss">
                    close
                </span>
            </div>
        </div>
        <div *ngIf="bank" class="card__container">
            <mat-card-content>
                <div style="display: block;">
                    <div class="info-section">
                        <div class="info-label">Main Info</div>
                        <div class="bank-info">
                            <span class="field-label">Bank Name</span>
                            <span class="field-value">{{bank.bankName}}</span>
                        </div>
                        <div class="bank-info">
                            <span class="field-label">National ID</span>
                            <span class="field-value">{{bank.nationalId}}</span>
                        </div>
                        <div class="bank-info">
                            <span class="field-label">Country Code</span>
                            <span class="field-value">{{bank.countryCode}}</span>
                        </div>
                        <div class="bank-info">
                            <span class="field-label">Expiration</span>
                            <span class="field-value">{{bank.expirationDate}}</span>
                        </div>
                    </div>
                </div>
                <div style="display: block;">
                    <div class="info-section">
                        <div class="info-label">Fed ACH</div>
                        <div class="bank-info">
                            <span class="field-label">State, Zip</span>
                            <span class="field-value">{{bank.fedach.statePostalCode}}</span>
                        </div>
                        <div class="bank-info">
                            <span class="field-label">Phone</span>
                            <span class="field-value">{{bank.fedach.phone}}</span>
                        </div>
                        <div class="bank-info">
                            <span class="field-label">Status Code</span>
                            <span class="field-value">{{bank.fedach.statusCode}}</span>
                        </div>
                        <div class="bank-info">
                            <span class="field-label">Connectivity</span>
                            <span class="field-value"
                                  [class.connectivity-direct] = "bank.fedach.connectivity.toLowerCase() === 'direct'"
                                  [class.connectivity-indirect] = "bank.fedach.connectivity.toLowerCase() === 'indirect'"
                            >{{bank.fedach.connectivity}}</span>
                        </div>
                    </div>
                </div>
                <div style="display: block;">
                    <div class="info-section">
                        <div class="info-label">Fed Wire</div>
                        <div class="bank-info">
                            <span class="field-label">FedWire Fund Status</span>
                            <span class="field-value"
                                  [class.connectivity-direct] = "bank.fedwire.fedwireFundStatus.toLowerCase() === 'direct'"
                                  [class.connectivity-indirect] = "bank.fedwire.fedwireFundStatus.toLowerCase() === 'indirect'"
                            >{{bank.fedwire.fedwireFundStatus}}</span>
                        </div>
                        <div class="bank-info">
                            <span class="field-label">Wire Transaction Code</span>
                            <span class="field-value">{{bank.fedwire.wireTransactCode}}</span>
                        </div>
                        <div class="bank-info">
                            <span class="field-label">FedWire Corr</span>
                            <span class="field-value">{{bank.fedwire.fedwireCorr}}</span>
                        </div>
                        <div class="bank-info">
                            <span class="field-label">Intermediary ID</span>
                            <span class="field-value">{{bank.fedwire.intermediaryId}}</span>
                        </div>
                        <div class="bank-info">
                            <span class="field-label">Connectivity</span>
                            <span class="field-value"
                                  [class.connectivity-direct] = "bank.fedwire.connectivity.toLowerCase() === 'direct'"
                                  [class.connectivity-indirect] = "bank.fedwire.connectivity.toLowerCase() === 'indirect'"
                            >{{bank.fedwire.connectivity}}</span>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </div>
    </mat-card>
</div>
