<div *ngIf="(fetching)" class="fetching-files">
    <mat-spinner></mat-spinner>
</div>
<div id="cache-container" class="cache-container">
    <div id="header-bar" class="header-bar">
        <div class="header-text-div">
            <span id="header-text" class="header-text">{{cachedBankCodesTitle}}</span>
        </div>
    </div>
    <div id="data-container" class="data-container">
        <div id="sub-header-bar" class="sub-header-bar">
            <span id="sub-header-text" class="sub-header-text">{{cachedBankCodesSubtitle}}</span>
        </div>
        <div *ngIf="cachedBankCodesCount > 0" id="count-div" class="count-div">
            <span id="cache-count" class="chache-count">{{cachedBankCodesCount}} {{cachedBankCodesCountText}}</span>
        </div>
        <div id="search-bar" class="search-bar">
            <app-search
                data-testid="app-caches-search"
                (newSearchEvent)="performSearch($event)"></app-search>
        </div>
        <div id="action-area" class="action-area">
            <div id="upload-div" class="upload-div"
                 (click)="export()"
            >
                <span id="export-span"
                      class="material-symbols-outlined action-icon action-icon-margin"
                >download</span>
                <span class="export-label" data-testid="export-button">{{exportInProgress ? exporting : exportLabel}}</span>
            </div>
        </div>
    </div>
    <div class="table-container">
        <app-cache-table class="cache-table"
                         data-testid="app-cache-table"
                         [cacheRecords]="cacheRecords"
                         [searchText]="searchValue"
                         [fetching]="fetching"
                         (deleteEvent)="handleDelete($event)"
        ></app-cache-table>
    </div>
</div>
