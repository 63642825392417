import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfigData, ModalReviewConfigData, VendorRecord, VendorTabNames } from '../shared';
import { AvailableColumns } from './table.columns.types';

type TableElement = {
    selected?: boolean;
    vendor?: VendorRecord;
};

@Component({
    selector: 'app-vendor-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent implements AfterViewInit, OnChanges {
    @Input() vendorRecords: VendorRecord[];
    @Input() searchText: string;
    public get displayedColumns(): string[] {
        return this._displayedColumns;
    }
    @Input() set displayedColumns(value: string[]) {
        if (value && value.length > 0) {
            this._displayedColumns = value;
        } else {
            this._displayedColumns = this.defaultColumns;
        }
    }
    @Input() selectedTabIndex: number;
    @Input() fetching: boolean;

    @ViewChild(MatSort) sort: MatSort;
    @Output() editDeleteEvent = new EventEmitter<ModalConfigData>();
    @Output() showDetailsEvent = new EventEmitter<VendorRecord>();
    @Output() selectedIdsEvent = new EventEmitter<number[]>();
    @Output() reviewEvent = new EventEmitter<ModalReviewConfigData>();

    public dataSource: MatTableDataSource<TableElement>;
    public tableData: TableElement[] = [];

    // This is used to check the users access; if they do not have access the
    // icons are not displayed.
    public editAccessCheck = 'vendor-edit';
    public deleteAccessCheck = 'vendor-delete';
    public rejectAccessCheck = 'vendor-reject';
    public approveAccessCheck = 'vendor-approve';
    public allChecked = false;

    private _displayedColumns: string[];
    private defaultColumns: string[] = [
        AvailableColumns.PayeeName,
        AvailableColumns.AccountTitle,
        AvailableColumns.AccountNumber,
        AvailableColumns.AbaSwift,
        AvailableColumns.BankCountry,
        AvailableColumns.Currency,
        AvailableColumns.SortCode,
        AvailableColumns.Details,
    ];

    constructor(public matDialog: MatDialog) {
        if (this.vendorRecords && Array.isArray(this.vendorRecords) && this.vendorRecords.length > 0) {
            this.tableData = this.vendorRecords.map((row) => {
                return {
                    selected: false,
                    vendor: row,
                };
            });
        } else {
            this.tableData = [];
        }

        // Assign the data to the data source for the table to render
        this.dataSource = new MatTableDataSource(this.tableData);
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
        // This was added because the Account Number was being sorted
        // numerically instead of alphabetically.
        this.dataSource.sortingDataAccessor = (data, attribute) => {
            switch (attribute) {
                case 'payeeName': {
                    return data.vendor.payeeName;
                }
                case 'accountTitle': {
                    return data.vendor.accountTitle;
                }
                case 'accountNumber': {
                    return data.vendor.accountNumber;
                }
                case 'abaSwift': {
                    return data.vendor.abaSwift;
                }
                case 'bankCountry': {
                    return data.vendor.bankCountry;
                }
                case 'currency': {
                    return data.vendor.currency;
                }
                case 'sortCode': {
                    return data.vendor.sortCode;
                }
                case 'updatedDt': {
                    return data.vendor.updatedDt;
                }
                case 'reviewStatus': {
                    return data.vendor.status;
                }
                case 'reviewType': {
                    return data.vendor.reviewType;
                }
                case 'rejectionDt': {
                    return data.vendor.updatedDt;
                }
                case 'dealCount': {
                    return data.vendor.dealCount;
                }
                default: {
                    return data[attribute];
                }
            }
        };
    }

    ngOnChanges(_: SimpleChanges): void {
        if (this.vendorRecords && Array.isArray(this.vendorRecords) && this.vendorRecords.length > 0) {
            this.tableData = this.vendorRecords.map((row) => {
                return {
                    selected: false,
                    vendor: row,
                };
            });
        } else {
            this.tableData = [];
        }

        this.dataSource.data = this.tableData;
    }

    public displayDetails(row: VendorRecord) {
        this.showDetailsEvent.emit(row);
    }

    public editRow(row: VendorRecord) {
        this.editDeleteEvent.emit({
            title: 'Edit Vendor Account',
            action: 'edit',
            selected: row,
        });
    }

    public reviewRow(row: VendorRecord) {
        this.reviewEvent.emit({
            title: 'Account Overview',
            action: 'review',
            selected: row,
        });
    }

    public deleteRow(row: VendorRecord) {
        this.editDeleteEvent.emit({
            title: 'Delete Vendor',
            action: 'delete',
            selected: row,
        });
    }

    public isApprovedTab(): boolean {
        return Object.values(VendorTabNames)[this.selectedTabIndex] === VendorTabNames.APPROVED;
    }

    public isInReviewTab(): boolean {
        return Object.values(VendorTabNames)[this.selectedTabIndex] === VendorTabNames.IN_REVIEW;
    }

    public isRejectedTab(): boolean {
        return Object.values(VendorTabNames)[this.selectedTabIndex] === VendorTabNames.REJECTED;
    }

    public someComplete(): boolean {
        const hasChecked = this.tableData.find((row) => row.selected === true);
        const hasUnchecked = this.tableData.find((row) => row.selected === false);

        this.allChecked = hasChecked != null && hasUnchecked == null;

        return hasChecked != null && hasUnchecked != null;
    }

    public setAll($event: boolean) {
        this.tableData.forEach((row) => (row.selected = $event));
        this.allChecked = $event;
        this.emitSelectedIds();
    }

    public vendorExportChange($event: boolean, tableItem: TableElement) {
        tableItem.selected = $event;
        this.emitSelectedIds();
    }

    private emitSelectedIds() {
        const temp = this.tableData.filter((row) => row.selected).map((row) => row.vendor.id);
        this.selectedIdsEvent.emit(temp);
    }
}
