import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BankFileExplorerRoutingModule } from './bank-files-routing.module';
import { BankFilesComponent } from './bank-files.component';
import { BankFileTableComponent } from './bank-file-table/bank-file-table.component';
import { SharedModule } from '../components/shared.module';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { CoreModule } from '../core/core.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BfpModalComponent } from './bfp-modal/bfp-modal.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [BankFilesComponent, BankFileTableComponent, BfpModalComponent],
    imports: [
        CommonModule,
        BankFileExplorerRoutingModule,
        SharedModule,
        DropdownModule,
        FormsModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatSortModule,
        MatTableModule,
        CoreModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatButtonModule,
    ],
})
export class BankFilesModule {}
