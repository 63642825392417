import { Injectable } from '@angular/core';
import { catchError, Observable, Subscriber } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigurationService } from '../../core/services/configuration.service';
import { ActivityRecord, ActivityRequest } from './activity.types';
import { handleMissingServerError } from '../../core/utils/http-response-handlers';
import { ExportConfig } from '../../brokerage-accounts/shared';

@Injectable({
    providedIn: 'root',
})
export class ActivityService {
    private serverName: string = 'Activity API';

    constructor(
        private http: HttpClient,
        private configurationService: ConfigurationService,
    ) {}

    public get(activityRequest: ActivityRequest): Observable<ActivityRecord[]> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().activityHost,
            'activity',
            this.configurationService.envConfig.getValue().activityApiVer,
        ].join('/');

        let params = new HttpParams().set('page', activityRequest.page).set('size', activityRequest.size);

        if (activityRequest.userType) {
            params = params.set('ut', activityRequest.userType);
        }

        if (activityRequest.userSearch) {
            params = params.set('us', activityRequest.userSearch);
        }

        if (activityRequest.feature) {
            params = params.set('f', activityRequest.feature);
        }

        if (activityRequest.startDate) {
            params = params.set('sd', activityRequest.startDate);
        }

        if (activityRequest.endDate) {
            params = params.set('ed', activityRequest.endDate);
        }

        if (activityRequest.sortField) {
            params = params.set(
                'sort',
                activityRequest.sortField +
                    (activityRequest.sortDir && activityRequest.sortDir === 'asc' ? ':asc' : ':desc'),
            );
        }

        // const activities: ActivityRecord[] = [
        //     {
        //         id: 1,
        //         username: 'Maddie Birrenkott',
        //         usertype: 'Internal',
        //         activity: 'Exported Bank Codes List',
        //         feature: 'Cached Bank Codes',
        //         timestamp: new Date(1704092400000 + Math.floor(Math.random() * 25183470000)).getTime(),
        //         auditDetails: null,
        //     },
        //     {
        //         id: 2,
        //         username: 'Mitchell Eckberg',
        //         usertype: 'Internal',
        //         activity: 'Deleted Bank Code',
        //         feature: 'Cached Bank Codes',
        //         timestamp: new Date(1704092400000 + Math.floor(Math.random() * 25183470000)).getTime(),
        //         auditDetails: null,
        //     },
        //     {
        //         id: 3,
        //         username: 'Maddie Birrenkott',
        //         usertype: 'Internal',
        //         activity: 'Added Brokerage Account',
        //         feature: 'Common Brokerage Accounts',
        //         timestamp: new Date(1704092400000 + Math.floor(Math.random() * 25183470000)).getTime(),
        //         auditDetails: null,
        //     },
        //     {
        //         id: 4,
        //         username: 'Mitchell Eckberg',
        //         usertype: 'Internal',
        //         activity: 'Deleted Brokerage Account',
        //         feature: 'Common Brokerage Accounts',
        //         timestamp: new Date(1704092400000 + Math.floor(Math.random() * 25183470000)).getTime(),
        //         auditDetails: null,
        //     },
        //     {
        //         id: 5,
        //         username: 'Maddie Birrenkott',
        //         usertype: 'Internal',
        //         activity: 'Edited Brokerage Account',
        //         feature: 'Common Brokerage Accounts',
        //         timestamp: new Date(1704092400000 + Math.floor(Math.random() * 25183470000)).getTime(),
        //         auditDetails: null,
        //     },
        //     {
        //         id: 6,
        //         username: 'Mitchell Eckberg',
        //         usertype: 'Internal',
        //         activity: 'Exported Brokerage Account',
        //         feature: 'Common Brokerage Accounts',
        //         timestamp: new Date(1704092400000 + Math.floor(Math.random() * 25183470000)).getTime(),
        //         auditDetails: null,
        //     },
        //     {
        //         id: 7,
        //         username: 'Maddie Birrenkott',
        //         usertype: 'Internal',
        //         activity: 'Rejected Vendor',
        //         feature: 'Pre-Approved Vendors',
        //         timestamp: new Date(1704092400000 + Math.floor(Math.random() * 25183470000)).getTime(),
        //         auditDetails: null,
        //     },
        //     {
        //         id: 8,
        //         username: 'Mitchell Eckberg',
        //         usertype: 'Internal',
        //         activity: 'Approved Vendor',
        //         feature: 'Pre-Approved Vendors',
        //         timestamp: new Date(1704092400000 + Math.floor(Math.random() * 25183470000)).getTime(),
        //         auditDetails: null,
        //     },
        //     {
        //         id: 9,
        //         username: 'Maddie Birrenkott',
        //         usertype: 'Internal',
        //         activity: 'Approved Vendor',
        //         feature: 'Pre-Approved Vendors',
        //         timestamp: new Date(1704092400000 + Math.floor(Math.random() * 25183470000)).getTime(),
        //         auditDetails: null,
        //     },
        //     {
        //         id: 10,
        //         username: 'Mitchell Eckberg',
        //         usertype: 'Internal',
        //         activity: 'Exported Approved Vendor',
        //         feature: 'Pre-Approved Vendors',
        //         timestamp: new Date(1704092400000 + Math.floor(Math.random() * 25183470000)).getTime(),
        //         auditDetails: null,
        //     },
        //     {
        //         id: 11,
        //         username: 'Mitchell Eckberg',
        //         usertype: 'Internal',
        //         activity: 'Created User',
        //         feature: 'Internal Admin',
        //         timestamp: new Date(1704092400000 + Math.floor(Math.random() * 25183470000)).getTime(),
        //         auditDetails: null,
        //     },
        //     {
        //         id: 12,
        //         username: 'Maddie Birrenkott',
        //         usertype: 'Internal',
        //         activity: 'Edited User',
        //         feature: 'Internal Admin',
        //         timestamp: new Date(1704092400000 + Math.floor(Math.random() * 25183470000)).getTime(),
        //         auditDetails: null,
        //     },
        //     {
        //         id: 13,
        //         username: "Sean 'O Leary",
        //         usertype: 'Internal',
        //         activity: 'Updated Access',
        //         feature: 'Internal Admin',
        //         timestamp: new Date(1704092400000 + Math.floor(Math.random() * 25183470000)).getTime(),
        //         auditDetails: null,
        //     },
        // ];
        //
        // const activityResponse = {
        //     body: activities,
        //     headers: {
        //         get: () => {
        //             return `content-range: 1-${activities.length}/${activities.length}`;
        //         },
        //     },
        // };
        //
        // return new Observable((observer: Subscriber<any>) => {
        //     observer.next(activityResponse);
        //     observer.complete();
        // });
        return this.http
            .get(requestUrl, { params, observe: 'response' })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public export(exportConfig: ExportConfig): Observable<Response> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().activityHost,
            'activity',
            this.configurationService.envConfig.getValue().activityApiVer,
            'export',
        ].join('/');

        return this.http
            .post(requestUrl, exportConfig, {
                headers: { 'Content-Type': 'application/json' },
                responseType: 'blob',
                observe: 'response',
            })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }
}
