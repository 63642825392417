export enum InvalidGiactEntityErrors {
    INVALID_ENTITY_TYPE = 'Invalid EntityType: EntityType must be "Person" or "Business".',
    INVALID_NAME = 'Invalid Name: Name is required and must not be empty.',
    INVALID_RECORD_ROW = 'Invalid record: the record must contain values for each column of the file, even if the value is empty.',
    INVALID_EMAIL_ADDRESS = 'Invalid EmailAddress: please check the email address. It should follow email address standards.',
    INVALID_IP_ADDRESS = 'Invalid IPAddress: ip address must conform to IPv4 standards.',
    INVALID_ZIP_CODE = 'Invalid ZipCode: zip codes must be US based following the form of xxxxx or xxxxx-xxxx.',
    INVALID_STATE = 'Invalid State: state must be one of the 50 US or DC using the two character abbreviation.',
    INVALID_COUNTRY = 'Invalid Country: country must be "US".',
    INVALID_UNIQUE_ID = 'Invalid Unique Id: Unique Id must be present and can contain 50 alphanumeric characters',
    INVALID_ROUTING_NUMBER = 'Invalid Routing Number: Routing number must be 9 numeric characters',
    INVALID_ACCOUNT_NUMBER = 'Invalid Account Number: Account number must be 4 to 17 alphanumeric characters',
    INVALID_TAX_ID = 'Invalid Tax Id: Tax Id must be either 4 or 9 numeric characters',
    INVALID_PHONE_NUMBER = 'Invalid Phone Number: Phone Number must be 10 numeric characters. Do NOT use separators in the Phone Number.',
    INVALID_ADDRESS_LINE_2 = 'Invalid AddressLine2: AddressLine2 may only be used if AddressLine1 is filled in.',
    INVALID_CITY = 'Invalid City: City must filled in if AddressLine1 is filled in.',
}

export interface GiactImportEntity {
    EntityType: string;
    Name: string;
    AddressLine1?: string;
    AddressLine2?: string;
    City?: string;
    State?: string;
    ZipCode?: string;
    Country?: string;
    EmailAddress?: string;
    CurrentIpAddress?: string;
    RoutingNumber?: string;
    AccountNumber?: string;
    TaxId?: string;
    UniqueId?: string;
    PhoneNumber?: string;

    [propname: string]: string;
}

export interface GiactRecord {
    lineNumber: number;
    record: GiactImportEntity;
    error?: string;
}

export interface InvalidGiactRecord {
    lineNumber: number;
    error: string;
    record: string;
}

export interface DuplicateGiactRecord {
    lineNumber: number;
    matchedLineNumber: number;
}

export interface ValidGiactRecord {
    [Name: string]: GiactRecord[];
}

export interface GiactRecordMap {
    validGiactRecords: ValidGiactRecord;
    invalidGiactRecords: InvalidGiactRecord[];
    duplicateGiactRecords: DuplicateGiactRecord[];
    warningGiactRecords: InvalidGiactRecord[];
}
// Including DC
export const states: string[] = [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DC',
    'DE',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
];

export enum SelectedService {
    ENABLE_EMAIL_VERIFICATION_PLUS_ADDRESS = 'EnableEmailVerificationPlusAddress',
    ENABLE_BANK_VERIFICATION = 'EnableBankVerification',
}

export enum EntityType {
    PERSON = 'PERSON',
    BUSINESS = 'BUSINESS',
}

export const emailAndAddressAllowedFields = [
    'EntityType',
    'Name',
    'UniqueId',
    'EmailAddress',
    'CurrentIpAddress',
    'TaxId',
    'PhoneNumber',
    'AddressLine1',
    'AddressLine2',
    'City',
    'State',
    'ZipCode',
    'Country',
];
