<div *ngIf="(fetching)" class="fetching-files">
    <mat-spinner></mat-spinner>
</div>
<div class="users-container" id="users-container">
    <ng-container
        *ngTemplateOutlet="selectedUser ? singleUser : multipleUsers"
    ></ng-container>
</div>

<ng-template #singleUser>
    <div class="wrapper-div">
        <div class="page-title-actions">
            <div class="title-actions">
                <span class="title">{{ selectedUser?.userUi?.firstName }} {{ selectedUser?.userUi?.lastName }}</span>
                <div class="actions">
                    <div [matMenuTriggerFor]="userMenu" class="button-div" data-testid="user-actions">
                        <div class="button-elem">
                            <span class="button-label">User Actions</span>
                            <mat-icon>keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                    <mat-menu #userMenu="matMenu" xPosition="before" yPosition="below">
                        <div (click)="handleEditUserClick()"
                             *ngIf="canEditUser(selectedUser.userUi?.status)"
                             class="menu-option"
                             data-testid="single-user-edit-user-option"
                             mat-menu-item>Edit User
                        </div>
                        <div (click)="handleUserActivityClick()"
                             *ngIf="canViewUserActivity(selectedUser.userUi?.status)"
                             class="menu-option"
                             mat-menu-item>User Activity
                        </div>
                        <div (click)="handleResendInvitation()"
                             *ngIf="canResendInvitation(selectedUser.userUi?.status)"
                             class="menu-option"
                             mat-menu-item>Resend Invitation
                        </div>
                        <div (click)="handleSendPwResetClick()"
                             *ngIf="canSendPwReset(selectedUser.userUi?.status)"
                             class="menu-option"
                             mat-menu-item>Send Password Reset
                        </div>
                        <div (click)="handleBlockClick()"
                             *ngIf="canBlock(selectedUser.userUi?.status)"
                             class="menu-option menu-option-red"
                             mat-menu-item>Block Authentication
                        </div>
                        <div (click)="handleUnblockClick()"
                             *ngIf="canUnblock(selectedUser.userUi?.status)"
                             class="menu-option menu-option-red"
                             mat-menu-item>Unblock Authentication
                        </div>
                        <div (click)="handleRevokeAllAccessClick()"
                             *ngIf="showRevokeAccess && dealRecords?.length > 0"
                             class="menu-option menu-option-red"
                             mat-menu-item>Revoke All Access
                        </div>
                        <div (click)="handleDeactivateClick()"
                             *ngIf="canDeactivate(selectedUser.userUi?.status)"
                             class="menu-option menu-option-red"
                             mat-menu-item>Deactivate User
                        </div>
                    </mat-menu>
                </div>
            </div>
        </div>
        <div class="user-frame-div">
            <div class="user-details-div">
                <div class="info-div">
                    <div class="info-label">
                        {{ 'User Info' | uppercase }}
                    </div>
                    <div class="info-data">
                        <span class="info-data-label">Email</span>
                        <span class="info-data-value">{{ selectedUser?.userUi?.email }}</span>
                    </div>
                    <div class="info-data">
                        <span class="info-data-label">Netsuite ID</span>
                        <!--                            <a class="info-data-value" [href]="getUserNsUrl(selectedUser?.userUi?.nsId)" (click)="openInNewTab(selectedUser?.userUi?.nsId)">{{selectedUser?.userUi?.nsId > 0 ? selectedUser?.userUi?.nsId : ''}}</a>-->
                        <span (click)="openInNewTab(getUserNsUrl(selectedUser?.userUi?.nsId))"
                              [matTooltip]="getUserNsUrl(selectedUser?.userUi?.nsId)"
                              class="as-link"
                              matTooltipHideDelay="200"
                              matTooltipPosition="below"
                              matTooltipShowDelay="300"
                        >{{ selectedUser?.userUi?.nsId > 0 ? selectedUser?.userUi?.nsId : '' }}</span>
                    </div>
                    <div class="info-data">
                        <span class="info-data-label">Subsidiary</span>
                        <span class="info-data-value" *ngFor="let sub of selectedUser?.userUi.subsidiaries">{{ sub }}</span>
                    </div>
                    <div class="info-data">
                        <span class="info-data-label">Products</span>
                        <span *ngFor="let product of selectedUser?.userUi?.products"
                              class="product-data-value">{{ product.name }}</span>
                    </div>
                    <div class="auth0-info-data">
                        <span class="info-data-label">Auth0 Status</span>
                        <span [class.auth0-active]="selectedUser?.userUi?.status?.toLowerCase() === 'active'"
                              [class.auth0-blocked]="selectedUser?.userUi?.status?.toLowerCase() === 'blocked'"
                              [class.auth0-deactivated]="selectedUser?.userUi?.status?.toLowerCase() === 'deactivated'"
                              [class.auth0-invited]="selectedUser?.userUi?.status?.toLowerCase() === 'invited'"
                              class="auth0-chip"
                        >
                            <span class="auth0-label">{{ selectedUser?.userUi?.status }}</span>
                        </span>
                    </div>
                    <div class="info-data">
                        <span class="info-data-label">Multi-Factor Authentication</span>
                        <div *ngIf="selectedUser?.userUi?.mfaMethods" class="authentication-method-container">
                            <span class="authentication-method-title">Authenticator Phone App</span>
                            <span *ngIf="selectedUser?.userUi?.mfaMethods['totp'] !== undefined" class="authentication-method-label">
                                Added on <span class="authentication-date">{{selectedUser?.userUi?.mfaMethods['totp']?.created_at | date: "MMM dd, yyyy" : 'UTC'}}</span>
                            </span>
                            <span [class.auth-method-verified]="selectedUser?.userUi?.mfaMethods['totp']?.confirmed"
                                  [class.auth-method-invited]="selectedUser?.userUi?.mfaMethods['totp']?.confirmed === false"
                                  [class.auth-method-not-set]="selectedUser?.userUi?.mfaMethods['totp'] === undefined"
                                  class="authentication-method-chip"
                            >
                                <span *ngIf="selectedUser?.userUi?.mfaMethods['totp']?.confirmed" class="authentication-method-chip-label">Verified</span>
                                <span *ngIf="selectedUser?.userUi?.mfaMethods['totp']?.confirmed === false" class="authentication-method-chip-label">Not Verified</span>
                                <span *ngIf="selectedUser?.userUi?.mfaMethods['totp'] === undefined" class="authentication-method-chip-label">Not Set</span>
                            </span>
                            <mat-icon *ngIf="selectedUser?.userUi?.mfaMethods['totp'] !== undefined" class="delete-authentication-icon"
                                      (click)="deleteAuthenticationMethod(selectedUser?.userUi?.mfaMethods['totp']?.id, 'totp')">
                                delete
                            </mat-icon>
                        </div>
                        <mat-divider></mat-divider>
                        <div *ngIf="selectedUser?.userUi?.mfaMethods" class="authentication-method-container">
                            <span class="authentication-method-title">Phone Authentication</span>
                            <span *ngIf="selectedUser?.userUi?.mfaMethods['phone'] !== undefined" class="authentication-method-label">
                                {{selectedUser?.userUi?.mfaMethods['phone']?.phone_number}}
                            </span>
                            <span [class.auth-method-verified]="selectedUser?.userUi?.mfaMethods['phone']?.confirmed"
                                  [class.auth-method-invited]="selectedUser?.userUi?.mfaMethods['phone']?.confirmed === false"
                                  [class.auth-method-not-set]="selectedUser?.userUi?.mfaMethods['phone'] === undefined"
                                  class="authentication-method-chip"
                            >
                                <span *ngIf="selectedUser?.userUi?.mfaMethods['phone']?.confirmed" class="authentication-method-chip-label">Verified</span>
                                <span *ngIf="selectedUser?.userUi?.mfaMethods['phone']?.confirmed === false" class="authentication-method-chip-label">Not Verified</span>
                                <span *ngIf="selectedUser?.userUi?.mfaMethods['phone'] === undefined" class="authentication-method-chip-label">Not Set</span>
                            </span>
                            <mat-icon *ngIf="selectedUser?.userUi?.mfaMethods['phone'] !== undefined" class="delete-authentication-icon"
                                      (click)="deleteAuthenticationMethod(selectedUser?.userUi?.mfaMethods['phone']?.id, 'phone')">
                                delete
                            </mat-icon>
                        </div>
                    </div>
                </div>
            </div>
            <div class="deals-section-div">
                <mat-tab-group (selectedTabChange)="tabChangeSingleUser($event);" [(selectedIndex)]="selectedIndexSingleUser" [disableRipple]="true"
                               animationDuration="0ms"
                               class="tool-bar"
                               fitInkBarToContent="true"
                               mat-align-tabs="start"
                               mat-stretch-tabs="false"
                >
                    <mat-tab label="Deal Dashboard">
                        <ng-container
                            *ngTemplateOutlet="dealDashboardTableTemplate"
                        ></ng-container>
                    </mat-tab>
                    <mat-tab label="Shareholder Portal">
                        <ng-container
                            *ngTemplateOutlet="shareholderPortalTableTemplate"
                        ></ng-container>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #dealDashboardTableTemplate matTabContent>
    <div class="search-bar-deals" id="search-bar-deals">
        <app-user-access-search
            [initialValue]="searchValue"
            (newSearchEvent)="performSearchDealUserAccess($event)"
            data-testid="app-deals-search"
            placeholder="Filter DealUserAccess"></app-user-access-search>
    </div>
    <div class="table-container">
        <app-deal-table
            (dealActivityNav)="handleDealActivityNavigation($event)"
            (dealDashboardNav)="handleDealDashboardNavigation($event)"
            (pageEvent)="handlePageEventDealUserAccess($event)"
            (revokeAccessEvent)="handleRevokeAccessEventDD($event)"
            (sortEvent)="handleSortChangeDealUserAccess($event)"
            [dealRecords]="dealRecords"
            [displayedColumns]="dealDisplayedColumns"
            [pageSize]="pageSizeDealUserAccess"
            [page]="pageDealUserAccess"
            [initialSort]="{ id: requestHelperDealUserAccess.sortField, start: requestHelperDealUserAccess.sortDir }"
            [searchText]="searchValueDealUserAccess"
            [showCreateUser]="showCreateUser"
            [showRevokeAccess]="showRevokeAccess"
            [totalRecords]="totalRecordsDealUserAccess"
            class="deals-table"
            data-testid="app-deals-table"
        ></app-deal-table>
    </div>
</ng-template>

<ng-template #shareholderPortalTableTemplate matTabContent>
    <div class="users-frame-div">
        <div class="users-search-bar">
            <app-user-access-search
                [initialValue]="searchValue"
                (newSearchEvent)="performSearchShareholderUserAccess($event)"
                data-testid="app-accounts-search-users"
                placeholder="Filter by deal, role, or shareholder...">
            </app-user-access-search>
        </div>
    </div>
    <div class="table-container">
        <app-shareholder-user-access-table
            (pageEvent)="handlePageEventShareholderUserAccess($event)"
            (revokeAccessEvent)="handleRevokeAccessEventSH($event)"
            (shareholderClickedEvent)="handleShareholderClickedEventShareholderUserAccess($event)"
            (sortEvent)="handleSortChangeShareholderUserAccess($event)"
            (userClickedEvent)="handleUserClickedEventShareholderUserAccess($event)"
            [displayedColumns]="shareholderDealUserDisplayedColumns"
            [fetching]="fetchingShareholderUserAccess"
            [pageSize]="pageSize"
            [page]="page"
            [initialSort]="{ id: requestHelperShareholderUserAccess.sortField, start: requestHelperShareholderUserAccess.sortDir }"
            [records]="shareholderUserAccessRecords"
            [searchText]="searchValueShareholderUserAccess"
            [showRevokeAccess]="showRevokeAccess"
            [totalRecords]="totalRecordsShareholderUserAccess"
            class="shareholder-user-access-table"
        ></app-shareholder-user-access-table>
    </div>
</ng-template>

<ng-template #multipleUsers>
    <ng-container *ngTemplateOutlet="showCreateUser ? createUserForm : null">
    </ng-container>
    <div *ngIf="!showCreateUser" class="multiple-users-container">
        <div class="header-bar" id="header-bar">
            <div class="header-text-div">
                <span class="header-text" id="header-text">Users</span>
            </div>
        </div>
        <div class="main">
            <div class="frame-div">
                <div class="search-bar" id="search-bar">
                    <app-user-access-search
                        [initialValue]="searchValue"
                        (newSearchEvent)="performSearch($event)"
                        data-testid="app-accounts-search"
                        placeholder="Filter by name, email...">
                    </app-user-access-search>
                </div>
                <div *ngIf="showAddUserMenu()" class="button-div">
                    <div class="button-elem" (click)="createUserClick()" data-testid="add-user">
                        <span class="button-label">Add User</span>
                    </div>
                </div>
            </div>
            <div class="table-container">
                <app-user-table
                    (pageEvent)="handlePageEvent($event)"
                    (sortEvent)="handleSortChange($event)"
                    (userActionNav)="handleUserAction($event)"
                    (userToEditEvent)="handleUserToEditEvent($event)"
                    [displayedColumns]="userDisplayedColumns"
                    [fetching]="fetching"
                    [pageSize]="pageSize"
                    [page]="page"
                    [initialSort]="{ id: requestHelper.sortField, start: requestHelper.sortDir }"
                    [searchText]="searchValue"
                    [showCreateUser]="showCreateUser"
                    [totalRecords]="totalRecords"
                    [userRecords]="userRecords"
                    class="users-table"
                    data-testid="app-user-table"
                ></app-user-table>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #createUserForm>
    <app-create-edit-user id="create-edit-user"
                          [mode]="createEditUserMode"
                          [userToEditId]="userToEditId"
                          (showCreateEditUserCloseEvent)="handleShowCreateEditUserCancelEvent()"
    ></app-create-edit-user>
</ng-template>
