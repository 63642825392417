import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ModalConfigData, ModalResult } from '../brokerage-accounts/shared';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivityRecord, ActivityRequest, ActivityService } from './shared';
import { ActivityModalComponent } from './activity-modal/activity-modal.component';
import { FilterConfig, FilterOption } from '../components/filter/filter.model.types';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
    selector: 'app-activity',
    templateUrl: './activity.component.html',
    styleUrls: ['./activity.component.scss'],
})
export class ActivityComponent implements OnInit {
    public activityTitle = 'Activity';
    public activityRecords: ActivityRecord[] = [];
    public totalRecords: number = 0;
    public userTypeFilterSelected: FilterOption;
    public featureFilterSelected: FilterOption;
    public fetching = true;
    public activityModal: MatDialogRef<ActivityModalComponent>;

    public userTypeFilterConfig: FilterConfig = {
        label: 'User Type',
        filterOptions: [
            { value: 'all', viewValue: 'All Users' },
            { value: 'internal', viewValue: 'Internal Only' },
            { value: 'external', viewValue: 'External Only' },
        ],
    };

    public featureFilterConfig: FilterConfig = {
        label: 'Feature / App',
        filterOptions: [
            { value: 'all', viewValue: 'All Features / Apps' },
            { value: 'bank-codes', viewValue: 'Bank Codes' },
            { value: 'brokerage-accounts', viewValue: 'Brokerage Accounts' },
            { value: 'vendors', viewValue: 'Vendors' },
            { value: 'user-access', viewValue: 'User Access' },
            { value: 'idd', viewValue: 'Deal Dashboard' },
            { value: 'sharp', viewValue: 'Shareholder Portal' },
        ],
    };

    public readonly range = new FormGroup({
        start: new FormControl<Date | null>(null),
        end: new FormControl<Date | null>(null),
    });

    public activityRequest: ActivityRequest = {
        userType: 'all',
        userSearch: '',
        feature: '',
        startDate: 0,
        endDate: 0,
        page: 0,
        size: 100,
        sortField: 'timestamp',
        sortDir: 'asc',
    };

    private modalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: true,
        minWidth: '700px', // TODO Fix dimensions
        minHeight: '250px', // TODO Fix dimensions
    };

    constructor(
        public matDialog: MatDialog,
        private snackBar: MatSnackBar,
        private activityService: ActivityService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe({
            next: (params: Params) => {
                if (!params) {
                    return;
                }

                this.activityRequest.userSearch = params.userSearch ?? '';
                this.userTypeFilterSelected =
                    this.userTypeFilterConfig.filterOptions.find((option) =>
                        params.userType?.startsWith(option.value),
                    ) ?? this.userTypeFilterConfig.filterOptions[0];
                this.activityRequest.userType = params.userType ?? 'all';
                this.featureFilterSelected =
                    this.featureFilterConfig.filterOptions.find((option) => params.feature?.startsWith(option.value)) ??
                    this.featureFilterConfig.filterOptions[0];
                this.activityRequest.feature = params.feature ?? '';
                this.activityRequest.startDate = params.startDate ?? 0;
                this.range.controls['start'].setValue(params.startDate ? new Date(+params.startDate) : null);
                this.activityRequest.endDate = params.endDate ?? 0;
                this.range.controls['end'].setValue(params.endDate ? new Date(+params.endDate) : null);
                this.activityRequest.page = params.page ?? 0;
                this.activityRequest.size = params.size ?? 100;
                this.activityRequest.sortField = params.sortField ?? 'timestamp';
                this.activityRequest.sortDir = params.sortDir ?? 'asc';

                this.fetch(this.activityRequest);
            },
            error: (error) => this.handleError(error),
        });
    }

    public export() {
        const newNoteConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Export Activities',
                action: 'export',
            },
        };

        this.openModal(newNoteConfig);
    }

    public filterTable(eventType: string, $event: FilterOption) {
        this.router.navigate(['/activity'], {
            queryParams: {
                userType: eventType === 'userType' ? $event?.value : this.activityRequest.userType,
                feature: eventType === 'feature' ? $event?.value : this.activityRequest.feature,
            },
            queryParamsHandling: 'merge',
        });
    }

    public performSearch(searchVal: string) {
        this.router.navigate(['/activity'], {
            queryParams: {
                userSearch: searchVal,
            },
            queryParamsHandling: 'merge',
        });
    }

    public handleStartDateChangeEvent(event: MatDatepickerInputEvent<Date>) {
        this.router.navigate(['/activity'], {
            queryParams: {
                startDate: event?.value?.getTime(),
            },
            queryParamsHandling: 'merge',
        });
    }

    public handleEndDateChangeEvent(event: MatDatepickerInputEvent<Date>) {
        this.router.navigate(['/activity'], {
            queryParams: {
                endDate: event?.value?.getTime(),
            },
            queryParamsHandling: 'merge',
        });
    }

    public handlePageEvent(e: PageEvent) {
        this.router.navigate(['/activity'], {
            queryParams: {
                page: e.pageIndex,
                size: e.pageSize,
            },
            queryParamsHandling: 'merge',
        });
    }

    public handleSortChange(s: Sort) {
        this.router.navigate(['/activity'], {
            queryParams: {
                sortField: s.active ?? null,
                sortDir: s.direction ?? null,
                page: s.direction ? this.activityRequest.page : 0,
            },
            queryParamsHandling: 'merge',
        });
    }

    private openModal(modalConfig: MatDialogConfig) {
        this.activityModal = this.matDialog.open(ActivityModalComponent, modalConfig);
        this.activityModal.afterClosed().subscribe((res: ModalResult) => {
            if (res?.exported) {
                this.snackBar.open('Activity data has been successfully exported', undefined, {
                    duration: 6000,
                });
            }
        });
    }

    private fetch(activityRequest: ActivityRequest) {
        this.fetching = true;

        this.activityService.get(activityRequest).subscribe({
            next: (res: any) => {
                this.fetching = false;
                const contentRange = res.headers.get('content-range'); // "1-3/3"
                this.totalRecords = +contentRange.substring(contentRange.indexOf('/') + 1);
                this.activityRecords = res.body;
            },
            error: (error) => this.handleError(error),
        });
    }

    private handleError(error: HttpErrorResponse): void {
        this.fetching = false;
        this.activityRecords = [];
        console.error(error);
        this.snackBar.open('Server error', undefined, {
            duration: 10000,
        });
    }
}
