import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FlagsService } from '../services/flags.service';
import { FlagStatus } from '../configuration.model';

@Directive({
    selector: '[appFlags]',
})
export class FlagsDirective implements OnInit {
    private requiredFlag: string = '';
    private isHidden = true;

    @Input() set appFlags(val) {
        if (val) {
            this.requiredFlag = val;
            this.updateView();
        }
    }

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private flagsService: FlagsService,
    ) {}

    ngOnInit() {
        this.updateView();
    }

    private updateView() {
        if (this.checkValidity()) {
            if (this.isHidden) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.isHidden = false;
            }
        } else {
            this.viewContainer.clear();
            this.isHidden = true;
        }
    }

    private checkValidity() {
        return this.requiredFlag && this.flagsService.fetchFlagStatus(this.requiredFlag) === FlagStatus.Enabled;
    }
}
