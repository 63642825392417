import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { VendorDetailConfig, VendorRecord } from '../shared';
import { ConfigurationService } from '../../core/services/configuration.service';

@Component({
    selector: 'app-slider-panel',
    templateUrl: './slider-panel.component.html',
    styleUrls: ['./slider-panel.component.scss'],
})
export class SliderPanelComponent {
    @HostListener('document:keydown.escape', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
        this.closeSlider();
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        // If this element does not contain the target element, then a click
        // happened outside the panel and should be closed. But because this is
        // listening to all document clicks, including the click to open the
        // slider, the click count needs to be reset after (re)opening. This is
        // to effectively ignore initial click to open the container.
        if (!this.eRef.nativeElement.contains(event.target) && this.clickCount > 0 && this.isOpen) {
            this.closeSlider();
        }

        this.clickCount++;
    }

    public get isOpen(): boolean {
        return this._isOpen;
    }
    @Input() set isOpen(value: boolean) {
        this._isOpen = value;
        if (this._isOpen) {
            this.clickCount = 0;
        }
    }

    public get vendorConfig(): VendorDetailConfig {
        return this._vendorConfig;
    }
    @Input() set vendorConfig(value: VendorDetailConfig) {
        this._vendorConfig = value;
    }

    @Output() closeSliderEvent = new EventEmitter<any>();
    @Output() rejectVendorEvent = new EventEmitter<VendorRecord>();
    @Output() submitVendorEvent = new EventEmitter<VendorRecord>();
    @Output() addNoteEvent = new EventEmitter<VendorRecord>();
    @Output() hideVendorEvent = new EventEmitter<VendorRecord>();

    public rejectAccessCheck = 'vendor-reject';
    public approveAccessCheck = 'vendor-approve';
    public hideAccessCheck = 'vendor-hide';

    private clickCount = 0;
    private _isOpen = false;
    private _vendorConfig: VendorDetailConfig;
    private formatter = new Intl.DateTimeFormat('en-US', {
        timeZoneName: 'short',
    });

    constructor(
        private eRef: ElementRef,
        private configurationService: ConfigurationService,
    ) {}

    public closeSlider() {
        this.closeSliderEvent.next(null);
    }

    public rejectVendor() {
        this.rejectVendorEvent.emit(this.vendorConfig.selected);
    }

    public hideButtonSubmit() {
        this.hideVendorEvent.emit(this.vendorConfig.selected);
    }

    public submitVendor() {
        this.submitVendorEvent.emit(this.vendorConfig.selected);
    }

    public addNote() {
        this.addNoteEvent.emit(this.vendorConfig.selected);
    }

    public getSubmitTooltip(): string {
        if (this.isSubmitDisabled()) {
            return 'Submit is disabled as you originated the request.';
        }
        return 'Approve the change';
    }

    public isSubmitDisabled(): boolean {
        return this.vendorConfig?.submitDisabled ? this.vendorConfig?.submitDisabled : false;
    }

    public openInNewTab($event, url: string) {
        // This is to prevent the click event from propagating up.
        $event?.stopPropagation();
        $event?.preventDefault();
        window.open(url, '_blank').focus();
    }

    public getShareholderNsUrl(nsId: number): string {
        const nsCustomerUrl = this.configurationService.envConfig.getValue().nsCustomerUrl;

        return nsCustomerUrl && nsId ? nsCustomerUrl.replace(/INSERTIDHERE/, nsId.toString()) : '';
    }

    public getTimeZoneName(date: number): string {
        if (!date || date < 0) {
            return '';
        }

        // Create an instance of Intl.DateTimeFormat with the option to include the short timezone name.
        // Format the date and split the result to get the timezone abbreviation.
        const parts = this.formatter.formatToParts(date);
        return parts.find((part) => part.type === 'timeZoneName').value;
    }
}
