import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, Data } from '@angular/router';
import { Observable } from 'rxjs';
import { FlagsService } from '../services/flags.service';

interface IFeatureToggleRouteData {
    requiredFeatureFlag: string;
    featureFlagRedirect: string;
}

@Injectable({
    providedIn: 'root',
})
export class FeatureToggleGuard {
    constructor(
        private router: Router,
        private flagsService: FlagsService,
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const routeData = route.data as IFeatureToggleRouteData;
        const requiredFeatureFlag: string = routeData.requiredFeatureFlag;
        const featureFlagRedirect: string = routeData.featureFlagRedirect || '/';
        return this.flagsService.fetchFlagStatus(requiredFeatureFlag) === 1
            ? true
            : this.router.createUrlTree([featureFlagRedirect]);
    }
}
