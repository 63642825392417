import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => {
        const ErrorMessageContainerElement = document.getElementById('app_init_error__backdrop');
        ErrorMessageContainerElement.hidden = false;
        const ErrorMessageTextElement = document.getElementById('app_init_error__text');
        let message = '';
        if (err) {
            if (err.message) {
                message = message + err.message;
            } else {
                message = message + err;
            }
        }
        ErrorMessageTextElement.textContent = message;
        // TODO: log this to log server that will show up in Kibana reports
        console.error(err);
    });

export * from 'src/app/ds-components/ds-components.module';
import { DIRECTIVES } from 'src/app/ds-components/angular-component-library';
export * from 'src/app/ds-components/angular-component-library/components';
