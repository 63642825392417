import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AccessTypes, AuthService } from '../services/auth.service';

@Directive({
    selector: '[appAccessCheckSection]',
})
export class AccessDirective implements OnInit {
    private requiredFlag: string = '';
    private isHidden = false;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authService: AuthService,
    ) {}

    @Input() appAccessCheckSection: string;

    ngOnInit() {
        this.updateView();
    }

    private updateView() {
        if (this.checkFullAccess()) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.isHidden = false;
        } else {
            this.viewContainer.clear();
            this.isHidden = true;
        }
    }

    private checkFullAccess() {
        const x = this.authService.checkAccess(this.appAccessCheckSection);
        return x === AccessTypes.FULL;
    }
}
