import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { InternalAdminRoutingModule } from './internal-admin-routing.module';
import { InternalAdminComponent } from './internal-admin.component';
import { DealsComponent } from './deals/deals.component';
import { UsersComponent } from './users/users.component';
import { DealTableComponent } from './deal-table/deal-table.component';
import { ActivityComponent } from './activity/activity.component';
import { ShareholdersComponent } from './shareholders/shareholders.component';

import { CoreModule } from '../core/core.module';
import { MatButtonModule } from '@angular/material/button';
import { IntadmSearchComponent } from './intadm-search/intadm-search.component';
import { InputTextModule } from 'primeng/inputtext';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonModule } from 'primeng/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { UserTableComponent } from './user-table/user-table.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SliderActivityDetailsComponent } from './slider-activity-details/slider-activity-details.component';
import { UserSliderPanelComponent } from './user-slider-panel/user-slider-panel.component';
import { InternalAdminModalComponent } from './internal-admin-modal/internal-admin-modal.component';
import { InternalAdminSnackbarComponent } from './internal-admin-snackbar/internal-admin-snackbar.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ShareholderTableComponent } from './shareholder-table/shareholder-table.component';
import { ShareholderDealUserTableComponent } from './shareholder-deal-user-table/shareholder-deal-user-table.component';

@NgModule({
    declarations: [
        InternalAdminComponent,
        DealsComponent,
        UsersComponent,
        ActivityComponent,
        ShareholdersComponent,
        IntadmSearchComponent,
        DealTableComponent,
        UserTableComponent,
        SliderActivityDetailsComponent,
        UserSliderPanelComponent,
        InternalAdminModalComponent,
        InternalAdminSnackbarComponent,
        ShareholderTableComponent,
        ShareholderDealUserTableComponent,
    ],
    imports: [
        InternalAdminRoutingModule,
        CommonModule,
        InputTextModule,
        MatButtonModule,
        MatIconModule,
        MatSortModule,
        MatTableModule,
        ReactiveFormsModule,
        CoreModule,
        MatCardModule,
        MatCheckboxModule,
        MatDividerModule,
        MatFormFieldModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        MatSnackBarModule,
        MatTooltipModule,
        ButtonModule,
        MatTabsModule,
        MatDialogModule,
        MatMenuModule,
        MatButtonToggleModule,
        MatPaginatorModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        MatChipsModule,
        AsyncPipe,
        BrowserAnimationsModule,
        MatListModule,
        MatProgressSpinnerModule,
    ],
})
export class InternalAdminModule {}
