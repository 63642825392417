import { Component, OnInit } from '@angular/core';
import {
    AdminRequestHelper,
    GetType,
    InternalAdminService,
    ModalConfigData,
    ShareholderDealUser,
    ShareholderRecord,
    ShareholderSearchColumns,
    UserActionEnum,
    UserRecord,
} from '../shared';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfigurationService } from '../../core/services/configuration.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AvailableColumns } from '../shareholder-deal-user-table/shareholder-deal-user-table.columns.types';

@Component({
    selector: 'app-shareholders',
    templateUrl: './shareholders.component.html',
    styleUrls: ['./shareholders.component.scss'],
})
export class ShareholdersComponent implements OnInit {
    public shareholderRecords: ShareholderRecord[];
    public searchValue: string;
    public page: number;
    public pageSize: number;
    public totalRecords: number;
    public fetching = false;
    private requestHelper: AdminRequestHelper;

    // For a single shareholder is selected
    public selectedShareholderId: number;
    public selectedShareholder: ShareholderRecord;
    public shareholderDealUserRecords: ShareholderDealUser[];
    public searchValueDealsUsers: string;
    public pageDealsUsers: number;
    public pageSizeDealsUsers: number;
    public totalRecordsDealsUsers: number;
    public fetchingDealsUsers = true;
    public showRevokeAccess: boolean;
    public displayedColumns: string[] = [
        AvailableColumns.Deal,
        AvailableColumns.User,
        AvailableColumns.Permission,
        AvailableColumns.Actions,
    ];
    private requestHelperDealsUsers: AdminRequestHelper;

    constructor(
        private internalAdminService: InternalAdminService,
        private snackBar: MatSnackBar,
        private matDialog: MatDialog,
        private configurationService: ConfigurationService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.showRevokeAccess = true;
        this.requestHelper = new AdminRequestHelper(GetType.shareholders);
        this.requestHelperDealsUsers = new AdminRequestHelper(GetType.shareholders);
        this.page = this.requestHelper.page;
        this.pageSize = this.requestHelper.size;
        this.pageDealsUsers = this.requestHelperDealsUsers.page;
        this.pageSizeDealsUsers = this.requestHelperDealsUsers.size;

        this.activatedRoute.queryParams.subscribe({
            next: (params: Params) => {
                const isEmpty = Object.keys(params).length === 0;

                if (!params || isEmpty || params['clear']) {
                    this.selectedShareholderId = null;
                    this.selectedShareholder = null;
                    // Set the default sort order to newest shareholders first.
                    this.requestHelper.sortField = 'shareholder';
                    this.requestHelper.sortDir = 'asc';

                    this.fetchShareholders();
                } else if (params['shareholderId']) {
                    this.selectedShareholderId = params['shareholderId'];
                    this.fetchSingleShareholder(this.selectedShareholderId);
                }
            },
            error: (error) => this.handleError(error),
        });
    }

    public performSearch(searchVal: string) {
        this.searchValue = searchVal;

        if (this.searchValue && this.searchValue.trim().length > 0) {
            this.requestHelper.srchVal = this.searchValue;
            this.requestHelper.srchFields = ShareholderSearchColumns;
        } else {
            this.requestHelper.srchVal = null;
            this.requestHelper.srchFields = null;
        }
        // What if they had already initiated a search, paged to page N, then
        // adjusted the search value. Should it be reset to 0 again?
        this.requestHelper.page = 0;

        this.fetchShareholders();
    }

    public performSearchDealsUsers(searchVal: string) {
        this.searchValueDealsUsers = searchVal;

        if (this.searchValueDealsUsers && this.searchValueDealsUsers.trim().length > 0) {
            this.requestHelperDealsUsers.srchVal = this.searchValueDealsUsers;
            this.requestHelperDealsUsers.srchFields = ShareholderSearchColumns;
        } else {
            this.requestHelperDealsUsers.srchVal = null;
            this.requestHelperDealsUsers.srchFields = null;
        }
        // What if they had already initiated a search, paged to page N, then
        // adjusted the search value. Should it be reset to 0 again?
        this.requestHelperDealsUsers.page = 0;

        this.fetchSingleShareholder(this.selectedShareholderId);
    }

    public handlePageEvent(e: PageEvent) {
        this.requestHelper.size = e.pageSize;
        this.requestHelper.page = e.pageIndex;

        this.fetchShareholders();
    }

    public handleSortChange(s: Sort) {
        // Sort object when sorting is turned off: { active: "seller", direction: "" }
        if (s.direction) {
            this.requestHelper.sortField = s.active;
            this.requestHelper.sortDir = s.direction;
            // Reset the page back to 0
            this.requestHelper.page = 0;

            this.fetchShareholders();
        } else {
            // Do not fetch if sorting has been turned off
            this.requestHelper.sortField = null;
            this.requestHelper.sortDir = null;
        }
    }

    public handlePageEventDealsUsers(e: PageEvent) {
        this.requestHelperDealsUsers.size = e.pageSize;
        this.requestHelperDealsUsers.page = e.pageIndex;

        this.fetchSingleShareholder(this.selectedShareholderId);
    }

    public handleSortChangeDealsUsers(s: Sort) {
        // Sort object when sorting is turned off: { active: "seller", direction: "" }
        if (s.direction) {
            this.requestHelperDealsUsers.sortField = s.active;
            this.requestHelperDealsUsers.sortDir = s.direction;
            // Reset the page back to 0
            this.requestHelperDealsUsers.page = 0;

            this.fetchSingleShareholder(this.selectedShareholderId);
        } else {
            // Do not fetch if sorting has been turned off
            this.requestHelperDealsUsers.sortField = null;
            this.requestHelperDealsUsers.sortDir = null;
        }
    }

    public handleShareholderSelected(sid: number) {
        this.fetchSingleShareholder(sid);
    }

    public handleRevokeAccessEvent(user: UserRecord) {
        // TODO
        // this.revokeAccessUser = user;
        // const modalConfig: MatDialogConfig<ModalConfigData> = {
        //     ...this.userModalConfigTemplate,
        //     data: {
        //         title: 'Confirm Revoke User Access',
        //         action: UserActionEnum.revokeAccess,
        //         messageHTML: "This will remove the user's assigned role to this deal.",
        //         id: user.id,
        //     },
        // };
        // this.openModal(modalConfig);
    }

    public handleEditUserClick() {
        // TODO
        // this.showCreateUser = true;
        // this.editMode = true;
        // this.userToEdit = this.selectedUser;
        // this.singleUserEditMode = true;
        // this.requestHelperDeals.uid = this.userToEdit.id;
        // this.internalAdminService.getDeals(this.requestHelperDeals).subscribe({
        //     next: (res: any) => {
        //         this.singleUserEditDealRecords = res.body;
        //     },
        //     error: (error) => this.handleError(error),
        // });
        // this.requestHelperDeals.uid = null;
        // this.internalAdminService.getDeals(this.requestHelperDeals).subscribe({
        //     next: (res: any) => {
        //         this.singleUserAllDealRecords = res.body;
        //     },
        //     error: (error) => this.handleError(error),
        // });
    }

    public shareholderActivityClick(name?: string) {
        this.router.navigate(['/internal-admin/activity'], {
            queryParams: {
                shareholderName: name ? name : this.selectedShareholder.shareholder,
            },
        });
    }

    public shareholderPortalClick(sid?: number) {
        console.log('*** ShareholdersComponent.shareholderDashboardClick()');
    }

    public openInNewTab($event, url: string) {
        // This is to prevent the click event from propagating up.
        $event?.stopPropagation();
        $event?.preventDefault();
        window.open(url, '_blank').focus();
    }

    public getShareholderNsUrl(nsId: number): string {
        const nsCustomerUrl = this.configurationService.envConfig.getValue().nsCustomerUrl;

        return nsCustomerUrl && nsId ? nsCustomerUrl.replace(/INSERTIDHERE/, nsId.toString()) : '';
    }

    public handleUserClickedEvent(uid: number) {
        this.router.navigate(['/internal-admin/users'], {
            queryParams: { userId: uid },
        });
    }

    public handleDealClickedEvent(uid: number) {
        this.router.navigate(['/internal-admin/deals'], {
            queryParams: { dealId: uid },
        });
    }

    /**
     * The "single shareholder" comes from the original list of shareholders,
     * so this search is to retrieve the linked deals and users, I.e. ShareholderDataAccess data.
     * @param sid
     * @private
     */
    private fetchSingleShareholder(sid: number) {
        this.fetchingDealsUsers = true;
        this.fetching = true;
        this.selectedShareholderId = sid;
        // Using '==' instead of '===' as the sid has come in as a string, whereas nsId is a number.
        this.selectedShareholder = this.shareholderRecords?.find((shareholder) => shareholder.nsId == sid);

        this.requestHelperDealsUsers.sid = sid;

        this.internalAdminService.getShareholdersDealsUsers(this.requestHelperDealsUsers).subscribe({
            next: (res: any) => {
                this.fetchingDealsUsers = false;
                this.fetching = false;
                const contentRange = res.headers.get('content-range'); // "1-3/3"
                this.totalRecordsDealsUsers = +contentRange.substring(contentRange.indexOf('/') + 1);
                this.shareholderDealUserRecords = res.body;
            },
            error: (error) => this.handleError(error),
        });
    }

    private fetchShareholders() {
        this.fetching = true;
        this.internalAdminService.getShareholders(this.requestHelper).subscribe({
            next: (res: any) => {
                this.fetching = false;
                const contentRange = res.headers.get('content-range'); // "1-3/3"
                this.totalRecords = +contentRange.substring(contentRange.indexOf('/') + 1);
                this.shareholderRecords = res.body;
            },
            error: (error) => this.handleError(error),
        });
    }

    private handleError(error: HttpErrorResponse): void {
        this.fetching = false;
        this.fetchingDealsUsers = false;
        error['stack'] ? console.error(error['stack']) : console.error(error);

        let msg: string;
        if (error?.status >= 400 && error?.status < 500) {
            msg = error?.error?.message ? error.error.message : 'Server error';
        } else {
            msg = 'Server error';
        }

        this.snackBar.open(msg, undefined, {
            duration: 10000,
        });
    }
}
