<div *ngIf="(fetching)" class="fetching-files">
    <mat-spinner></mat-spinner>
</div>
<div id="brokerage-accounts-container" class="brokerage-accounts-container">
    <div id="header-bar" class="header-bar">
        <div class="header-text-div">
            <span id="header-text" class="header-text">{{brokerageTitle}}</span>
        </div>
        <div id="action-area" class="action-area">
            <div id="upload-div" class="upload-div"
                 (click)="export()"
            >
                <span id="export-span"
                      class="material-symbols-outlined action-icon action-icon-margin"
                >download</span>
                <span class="export-label" data-testid="export-button">Export</span>
            </div>
            <button mat-flat-button
                    id="add-new-button"
                    data-testid="add-new-button"
                    class="add-new-button"
                    [disableRipple]="true"
                    (click)="addRow()"
                    *appAccessCheckSection="addSectionTitle"
            >Add New Account</button>
        </div>
    </div>
    <div class="main">
        <div id="search-bar" class="search-bar">
            <app-search
                [initialValue]="searchValue"
                placeholder="Search By Number, Title, Name"
                data-testid="app-accounts-search"
                (newSearchEvent)="performSearch($event)"
            ></app-search>
        </div>
        <div class="table-container">
            <app-brokerage-table class="brokerage-table"
                                 data-testid="app-brokerage-table"
                                 [accountRecords]="accountRecords"
                                 [searchText]="searchValue"
                                 [initialSort]="{ id: initialSortId, start: initialSortDirection }"
                                 [fetching]="fetching"
                                 (sortEvent)="sortEvent($event)"
                                 (editDeleteEvent)="handleEditDelete($event)"
            ></app-brokerage-table>
        </div>
    </div>
</div>
