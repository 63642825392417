
import * as d from './components';

export const DIRECTIVES = [
  d.SrsaAccordion,
  d.SrsaAlert,
  d.SrsaButton,
  d.SrsaCheckbox,
  d.SrsaChip,
  d.SrsaGrid,
  d.SrsaMenu,
  d.SrsaModal,
  d.SrsaNavbar,
  d.SrsaProfilePhoto,
  d.SrsaSelect,
  d.SrsaSort,
  d.SrsaTextField
];
