import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { BankValidationResponse, BrokerageAccount, ModalConfigData, ModalResult } from '../shared';
import { BrokerageAccountsService } from '../shared/brokerage-accounts.service';
import { BankValidationService } from '../shared/bank-validation.service';
import { ExportConfig } from '../../vendor-payments/shared';

@Component({
    selector: 'app-brokerage-account-modal',
    templateUrl: './brokerage-account-modal.component.html',
    styleUrls: ['./brokerage-account-modal.component.scss'],
})
export class BrokerageAccountModalComponent implements OnInit {
    public form = new FormGroup({
        countryCode: new FormControl<string>('', [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(2),
            Validators.pattern('[a-zA-Z]{2}'),
        ]),
        brokerageName: new FormControl<string>('', [Validators.required, Validators.maxLength(100)]),
        brokerageAccountNumber: new FormControl<string>('', [Validators.required, Validators.maxLength(50)]),
        bankRoutingNumber: new FormControl<string>('', [Validators.required, Validators.maxLength(50)]),
        bankName: new FormControl<string>('', [Validators.maxLength(100)]),
    });
    public exportForm = new FormGroup({
        filename: new FormControl<string>('', [Validators.required, Validators.maxLength(150)]),
    });

    public areChangesSaved: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public modalTooltip: string = 'Export to Excel';
    public submitButtonText: string;
    public validating: boolean = false;
    public validateText: string = 'Validating';
    public validationSuccess: boolean = false;
    public saveWithError: boolean = false;
    public saveWithErrorText: string = 'Save (with Potential Error)';
    public lexisnexisComment: string = null;
    public bankValidationResultsJson: any;
    public bankValidationStatus: string;
    public isDisabled: boolean = false;

    private updateData: boolean = false;
    private BANK_VALIDATION_PASS = 'PASS';
    private BANK_VALIDATION_FAIL = 'FAIL';
    private BANK_VALIDATION_CAUTION = 'CAUTION';
    private exported = false;

    constructor(
        @Optional() public dialogRef: MatDialogRef<BrokerageAccountModalComponent>,
        @Inject(MAT_DIALOG_DATA) public modalData: ModalConfigData,
        private snackBar: MatSnackBar,
        private brokerageAccountsService: BrokerageAccountsService,
        private bankValidationService: BankValidationService,
    ) {
        this.isDisabled = false;

        if (this.modalData.action === 'create' || this.modalData.action === 'edit') {
            this.submitButtonText = 'Save Changes';
        } else {
            this.submitButtonText = 'Delete';
        }

        if (this.modalData.action === 'edit' || this.modalData.action === 'delete') {
            this.form.controls.countryCode.setValue(this.modalData.selected.countryCode);
            this.form.controls.brokerageName.setValue(this.modalData.selected.brokerageAccountName);
            this.form.controls.brokerageAccountNumber.setValue(this.modalData.selected.brokerageAccountNumber);
            this.form.controls.bankRoutingNumber.setValue(this.modalData.selected.bankRoutingNumber);
            this.form.controls.bankName.setValue(this.modalData.selected.bankName);

            if (this.modalData.action === 'delete') {
                this.form.disable();
            }
        }

        // If changes were made to the form values then any previous validation
        // will have to be re-run.
        this.form.valueChanges.subscribe(() => {
            this.validating = false;
            this.saveWithError = false;
            let tempDisabled: boolean = false;

            for (const key in this.form.controls) {
                const control = this.form.controls[key];

                if (control?.errors) {
                    for (const errorKey in control.errors) {
                        // Any error other than "lexisnexis" is a form validation
                        // error and the submit button should be disabled. "lexisnexis"
                        // errors are more warnings that the user can override and
                        // save anyway.
                        if (errorKey != 'lexisnexis') {
                            tempDisabled = true;
                            break;
                        }
                    }
                }

                if (tempDisabled) {
                    break;
                }
            }

            this.isDisabled = tempDisabled;
        });
    }

    public ngOnInit() {
        // Close the dialog if the user presses the Escape key.
        this.dialogRef.keydownEvents().subscribe((event) => {
            if (event?.key === 'Escape') {
                this.closeModal();
            }
        });

        // Close the dialog if the user clicks outside the dialog.
        this.dialogRef.backdropClick().subscribe((e) => {
            if (e != null) {
                // null is getting passed during unit tests; this avoids testing problems.
                this.closeModal();
            }
        });

        if (this.modalData.action === 'export') {
            // Supply a default filename for exports
            const currentDate = new Date();
            let year = currentDate.getFullYear();
            let month = (1 + currentDate.getMonth()).toString().padStart(2, '0');
            let day = currentDate.getDate().toString().padStart(2, '0');
            let hour = currentDate.getHours().toString().padStart(2, '0');
            let min = currentDate.getMinutes().toString().padStart(2, '0');
            this.exportForm.controls.filename.setValue(
                `Common Brokerage Accounts ${month + '-' + day + '-' + year + ' ' + hour + '_' + min}`,
            );
        }
    }

    public closeModal() {
        const resp: ModalResult = {
            updateData: this.updateData,
            areChangesSaved: this.areChangesSaved.getValue(),
            exported: this.exported,
        };
        this.dialogRef.close(resp);
    }

    public lostFocus($event: FocusEvent) {
        if (
            this.modalData.action === 'edit' &&
            this.form.value?.brokerageAccountNumber == this.modalData.selected.brokerageAccountNumber &&
            this.form.value?.bankRoutingNumber == this.modalData.selected.bankRoutingNumber
        ) {
            // If the values match the original value when editing an existing
            // brokerage account then clear any errors.
            this.form.controls.brokerageAccountNumber.setErrors(null);
            this.form.controls.bankRoutingNumber.setErrors(null);
        }

        // Only call to check for uniqueness when editing and either of the account
        // number or routing number has changed, or when creating a new account
        // and both values have been entered.
        if (
            (this.modalData.action === 'edit' &&
                (this.form.value?.brokerageAccountNumber != this.modalData.selected.brokerageAccountNumber ||
                    this.form.value?.bankRoutingNumber != this.modalData.selected.bankRoutingNumber)) ||
            (this.modalData.action === 'create' &&
                this.form.value?.brokerageAccountNumber &&
                this.form.value?.bankRoutingNumber)
        ) {
            this.brokerageAccountsService
                .isUnique(
                    this.modalData.selected?.id,
                    this.form.value?.brokerageAccountNumber,
                    this.form.value?.bankRoutingNumber,
                )
                .subscribe({
                    next: (res) => {
                        if (res && res.isUnique === true) {
                            this.isDisabled = false;
                            // If the values are unique then clear any old errors.
                            this.form.controls.brokerageAccountNumber.setErrors(null);
                            this.form.controls.bankRoutingNumber.setErrors(null);
                        } else {
                            this.isDisabled = true;
                            // The values duplicate an existing brokerage account,
                            // so flag them as in error.
                            this.form.controls.brokerageAccountNumber.setErrors({
                                duplicate: true,
                            });
                            this.form.controls.bankRoutingNumber.setErrors({
                                duplicate: true,
                            });
                        }
                    },
                    error: (error) => {
                        if (error.status === 409) {
                            this.validating = false;
                            this.snackBar.open(
                                'Account Number and Routing Number combination already exists',
                                undefined,
                                {
                                    duration: 10000,
                                    panelClass: ['swatch-red'],
                                },
                            );
                        } else {
                            this.handleError(error);
                        }
                    },
                });
        }
    }

    public async submit() {
        // For Create && Edit the first time in always starts with 'validating',
        // or if the form is dirty
        if (
            !this.validating &&
            !this.saveWithError &&
            (this.modalData.action === 'create' || this.modalData.action === 'edit')
        ) {
            this.validating = true;
            this.isDisabled = true;
        }

        const account: BrokerageAccount = {
            countryCode: this.form.value?.countryCode?.toUpperCase(),
            brokerageAccountName: this.convertToUpper(this.form.value?.brokerageName),
            brokerageAccountNumber: this.form.value?.brokerageAccountNumber,
            bankRoutingNumber: this.form.value?.bankRoutingNumber,
            bankName: this.convertToUpper(this.form.value?.bankName),
        };

        if (this.modalData.action === 'edit' || this.modalData.action === 'delete') {
            account.id = this.modalData.selected.id;
        }

        switch (this.modalData.action) {
            case 'create':
                this.validationSuccess = false;

                if (this.validating) {
                    await this.validateBrokerageAccount(account);
                }

                // If validation was successful or the user clicked on Save with Potential Error,
                // then make the call to persist the record.
                if (this.validationSuccess || this.saveWithError) {
                    this.brokerageAccountsService.add(account).subscribe({
                        next: (res) => this.handleNext(res),
                        error: (error) => this.handleError(error),
                    });
                }

                break;
            case 'edit':
                this.validationSuccess = false;

                if (this.validating) {
                    await this.validateBrokerageAccount(account);
                }

                // If validation was successful or the user clicked on Save with Potential Error,
                // then make the call to persist the record.
                if (this.validationSuccess || this.saveWithError) {
                    this.brokerageAccountsService.update(account).subscribe({
                        next: (res) => this.handleNext(res),
                        error: (error) => this.handleError(error),
                    });
                }

                break;
            case 'delete':
                this.brokerageAccountsService.delete(account.id).subscribe({
                    next: (res) => this.handleNext(res),
                    error: (error) => this.handleError(error),
                });

                break;
            case 'export': {
                const exportConfig: ExportConfig = {
                    filename: this.exportForm.controls?.filename.value,
                    exportas: 'xlsx',
                };

                let filename = exportConfig.filename + '.' + exportConfig.exportas;
                this.brokerageAccountsService.export(exportConfig).subscribe({
                    next: (res: any) => {
                        const errorMessage = `${res?.statusText}`;

                        if (res.status < 200 || res.status > 299) {
                            console.error(
                                'ModalComponent export response =',
                                JSON.stringify(res, ['status', 'statusText', 'body', 'type', 'headers', 'header']),
                            );
                            throw new Error(errorMessage);
                        }

                        let binaryData = [];
                        binaryData.push(res.body);
                        let downloadLink = document.createElement('a');
                        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: res.type }));

                        if (filename) {
                            downloadLink.setAttribute('download', filename);
                        }
                        document.body.appendChild(downloadLink);
                        downloadLink.click();

                        this.areChangesSaved.next(true);
                        this.exported = true;
                        this.closeModal();
                    },
                    error: (error) => this.handleError(error),
                });

                break;
            }
        }

        // If the logic made it to here, then the modal was not closed. Check whether
        // to display a warning message.
        if (this.validating && !this.validationSuccess) {
            this.snackBar.open('Warning: There may be an error in the information you have entered.', null, {
                duration: 5000,
                panelClass: ['swatch-red'],
            });

            this.validating = false;
            this.saveWithError = true;
        }

        this.isDisabled = false;
    }

    private handleNext(res): void {
        this.areChangesSaved.next(true);
        this.updateData = true;
        this.closeModal();
    }

    private handleError(error: HttpErrorResponse): void {
        console.error(error);
        this.snackBar.open('Server error', undefined, {
            duration: 10000,
            panelClass: ['swatch-red'],
        });
    }

    /**
     * Convert what the user typed to upper case, except for articles.
     * @param name
     * @private
     */
    private convertToUpper(name: string): string {
        const skipWords = ['a', 'and', 'or', 'the'];
        const parts = name.split(' ');
        let nowUpper = '';
        if (parts) {
            for (let i = 0; i < parts.length; i++) {
                if (skipWords.indexOf(parts[i].toLowerCase()) < 0) {
                    parts[i] = parts[i].charAt(0).toUpperCase() + (parts[i].length > 1 ? parts[i].substr(1) : '');
                }
            }
            nowUpper = parts.join(' ').trim();
        }
        return nowUpper;
    }

    private async validateBrokerageAccount(account: BrokerageAccount): Promise<void> {
        this.bankValidationStatus = null;

        // Call the ms-bank-validation service & check for errors
        return new Promise(async (resolve, reject) => {
            this.bankValidationService.validateBank(account).subscribe({
                next: (validationResponse: BankValidationResponse[]) => {
                    let res = null;

                    if (validationResponse && Array.isArray(validationResponse) && validationResponse.length > 0) {
                        res = validationResponse[0];
                    }

                    if (res) {
                        this.bankValidationStatus =
                            res.responseStatus?.toUpperCase() ?? res.responseData?.status?.toUpperCase();

                        if (this.bankValidationStatus === this.BANK_VALIDATION_PASS) {
                            this.validationSuccess = true;
                            this.bankValidationResultsJson = null;
                        } else {
                            this.bankValidationResultsJson = res;
                            this.lexisnexisComment = res.responseComment ?? res.responseData?.comment;

                            if (this.lexisnexisComment?.toLowerCase().indexOf('country code') >= 0) {
                                this.form.controls.countryCode.setErrors({
                                    lexisnexis: true,
                                });
                                this.form.controls.countryCode.markAsTouched({ onlySelf: true });
                            } else if (this.lexisnexisComment?.toLowerCase().indexOf('bank code') >= 0) {
                                this.form.controls.bankRoutingNumber.setErrors({
                                    lexisnexis: true,
                                });
                                this.form.controls.bankRoutingNumber.markAsTouched({ onlySelf: true });
                            } else if (this.lexisnexisComment?.toLowerCase().indexOf('account') >= 0) {
                                this.form.controls.brokerageAccountNumber.setErrors({
                                    lexisnexis: true,
                                });
                                this.form.controls.brokerageAccountNumber.markAsTouched({ onlySelf: true });
                            }
                        }
                    } else {
                        this.snackBar.open(
                            'Warning: no response received from the bank validation service.',
                            undefined,
                            {
                                duration: 5000,
                                panelClass: ['swatch-red'],
                            },
                        );
                    }
                    resolve();
                },
                error: (error: HttpErrorResponse) => {
                    this.validating = false;
                    if (this.modalData.action === 'create' || this.modalData.action === 'edit') {
                        this.submitButtonText = 'Save Changes';
                    } else {
                        this.submitButtonText = 'Delete';
                    }

                    this.bankValidationStatus = this.BANK_VALIDATION_FAIL;
                    console.error(error);
                    let errorMessage =
                        error?.error?.message ?? error?.message ?? 'Error received calling bank validation service.';
                    this.snackBar.open(errorMessage, undefined, {
                        duration: 10000,
                        panelClass: ['swatch-red'],
                    });

                    reject(errorMessage);
                },
            });
        });
    }
}
