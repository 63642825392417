import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BfdFile, DownloadList } from '../shared';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';

type TableElement = {
    selected?: boolean;
    file: BfdFile;
};

@Component({
    selector: 'app-bank-file-table',
    templateUrl: './bank-file-table.component.html',
    styleUrls: ['./bank-file-table.component.scss'],
})
export class BankFileTableComponent implements AfterViewInit, OnChanges {
    @Input() bfdFiles: BfdFile[];
    @Input() searchText: string;
    @Input() initialSort: MatSortable;
    @Input() downloadInProgress: boolean;
    @Input() fetching: boolean;
    @ViewChild(MatSort) sort: MatSort;
    @Output() sortEvent = new EventEmitter<Sort>();
    @Output() dirClickEvent = new EventEmitter<BfdFile>();
    @Output() rowsSelected = new EventEmitter<DownloadList>();
    @Output() fileToPreviewEvent = new EventEmitter<BfdFile>();

    public form: FormGroup = new FormGroup({
        filterBy: new FormControl<string>(''),
    });
    public displayedColumns: string[] = ['bfdName', 'bfdExt', 'bfdLastModified', 'bfdSize'];
    public dataSource: MatTableDataSource<TableElement>;
    public tableData: TableElement[] = [];

    public allChecked = false;
    public someComplete = false;
    private downloadList: DownloadList = {};

    constructor(private cdr: ChangeDetectorRef) {
        // Assign the data to the data source for the table to render
        this.dataSource = new MatTableDataSource(this.tableData);
        this.dataSource.sort = this.sort;
    }

    ngAfterViewInit() {
        if (this.sort) {
            this.sort.sort({ ...this.initialSort, disableClear: false });
            this.cdr.detectChanges();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['downloadInProgress']) {
            // {"downloadInProgress":{"previousValue":false,"currentValue":true,"firstChange":false}}
            if (changes['downloadInProgress'].currentValue === true) {
                this.displayedColumns.splice(0, 0, 'downloadFile');
            } else {
                if (this.displayedColumns[0] === 'downloadFile') {
                    this.displayedColumns.splice(0, 1);
                }
                this.downloadList = {};
                this.tableData.forEach((row) => (row.selected = false));
                this.allChecked = false;
            }
        } else if (changes['bfdFiles']) {
            if (this.bfdFiles && Array.isArray(this.bfdFiles) && this.bfdFiles.length > 0) {
                this.tableData = this.bfdFiles.map((row) => {
                    return {
                        selected: false,
                        file: row,
                    };
                });
                this.downloadList = {};
                this.emitSelectedNames();
            } else {
                this.tableData = [];
            }

            // Assign the data to the data source for the table to render
            this.dataSource = new MatTableDataSource(this.tableData);
        }
        this.updateSomeComplete();
    }

    public getLastModifiedUTC(lm: number): string {
        if (lm) {
            return new Date(lm).toUTCString();
        } else {
            return '';
        }
    }

    public handleNameClick(element: BfdFile) {
        if (element.isDir) {
            this.dirClickEvent.emit(element);
        } else {
            this.fileToPreviewEvent.emit(element);
        }
    }

    handleSortChange(s: Sort) {
        this.sortEvent.emit(s);
    }

    public updateSomeComplete(): void {
        const hasChecked = this.tableData.find((row) => !row.file.isDir && row.selected === true);
        const hasUnchecked = this.tableData.find((row) => !row.file.isDir && row.selected === false);

        setTimeout(() => {
            // Avoiding this error: Expression has changed after it was checked
            this.allChecked = hasChecked != null && hasUnchecked == null;
        });

        this.someComplete = hasChecked != null && hasUnchecked != null;
    }

    public setAll($event: boolean) {
        this.tableData.forEach((row) => {
            if (!row.file.isDir) {
                row.selected = $event;

                if (row.selected) {
                    this.downloadList[row.file.name] = {
                        name: row.file.name,
                        selected: $event,
                    };
                }
            }
        });

        this.allChecked = $event;
        if (!this.allChecked) {
            this.downloadList = {};
        }

        this.emitSelectedNames();
        this.updateSomeComplete();
    }

    public selectedChange($event: boolean, tableItem: TableElement) {
        tableItem.selected = $event;

        this.downloadList[tableItem.file.name] = {
            name: tableItem.file.name,
            selected: $event,
        };

        this.emitSelectedNames();
        this.updateSomeComplete();
    }

    private emitSelectedNames() {
        this.rowsSelected.emit(this.downloadList);
    }
}
