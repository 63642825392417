import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../core/guards/auth-guard.service';
import { BrokerageAccountsComponent } from './brokerage-accounts.component';
import { AllowedRoles } from '../core/guards/allowed-roles.guard';
import { OneLoginRoles } from '../core/services/auth.service';

const routes: Routes = [
    {
        path: 'brokerage-accounts',
        component: BrokerageAccountsComponent,
        canActivate: [AuthGuard, AllowedRoles],
        data: {
            AllowedRolesData: {
                oneOfRoles: [OneLoginRoles.cloak, OneLoginRoles.cloakAdmin],
            },
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class BrokerageAccountsRoutingModule {}
