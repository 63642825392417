<div style="height: 100%;">
  <mat-card appearance="outlined">
    <mat-card-header>
      <!-- <mat-card-title>{{editMode ? 'Edit Deal Dashboard User' : 'Create Deal Dashboard User'}}</mat-card-title> -->
      <mat-card-title>{{cardTitle}}</mat-card-title>
      <span id="x-dismiss"
      (click)="closeSlider()"
      class="material-symbols-outlined x-dismiss"
      data-testid="x-dismiss"
      >close
      </span>
    </mat-card-header>
    <mat-card-content>
      <div class="user-fields">
        <form class="user-form" id="addUserForm" [formGroup]="addUserForm" (ngSubmit)="onSubmit(addUserForm)">
          <div *ngIf="!addExistingUserMode" class="name-row">
            <mat-form-field appearance="outline">
              <mat-label>First Name</mat-label>
              <input data-testid="firstName" formControlName="firstName" matInput>
              <mat-error *ngIf="addUserForm.get('firstName').invalid && addUserForm.get('firstName').touched">
                First name required
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Last Name</mat-label>
              <input data-testid="lastName" formControlName="lastName" matInput>
              <mat-error *ngIf="addUserForm.get('lastName').invalid && addUserForm.get('lastName').touched">
                Last name required
              </mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="!addExistingUserMode" class="input-row">
            <mat-form-field appearance="outline">
              <mat-label>Email Address</mat-label>
              <input data-testid="email" formControlName="email" matInput>
              <mat-error *ngIf="addUserForm.get('email').invalid && addUserForm.get('email').touched && !addUserForm.controls.email.hasError('emailAlreadyExists')">
                Please enter a valid email address
              </mat-error>
              <mat-error *ngIf="addUserForm.controls.email.hasError('emailAlreadyExists')">
                User already exists within the system
              </mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="!addExistingUserMode" class="phone-row">
            <mat-form-field appearance="outline">
              <mat-label>Phone Number (Optional)</mat-label>
              <input data-testid="phoneNumber" formControlName="phoneNumber" matInput>
            </mat-form-field>
            <a href="https://support.acquiom.com/hc/en-us/articles/9570074017044-Understanding-Roles-and-Permissions" target="_blank">
              <button mat-icon-button type="button">
                <mat-icon aria-hidden="false" aria-label="help icon">help_outline</mat-icon>
              </button>
            </a>
          </div>
          <div *ngIf="addExistingUserMode" class="phone-row">
            <mat-form-field appearance="outline">
              <mat-label>Existing User</mat-label>
              <mat-select (selectionChange)="onAddExistingUserChange($event)" formControlName="existingUser">
                <mat-select-trigger>
                  {{selectedExistingUser?.firstName}} {{selectedExistingUser?.lastName}}
                </mat-select-trigger>
                  <mat-option *ngFor='let user of addExistingUserRecords;'
                              [value]="user.id"><div class="role-options">{{user.firstName}} {{user.lastName}}</div>
                  <div class="role-description">{{user.email}}</div>
                  </mat-option>
              </mat-select>            </mat-form-field>
            <a *ngIf="addExistingUserMode" href="https://support.acquiom.com/hc/en-us/articles/9570074017044-Understanding-Roles-and-Permissions" target="_blank">
              <button mat-icon-button type="button">
                <mat-icon aria-hidden="false" aria-label="help icon">help_outline</mat-icon>
              </button>
            </a>
          </div>
          <div class="role-row">
            <mat-form-field appearance="outline">
              <mat-label>Role</mat-label>
              <mat-select data-testid="role" (selectionChange)="onRoleChange($event)" formControlName="role">
                <mat-select-trigger>
                  {{currentRole?.name}}
                </mat-select-trigger>
                  <mat-option *ngFor='let role of roleRecords;'
                              [value]="role.id"><div class="role-options">{{role.name}}</div>
                  <div class="role-description">{{role.description}}</div>
                  </mat-option>
              </mat-select>
              <mat-error *ngIf="addUserForm.get('role').invalid && addUserForm.get('role').touched">
                Role required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="input-row">
            <mat-form-field [floatLabel]="allUsersEditMode || singleUserEditMode || addExistingUserMode ? 'always' : 'auto'" #dealsMatSelect appearance="outline">
              <mat-label>Deal Access</mat-label>
              <mat-select *ngIf="!dealRecords" data-testid="deal" [disabled]="isDisabled" formControlName="deal">
                <mat-option [value]="selectedDeal?.name">{{selectedDeal?.name}}</mat-option>
              </mat-select>
              <div *ngIf="dealRecords">
                <mat-chip-grid #chipGrid aria-label="Deal access selection">
                    <mat-chip-row *ngFor="let deal of deals; trackBy:identify" (removed)="remove(deal)">
                      {{deal}}
                      <button matChipRemove [attr.aria-label]="'remove ' + deal">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip-row>
                </mat-chip-grid>
                <input placeholder="New Deal..." #dealInput matInput formControlName="deal"
                  data-testid="multiple-deals" [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"/>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                    <mat-option *ngFor="let deal of filteredDeals | async; trackBy:identify" [value]="deal">{{deal}}</mat-option>
                </mat-autocomplete>
              </div>
            </mat-form-field>
          </div>
        </form>
      </div>
    </mat-card-content>
    <div class="actions-div">
      <div class="inner-actions-div">
        <div *ngIf="!editMode && !addExistingUserMode" class="add-existing-user-button">
          <button *appAccessCheckSection="permissionAddUser" data-testid="add-existing-user-button" (click)="onAddExistingUserClick()" mat-button>Add Existing User</button>
        </div>
        <div *ngIf="addExistingUserMode" class="add-existing-user-button">
          <button *appAccessCheckSection="permissionAddUser" data-testid="new-user-button" (click)="onNewUserClick()" mat-button>New User</button>
        </div>
        <div *ngIf="editMode && !allUsersEditMode && !singleUserEditMode" class="delete-user-rel-button">
          <button *appAccessCheckSection="permissionRemoveUser" data-testid="delete-user-rel-button" (click)="removeUserFromDeal()" mat-button>Remove from Deal</button>
        </div>
        <mat-card-actions>
          <div *ngIf="!editMode" class="save-actions">
            <button *appAccessCheckSection="permissionAddUser"
                    data-testid="save-and-add-button"
                    [disabled]="!addUserForm.valid || !selectedCalled && addExistingUserMode && !selectedDeal"
                    (click)="onSubmit(addUserForm, 'save and add')"
                    class="save-and-add-button"
                    mat-stroked-button
            >Save & Add Another</button>
            <button *appAccessCheckSection="permissionAddUser"
                    data-testid="save-button"
                    [disabled]="!addUserForm.valid || !selectedCalled && addExistingUserMode && !selectedDeal"
                    (click)="onSubmit(addUserForm, 'save')"
                    class="save-button" mat-button
            >Save</button>
          </div>
          <div *ngIf="editMode && canAddUser()" class="edit-actions">
            <button data-testid="edit-cancel-button" class="cancel-button" (click)="closeSlider()" mat-stroked-button>Cancel</button>
            <button *ngIf="!allUsersEditMode && !singleUserEditMode" data-testid="edit-save-button" [disabled]="!addUserForm.dirty" class="edit-save-button" (click)="onSubmit(addUserForm)" mat-button>Save</button>
            <button *ngIf="allUsersEditMode || singleUserEditMode" data-testid="all-users-edit-save-button" [disabled]="removeCalled ? !removeCalled : !addUserForm.dirty" class="edit-save-button" (click)="handleAllUsersSave(addUserForm)" mat-button>Save</button>
          </div>
        </mat-card-actions>
      </div>
    </div>
  </mat-card>
</div>
