<div *ngIf="!(isDoneLoading$ | async)" class="app-loading">
    <mat-spinner></mat-spinner>
    <p>Loading... Please wait while we connect with One Login.</p>
</div>
<div *ngIf="(isTokenExpiring | async) && (isAuthenticated$ | async)" class="auth-expiring_modal_bg">
    <mat-card appearance="outlined">
        <div class="session-expiring">
            <mat-card-title>Your Session is Expiring!</mat-card-title>
            <mat-card-subtitle>Time Remaining: <b>{{sessionTime | async}}</b></mat-card-subtitle>
            <mat-card-content *ngIf="(refreshLoginFailed | async) !== true">Would you like to continue your current session by staying logged in?</mat-card-content>
            <mat-card-content *ngIf="(refreshLoginFailed | async) === true">Renewing your session failed. You will need to log out and then log back in.</mat-card-content>
            <mat-card-actions class="card-actions">
                <button mat-button (click)="refreshLogin()" *ngIf="(refreshLoginFailed | async) !== true">Continue</button>
                <button mat-button (click)="logout()">Log Out</button>
            </mat-card-actions>
        </div>
    </mat-card>
</div>
<div class="app">
    <app-armory-header></app-armory-header>
    <mat-sidenav-container autosize class="app__content">
        <mat-sidenav mode="side" opened>
            <app-armory-sidebar></app-armory-sidebar>
        </mat-sidenav>
        <mat-sidenav-content>
            <main>
                <router-outlet></router-outlet>
                <div *ngIf="error" class="app__error">
                    <p>{{ error.message }}</p>
                </div>
            </main>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
