import { Component, OnInit } from '@angular/core';
import { NavigationHelperService, SubmenuOptions } from '../core/services/navigation-helper.service';

@Component({
    selector: 'app-internal-admin',
    templateUrl: './internal-admin.component.html',
    styleUrls: ['./internal-admin.component.scss'],
})
export class InternalAdminComponent implements OnInit {
    private submenu: SubmenuOptions = {
        parentPath: 'internal-admin',
        appName: 'internalAdmin', // This has to match the AppRegistry key.
        childPaths: [
            {
                path: 'deals',
                displayName: 'Deals',
            },
            {
                path: 'users',
                displayName: 'Users',
            },
            {
                path: 'shareholders',
                displayName: 'Shareholders',
            },
            {
                path: 'activity',
                displayName: 'Activity',
            },
        ],
    };

    constructor(private navigationHelperService: NavigationHelperService) {}

    ngOnInit() {
        this.navigationHelperService.addSubmenu(this.submenu);
    }
}
