import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search/search.component';
import { FilterComponent } from './filter/filter.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DsComponentsModule } from '../ds-components/ds-components.module';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
    declarations: [SearchComponent, FilterComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        MatInputModule,
        MatDividerModule,
        ReactiveFormsModule,
        DsComponentsModule,
        DropdownModule,
        FormsModule,
    ],
    exports: [SearchComponent, FilterComponent],
})
export class SharedModule {}
