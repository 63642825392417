export enum DealAvailableColumns {
    NsId = 'nsId',
    Name = 'name',
    ProjectName = 'projectName',
    Seller = 'seller',
    Buyer = 'buyer',
    Role = 'role',
    ClosingDate = 'closingDt',
    Actions = 'actions',
}
