import { UntypedFormControl } from '@angular/forms';

export function requiredFileType(type: string) {
    return (control: UntypedFormControl) => {
        const file = control.value;
        if (file) {
            const extension = file.name.split('.')[1].toLowerCase();
            if (type.toLowerCase() !== extension.toLowerCase()) {
                return {
                    requiredFileType: true,
                };
            }
            return null;
        }
        return null;
    };
}
