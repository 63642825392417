import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    IntAdmPermissions,
    UserActionConfig,
    UserActionEnum,
    DealUserAccess,
    UserHelperService,
    UserRecord,
} from '../shared';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AccessTypes, AuthService } from '../../core/services/auth.service';
import { UserAvailableColumns } from './user-table.column.types';
import { Router } from '@angular/router';

@Component({
    selector: 'app-user-table',
    templateUrl: './user-table.component.html',
    styleUrls: ['./user-table.component.scss'],
})
export class UserTableComponent implements AfterViewInit, OnChanges {
    @Input() userRecords: UserRecord[];
    @Input() dealUserAccessRecords: DealUserAccess[];
    @Input() searchText: string;
    @Input() initialSort: MatSortable;
    @Input() page: number;
    @Input() pageSize: number;
    @Input() totalRecords: number = 0;
    @Input() showCreateUser: boolean;
    @Input() showRevokeAccess: boolean;
    @Input() fetching: boolean;
    @Input() set displayedColumns(value: string[]) {
        if (value && value.length > 0) {
            this._displayedColumns = value;
        } else {
            this._displayedColumns = this.defaultColumns;
        }
    }

    @Output() pageEvent = new EventEmitter<PageEvent>();
    @Output() sortEvent = new EventEmitter<Sort>();
    @Output() userToEditEvent = new EventEmitter<UserRecord>();
    @Output() userActionNav = new EventEmitter<UserActionConfig>();
    @Output() revokeAccessEvent = new EventEmitter<UserRecord>();

    @ViewChild(MatSort) sort: MatSort;

    public dataSource: MatTableDataSource<UserRecord>;
    public defaultColumns: string[] = [
        UserAvailableColumns.Email,
        UserAvailableColumns.User,
        UserAvailableColumns.Status,
        UserAvailableColumns.Products,
        UserAvailableColumns.Actions,
    ];
    public get displayedColumns(): string[] {
        return this._displayedColumns;
    }
    public iconWasClicked = false;
    public selectedIndex = null;
    public permissionBlockUser = IntAdmPermissions.userBlock;
    public permissionUnblockUser = IntAdmPermissions.userUnblock;
    public permissionEditUser = IntAdmPermissions.userEdit;
    public permissionDeactivateUser = IntAdmPermissions.userDeactivate;
    public permissionSendPwResetUser = IntAdmPermissions.userSendPwReset;
    public permissionResendInvUser = IntAdmPermissions.userResendInv;

    private _displayedColumns: string[];

    constructor(
        private userHelperService: UserHelperService,
        private authService: AuthService,
        private router: Router,
        private cdr: ChangeDetectorRef,
    ) {
        // Assign the data to the data source for the table to render
        this.dataSource = new MatTableDataSource(this.userRecords);
    }

    ngAfterViewInit() {
        if (this.sort) {
            if (this.initialSort.id === 'firstName') {
                this.initialSort.id = 'user';
            }
            this.sort.sort({ ...this.initialSort, disableClear: false });
            this.dataSource.sort = this.sort;
            this.cdr.detectChanges();
        }
    }

    ngOnChanges(_: SimpleChanges): void {
        this.dataSource = new MatTableDataSource(this.userRecords);
    }

    handlePageEvent(e: PageEvent) {
        this.pageEvent.emit(e);
    }

    handleSortChange(s: Sort) {
        if (s.active === 'user') {
            s.active = 'firstName';
        }
        this.sortEvent.emit(s);
    }

    handleUserSelected(u: UserRecord) {
        this.router.navigate(['/user-access/users'], {
            queryParams: { userId: u.id },
        });
    }

    public openMenu($event: MouseEvent, index: number) {
        this.iconWasClicked = true;
        this.selectedIndex = index;
        $event?.stopPropagation();
        $event?.preventDefault();
    }

    public handleMenuClosedEvent($event, index: number) {
        this.iconWasClicked = false;
        this.selectedIndex = -1;
        $event?.stopPropagation();
        $event?.preventDefault();
    }

    public hideMenu() {
        this.iconWasClicked = false;
        this.selectedIndex = -1;
    }

    public canEditUser(status): boolean {
        const canEditUser = this.userHelperService.canEditUser(status);
        const perm = this.authService.checkAccess(this.permissionEditUser) === AccessTypes.FULL;
        return canEditUser && perm;
    }

    public canViewUserActivity(status): boolean {
        return !!this.userHelperService.canViewUserActivity(status);
    }

    public canResendInvitation(status): boolean {
        const canResendInvitation = !!this.userHelperService.canResendInvitation(status);
        const perm = this.authService.checkAccess(this.permissionResendInvUser) === AccessTypes.FULL;
        return canResendInvitation && perm;
    }

    public canSendPwReset(status): boolean {
        const canSendPwReset = !!this.userHelperService.canSendPwReset(status);
        const perm = this.authService.checkAccess(this.permissionSendPwResetUser) === AccessTypes.FULL;
        return canSendPwReset && perm;
    }

    public canBlock(status: string): boolean {
        const canBlock = this.userHelperService.canBlock(status);
        const perm = this.authService.checkAccess(this.permissionBlockUser) === AccessTypes.FULL;
        return canBlock && perm;
    }

    public canUnblock(status: string): boolean {
        const canUnblock = this.userHelperService.canUnblock(status);
        const perm = this.authService.checkAccess(this.permissionUnblockUser) === AccessTypes.FULL;
        return canUnblock && perm;
    }

    public canDeactivate(status): boolean {
        const canDeactivate = !!this.userHelperService.canDeactivate(status);
        const perm = this.authService.checkAccess(this.permissionDeactivateUser) === AccessTypes.FULL;
        return canDeactivate && perm;
    }

    public handleEditUserClick(element) {
        this.userToEditEvent.emit(element);
        this.hideMenu();
    }

    public handleUserActivityClick(index) {
        this.hideMenu();

        this.userActionNav.emit({
            arrayIndex: index,
            action: UserActionEnum.activity,
        });
    }

    public handleResendInvitation(index) {
        this.hideMenu();
        this.userActionNav.emit({
            arrayIndex: index,
            action: UserActionEnum.resendInv,
        });
    }

    public handleSendPwResetClick(index) {
        this.hideMenu();
        this.userActionNav.emit({
            arrayIndex: index,
            action: UserActionEnum.sendPwReset,
        });
    }

    public handleBlockClick(index) {
        this.hideMenu();
        this.userActionNav.emit({
            arrayIndex: index,
            action: UserActionEnum.block,
        });
    }

    public handleUnblockClick(index) {
        this.hideMenu();
        this.userActionNav.emit({
            arrayIndex: index,
            action: UserActionEnum.unblock,
        });
    }

    public handleDeactivateClick(index) {
        this.hideMenu();
        this.userActionNav.emit({
            arrayIndex: index,
            action: UserActionEnum.deactivate,
        });
    }

    public handleRevokeAccessClick(element) {
        this.hideMenu();
        this.revokeAccessEvent.emit(element);
    }
}
