<div *ngIf="(fetching)" class="fetching-files">
    <mat-spinner></mat-spinner>
</div>
<div class="host-div">
    <div id="vendor-payments-container" class="vendor-payments-container">
        <div class="header-text-div">
            <span id="header-text" class="header-text">Vendors</span>
        </div>
        <div class="main">
            <mat-tab-group
                class="tab-group"
                mat-align-tabs="start"
                animationDuration="0ms"
                mat-stretch-tabs="false"
                [(selectedIndex)]="selectedTabIndex"
                (selectedTabChange)="onTabChanged();"
                [disableRipple]="true"
                fitInkBarToContent="true"
            >
                <mat-tab label="{{VendorTabNames.IN_REVIEW}}" class="tab-template">
                    <ng-container
                        *ngTemplateOutlet="inReviewTabTemplate"
                    ></ng-container>
                </mat-tab>
                <mat-tab label="{{VendorTabNames.APPROVED}}" class="tab-template">
                    <ng-container
                        *ngTemplateOutlet="approvedTabTemplate"
                    ></ng-container>
                </mat-tab>
                <mat-tab label="{{VendorTabNames.REJECTED}}" class="tab-template">
                    <ng-container
                        *ngTemplateOutlet="rejectedTabTemplate"
                    ></ng-container>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>
<div id="slideout" class="slideout" [class.show-details]="showDetails" data-testid="details-slideout">
    <app-slider-panel
        [isOpen]="showDetails"
        [vendorConfig]="vendorConfig"
        (closeSliderEvent)="closeSliderPanel()"
        (rejectVendorEvent)="rejectInReviewVendor($event)"
        (submitVendorEvent)="submitVendorDetails($event)"
        (addNoteEvent)="addVendorNote($event)"
        (hideVendorEvent)="hideInReviewVendor($event)"
    ></app-slider-panel>
</div>

<ng-template matTabContent #inReviewTabTemplate>
    <div id="in-review-table" class="approved-table-template" data-testid="approved-table-template">
        <div class="search-bar review-search-bar">
            <app-search
                placeholder="Search By Number, Title, Name, etc."
                [initialValue]="vendorSearchHelpers[TAB_IN_REVIEW].search"
                data-testid="app-search"
                (newSearchEvent)="performSearch($event)"
            ></app-search>
            <app-filter
                [filterConfig]="filterConfig"
                [selected]="selectedFilter"
                data-testid="app-filter"
                (filterEvent)="filterTable($event)"
            ></app-filter>
            <div *ngIf="selectedFilter?.viewValue === DEAL_COUNT_LABEL" class="deal-count-div">
                <div id="deal-input-div" class="deal-input-div">
                    <span class="deal-count-label">Deal Count</span>
                    <div class="deal-count-wrapper">
                        <form class="vendor-form" [formGroup]="form">
                            <mat-form-field>
                                <input id="deal-count-filter-input"
                                       class="deal-count-input"
                                       matInput
                                       formControlName="dealCount"
                                       #dealCountInput
                                       type="text"
                                       (keyup)="onDealCountChange($event.key)"
                                       data-testid="deal-count-filter-input"
                                />
                            </mat-form-field>
                        </form>
                    </div>
                </div>
            </div>
            <div class="my-review-only-div">
                <mat-checkbox (change)="myReviewOnlySelectedChange($event.checked)"
                              [checked]="myReviewOnlySelected"
                ></mat-checkbox>
                <span>Pending My Review Only</span>
            </div>
        </div>
        <div id="review-count-bar" class="count-bar" data-testid="count-bar-div">
            You have
            <span class="count-bar-count">
                {{vendorSearchHelpers[TAB_IN_REVIEW].vendorRecordsFiltered && vendorSearchHelpers[TAB_IN_REVIEW].vendorRecordsFiltered.length > 0 ?
                    vendorSearchHelpers[TAB_IN_REVIEW].vendorRecordsFiltered.length :
                    'no'}}
            </span>
            Vendor Accounts in this list.
        </div>
        <div class="table-container">
            <app-vendor-table id="inreview-table"
                              class="vendor-table"
                              data-testid="app-vendor-table"
                              [selectedTabIndex]="selectedTabIndex"
                              [vendorRecords]="vendorSearchHelpers[TAB_IN_REVIEW].vendorRecordsFiltered"
                              [displayedColumns]="inReviewColumns"
                              [searchText]="vendorSearchHelpers[TAB_IN_REVIEW].search"
                              [fetching]="fetching"
                              [initialSort]="{ id: vendorSearchHelpers[TAB_IN_REVIEW].sortId, start: vendorSearchHelpers[TAB_IN_REVIEW].sortDir }"
                              (sortEvent)="handleSortChange($event, 0)"
                              (showDetailsEvent)="openSlideoutPanel($event)"
                              (reviewEvent)="handleReviewEvent($event)"
            ></app-vendor-table>
        </div>
    </div>
</ng-template>

<ng-template matTabContent #approvedTabTemplate>
    <div id="approved-table-div" class="approved-table-template" data-testid="approved-table-template">
        <div class="search-bar">
            <app-search
                [initialValue]="vendorSearchHelpers[TAB_APPROVED].search"
                data-testid="app-search"
                (newSearchEvent)="performSearch($event)"></app-search>
            <div class="action-bar">
                <button mat-flat-button
                        id="add-new-button"
                        data-testid="add-new-button"
                        class="add-new-button"
                        (click)="addRow()"
                        *appAccessCheckSection="ADD_SECTION_TITLE"
                >Add New Vendor</button>
                <div id="action-area" class="action-area">
                    <div *ngIf="isApprovedTab()" id="upload-div" class="upload-div"
                            [class.export-label-inprogress]="exportVendorsInProgress"
                            (click)="exportVendors()"
                    >
                        <span id="export-span"
                                class="material-symbols-outlined action-icon action-icon-margin"
                        >download</span>
                        <span class="export-label" data-testid="export-button">{{exportVendorsInProgress ? EXPORT_VENDOR_SELECTED : EXPORT_VENDOR_DEFAULT}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div id="approved-count-bar" class="count-bar" data-testid="count-bar-div">
            You have
            <span class="count-bar-count">
                {{vendorSearchHelpers[TAB_APPROVED].vendorRecordsFiltered && vendorSearchHelpers[TAB_APPROVED].vendorRecordsFiltered.length > 0 ?
                    vendorSearchHelpers[TAB_APPROVED].vendorRecordsFiltered.length :
                    'no'}}
            </span>
            Vendor Accounts in this list.
        </div>
        <div class="table-container">
            <app-vendor-table id="approved-table"
                              class="vendor-table"
                              data-testid="app-vendor-table"
                              [selectedTabIndex]="selectedTabIndex"
                              [vendorRecords]="vendorSearchHelpers[TAB_APPROVED].vendorRecordsFiltered"
                              [displayedColumns]="approvedColumns"
                              [searchText]="vendorSearchHelpers[TAB_APPROVED].search"
                              [fetching]="fetching"
                              [initialSort]="{ id: vendorSearchHelpers[TAB_APPROVED].sortId, start: vendorSearchHelpers[TAB_APPROVED].sortDir }"
                              (sortEvent)="handleSortChange($event, 1)"
                              (editRejectEvent)="handleEditReject($event)"
                              (showDetailsEvent)="openSlideoutPanel($event)"
                              (selectedIdsEvent)="idsForExport($event)"
            ></app-vendor-table>
        </div>
    </div>
</ng-template>

<ng-template matTabContent #rejectedTabTemplate>
    <div id="rejected-table-div" class="rejected-table-template" data-testid="rejected-table-template">
        <div id="rejected-search-bar" class="search-bar">
            <app-search
                [initialValue]="vendorSearchHelpers[TAB_REJECTED].search"
                data-testid="app-search"
                (newSearchEvent)="performSearch($event)"></app-search>
        </div>
        <div id="rejected-count-bar" class="count-bar" data-testid="count-bar-div">
            You have
            <span class="count-bar-count">
                {{vendorSearchHelpers[TAB_REJECTED].vendorRecordsFiltered && vendorSearchHelpers[TAB_REJECTED].vendorRecordsFiltered.length > 0 ?
                    vendorSearchHelpers[TAB_REJECTED].vendorRecordsFiltered.length :
                    'no'}}
            </span>
            Vendor Accounts in this list.
        </div>
        <div class="table-container">
            <app-vendor-table id="rejected-table"
                              class="vendor-table"
                              data-testid="app-vendor-table"
                              [selectedTabIndex]="selectedTabIndex"
                              [vendorRecords]="vendorSearchHelpers[TAB_REJECTED].vendorRecordsFiltered"
                              [displayedColumns]="rejectedColumns"
                              [searchText]="vendorSearchHelpers[TAB_REJECTED].search"
                              [fetching]="fetching"
                              [initialSort]="{ id: vendorSearchHelpers[TAB_REJECTED].sortId, start: vendorSearchHelpers[TAB_REJECTED].sortDir }"
                              (sortEvent)="handleSortChange($event, 2)"
                              (showDetailsEvent)="openSlideoutPanel($event)"
            ></app-vendor-table>
        </div>
    </div>
</ng-template>
