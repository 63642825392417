import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ModalConfigData, VendorService } from '../shared';
import { convertToUpper } from '../../core/utils/convert-words-to-first-letter-uppercase';
import { formatDate } from '@angular/common';

@Component({
    selector: 'app-vendor-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
    public form: FormGroup = new FormGroup({
        // Payee Information
        payeeName: new FormControl<string>('', [Validators.required, Validators.maxLength(150)]),
        subsidiary: new FormControl<string>(''), // Read only
        email: new FormControl<string>('', [Validators.maxLength(150), Validators.pattern(/^.*@.*\..*$/)]),
        shrhldId: new FormControl<string>('', [Validators.required]),
        createdDate: new FormControl<string>(''), // Read only

        // Banking Details
        accountTitle: new FormControl<string>('', [Validators.required, Validators.maxLength(150)]),
        accountNumber: new FormControl<string>(''), // Read only
        currency: new FormControl<string>('', [
            Validators.minLength(3),
            Validators.maxLength(3),
            Validators.pattern('[a-zA-Z]{3}'),
        ]),
        bankCountry: new FormControl<string>('', [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(3),
            Validators.pattern('[a-zA-Z]{3}'),
        ]),
        abaSwift: new FormControl<string>('', [Validators.required, Validators.maxLength(50)]),
        sortCode: new FormControl<string>('', [Validators.maxLength(35)]),
        bankName: new FormControl<string>('', [Validators.required, Validators.maxLength(150)]),
        ffcName: new FormControl<string>('', [Validators.maxLength(150)]),
        ffcAcct: new FormControl<string>('', [Validators.maxLength(50)]),
        imbSwiftbic: new FormControl<string>('', [Validators.maxLength(100)]),
        imbBankname: new FormControl<string>('', [Validators.maxLength(150)]),

        // Payee Address
        payeeAddress1: new FormControl<string>('', [Validators.maxLength(100)]),
        payeeAddress2: new FormControl<string>('', [Validators.maxLength(100)]),
        payeeAddress3: new FormControl<string>('', [Validators.maxLength(100)]),
        payeeCity: new FormControl<string>('', [Validators.maxLength(100)]),
        payeeState: new FormControl<string>('', [
            // payeeState is not required, but if entered must be 2 characters.
            Validators.pattern('^$|[a-zA-Z]{2}'),
        ]),
        payeePostalCode: new FormControl<string>('', [Validators.maxLength(10)]),
        payeeCountry: new FormControl<string>('', [Validators.maxLength(100)]),

        // These are not displayed yet.
        // payApprovedDate: new FormControl<string>('', [Validators.maxLength(100)]),
        // Vendor Payment Screening
        // vpsDt: new FormControl<string>('', []),
        // vpsReq: new FormControl<string>('', []),
        // vpsStatus: new FormControl<string>('', []),

        vendorNote: new FormControl<string>('', [Validators.required, Validators.maxLength(1000)]),
    });

    public areChangesSaved: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public submitButtonText: string;

    private updateData: boolean = false;

    constructor(
        @Optional() public dialogRef: MatDialogRef<ModalComponent>,
        @Inject(MAT_DIALOG_DATA) public modalData: ModalConfigData,
        private snackBar: MatSnackBar,
        private vendorService: VendorService,
    ) {}

    public ngOnInit() {
        // Close the dialog if the user presses the Escape key.
        this.dialogRef.keydownEvents().subscribe((event) => {
            if (event?.key === 'Escape') {
                this.closeModal();
            }
        });

        // Close the dialog if the user clicks outside the dialog.
        this.dialogRef.backdropClick().subscribe((e) => {
            if (e != null) {
                // null is getting passed during unit tests; this avoids testing problems.
                this.closeModal();
            }
        });

        if (this.modalData.action === 'edit') {
            this.submitButtonText = 'Submit for Review';
        } else {
            this.submitButtonText = '';
        }

        if (this.modalData.action === 'edit') {
            // Payee Information
            this.form.controls.payeeName.setValue(this.modalData.selected.payeeName);
            this.form.controls.subsidiary.setValue(this.modalData.selected.subsidiary);
            this.form.controls.email.setValue(this.modalData.selected.email);
            this.form.controls.shrhldId.setValue(this.modalData.selected.shrhldId);
            this.form.controls.createdDate.setValue(
                formatDate(this.modalData.selected.createdDate, 'MM/dd/yyyy', 'en'),
            );

            // Banking Details
            this.form.controls.accountTitle.setValue(this.modalData.selected.accountTitle);
            this.form.controls.accountNumber.setValue(this.modalData.selected.accountNumber);
            this.form.controls.currency.setValue(this.modalData.selected.currency);
            this.form.controls.bankCountry.setValue(this.modalData.selected.bankCountry);
            this.form.controls.abaSwift.setValue(this.modalData.selected.abaSwift);
            this.form.controls.sortCode.setValue(this.modalData.selected.sortCode);
            this.form.controls.bankName.setValue(this.modalData.selected.bankName);
            this.form.controls.ffcName.setValue(this.modalData.selected.ffcName);
            this.form.controls.ffcAcct.setValue(this.modalData.selected.ffcAcct);
            this.form.controls.imbSwiftbic.setValue(this.modalData.selected.imbSwiftbic);
            this.form.controls.imbBankname.setValue(this.modalData.selected.imbBankname);

            // Payee Address
            this.form.controls.payeeAddress1.setValue(this.modalData.selected.payeeAddress1);
            this.form.controls.payeeAddress2.setValue(this.modalData.selected.payeeAddress2);
            this.form.controls.payeeAddress3.setValue(this.modalData.selected.payeeAddress3);
            this.form.controls.payeeCity.setValue(this.modalData.selected.payeeCity);
            this.form.controls.payeeState.setValue(this.modalData.selected.payeeState);
            this.form.controls.payeePostalCode.setValue(this.modalData.selected.payeePostalCode);
            this.form.controls.payeeCountry.setValue(this.modalData.selected.payeeCountry);

            // These are not displayed yet.
            // this.form.controls.payApprovedDate.setValue(this.modalData.selected.payApprovedDate);
            // // Vendor Payment Screening
            // this.form.controls.vpsDt.setValue(this.modalData.selected.vpsDt);
            // this.form.controls.vpsReq.setValue(this.modalData.selected.vpsReq);
            // this.form.controls.vpsStatus.setValue(this.modalData.selected.vpsStatus);
        }
    }

    public closeModal() {
        this.dialogRef.close({ updateData: this.updateData, areChangesSaved: this.areChangesSaved.getValue() });
    }

    public isSubmitDisabled(): boolean {
        return !this.form.valid;
    }

    public async submit() {
        // Payee Information
        this.modalData.selected.payeeName = convertToUpper(this.form.value?.payeeName);
        // subsidiary = read only
        this.modalData.selected.email = this.form.value?.email?.trim();
        this.modalData.selected.shrhldId = this.form.value?.shrhldId;
        // createdDate = read only

        // Banking Details
        this.modalData.selected.accountTitle = convertToUpper(this.form.value?.accountTitle?.trim());
        // accountNumber = read only

        this.modalData.selected.currency = this.form.value?.currency?.trim()?.toUpperCase();
        this.modalData.selected.bankCountry = this.form.value?.bankCountry?.trim()?.toUpperCase();
        this.modalData.selected.abaSwift = this.form.value?.abaSwift?.trim()?.toUpperCase();
        this.modalData.selected.sortCode = this.form.value?.sortCode?.trim()?.toUpperCase();
        this.modalData.selected.bankName = convertToUpper(this.form.value?.bankName);
        this.modalData.selected.ffcName = convertToUpper(this.form.value?.ffcName?.trim());
        this.modalData.selected.ffcAcct = this.form.value?.ffcAcct?.trim()?.toUpperCase();
        this.modalData.selected.imbSwiftbic = this.form.value?.imbSwiftbic?.trim()?.toUpperCase();
        this.modalData.selected.imbBankname = convertToUpper(this.form.value?.imbBankname);

        // Payee Address
        this.modalData.selected.payeeAddress1 = this.form.value?.payeeAddress1?.trim();
        this.modalData.selected.payeeAddress2 = this.form.value?.payeeAddress2?.trim();
        this.modalData.selected.payeeAddress3 = this.form.value?.payeeAddress3?.trim();
        this.modalData.selected.payeeCity = convertToUpper(this.form.value?.payeeCity?.trim());
        this.modalData.selected.payeeState = this.form.value?.payeeState?.trim()?.toUpperCase();
        this.modalData.selected.payeePostalCode = this.form.value?.payeePostalCode?.trim();
        this.modalData.selected.payeeCountry = convertToUpper(this.form.value?.payeeCountry);

        // payApprovedDate = read only
        // vpsStatus = read only
        // vpsReq = read only
        // vpsDt = read only

        const note = this.form.value?.vendorNote?.trim();

        switch (this.modalData.action) {
            case 'edit':
                this.vendorService.submitEditsForApproval(this.modalData.selected, note).subscribe({
                    next: (res) => this.handleNext(res),
                    error: (error) => this.handleError(error),
                });

                break;
            default:
                this.closeModal();

                break;
        }
    }

    private handleNext(res): void {
        this.areChangesSaved.next(true);
        this.updateData = true;
        this.closeModal();
    }

    private handleError(error: HttpErrorResponse): void {
        console.error(error);
        this.snackBar.open('Server error', undefined, {
            duration: 10000,
            panelClass: ['swatch-red'],
        });
    }
}
