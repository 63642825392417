<div id="main-div" class="main-div">
    <table mat-table matSort [dataSource]="dataSource" class="table-div">
        <ng-container matColumnDef="exportVendor">
            <th mat-header-cell *matHeaderCellDef>
                    <div class="export-checkbox-div">
                        <mat-checkbox id="export-header-checkbox"
                                      class="export-checkbox"
                                      [checked]="allChecked"
                                      [indeterminate]="someComplete()"
                                      (change)="setAll($event.checked)"
                        ></mat-checkbox>
                    </div>
            </th>
            <td mat-cell *matCellDef="let element; index as i;">
                    <div class="export-checkbox-div">
                        <mat-checkbox id="export-checkbox-{{i}}"
                                      class="export-checkbox"
                                      [checked]="element?.selected"
                                      (change)="vendorExportChange($event.checked, element)"
                        ></mat-checkbox>
                    </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="payeeName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="payeeName"> Payee Name</th>
            <td mat-cell
                *matCellDef="let element"
                appHighlight
                [searchedWords]="searchText"
                [text]="element.vendor.payeeName"
                [ignoreCase]="true"
                classToApply="selected"
            >{{element.vendor.payeeName}}</td>
        </ng-container>

        <ng-container matColumnDef="accountTitle">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="accountTitle"> Account Title</th>
            <td mat-cell
                *matCellDef="let element"
                appHighlight
                [searchedWords]="searchText"
                [text]="element.vendor.accountTitle"
                [ignoreCase]="true"
                classToApply="selected"
            >{{element.vendor.accountTitle}}</td>
        </ng-container>

        <ng-container matColumnDef="accountNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="accountNumber"> Account Number</th>
            <td mat-cell
                *matCellDef="let element"
                appHighlight
                [searchedWords]="searchText"
                [text]="element.vendor.accountNumber"
                [ignoreCase]="true"
                classToApply="selected"
            >{{element.vendor.accountNumber}}</td>
        </ng-container>

        <ng-container matColumnDef="abaSwift">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="abaSwift"> Routing / SWIFT</th>
            <td mat-cell
                *matCellDef="let element"
                appHighlight
                [searchedWords]="searchText"
                [text]="element.vendor.abaSwift"
                [ignoreCase]="true"
                classToApply="selected"
            >{{element.vendor.abaSwift}}</td>
        </ng-container>

        <ng-container matColumnDef="subsidiary">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="subsidiary"> Subsidiary</th>
            <td mat-cell
                *matCellDef="let element"
                appHighlight
                [searchedWords]="searchText"
                [text]="element.vendor.subsidiary"
                [ignoreCase]="true"
                classToApply="selected"
            >{{element.vendor.subsidiary}}</td>
        </ng-container>

        <ng-container matColumnDef="taxpayerId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="taxpayerId"> Taxpayer ID</th>
            <td mat-cell
                *matCellDef="let element"
                appHighlight
                [searchedWords]="searchText"
                [text]="element.vendor.taxpayerId"
                [ignoreCase]="true"
                classToApply="selected"
            >{{element.vendor.taxpayerId}}</td>
        </ng-container>

        <ng-container matColumnDef="bankCountry">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="bankCountry"> CC</th>
            <td mat-cell
                *matCellDef="let element"
                appHighlight
                [searchedWords]="searchText"
                [text]="element.vendor.bankCountry"
                [ignoreCase]="true"
                classToApply="selected"
            >{{element.vendor.bankCountry}}</td>
        </ng-container>

        <ng-container matColumnDef="currency">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="currency"> CCY</th>
            <td mat-cell
                *matCellDef="let element"
                appHighlight
                [searchedWords]="searchText"
                [text]="element.vendor.currency"
                [ignoreCase]="true"
                classToApply="selected"
            >{{element.vendor.currency}}</td>
        </ng-container>

        <ng-container matColumnDef="sortCode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="sortCode"> Sort Code</th>
            <td mat-cell
                *matCellDef="let element"
                appHighlight
                [searchedWords]="searchText"
                [text]="element.vendor.sortCode"
                [ignoreCase]="true"
                classToApply="selected"
            >{{element.vendor.sortCode}}</td>
        </ng-container>

        <ng-container matColumnDef="updatedDt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="updatedDt"> Last Modified</th>
            <td mat-cell
                *matCellDef="let element"
            >{{element.vendor.updatedDt | date: 'MMM dd, yyyy'}}</td>
        </ng-container>

        <ng-container matColumnDef="reviewStatusInReview">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="reviewStatusInReview"> Status</th>
            <td mat-cell
                *matCellDef="let element"
            ><span class="review-status">IN REVIEW</span></td>
        </ng-container>

        <ng-container matColumnDef="reviewStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="reviewStatus"> Status</th>
            <td mat-cell
                *matCellDef="let element"
            ><span
                [class.review-status-deleted]="element.vendor.status === 'DELETED'"
                [class.review-status-rejected]="element.vendor.status === 'REJECTED'"
            >{{element.vendor.status}}</span></td>
        </ng-container>

        <ng-container matColumnDef="reviewType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="reviewType"> Review Type</th>
            <td mat-cell
                *matCellDef="let element"
            >{{element.vendor.reviewType}}</td>
        </ng-container>

        <ng-container matColumnDef="rejectionDt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="rejectionDt"> Rejection Date</th>
            <td mat-cell
                *matCellDef="let element"
            >{{element.vendor.updatedDt | date: 'MMM dd, yyyy'}}</td>
        </ng-container>

        <ng-container matColumnDef="dealCount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="dealCount"> Deal Count</th>
            <td mat-cell
                *matCellDef="let element"
            >{{element.vendor.dealCount}}</td>
        </ng-container>

        <ng-container matColumnDef="details" class="mat-header-cell" style="vertical-align: middle;">
            <th mat-header-cell *matHeaderCellDef> Actions</th>
            <td mat-cell *matCellDef="let element; index as i;" class="details-column">
                <div class="details-div">
                    <span class="action-icon action-icon-margin">
                        <span id="visibility-span-{{i}}"
                              class="material-symbols-outlined"
                              (click)="displayDetails(element.vendor)"
                        >visibility</span>
                    </span>
                    <span *ngIf="isApprovedTab()" class="action-icon-margin"
                          [class.action-icon]="!element.vendor.inEdit"
                          [class.action-icon-disabled]="element.vendor.inEdit"
                          matTooltip="A review is already in progress"
                          [matTooltipDisabled]="!element.vendor.inEdit"
                          matTooltipPosition="above"
                          matTooltipShowDelay="500"
                          matTooltipClass="icon-tooltip"
                    >
                        <span id="edit-span-{{i}}"
                              class="material-symbols-outlined"
                              (click)="!element.vendor.inEdit && editRow(element.vendor)"
                              *appAccessCheckSection="editAccessCheck"
                        >edit</span>
                    </span>
                    <span *ngIf="isInReviewTab() && element.vendor.status === 'NEW'" class="action-icon-margin action-icon"
                          matTooltip="Review this vendor and its related records"
                          matTooltipPosition="left"
                          matTooltipShowDelay="500"
                          matTooltipClass="icon-tooltip"
                    >
                        <span id="review-span-{{i}}"
                              class="material-symbols-outlined"
                              (click)="reviewRow(element.vendor)"
                        >task</span>
                    </span>
                    <span *ngIf="isApprovedTab()" class="action-icon-margin"
                          [class.action-icon]="!element.vendor.inEdit"
                          [class.action-icon-disabled]="element.vendor.inEdit"
                          matTooltip="A review is already in progress"
                          [matTooltipDisabled]="!element.vendor.inEdit"
                          matTooltipPosition="above"
                          matTooltipShowDelay="500"
                          matTooltipClass="icon-tooltip"
                    >
                        <span id="delete-span-{{i}}"
                              class="material-symbols-outlined"
                              (click)="!element.vendor.inEdit && deleteRow(element.vendor)"
                              *appAccessCheckSection="deleteAccessCheck"
                        >delete</span>
                    </span>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="intadm-table-row"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell no-data" colspan="10"><span *ngIf="!fetching">No data</span></td>
        </tr>
    </table>
</div>
