import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
    selector: 'app-user-access-snackbar',
    templateUrl: './user-access-snackbar.component.html',
    styleUrls: ['./user-access-snackbar.component.scss'],
})
export class UserAccessSnackbarComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string) {}
}
