import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-ds-components-showcase',
    templateUrl: './ds-components-showcase.component.html',
    styleUrls: ['./ds-components-showcase.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DsComponentsShowcaseComponent implements OnInit {
    public typographyClasses = [
        'base-heading-1',
        'base-heading-2',
        'base-heading-3',
        'base-heading-4',
        'base-heading-5',
        'base-heading-6',
        'base-body-caption',
        'base-body-emphasize',
        'base-body-focused',
        'base-body-highlight',
        'base-body-interactive',
        'base-body-link',
        'base-body-number',
        'base-body-paragraph',
        'base-body-strong',
        'base-fine-strong',
    ];

    constructor() {}

    ngOnInit(): void {
        this.loadColorsDemo();
        this.loadMenuDemo();
        this.loadSortDemo();
        this.loadTextFieldDemo();
        // this.loadTypographyDemo();
        this.loadSelectDemo();
        this.loadGridDemo();
        this.loadNavbarDemo();
    }

    public loremIpsum =
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum';

    public loremIpsumSnippet(oneWord?: boolean) {
        const loremSplit = this.loremIpsum.split(oneWord ? ' ' : '.');

        return loremSplit[Math.floor(Math.random() * loremSplit.length)];
    }

    public generateRandomDropdownOptions(addIcon?: boolean, specificLength?: number) {
        const arrayLength = specificLength || Math.floor(Math.random() * 10) + 1;
        const newArray = [];

        for (let i = 0; i < arrayLength; i++) {
            const option = { internalLabel: this.loremIpsumSnippet(true) };

            if (addIcon) {
                option['leadingIcon'] = ['format_shapes', 'forum'][Math.floor(Math.random() * 2)];
            }

            newArray.push(option);
        }

        return newArray;
    }

    public accordionDemoAddContent() {
        const dropdowns = document.querySelectorAll(
            '.accordion-demo .accordion-demo-dropdowns .children.show [slot="children"]',
        );
        const textblock = document.querySelectorAll(
            '.accordion-demo .accordion-demo-textblock .children.show [slot="children"]',
        );

        dropdowns.forEach((el) => {
            const dropdownDiv = document.createElement('button');

            dropdownDiv.textContent = this.loremIpsumSnippet(true);
            el.appendChild(dropdownDiv);
        });

        textblock.forEach((el) => {
            const textblockDiv = document.createElement('div');

            textblockDiv.textContent = this.loremIpsumSnippet();
            el.appendChild(textblockDiv);
        });
    }

    public accordionDemoRemoveContent() {
        const dropdowns = document.querySelectorAll(
            '.accordion-demo .accordion-demo-dropdowns .children.show [slot="children"]',
        );
        const textblock = document.querySelectorAll(
            '.accordion-demo .accordion-demo-textblock .children.show [slot="children"]',
        );

        dropdowns.forEach((el) => {
            if (el.lastChild) {
                el.removeChild(el.lastChild);
            }
        });

        textblock.forEach((el) => {
            if (el.lastChild) {
                el.removeChild(el.lastChild);
            }
        });
    }

    public toggleAccordion(e) {
        const componentContainer = e.target.closest('srsa-accordion');

        if (componentContainer && componentContainer.hasAttribute('is-expanded')) {
            const isCurrentlyExpanded = componentContainer.getAttribute('is-expanded') === 'true';
            const currentClassList = componentContainer.querySelector('.icon').classList;

            componentContainer.setAttribute('is-expanded', isCurrentlyExpanded ? 'false' : 'true'); // Reverse current state

            isCurrentlyExpanded ? currentClassList.remove('rotate') : currentClassList.add('rotate');
        }
    }

    public isSameDomain(styleSheet) {
        // Internal style blocks won't have an href value
        if (!styleSheet.href) {
            return true;
        }
        return styleSheet.href.indexOf(window.location.origin) === 0;
    }

    public loadColorsDemo() {
        // https://developer.mozilla.org/en-US/docs/Web/API/CSSRule#Type_constants
        const isStyleRule = (rule) => rule.type === 1;

        // styleSheets is array-like, so we convert it to an array.
        // Filter out any stylesheets not on this domain
        // @ts-ignore
        const customPropColors = [...document.styleSheets].filter(this.isSameDomain).reduce(
            (finalArr, sheet) =>
                finalArr.concat(
                    // cssRules is array-like, so we convert it to an array
                    [...sheet.cssRules].filter(isStyleRule).reduce((propValArr, rule) => {
                        const props = [...rule.style]
                            .map((propName) => [propName.trim(), rule.style.getPropertyValue(propName).trim()])
                            // Discard any props that don't start with "--". Custom props are required to.
                            .filter(([propName]) => propName.indexOf('--') === 0)
                            // Discard any that don't start with a '#', this also ignores rgba(...) style colors
                            .filter(([_propName, propValue]) => propValue.indexOf('#') === 0);
                        return [...propValArr, ...props];
                    }, []),
                ),
            [],
        );

        // Group into "core", "semantic", "light", "dark", "other"
        const colorCategories = ['core', 'semantic', 'light', 'dark'];
        const groupedCustomPropColors = customPropColors.reduce((acc, prop) => {
            const parsedType = prop[0].match(/^--(\w+)-.*$/)[1];
            const type = colorCategories.includes(parsedType) ? parsedType : 'other';

            if (Array.isArray(acc[type])) {
                acc[type].push(prop);
            } else {
                acc[type] = [prop];
            }

            return acc;
        }, {});

        const createDemoColorItem = (prop, val) =>
            `<li class="card color">
        <b class="swatch" style="--color: ${val}"></b>
        <div class="row">
          <input class="color-name" value="${prop}" readonly />
          <button class="copy" onClick="navigator.clipboard.writeText('${prop}').then(() => alert('Copied!'))">
            <span class="material-symbols-sharp">content_copy</span>
          </button>
        </div>
      </li>`;

        // Create containers for each Color Category with a heading and a list of color swatches
        const colorContainers = colorCategories.map((colorCategory) => {
            const props = groupedCustomPropColors[colorCategory];

            // Create a new heading
            const heading = document.createElement('div');
            heading.classList.add('base-heading-2');
            heading.classList.add('component-title');
            heading.classList.add('color-category');
            heading.innerText = colorCategory;

            // Create a new UL
            const list = document.createElement('ul');
            list.className = 'colors';
            list.innerHTML = props.reduce((str, [prop, val]) => str + createDemoColorItem(prop, val), '');

            // Create a div container holding the heading and the list
            const colorContainer = document.createElement('div');
            colorContainer.appendChild(heading);
            colorContainer.appendChild(list);

            return colorContainer;
        });

        // Add all the color containers to the DOM
        const colorsContainer = document.querySelector('#demo-colors');
        colorContainers.forEach((colorContainer) => {
            colorsContainer.appendChild(colorContainer);
        });
    }

    // public loadTypographyDemo() {
    //     const typographyClasses = [
    //         'base-heading-1',
    //         'base-heading-2',
    //         'base-heading-3',
    //         'base-heading-4',
    //         'base-heading-5',
    //         'base-heading-6',
    //         'base-body-caption',
    //         'base-body-emphasize',
    //         'base-body-focused',
    //         'base-body-highlight',
    //         'base-body-interactive',
    //         'base-body-link',
    //         'base-body-number',
    //         'base-body-paragraph',
    //         'base-body-strong',
    //         'base-fine-strong',
    //     ];
    //
    //     // Create container for each Typography class, with a heading and an example
    //     const typographyDemoItemContainers = typographyClasses.map((typographyClass) => {
    //         const label = document.createElement('div');
    //         label.classList.add('base-heading-3');
    //         label.classList.add('label');
    //         label.textContent = typographyClass;
    //
    //         const example = document.createElement('div');
    //         example.classList.add(typographyClass);
    //         example.classList.add('example');
    //         example.textContent = this.loremIpsum;
    //
    //         const container = document.createElement('div');
    //         container.classList.add('typography-demo-item');
    //         container.classList.add('card');
    //         container.appendChild(label);
    //         container.appendChild(example);
    //
    //         return container;
    //     });
    //
    //     const typographyDemoContainer = document.querySelector('#demo-typography');
    //     typographyDemoItemContainers.forEach((typographyDemoItem) => {
    //         typographyDemoContainer.appendChild(typographyDemoItem);
    //     });
    // };

    public loadMenuDemo() {
        const menuEls = document.querySelectorAll('srsa-menu[internal-id^=menu-demo-]');

        menuEls[0]['options'] = this.generateRandomDropdownOptions(false, 2);
        menuEls[1]['options'] = this.generateRandomDropdownOptions(true, 10);
        menuEls[2]['options'] = this.generateRandomDropdownOptions(false, 5);
        menuEls[3]['options'] = this.generateRandomDropdownOptions(true);
        menuEls[4]['options'] = this.generateRandomDropdownOptions(false, 2);
        menuEls[5]['options'] = this.generateRandomDropdownOptions();

        menuEls[4]['options'] = [
            // Completely overwrites internal options
            {
                internalLabel: 'https://www.srsacquiom.com/',
                href: 'https://www.srsacquiom.com/',
                internalAriaLabel: 'custom aria option',
            },
        ];

        menuEls[1]['buttonConfig'] = {
            ...menuEls[1]['buttonConfig'],
            internalLabel: 'Scrolls to option 5',
            leadingIcon: null,
            trailingIcon: 'download',
        };
        menuEls[2]['buttonConfig'] = {
            ...menuEls[2]['buttonConfig'],
            internalLabel: 'Demo Menu 3 that closes after selection',
            leadingIcon: null,
            badgeLabel: '#',
            internalClass: 'primary',
        };
        menuEls[3]['buttonConfig'] = {
            ...menuEls[3]['buttonConfig'],
            internalLabel: 'Disabled Menu',
            leadingIcon: null,
        };
        menuEls[4]['buttonConfig'] = { internalLabel: 'Demo Menu 5 with an href' }; // Completely overwrites internal options

        menuEls[1]['dropdownContainerConfig'] = {
            ...menuEls[1]['dropdownContainerConfig'],
            maxheight: '10rem',
            overflowYScroll: true,
            scrollto: 4,
            width: '10rem',
        };
        menuEls[2]['dropdownContainerConfig'] = {
            ...menuEls[2]['dropdownContainerConfig'],
            position: 'right', // Typescript Enum in js
            closeOnClick: true,
        };
    }

    public loadSortDemo() {
        const sortEls = document.querySelectorAll('srsa-sort[internal-id^=sort-demo-]');

        sortEls[0]['options'] = [
            { internalLabel: 'タイトル: asc', sort: { column: 'タイトル', direction: 'asc' } },
            { internalLabel: 'タイトル: desc', sort: { column: 'タイトル', direction: 'desc' } },
            { internalLabel: 'Price: asc', sort: { column: 'price', direction: 'asc' } },
            { internalLabel: 'Price: desc', sort: { column: 'price', direction: 'desc' } },
        ];

        sortEls[0]['dropdownContainerConfig'] =
            sortEls[1]['dropdownContainerConfig'] =
            sortEls[4]['dropdownContainerConfig'] =
                {
                    ...sortEls[0]['dropdownContainerConfig'],
                    width: '12.5rem',
                    showHeader: true,
                };

        sortEls[1]['options'] = [
            {
                internalLabel: 'KraftfahrzeugHaftpflichtversicherung',
                sort: { column: 'kraftfahrzeugHaftpflichtversicherung', direction: 'desc' },
            },
            { internalLabel: 'Orangeness', disabled: true, sort: { column: 'orangeness', direction: 'asc' } },
            { internalLabel: 'Floatability', sort: { column: 'floatability', direction: 'asc' } },
            { internalLabel: 'Pizza mit Käse: asc', sort: { column: 'pizza mit käse', direction: 'asc' } },
            { internalLabel: 'Pizza mit Käse: desc', sort: { column: 'pizza mit käse', direction: 'desc' } },
            { internalLabel: this.loremIpsumSnippet(), sort: { column: 'any column', direction: 'desc' } },
        ];
        sortEls[1]['buttonConfig'] = { ...sortEls[1]['buttonConfig'], internalLabel: 'Sort by', badgeCount: true };
        sortEls[1]['values'] = sortEls[1]['values']
            ? [
                  ...sortEls[1]['values'],
                  { column: 'kraftfahrzeugHaftpflichtversicherung', direction: 'desc' },
                  { column: 'floatability', direction: 'asc' },
              ]
            : [
                  { column: 'kraftfahrzeugHaftpflichtversicherung', direction: 'desc' },
                  { column: 'floatability', direction: 'asc' },
              ];

        sortEls[4]['options'] = [
            { internalLabel: 'single sort: asc', sort: { column: 'single sort', direction: 'asc' } },
            { internalLabel: 'single sort: desc', sort: { column: 'single sort', direction: 'desc' } },
            { internalLabel: 'pick one: asc', sort: { column: 'pick just', direction: 'asc' } },
            { internalLabel: 'pick one: desc', sort: { column: 'pick just', direction: 'desc' } },
        ];
    }

    public loadGridDemo() {
        const usdCurrencyFormatter = (val) =>
            new Intl.NumberFormat(undefined, { style: 'currency', currency: 'USD' }).format(+val);
        const grids = document.querySelectorAll('.srsa-grid-demo srsa-grid[internal-id^="srsa-grid-"]');

        grids[0]['gridStyling'] = { ...grids[0]['gridStyling'], padding: '0.5rem 0.375rem', rowBorder: true };
        grids[0]['columnDefinitions'] = [
            {
                columnType: 'text',
                property: 'account_type',
                label: 'Account / Shareholder',
                detailProperty: 'name',
            },
            {
                columnType: 'text',
                property: 'initial_deposit',
                label: 'Initial Deposit',
                textAlign: 'right',
                isMonoSpace: true,
                dataFormatter: usdCurrencyFormatter,
            },
            {
                columnType: 'text',
                property: 'investment_earnings',
                label: 'Earnings',
                textAlign: 'right',
                isMonoSpace: true,
                dataFormatter: usdCurrencyFormatter,
            },
            {
                columnType: 'text',
                property: 'claims_paid',
                label: 'Claims Paid',
                textAlign: 'right',
                isMonoSpace: true,
                dataFormatter: usdCurrencyFormatter,
            },
            {
                columnType: 'text',
                property: 'expenses',
                label: 'Expenses',
                textAlign: 'right',
                isMonoSpace: true,
                dataFormatter: usdCurrencyFormatter,
            },
            {
                columnType: 'text',
                property: 'disbursements',
                label: 'Disbursement',
                textAlign: 'right',
                isMonoSpace: true,
                dataFormatter: usdCurrencyFormatter,
            },
            {
                columnType: 'text',
                label: 'Balance',
                textAlign: 'right',
                isMonoSpace: true,
                computed: (data) =>
                    usdCurrencyFormatter(
                        data.initial_deposit +
                            data.investment_earnings -
                            data.claims_paid -
                            data.expenses -
                            data.disbursements,
                    ),
            },
        ];

        grids[1]['gridStyling'] = {
            ...grids[1]['gridStyling'],
            'grid-template-columns': '15rem 10rem 15rem 15rem 15rem',
            padding: '0.5rem 0.375rem',
        };
        grids[1]['columnDefinitions'] = [
            {
                columnType: 'text',
                property: 'randomname',
                label: 'Lorem',
                detailProperty: 'randomdetail',
                detailDataFormatter: (data) => data + ' abc def ghi jkl',
                isNotSortable: true,
            },
            {
                columnType: 'text',
                property: 'randomnumber',
                label: this.loremIpsumSnippet(true),
                isMonoSpace: true,
                dataFormatter: usdCurrencyFormatter,
                isNotSortable: true,
            },
            {
                columnType: 'text',
                property: 'randomtext',
                label: this.loremIpsumSnippet(true),
                textAlign: 'right',
                isNotSortable: true,
            },
            {
                columnType: 'text',
                property: 'randomnumber',
                label: this.loremIpsumSnippet(true),
                textAlign: 'right',
                isNotSortable: true,
            },
            {
                columnType: 'text',
                property: 'randomtext',
                label: this.loremIpsumSnippet(true),
                textAlign: 'right',
                isNotSortable: true,
            },
        ];

        grids[2]['gridStyling'] = {
            ...grids[2]['gridStyling'],
            'grid-template-columns': '10rem 10rem 30rem 10rem 30rem',
            padding: '0.5rem 0.375rem',
            rowBorder: true,
        };
        grids[2]['columnDefinitions'] = [
            {
                columnType: 'text',
                property: 'randomname',
                label: 'Lorem',
                detailProperty: 'randomdetail',
                detailDataFormatter: (data) => data + ' 3.141592653589793238462643383279',
            },
            {
                columnType: 'text',
                property: 'randomnumber',
                label: this.loremIpsumSnippet(true),
                isNotSortable: true,
            },
            {
                columnType: 'text',
                property: 'randomtext',
                label: this.loremIpsumSnippet(true),
                isTextWrap: true,
            },
            {
                columnType: 'text',
                property: 'randomnumber',
                label: this.loremIpsumSnippet(true),
                textAlign: 'right',
                isNotSortable: true,
                isMonoSpace: true,
                dataFormatter: usdCurrencyFormatter,
            },
            {
                columnType: 'text',
                property: 'randomtext',
                label: this.loremIpsumSnippet(true),
                isNotSortable: true,
                isTextWrap: true,
            },
        ];

        const accountTypes = ['Expense Fund', 'Tax Escrow'];
        const names = ['Kovacek Inc', 'Hagenes - Ratke', 'Corwin Group', 'Swaniaski - Robel'];

        const randomDataSource = [
            ...Array(30)
                .fill(0)
                .map((x) => ({
                    account_type: accountTypes[Math.floor(Math.random() * accountTypes.length)],
                    name: names[Math.floor(Math.random() * names.length)],
                    disbursements: Math.floor(Math.random() * 10000 + 1000),
                    expenses: Math.floor(Math.random() * 10000 + 1000),
                    claims_paid: Math.floor(Math.random() * 10000 + 1000),
                    investment_earnings: [0, 1, 2][Math.floor(Math.random() * 3)], // For multisort demoing
                    initial_deposit: Math.floor(Math.random() * 1000000 + 1000),
                })),
        ];

        const randomDataSource2 = [
            ...Array(10)
                .fill(0)
                .map(() => ({
                    randomname: this.loremIpsumSnippet(true),
                    randomdetail: this.loremIpsumSnippet(true),
                    randomnumber: Math.floor(Math.random() * 1000000 + 1000000),
                    randomtext: this.loremIpsumSnippet(),
                })),
        ];

        grids[0]['dataSource'] = randomDataSource;
        grids[1]['dataSource'] = grids[2]['dataSource'] = randomDataSource2;

        // public listenAndMultisort(gridComponent, dataSource) {
        //     gridComponent.addEventListener('srsaGridSortChange', (e) => {
        //         const sortOrder = e.detail.value;
        //
        //         if (sortOrder.length === 0) {
        //             gridComponent.dataSource = dataSource;
        //         } else {
        //             const sortedArr = dataSource.toSorted((a, b) => {
        //                 public sortLogic(a, b) {
        //                     if (typeof a === 'string') {
        //                         return a.localeCompare(b);
        //                     } else {
        //                         return a - b;
        //                     }
        //                 };
        //
        //                 let compare = 0;
        //                 let x = 0;
        //
        //                 // Gets the first 1/-1 sort value over the entire sort array
        //                 // If they're all "equal" by the end of the sort array, return 0
        //                 while (x < sortOrder.length && compare === 0) {
        //                     if (sortOrder[x].direction === 'asc') {
        //                         compare = sortLogic(a[sortOrder[x].column], b[sortOrder[x].column]);
        //                     } else {
        //                         compare = sortLogic(b[sortOrder[x].column], a[sortOrder[x].column]);
        //                     }
        //
        //                     x++;
        //                 }
        //
        //                 return compare;
        //             });
        //
        //             gridComponent.dataSource = sortedArr;
        //         }
        //     });
        // };
        //
        // listenAndMultisort(grids[0], randomDataSource);
        // listenAndMultisort(grids[1], randomDataSource2);
        // listenAndMultisort(grids[2], randomDataSource2);
    }

    public loadTextFieldDemo() {
        const addressEl = document.querySelector('srsa-text-field[internal-id="address-validator"]');
        const zipEl = document.querySelector('srsa-text-field[internal-id="zip-validator"]');

        addressEl['validators'] = [
            { validator: (value) => value.includes('z'), message: `Need to use an z.` },
            { validator: (value) => value.includes('y'), message: `Need to use a y.` },
        ];

        zipEl['validators'] = [
            { validator: (value) => value.match(/(^\d{5}$)|(^\d{5}-\d{4}$)/), message: `Please enter valid zip.` },
        ];
    }

    public loadSelectDemo() {
        const selects = document.querySelectorAll('srsa-select');

        selects.forEach((select, i) => {
            select['options'] = [
                // {},
                { value: 0, id: '0', label: 'All Shareholders' },
                {
                    value: 1,
                    id: '1',
                    helper: 'This is helper text of different lengths',
                    label: 'Anuka International Business',
                },
                { value: 2, id: '2', helper: 'This is helper text', label: 'Barnett Gidman Network' },
                { value: 3, helper: 'This is helper text', label: 'Vyong Eastcost Parent' },
            ];
        });

        selects[1]['validators'] = [
            {
                validator: (value) => {
                    console.log('v: ', value);
                    return value == '3';
                },
                message: 'You must select the last option.',
            },
        ];

        selects[1]['selectContainerConfig'] = {
            accordionClass: 'acc-class',
            dropdownMaxHeight: '250px',
            dropdownWidth: '350px',
            overflowYScroll: true,
        };
    }

    public loadNavbarDemo() {
        const primaryNavBar = document.querySelector('srsa-navbar[internal-id="nav1"]');
        const secondaryNavBar = document.querySelector('srsa-navbar[internal-id="nav2"]');
        const disabledNavBar = document.querySelector('srsa-navbar[disabled="true"]');

        const options = [
            {
                menuConfig: {
                    options: [{ internalLabel: 'A really long name for a dropdown option' }],
                    internalId: 'fisrt',
                    buttonConfig: {
                        internalLabel: 'Really long name for a link that just keeps going and going',
                        internalClass: 'quaternary',
                        trailingIcon: 'expand_more',
                    },
                },
            },
            {
                buttonConfig: {
                    internalLabel: 'A simple link',
                    internalClass: 'quaternary',
                    internalId: 'sixth',
                },
            },
            {
                menuConfig: {
                    options: [
                        { internalLabel: 'One ring to bring them all' },
                        { internalLabel: 'and in the darkness bind them' },
                    ],
                    internalId: 'second',
                    buttonConfig: {
                        internalLabel: 'One ring to rule them all, one ring to find them',
                        internalClass: 'quaternary',
                        trailingIcon: 'expand_more',
                    },
                },
            },
            {
                buttonConfig: {
                    internalLabel: 'More long names for demo purposes',
                    internalClass: 'quaternary',
                    internalId: 'third',
                },
            },
            {
                menuConfig: {
                    options: [{ internalLabel: 'Lorem Option' }],
                    internalId: 'fourth',
                    buttonConfig: {
                        internalLabel: 'Lorem Ipsum',
                        internalClass: 'quaternary',
                        trailingIcon: 'expand_more',
                    },
                },
            },
            {
                menuConfig: {
                    options: [{ internalLabel: 'One last option' }],
                    internalId: 'fifth',
                    buttonConfig: {
                        internalLabel: 'supercalifragilisticexpialidocious',
                        internalClass: 'quaternary',
                        trailingIcon: 'expand_more',
                    },
                },
            },
        ];

        primaryNavBar['options'] = disabledNavBar['options'] = options;
        secondaryNavBar['options'] = options.map((o) => JSON.parse(JSON.stringify(o)));

        primaryNavBar.addEventListener('srsaButtonClicked', (e) => {
            const index = this.getIndex(e, primaryNavBar['options']);
            primaryNavBar['options'].forEach((option, i) => {
                this.setSelectedOption(option, i, index);
            });
            primaryNavBar['options'] = [...primaryNavBar['options']];
        });

        secondaryNavBar.addEventListener('srsaButtonClicked', (e) => {
            const index = this.getIndex(e, secondaryNavBar['options']);
            secondaryNavBar['options'].forEach((option, i) => {
                this.setSelectedOption(option, i, index);
            });
            secondaryNavBar['options'] = [...secondaryNavBar['options']];
        });

        // public getIndex(event) {
        //     return options
        //         .map((option) => {
        //             if ('buttonConfig' in option) {
        //                 return option.buttonConfig.internalId;
        //             } else if ('menuConfig' in option) {
        //                 return option.menuConfig.internalId;
        //             }
        //         })
        //         .indexOf(event.detail.id);
        // };
        //
        // public setSelectedOption(option, i, index) {
        //     option.isSelected = false;
        //     if (i === index) {
        //         option.isSelected = true;
        //     }
        // };
    }

    public getIndex(event, options) {
        return options
            .map((option) => {
                if ('buttonConfig' in option) {
                    return option.buttonConfig.internalId;
                } else if ('menuConfig' in option) {
                    return option.menuConfig.internalId;
                }
            })
            .indexOf(event.detail.id);
    }

    public setSelectedOption(option, i, index) {
        option.isSelected = false;
        if (i === index) {
            option.isSelected = true;
        }
    }

    public openModal(selector?: string, create?: boolean) {
        const modal = document.querySelector(selector);

        if (create) {
            const createdButtons = document.querySelectorAll('.modal3 .modal-body srsa-button[slot="modal-body"]');

            if (createdButtons.length <= 20) {
                const modalSlotContent = document.createElement('srsa-button');
                modalSlotContent.setAttribute('internal-class', 'primary');
                modalSlotContent.setAttribute('internal-label', 'This is a dynamically generated button');
                modalSlotContent.setAttribute('slot', 'modal-body');

                modal.appendChild(modalSlotContent);
            }
        }

        if (selector.includes('modal4')) {
            const modal4Container = document.querySelector('.modal4 .srsa-modal');

            modal4Container['style'].top = window.scrollY + 32 + 'px';
        }

        modal['show'] = true;
    }

    public openAlert(attributes) {
        const div = document.createElement(`srsa-alert`);
        Object.keys(attributes).forEach((key) => div.setAttribute(key, attributes[key]));
        document.body.appendChild(div);
    }
}
