import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
    selector: 'app-intadm-search',
    templateUrl: './intadm-search.component.html',
    styleUrls: ['./intadm-search.component.scss'],
})
export class IntadmSearchComponent implements OnInit {
    @Input() placeholder: string;
    @Output() newSearchEvent = new EventEmitter<string>();
    public searchControl: FormControl = new FormControl();

    constructor() {}

    ngOnInit(): void {
        this.searchControl.valueChanges
            .pipe(
                debounceTime(500), // Fire the callback method only if not fired in a specific interval.
                distinctUntilChanged(), // Execute only if a different value is emitted.
            )
            .subscribe((filterValue) => {
                this.newSearchEvent.emit(filterValue);
            });
    }
}
