import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-identity-verification',
    templateUrl: './identity-verification.component.html',
    styleUrls: ['./identity-verification.component.scss'],
})
export class IdentityVerificationComponent {
    constructor() {}
}
