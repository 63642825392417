export interface ActivityRequestHelper {
    srchval?: string;
    activity?: string;
    action?: string;
    startdt?: number;
    enddt?: number;
    excludename?: string;
    excludeid?: string;
    bynameunique?: boolean;
}

export interface ActivityTranslated {
    initials: string;
    text: string;
    activityDt: number;
}

export interface ActivityRecord {
    id: number;
    userName: string; // Affected user
    userId: string;
    // E.g.: added, removed, updated, invited, created, deactivated account, resent invitation, reset mfa method, sent password reset
    action: string;
    products: string; // DD, CH, SHARP
    // No    productIds: string;
    deals: string; // Deal "company name", comma separated if multiple.
    dealIds: string;
    dealUserRole: string;
    // shareholders: string;
    // shareholderIds: string;
    properties: string; // fields updated on user, deal role name, etc.
    byName: string; // Who made the change.
    byId: string;
    sourceSystem: string; // Where did this change originate. IntAdm for now, could be DD, CH, or SHARP in the future?
    activityDt: number; // When did this occur.
    userStatus: string;
}

/*

Examples:
=======================

Alyssa Vuong updated Gordan Baker phone number
--------------------------------------------------
user_name:    Gordan Baker
user_id:      1
action:       updated
products:     [ SHARP ]
product ids?
deals         [ Project Galaxy (Gunder Luh) 2022 ]
deal ids
properties:   [ phone number ]
by_name:      Alyssa Vuong
by_id:        2
source_system: IntAdm
activityDt:


Gustavo Bandao was removed from Shareholder Portal by Alyssa Vuong within Project Galaxy (Gunder Luh) 2022
--------------------------------------------------
user_name:   Gustavo Bandao
user_id:     3
action:      removed
products:    [ SHARP ]
product ids?
deals:       [ Project Galaxy (Gunder Luh) 2022 ]
deal ids?
properties
by_name:     Alyssa Vuong
by_id:       2
source_system: IntAdm
activityDt:


Sean O’Leary added 3 users to Shareholder Portal within Project Galaxy (Gunder Luh) 2022
NOT SUPPORTED !!  The users need to be listed individually
--------------------------------------------------
Could do one user at a time though
user_name:    User Name 1
user_id:      4
action:       added
products:     [ SHARP ]
product ids?
deals         [ Project Galaxy (Gunder Luh) 2022 ]
deal ids
properties
by_name:      Sean O’Leary
by_id:        5
source_system: IntAdm
activityDt:

*/
