import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { BankRecord, BankValidationService, ModalConfigData, SimpleModalResult } from './shared';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from './modal/modal.component';
import { SimpleModalConfigData } from '../vendor-payments/modal-simple/modal-simple.config.type';

@Component({
    selector: 'app-cache',
    templateUrl: './cached-bank-codes.component.html',
    styleUrls: ['./cached-bank-codes.component.scss'],
})
export class CachedBankCodesComponent implements OnInit {
    public cachedBankCodesTitle = 'Bank Code Admin Tool';
    public cachedBankCodesSubtitle = 'Stored Routing Numbers';
    public cachedBankCodesCountText = 'total routing numbers in this directory';
    public get cachedBankCodesCount(): number {
        return this.cacheRecords ? this.cacheRecords.length : 0;
    }
    public cacheRecords: BankRecord[];
    public fullList: BankRecord[];
    public searchValue: string;
    private modalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: true,
        minWidth: '500px',
        maxWidth: '500px',
        minHeight: '223px',
    };
    public cacheModal: MatDialogRef<ModalComponent>;
    public exportDefault = 'Export';
    public exporting = 'Exporting';
    public exportLabel = this.exportDefault;
    public exportInProgress = false;
    public fetching = true;

    private modalConfigTemplateExport: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: false,
        minWidth: '550px',
        maxWidth: '550px',
        minHeight: '259px',
        maxHeight: '300px',
    };

    constructor(
        public matDialog: MatDialog,
        private snackBar: MatSnackBar,
        private bankValidationService: BankValidationService,
    ) {}

    ngOnInit(): void {
        this.fetch();
    }

    public performSearch(searchVal: string) {
        this.searchValue = searchVal;
        if (!searchVal || searchVal.trim() == '') {
            // No search value, return all records
            this.cacheRecords = this.fullList;
        } else {
            this.cacheRecords = this.fullList.filter((val) => {
                if (searchVal.trim().toLowerCase() === 'direct' || searchVal.trim().toLowerCase() === 'indirect') {
                    return (
                        val.fedach.connectivity?.toLowerCase() === searchVal.trim().toLowerCase() ||
                        val.fedwire.connectivity?.toLowerCase() === searchVal.trim().toLowerCase()
                    );
                } else {
                    return (
                        val.bankName.toLowerCase().indexOf(searchVal.trim().toLowerCase()) >= 0 ||
                        val.nationalId.toLowerCase().indexOf(searchVal.trim().toLowerCase()) >= 0
                    );
                }
            });
        }
    }

    public export() {
        this.exportInProgress = true;
        const newNoteConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplateExport,
            data: {
                title: 'Export Bank Codes',
                action: 'export',
            },
        };

        this.openModal(newNoteConfig);
    }

    public handleDelete($event: ModalConfigData) {
        const deleteConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplate,
            data: $event,
        };
        this.openModal(deleteConfig);
    }

    private openModal(modalConfig: MatDialogConfig) {
        this.cacheModal = this.matDialog.open(ModalComponent, modalConfig);
        this.cacheModal.afterClosed().subscribe((res: SimpleModalResult) => {
            this.exportInProgress = false;

            if (res?.updateData) {
                let message: string;
                if (modalConfig.data.action === 'delete') {
                    message = modalConfig.data.selected.bankName + ' has been successfully removed from your list.';
                }

                this.snackBar.open(message, undefined, {
                    duration: 6000,
                });

                setTimeout(() => {
                    this.snackBar.open('Fetched cached bank codes after most recent changes', undefined, {
                        duration: 6000,
                    });
                }, 6000);

                this.fetch();
            } else if (res?.exported) {
                this.snackBar.open(`Success!`, undefined, {
                    duration: 6000,
                });
            }
        });
    }

    private fetch() {
        this.fetching = true;
        this.bankValidationService.get().subscribe({
            next: (res) => {
                this.fetching = false;
                this.cacheRecords = this.fullList = res;
            },
            error: (error: HttpErrorResponse) => {
                this.fetching = false;
                console.error(error);
                this.snackBar.open('Server error', undefined, {
                    duration: 10000,
                });
            },
        });
    }
}
