import { Component } from '@angular/core';
import { AuthService, LocalStorageItems } from '../../core/services/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-callback',
    styles: [],
    template: ``,
})
export class CallbackComponent {
    constructor(
        private authService: AuthService,
        private router: Router,
    ) {
        this.handleCallback();
    }

    private handleCallback(): void {
        this.authService.loginCallback().then(() => {
            const redirectUrlSaved = localStorage.getItem(LocalStorageItems.LOGIN_REDIRECT);
            if (!redirectUrlSaved) {
                // If there is no redirect URL saved, navigate to the home page
                this.router.navigate(['']);
                return;
            }
            const redirectUrl = decodeURIComponent(localStorage.getItem(LocalStorageItems.LOGIN_REDIRECT) || '');

            // Parse the URL to extract the path and query parameters
            const url = new URL(redirectUrl);
            let path = url.pathname;
            const queryParams = {};
            url.searchParams.forEach((value, key) => {
                queryParams[key] = value;
            });

            if (path.indexOf('login') > -1) {
                // If the path is the login page, redirect to the home page
                path = '';
            }

            // Navigate using the path and query parameters
            this.router.navigate([path], { queryParams });
        });
    }
}
