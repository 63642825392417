import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../core/guards/auth-guard.service';
import { IdentityVerificationComponent } from './identity-verification.component';
import { AllowedRoles } from '../core/guards/allowed-roles.guard';
import { OneLoginRoles } from '../core/services/auth.service';

const routes: Routes = [
    {
        path: 'identity-verification',
        component: IdentityVerificationComponent,
        canActivate: [AuthGuard, AllowedRoles],
        data: {
            AllowedRolesData: {
                oneOfRoles: [OneLoginRoles.shield, OneLoginRoles.armoryAuditor],
            },
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class IdentityVerificationRoutingModule {}
