<div id="main-div" class="main-div">
    <table mat-table matSort multiTemplateDataRows
           (matSortChange)="handleSortChange($event)"
           [dataSource]="dataSource"
           class="table-div"
    >
        <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="expand row" (click)="toggleRow(element); $event.stopPropagation()">
                    <span *ngIf="isExpanded(element)" class="material-symbols-outlined action-icon">
                        keyboard_arrow_down
                    </span>
                    <span *ngIf="!isExpanded(element)" class="material-symbols-outlined action-icon">
                        keyboard_arrow_right
                    </span>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="username">User</th>
            <td mat-cell
                *matCellDef="let element"
            >
                <span appHighlight
                      [searchedWords]="searchText"
                      [text]="element.username"
                      [ignoreCase]="true"
                      classToApply="selected"
                >{{element.username}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="usertype">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="usertype">User Type</th>
            <td mat-cell
                *matCellDef="let element"
            >
                <span>{{element.usertype}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="activity">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="activity">Activity</th>
            <td mat-cell
                *matCellDef="let element"
            >
                <span appHighlight
                      [searchedWords]="searchText"
                      [text]="element.activity"
                      [ignoreCase]="true"
                      classToApply="selected"
                >{{element.activity}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="feature">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="feature">Feature / App</th>
            <td mat-cell
                *matCellDef="let element"
            >
                <span appHighlight
                      [searchedWords]="searchText"
                      [text]="element.feature"
                      [ignoreCase]="true"
                      classToApply="selected"
                >{{element.feature}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="timestamp">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="timestamp">Timestamp</th>
            <td mat-cell
                *matCellDef="let element"
            >
                <span>{{getFormattedDate(element.timestamp)}}</span>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="expansion-activity-detail"
                     [class.expanded] = "isExpanded(element)"
                     [class.collapsed] = "!isExpanded(element)">
                    Some detail here
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"
            class="intadm-table-row"
            (click)="toggleRow(row)">
        </tr>

        <!-- expanded detail row, when selected -->
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="activity-detail-row"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell no-data" colspan="10"><span *ngIf="!fetching">No data</span></td>
        </tr>
    </table>
</div>
<div class="paginator-div">
    <mat-paginator [length]="totalRecords"
                   [pageIndex]="pageIndex"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="[10, 25, 50, 100]"
                   aria-label="Select page"
                   showFirstLastButtons=true
                   disabled=false
                   hidePageSize=false
                   (page)="handlePageEvent($event)">
    </mat-paginator>
</div>
