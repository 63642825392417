import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GiactImportComponent } from './giact-import/giact-import.component';
import { GiactExportComponent } from './giact-export/giact-export.component';
import { IdentityVerificationRoutingModule } from './identity-verification.routing.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { IdentityVerificationComponent } from './identity-verification.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { FileUploadComponent } from '../core/components/file-upload/file-upload.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { CoreModule } from '../core/core.module';

@NgModule({
    declarations: [GiactImportComponent, GiactExportComponent, IdentityVerificationComponent],
    imports: [
        CommonModule,
        IdentityVerificationRoutingModule,
        MatToolbarModule,
        MatTabsModule,
        MatButtonModule,
        MatProgressBarModule,
        MatTableModule,
        MatDialogModule,
        MatDividerModule,
        ReactiveFormsModule,
        MatRadioModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatNativeDateModule,
        CoreModule,
    ],
})
export class IdentityVerificationModule {}
