export interface CachedBankValidationResponse {
    countryCode?: string;
    nationalId?: string;
    serviceResponse?: ServiceResponse;
    created?: number;
}

export interface ServiceResponse {
    ref?: string;
    bic8?: string;
    group?: string;
    status?: string;
    comment?: string;
    dataStore?: string;
    nationalId?: string;
    countryCode?: string;
    accountNumber?: string;
    payServiceDetails?: PayServiceDetail[];
}

export interface PayServiceDetail {
    scheme?: string;
    currency?: string;
    serviceId?: string;
    countryCode?: string;
    addressableId?: string;
    participantId?: string;
    attributeType1?: string;
    attributeType2?: string;
    attributeType3?: string;
    directIndirect?: string;
    intermediaryId?: string;
    attributeValue1?: string;
    attributeValue2?: string;
    attributeValue3?: string;
    participantName?: string;
    participantType?: string;
}
