import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { IConfiguration, IEnvConfiguration, IOidcConfiguration } from '../configuration.model';

@Injectable()
export class ConfigurationService {
    constructor() {
        this._config$.subscribe((value) => {
            if (value) {
                this._envConfig$.next(value.envConfig);
                this._oidcConfig$.next(value.oidcConfig);
            }
        });
    }

    private _config$: BehaviorSubject<IConfiguration> = new BehaviorSubject<IConfiguration>(null);
    private _envConfig$: BehaviorSubject<IEnvConfiguration> = new BehaviorSubject<IEnvConfiguration>(null);
    private _oidcConfig$: BehaviorSubject<IOidcConfiguration> = new BehaviorSubject<IOidcConfiguration>(null);

    public set config(config: IConfiguration) {
        this._config$.next(config);
    }

    public get config(): IConfiguration {
        return this._config$.getValue();
    }

    public get envConfig(): BehaviorSubject<IEnvConfiguration> {
        return this._envConfig$;
    }

    public get oidcConfig(): IOidcConfiguration {
        return this._oidcConfig$.getValue();
    }
}
