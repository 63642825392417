import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { BankValidationService, ExportConfig, ModalConfigData, SimpleModalResult } from '../shared';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
    public areChangesSaved: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public deleteTextPre = 'Are you sure you want to delete the ';
    public deleteTextPost = ' routing information from your list?';
    public modalTooltip = 'Delete the cached routing number';
    public exportForm = new FormGroup({
        filename: new FormControl<string>('', [Validators.required, Validators.maxLength(150)]),
    });

    private updateData: boolean = false;
    private exported = false;

    constructor(
        @Optional() public dialogRef: MatDialogRef<ModalComponent>,
        @Inject(MAT_DIALOG_DATA) public modalData: ModalConfigData,
        private snackBar: MatSnackBar,
        private bankValidationService: BankValidationService,
    ) {}

    ngOnInit(): void {
        // Close the dialog if the user presses the Escape key.
        this.dialogRef.keydownEvents().subscribe((event) => {
            if (event?.key === 'Escape') {
                this.closeModal();
            }
        });

        // Close the dialog if the user clicks outside the dialog.
        this.dialogRef.backdropClick().subscribe((e) => {
            if (e != null) {
                // null is getting passed during unit tests; this avoids testing problems.
                this.closeModal();
            }
        });

        if (this.modalData.action === 'export') {
            // Supply a default filename for exports
            const currentDate = new Date();
            let year = currentDate.getFullYear();
            let month = (1 + currentDate.getMonth()).toString().padStart(2, '0');
            let day = currentDate.getDate().toString().padStart(2, '0');
            let hour = currentDate.getHours().toString().padStart(2, '0');
            let min = currentDate.getMinutes().toString().padStart(2, '0');
            this.exportForm.controls.filename.setValue(
                `Cached Bank Codes Export ${month + '-' + day + '-' + year + ' ' + hour + '_' + min}`,
            );
        }
    }

    public closeModal() {
        const resp: SimpleModalResult = {
            updateData: this.updateData,
            areChangesSaved: this.areChangesSaved.getValue(),
            exported: this.exported,
        };
        this.dialogRef.close(resp);
    }

    public async submit() {
        switch (this.modalData.action) {
            case 'delete': {
                this.bankValidationService
                    .delete(this.modalData.selected.countryCode, this.modalData.selected.nationalId)
                    .subscribe({
                        next: () => {
                            this.areChangesSaved.next(true);
                            this.updateData = true;
                            this.closeModal();
                        },
                        error: (error) => this.handleError(error),
                    });

                break;
            }
            case 'export': {
                const exportConfig: ExportConfig = {
                    filename: this.exportForm.controls?.filename.value,
                    exportas: 'xlsx',
                };

                let filename = exportConfig.filename + '.' + exportConfig.exportas;
                this.bankValidationService.export(exportConfig).subscribe({
                    next: (res: any) => {
                        const errorMessage = `${res?.statusText}`;

                        if (res.status < 200 || res.status > 299) {
                            console.error(
                                'ModalComponent export response =',
                                JSON.stringify(res, ['status', 'statusText', 'body', 'type', 'headers', 'header']),
                            );
                            throw new Error(errorMessage);
                        }

                        let binaryData = [];
                        binaryData.push(res.body);
                        let downloadLink = document.createElement('a');
                        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: res.type }));

                        if (filename) {
                            downloadLink.setAttribute('download', filename);
                        }

                        document.body.appendChild(downloadLink);
                        downloadLink.click();

                        this.exported = true;
                        this.closeModal();
                    },
                    error: (error) => this.handleError(error),
                });

                break;
            }
        }
    }

    private handleError(error: HttpErrorResponse): void {
        console.error(error);
        this.snackBar.open('Server error', undefined, {
            duration: 10000,
            panelClass: ['swatch-red'],
        });
    }
}
