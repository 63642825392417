import { Component, EventEmitter, Inject, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalCloseConfig, ModalConfigButtonClick, ModalConfigData, UserActionEnum } from '../shared';

@Component({
    selector: 'app-internal-admin-modal',
    templateUrl: './internal-admin-modal.component.html',
    styleUrls: ['./internal-admin-modal.component.scss'],
})
export class InternalAdminModalComponent {
    @Output() cancelButtonEvent = new EventEmitter<any>();
    @Output() confirmButtonEvent = new EventEmitter<any>();

    protected readonly UserActionEnum = UserActionEnum;
    public deactivateCheckbox: boolean = false;

    constructor(
        @Optional() public dialogRef: MatDialogRef<InternalAdminModalComponent>,
        @Inject(MAT_DIALOG_DATA) public modalData: ModalConfigData,
    ) {}

    public cancelModal(event) {
        const closeConfig: ModalCloseConfig = {
            action: this.modalData.action,
            buttonClick: ModalConfigButtonClick.Cancel,
            id: -1,
        };

        this.dialogRef.close();
        this.cancelButtonEvent.emit(closeConfig);
        this.deactivateCheckbox = false;
    }

    public confirmModal(event) {
        const closeConfig: ModalCloseConfig = {
            action: this.modalData.action,
            buttonClick: ModalConfigButtonClick.Confirm,
            id: this.modalData.id,
            ids: this.modalData.ids ? this.modalData.ids : null,
        };

        this.confirmButtonEvent.emit(closeConfig);
        this.deactivateCheckbox = false;
    }

    public checkClicked($event) {
        this.deactivateCheckbox = $event.checked;
    }
}
