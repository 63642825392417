import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
    AdminRequestHelper,
    Auth0APIConfig,
    CreateEditUserTypes,
    DealRecord,
    DealSearchColumns,
    GetType,
    IntAdmPermissions,
    UserAccessService,
    ModalCloseConfig,
    ModalConfigData,
    RequestDealUserRole,
    ShareholderUserAccess,
    ShareholderRecord,
    ShareholderSearchColumns,
    UserActionEnum,
    UserFullUi,
    UserHelperService,
    UserRecord,
    UserSearchColumns,
} from '../shared';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../core/services/user.service';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UserAccessModalComponent } from '../user-access-modal/user-access-modal.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfigurationService } from '../../core/services/configuration.service';
import { AccessTypes, AuthService } from '../../core/services/auth.service';
import { UserAvailableColumns } from '../user-table/user-table.column.types';
import { DealAvailableColumns } from '../deal-table/deal-table.column.types';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { AvailableColumns } from '../shareholder-user-access-table/shareholder-user-access-table.columns.types';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, AfterViewInit {
    public userRecords: UserRecord[];
    public dealRecords: DealRecord[];
    public shareholderRecords: ShareholderRecord[];

    // Single User ng-component
    public selectedUserId: string = null;
    public selectedUser: UserFullUi = null;
    public selectedShareholderId: number = null;
    public selectedShareholder: ShareholderRecord = null;
    public showRevokeAccess: boolean;
    public selectedDealId: number = null;
    public addExistingUserSelected: boolean = false;

    // Create/Edit User Component values.
    public createEditUserMode: CreateEditUserTypes;
    public showCreateUser: boolean = false;
    public userToEdit: UserFullUi;
    public userToEditId: string = null;

    public searchValue: string;
    public searchValueDealUserAccess: string;
    public page: number;
    public pageDealUserAccess: number;
    public pageShareholderUserAccess: number;
    public pageSize: number;
    public pageSizeDealUserAccess: number;
    public pageSizeShareholderUserAccess: number;
    public totalRecords: number;
    public totalRecordsDealUserAccess: number;
    public totalRecordsShareholderUserAccess: number;
    public internalAdminModal: MatDialogRef<UserAccessModalComponent>;
    public permissionCreateUser = IntAdmPermissions.userCreate;
    public permissionAddUser = IntAdmPermissions.userAdd;
    public permissionEditUser = IntAdmPermissions.userEdit;
    public permissionBlockUser = IntAdmPermissions.userBlock;
    public permissionUnblockUser = IntAdmPermissions.userUnblock;
    public permissionDeactivateUser = IntAdmPermissions.userDeactivate;
    public permissionSendPwResetUser = IntAdmPermissions.userSendPwReset;
    public permissionResendInvUser = IntAdmPermissions.userResendInv;
    public fetching = false;
    public fetchingShareholderUserAccess = false;
    public shareholderUserAccessRecords: ShareholderUserAccess[];
    public searchValueShareholderUserAccess: string;
    public userDisplayedColumns: string[] = [
        UserAvailableColumns.Email,
        UserAvailableColumns.User,
        UserAvailableColumns.Status,
        UserAvailableColumns.Products,
        UserAvailableColumns.Actions,
    ];
    public dealDisplayedColumns: string[] = [
        DealAvailableColumns.Name,
        DealAvailableColumns.Seller,
        DealAvailableColumns.Buyer,
        DealAvailableColumns.Role,
        DealAvailableColumns.ClosingDate,
        DealAvailableColumns.Actions,
    ];
    public shareholderDealUserDisplayedColumns: string[] = [
        AvailableColumns.Deal,
        AvailableColumns.Permission,
        AvailableColumns.Shareholder,
        AvailableColumns.Actions,
    ];
    public selectedIndexSingleUser = 0;

    public requestHelper: AdminRequestHelper;
    public requestHelperDealUserAccess: AdminRequestHelper;
    public requestHelperShareholderUserAccess: AdminRequestHelper;

    private readonly DEALS_URL = '/user-access/deals';
    private readonly USERS_URL = '/user-access/users';
    private readonly ACTIVITY_URL = '/user-access/activity';
    private readonly SHAREHOLDER_URL = '/user-access/shareholders';
    private readonly TAB_DEAL_DASHBOARD = 0;
    private readonly TAB_SHAREHOLDER_PORTAL = 1;

    private modalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: true,
        minWidth: '500px',
        minHeight: '200px',
        autoFocus: 'first-header',
    };

    constructor(
        private userAccessService: UserAccessService,
        private snackBar: MatSnackBar,
        private userService: UserService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private userHelperService: UserHelperService,
        private matDialog: MatDialog,
        private configurationService: ConfigurationService,
        private authService: AuthService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.showRevokeAccess = true;
        this.requestHelper = new AdminRequestHelper(GetType.users);
        this.requestHelperDealUserAccess = new AdminRequestHelper(GetType.dealUserAccess);
        this.requestHelperShareholderUserAccess = new AdminRequestHelper(GetType.shareholderUserAccess);

        this.activatedRoute.queryParams.subscribe({
            next: (params: Params) => {
                const isEmpty = params && Object.keys(params).length === 0;

                if (params) {
                    this.searchValue = this.requestHelper.srchVal = params['search'] ?? '';
                    this.requestHelper.sortDir = params['sortDir'] ?? '';
                    this.requestHelper.sortField = params['sortId'] ?? '';
                    this.page = this.requestHelper.page = params['page'] ?? 0;
                    this.pageDealUserAccess = params['page'] ?? 0;
                    this.pageShareholderUserAccess = params['page'] ?? 0;
                    this.pageSize = this.requestHelper.size = params['pageSize'] ?? this.requestHelper.size;
                    this.pageSizeDealUserAccess = params['pageSize'] ?? this.requestHelperDealUserAccess.size;
                    this.pageSizeShareholderUserAccess =
                        params['pageSize'] ?? this.requestHelperShareholderUserAccess.size;
                }

                if (!params || isEmpty || params['clear']) {
                    this.selectedUserId = null;
                    this.selectedUser = null;
                    this.selectedIndexSingleUser = 0;

                    this.showCreateUser = false;

                    this.requestHelper.uid = null;
                    this.requestHelperDealUserAccess.uid = null;

                    this.requestHelperDealUserAccess.sortField = 'id';
                    this.requestHelperDealUserAccess.sortDir = 'desc';
                } else if (params['userId']) {
                    if (params['tabSelectedSingleUser']) {
                        // Using the '+' to force the param to be a number.
                        this.selectedIndexSingleUser = +params['tabSelectedSingleUser'];

                        if (this.TAB_DEAL_DASHBOARD === this.selectedIndexSingleUser) {
                            // Deal Dashboard tab
                            this.requestHelperDealUserAccess.sortDir = params['sortDir'] ?? '';
                            this.requestHelperDealUserAccess.sortField = params['sortId'] ?? '';

                            // Fetching deals for a user is a different API call.
                            this.requestHelperDealUserAccess.uid = params['userId'];
                            this.requestHelperDealUserAccess.page = 0;
                            this.fetchDealUserAccess();
                        } else if (this.TAB_SHAREHOLDER_PORTAL === this.selectedIndexSingleUser) {
                            this.requestHelperShareholderUserAccess.sortDir = params['sortDir'] ?? '';
                            this.requestHelperShareholderUserAccess.sortField = params['sortId'] ?? '';

                            // Fetching deals for a user is a different API call.
                            this.requestHelperShareholderUserAccess.uid = params['userId'];
                            this.requestHelperShareholderUserAccess.page = 0;
                            this.fetchShareholderUserAccess();
                        }
                    }

                    if (this.selectedUserId !== params['userId']) {
                        console.log(
                            '*** UsersComponent.ngOnInit() this.selectedUserId is not set; setting & fetching user',
                        );
                        this.selectedUserId = params['userId'];
                        this.fetchSingleUser(this.selectedUserId);
                    } else {
                        console.log('*** UsersComponent.ngOnInit() param[userId] matches current value; ignoring');
                    }
                }
            },
            error: (error) => this.handleError(error),
        });
        this.fetchUsers();
    }

    ngAfterViewInit(): void {
        this.cdr.detectChanges();
    }

    public performSearch(searchVal: string) {
        this.searchValue = searchVal;

        if (this.searchValue && this.searchValue.trim().length > 0) {
            this.requestHelper.srchVal = this.searchValue;
            this.requestHelper.srchFields = UserSearchColumns;
        } else {
            this.requestHelper.srchVal = null;
            this.requestHelper.srchFields = null;
        }
        this.requestHelper.page = 0;

        this.router.navigate(['/user-access/users'], {
            queryParams: {
                search: searchVal,
            },
            queryParamsHandling: 'merge',
        });

        this.fetchUsers();
    }

    public performSearchDealUserAccess(searchVal: string) {
        this.searchValueDealUserAccess = searchVal;

        if (this.searchValueDealUserAccess && this.searchValueDealUserAccess.trim().length > 0) {
            this.requestHelperDealUserAccess.srchVal = this.searchValueDealUserAccess;
            this.requestHelperDealUserAccess.srchFields = DealSearchColumns;
        } else {
            this.requestHelperDealUserAccess.srchVal = null;
            this.requestHelperDealUserAccess.srchFields = null;
        }
        this.requestHelperDealUserAccess.page = 0;
        this.requestHelperDealUserAccess.uid = this.selectedUser.userUi.id;

        this.router.navigate(['/user-access/users'], {
            queryParams: {
                search: searchVal,
            },
            queryParamsHandling: 'merge',
        });

        this.fetchDealUserAccess();
    }

    public performSearchShareholderUserAccess(searchVal: string) {
        this.searchValueShareholderUserAccess = searchVal;

        if (this.searchValueShareholderUserAccess && this.searchValueShareholderUserAccess.trim().length > 0) {
            this.requestHelperShareholderUserAccess.srchVal = this.searchValueShareholderUserAccess;
            this.requestHelperShareholderUserAccess.srchFields = ShareholderSearchColumns;
        } else {
            this.requestHelperShareholderUserAccess.srchVal = null;
            this.requestHelperShareholderUserAccess.srchFields = null;
        }
        // What if they had already initiated a search, paged to page N, then
        // adjusted the search value. Should it be reset to 0 again?
        this.requestHelperShareholderUserAccess.page = 0;

        this.router.navigate(['/user-access/users'], {
            queryParams: {
                search: searchVal,
            },
            queryParamsHandling: 'merge',
        });

        this.fetchShareholderUserAccess();
    }

    public tabChangeSingleUser(e: MatTabChangeEvent) {
        this.router.navigate([this.USERS_URL], {
            queryParams: {
                userId: this.selectedUserId,
                tabSelectedSingleUser: e.index,
            },
            queryParamsHandling: 'merge',
        });
    }

    public handlePageEvent(e: PageEvent) {
        this.requestHelper.size = e.pageSize;
        this.requestHelper.page = e.pageIndex;

        this.fetchUsers();

        this.router.navigate(['/user-access/users'], {
            queryParams: {
                page: e.pageIndex,
                pageSize: e.pageSize,
            },
            queryParamsHandling: 'merge',
        });
    }

    public handlePageEventDealUserAccess(e: PageEvent) {
        this.requestHelperDealUserAccess.size = e.pageSize;
        this.requestHelperDealUserAccess.page = e.pageIndex;

        this.fetchDealUserAccess();

        this.router.navigate(['/user-access/users'], {
            queryParams: {
                page: e.pageIndex,
                pageSize: e.pageSize,
            },
            queryParamsHandling: 'merge',
        });
    }

    public handleSortChange(s: Sort) {
        // Sort object when sorting is turned off: { active: "seller", direction: "" }
        if (s.direction) {
            this.requestHelper.sortField = s.active;
            this.requestHelper.sortDir = s.direction;

            this.fetchUsers();
        } else {
            // Do not fetch if sorting has been turned off
            this.requestHelper.sortField = null;
            this.requestHelper.sortDir = null;
        }

        this.router.navigate(['/user-access/users'], {
            queryParams: {
                sortId: this.requestHelper.sortField || 'email',
                sortDir: this.requestHelper.sortDir || 'asc',
                clear: null,
            },
            queryParamsHandling: 'merge',
        });
        this.cdr.detectChanges();
    }

    public handleSortChangeDealUserAccess(s: Sort) {
        // Sort object when sorting is turned off: { active: "seller", direction: "" }
        if (s.direction) {
            this.requestHelperDealUserAccess.sortField = s.active;
            this.requestHelperDealUserAccess.sortDir = s.direction;

            this.fetchDealUserAccess();
        } else {
            // Do not fetch if sorting has been turned off
            this.requestHelperDealUserAccess.sortField = null;
            this.requestHelperDealUserAccess.sortDir = null;
        }

        this.router.navigate(['/user-access/users'], {
            queryParams: {
                sortId: this.requestHelperDealUserAccess.sortField,
                sortDir: this.requestHelperDealUserAccess.sortDir,
                clear: null,
            },
            queryParamsHandling: 'merge',
        });

        this.cdr.detectChanges();
    }

    /**
     * Here the $event is the index of the deal record.
     */
    public handleDealActivityNavigation($event) {
        const selectedDeal = this.dealRecords[$event];

        this.router.navigate([this.ACTIVITY_URL], {
            queryParams: {
                search: selectedDeal.name,
                filterBy: 'deals',
            },
            queryParamsHandling: 'merge',
        });
    }

    public handleDealDashboardNavigation($event) {
        // const selectedDeal = this.dealRecords[$event];
        // this.dealDashboardClick(selectedDeal.id);
    }

    public handleUserAction($event) {
        switch ($event.action) {
            case UserActionEnum.activity: {
                const selectedUser = this.userRecords[$event.arrayIndex];

                this.handleUserActivityClick(selectedUser.firstName + ' ' + selectedUser.lastName);

                break;
            }
            case UserActionEnum.sendPwReset: {
                const userName =
                    this.userRecords[$event.arrayIndex].firstName + ' ' + this.userRecords[$event.arrayIndex].lastName;

                const tempConfig: MatDialogConfig<ModalConfigData> = {
                    ...this.modalConfigTemplate,
                    data: {
                        title: 'Reset Password',
                        messageHTML: `Would you like to send a password reset for <b>${userName}</b>?`,
                        userName,
                        dealName: null,
                        action: UserActionEnum.sendPwReset,
                        id: this.userRecords[$event.arrayIndex].id,
                    },
                };
                this.openModal(tempConfig);

                break;
            }
            case UserActionEnum.resendInv: {
                const name =
                    this.userRecords[$event.arrayIndex].firstName + ' ' + this.userRecords[$event.arrayIndex].lastName;

                const modalConfig: MatDialogConfig<ModalConfigData> = {
                    ...this.modalConfigTemplate,
                    data: {
                        title: 'Resend Invitation',
                        messageHTML: `Would you like to resend the Deal Dashboard invitation to <b>${name}</b>?`,
                        userName: name,
                        dealName: null,
                        action: UserActionEnum.resendInv,
                        id: this.userRecords[$event.arrayIndex].id,
                    },
                };
                this.openModal(modalConfig);

                break;
            }
            case UserActionEnum.deactivate: {
                const name =
                    this.userRecords[$event.arrayIndex].firstName + ' ' + this.userRecords[$event.arrayIndex].lastName;

                const modalConfig: MatDialogConfig<ModalConfigData> = {
                    ...this.modalConfigTemplate,
                    data: {
                        title: 'Confirm Account Deactivation',
                        messageHTML: `This will revoke all access and deactivate <b>${name}</b>.`,
                        userName: name,
                        dealName: null,
                        action: UserActionEnum.deactivate,
                        id: this.userRecords[$event.arrayIndex].id,
                        deactivateFromUserTable: true,
                    },
                };
                this.openModal(modalConfig);

                break;
            }
            case UserActionEnum.block: {
                const name =
                    this.userRecords[$event.arrayIndex].firstName + ' ' + this.userRecords[$event.arrayIndex].lastName;

                const modalConfig: MatDialogConfig<ModalConfigData> = {
                    ...this.modalConfigTemplate,
                    data: {
                        title: 'Confirm Authentication Block',
                        messageHTML: `This will “block” the user in Auth0 without modifying deal permissions.`,
                        userName: name,
                        dealName: null,
                        action: UserActionEnum.block,
                        id: this.userRecords[$event.arrayIndex].id,
                    },
                };
                this.openModal(modalConfig);

                break;
            }
            case UserActionEnum.unblock: {
                const name =
                    this.userRecords[$event.arrayIndex].firstName + ' ' + this.userRecords[$event.arrayIndex].lastName;

                const modalConfig: MatDialogConfig<ModalConfigData> = {
                    ...this.modalConfigTemplate,
                    data: {
                        title: 'Unblock Authentication',
                        messageHTML: `This will remove the Blocked status in Auth0, allowing users to authenticate.`,
                        userName: name,
                        dealName: null,
                        action: UserActionEnum.unblock,
                        id: this.userRecords[$event.arrayIndex].id,
                    },
                };
                this.openModal(modalConfig);

                break;
            }
        }
    }

    public canEditUser(status: string): boolean {
        const canEditUser = this.userHelperService.canEditUser(status);
        const perm = this.authService.checkAccess(this.permissionEditUser) === AccessTypes.FULL;
        return canEditUser && perm;
    }

    public canViewUserActivity(status: string): boolean {
        return this.userHelperService.canViewUserActivity(status);
    }

    public canResendInvitation(status: string): boolean {
        const canResendInvitation = this.userHelperService.canResendInvitation(status);
        const perm = this.authService.checkAccess(this.permissionResendInvUser) === AccessTypes.FULL;
        return canResendInvitation && perm;
    }

    public canSendPwReset(status: string): boolean {
        const canSendPwReset = this.userHelperService.canSendPwReset(status);
        const perm = this.authService.checkAccess(this.permissionSendPwResetUser) === AccessTypes.FULL;
        return canSendPwReset && perm;
    }

    public canBlock(status: string): boolean {
        const canBlock = this.userHelperService.canBlock(status);
        const perm = this.authService.checkAccess(this.permissionBlockUser) === AccessTypes.FULL;
        return canBlock && perm;
    }

    public canUnblock(status: string): boolean {
        const canUnblock = this.userHelperService.canUnblock(status);
        const perm = this.authService.checkAccess(this.permissionUnblockUser) === AccessTypes.FULL;
        return canUnblock && perm;
    }

    public canDeactivate(status: string): boolean {
        const canDeactivate = this.userHelperService.canDeactivate(status);
        const perm = this.authService.checkAccess(this.permissionDeactivateUser) === AccessTypes.FULL;
        return canDeactivate && perm;
    }

    public showAddUserMenu(): boolean {
        return (
            this.authService.checkAccess(this.permissionCreateUser) === AccessTypes.FULL ||
            this.authService.checkAccess(this.permissionAddUser) === AccessTypes.FULL
        );
    }

    public handleEditUserClick() {
        this.createEditUserMode = CreateEditUserTypes.EDIT_USER;
        this.userToEdit = this.selectedUser;
        this.showCreateUser = true;
    }

    public async handleUserToEditEvent(e: UserRecord) {
        this.userToEditId = e.id;
        this.createEditUserMode = CreateEditUserTypes.EDIT_USER;
        this.showCreateUser = true;
    }

    public handleEditModeEvent(e: boolean) {
        this.createEditUserMode = CreateEditUserTypes.EDIT_USER;
        this.showCreateUser = true;
    }

    public handleShowCreateEditUserCancelEvent() {
        this.createEditUserMode = null;
        this.userToEdit = null;
        this.userToEditId = null;
        this.showCreateUser = false;
    }

    public handleRevokeAccessEventDD(d: DealRecord) {
        this.selectedDealId = d.id;
        const modalConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Confirm Revoke Access',
                action: UserActionEnum.revokeAccessDD,
                messageHTML: "This will remove the user's assigned role to this deal.",
                id: this.selectedDealId?.toString(),
            },
        };
        this.openModal(modalConfig);
    }

    public handleRevokeAccessEventSH(sdu: ShareholderUserAccess) {
        this.selectedDealId = sdu.dealId;
        const modalConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Confirm Revoke Access',
                action: UserActionEnum.revokeAccessSH,
                messageHTML: "This will remove the shareholder's assigned role to this deal and user.",
                userAccessRecord: sdu,
            },
        };
        this.openModal(modalConfig);
    }

    public handleRevokeAllAccessClick() {
        const modalConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Confirm Revoke All Access',
                action: UserActionEnum.revokeAllAccess,
                messageHTML: 'This will remove roles to all deals this user currently has been assigned.',
                id: this.selectedUserId,
            },
        };
        this.openModal(modalConfig);
    }

    public handleUserActivityClick(name?: string) {
        this.router.navigate([this.ACTIVITY_URL], {
            queryParams: {
                search: name ? name : this.selectedUser.userUi.firstName + ' ' + this.selectedUser.userUi.lastName,
                filterBy: 'users',
            },
            queryParamsHandling: 'merge',
        });
    }

    public handleResendInvitation() {
        const name = this.selectedUser.userUi.firstName + ' ' + this.selectedUser.userUi.lastName;

        const modalConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Resend Invitation',
                messageHTML: `Would you like to resend the Deal Dashboard invitation to <b>${name}</b>?`,
                userName: name,
                dealName: null,
                action: UserActionEnum.resendInv,
                id: this.selectedUser.userUi.id,
            },
        };
        this.openModal(modalConfig);
    }

    public handleSendPwResetClick() {
        const name = this.selectedUser.userUi.firstName + ' ' + this.selectedUser.userUi.lastName;

        const modalConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Send Password Reset?',
                messageHTML: `Would you like to send a password reset for <b>${name}</b>?`,
                userName: name,
                dealName: null,
                action: UserActionEnum.sendPwReset,
                id: this.selectedUser.userUi.id,
                email: this.selectedUser.userUi.email,
            },
        };
        this.openModal(modalConfig);
    }

    public handleBlockClick() {
        const name = this.selectedUser.userUi.firstName + ' ' + this.selectedUser.userUi.lastName;

        const modalConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Confirm Authentication Block',
                messageHTML: `This will “block” the user in Auth0 without modifying deal permissions.`,
                userName: name,
                dealName: null,
                action: UserActionEnum.block,
                id: this.selectedUser.userUi.id,
            },
        };
        this.openModal(modalConfig);
    }

    public handleUnblockClick() {
        const name = this.selectedUser.userUi.firstName + ' ' + this.selectedUser.userUi.lastName;

        const modalConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Unblock Authentication',
                messageHTML: `This will remove the Blocked status in Auth0, allowing users to authenticate.`,
                userName: name,
                dealName: null,
                action: UserActionEnum.unblock,
                id: this.selectedUser.userUi.id,
            },
        };
        this.openModal(modalConfig);
    }

    public handleDeactivateClick() {
        const name = this.selectedUser.userUi.firstName + ' ' + this.selectedUser.userUi.lastName;

        const modalConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Confirm Deactivate User',
                userName: name,
                dealName: null,
                action: UserActionEnum.deactivate,
                id: this.selectedUser.userUi.id,
            },
        };
        this.openModal(modalConfig);
    }

    public createUserClick() {
        this.createEditUserMode = CreateEditUserTypes.CREATE_USER;
        this.showCreateUser = true;
    }

    public handleNewUserButtonClick() {
        this.handleShowCreateEditUserCancelEvent();
        this.createUserClick();
    }

    public openInNewTab(url: string) {
        window.open(url, '_blank').focus();
    }

    public getUserNsUrl(nsId: number): string {
        const nsContactUrl = this.configurationService.envConfig.getValue().nsContactUrl;

        return nsContactUrl && nsId ? nsContactUrl.replace(/INSERTIDHERE/, nsId.toString()) : '';
    }

    public handleUserClickedEventShareholderUserAccess(uid: number) {
        // will need to pass in the real uid when it exists on the record
        this.router.navigate([this.USERS_URL], {
            queryParams: { userId: this.userRecords[0].id },
            queryParamsHandling: 'merge',
        });
    }

    public handleShareholderClickedEventShareholderUserAccess(sid: number) {
        this.router.navigate([this.SHAREHOLDER_URL], {
            queryParams: { sid: sid },
            queryParamsHandling: 'merge',
        });
    }

    public handlePageEventShareholderUserAccess(e: PageEvent) {
        this.requestHelperShareholderUserAccess.size = e.pageSize;
        this.requestHelperShareholderUserAccess.page = e.pageIndex;
        this.fetchShareholderUserAccess();

        this.router.navigate(['/user-access/users'], {
            queryParams: {
                page: e.pageIndex,
                pageSize: e.pageSize,
            },
            queryParamsHandling: 'merge',
        });
    }

    public handleSortChangeShareholderUserAccess(s: Sort) {
        if (s.direction) {
            this.requestHelperShareholderUserAccess.sortField = s.active;
            this.requestHelperShareholderUserAccess.sortDir = s.direction;
            this.fetchShareholderUserAccess();
        } else {
            // Do not fetch if sorting has been turned off
            this.requestHelperShareholderUserAccess.sortField = null;
            this.requestHelperShareholderUserAccess.sortDir = null;
        }

        this.router.navigate(['/user-access/users'], {
            queryParams: {
                sortId: this.requestHelperShareholderUserAccess.sortField,
                sortDir: this.requestHelperShareholderUserAccess.sortDir,
                clear: null,
            },
            queryParamsHandling: 'merge',
        });
        this.cdr.detectChanges();
    }

    public deleteAuthenticationMethod(mfaMethodId: string, mfaMethodName: string) {
        const name = this.selectedUser.userUi.firstName + ' ' + this.selectedUser.userUi.lastName;

        const modalConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Confirm MFA Method Removal',
                messageHTML: 'The user will need to use another authentication method until this one is set up again.',
                userName: name,
                mfaMethodId,
                mfaMethodName,
                action: UserActionEnum.resetMfa,
                id: this.selectedUser.userUi.id,
                email: this.selectedUser.userUi.email,
            },
        };
        this.openModal(modalConfig);
    }

    private openModal(modalConfig: MatDialogConfig) {
        this.internalAdminModal = this.matDialog.open(UserAccessModalComponent, modalConfig);
        this.internalAdminModal.componentInstance.confirmButtonEvent.subscribe((emittedValue: ModalCloseConfig) => {
            if (emittedValue) {
                this.internalAdminModal.close();

                const auth0Config: Auth0APIConfig = {
                    userId: emittedValue.id,
                    action: modalConfig.data.action,
                    byId: this.userService.user.sub,
                    byName: this.userService.user.name,
                    mfaMethodId: modalConfig.data.mfaMethodId,
                };

                switch (emittedValue.action) {
                    case UserActionEnum.resetMfa: {
                        this.userAccessService.userAuth0(auth0Config).subscribe({
                            next: (res: any) => {
                                if (res.status >= 200 && res.status <= 204) {
                                    this.snackBar.open('MFA Method Removed.', undefined, {
                                        duration: 6000,
                                        panelClass: ['swatch-teal'],
                                    });
                                    this.selectedUser.userUi.mfaMethods[modalConfig.data.mfaMethodName] = undefined;
                                } else {
                                    const msg = `Unable to remove the MFA method for ${modalConfig.data.userName}. Error: ${res.message}`;
                                    console.error(msg);
                                    this.snackBar.open(msg, undefined, {
                                        duration: 10000,
                                    });
                                }
                            },
                            error: (error) => this.handleError(error),
                        });

                        break;
                    }
                    case UserActionEnum.sendPwReset: {
                        this.userAccessService.userAuth0(auth0Config).subscribe({
                            next: (res: any) => {
                                if (res.status >= 200 && res.status <= 204) {
                                    const msg = `Email sent with password reset link.`;
                                    this.snackBar.open(msg, undefined, {
                                        duration: 6000,
                                    });
                                } else {
                                    const msg = `Unable to send password reset for ${modalConfig.data.userName}. Error: ${res.message}`;
                                    console.error(msg);
                                    this.snackBar.open(msg, undefined, {
                                        duration: 10000,
                                    });
                                }
                            },
                            error: (error) => this.handleError(error),
                        });

                        break;
                    }
                    case UserActionEnum.resendInv: {
                        this.userAccessService.userAuth0(auth0Config).subscribe({
                            next: (res: any) => {
                                if (res.status >= 200 && res.status <= 204) {
                                    this.snackBar.open(`Invitation to ${modalConfig.data.userName} resent`, undefined, {
                                        duration: 6000,
                                    });
                                } else {
                                    const msg = `Unable to resend the invitation to ${modalConfig.data.userName}. Error: ${res.message}`;
                                    console.error(msg);
                                    this.snackBar.open(msg, undefined, {
                                        duration: 10000,
                                    });
                                }
                            },
                            error: (error) => this.handleError(error),
                        });

                        break;
                    }
                    case UserActionEnum.deactivate: {
                        this.userAccessService.userAuth0(auth0Config).subscribe({
                            next: (res: any) => {
                                if (res.status >= 200 && res.status <= 204) {
                                    this.snackBar.open(
                                        `${modalConfig.data.userName} has been deactivated.`,
                                        undefined,
                                        {
                                            duration: 6000,
                                        },
                                    );

                                    if (this.selectedUser) {
                                        this.fetchSingleUser(this.selectedUserId);
                                    } else {
                                        this.fetchUsers();
                                    }
                                } else {
                                    const msg = `Unable to deactivate ${modalConfig.data.userName}. Error: ${res.message}`;
                                    console.error(msg);
                                    this.snackBar.open(msg, undefined, {
                                        duration: 10000,
                                    });
                                }
                            },
                            error: (error) => this.handleError(error),
                        });

                        break;
                    }
                    case UserActionEnum.block: {
                        this.userAccessService.userAuth0(auth0Config).subscribe({
                            next: (res: any) => {
                                if (res.status >= 200 && res.status <= 204) {
                                    this.snackBar.open(`User Authentication Blocked`, undefined, {
                                        duration: 6000,
                                    });

                                    if (this.selectedUser) {
                                        this.fetchSingleUser(this.selectedUserId);
                                    } else {
                                        this.fetchUsers();
                                    }
                                } else {
                                    const msg = `Unable to block ${modalConfig.data.userName}. Error: ${res.message}`;
                                    console.error(msg);
                                    this.snackBar.open(msg, undefined, {
                                        duration: 10000,
                                    });
                                }
                            },
                            error: (error) => this.handleError(error),
                        });

                        break;
                    }
                    case UserActionEnum.unblock: {
                        this.userAccessService.userAuth0(auth0Config).subscribe({
                            next: (res: any) => {
                                if (res.status >= 200 && res.status <= 204) {
                                    this.snackBar.open(`User Authentication Unblocked`, undefined, {
                                        duration: 6000,
                                    });

                                    if (this.selectedUser) {
                                        this.fetchSingleUser(this.selectedUserId);
                                    } else {
                                        this.fetchUsers();
                                    }
                                } else {
                                    const msg = `Unable to unblock ${modalConfig.data.userName}. Error: ${res.message}`;
                                    console.error(msg);
                                    this.snackBar.open(msg, undefined, {
                                        duration: 10000,
                                    });
                                }
                            },
                            error: (error) => this.handleError(error),
                        });

                        break;
                    }
                    case UserActionEnum.revokeAccessDD: {
                        const config: RequestDealUserRole = {
                            dealId: this.selectedDealId,
                            userId: modalConfig.data.id,
                            byId: this.userService.user.sub,
                            byName: this.userService.user.name,
                        };
                        this.userAccessService.deleteUserFromDeal(config).subscribe({
                            next: (res: any) => {
                                if (res.status >= 200 && res.status <= 204) {
                                    const msg = 'User access revoked';
                                    this.snackBar.open(msg, undefined, {
                                        duration: 10000,
                                    });
                                } else {
                                    const msg = 'Error occurred while revoking access';
                                    console.error(msg);
                                    this.snackBar.open(msg, undefined, {
                                        duration: 10000,
                                    });
                                }
                            },
                            error: (error) => this.handleError(error),
                        });

                        break;
                    }
                    case UserActionEnum.revokeAccessSH: {
                        this.userAccessService
                            .deleteShareholderUserAccess(modalConfig.data.userAccessRecord)
                            .subscribe({
                                next: (res: any) => {
                                    if (res.status >= 200 && res.status <= 204) {
                                        const msg = 'User access revoked';
                                        this.snackBar.open(msg, undefined, {
                                            duration: 10000,
                                        });
                                    } else {
                                        const msg = 'Error occurred while revoking access';
                                        console.error(msg);
                                        this.snackBar.open(msg, undefined, {
                                            duration: 10000,
                                        });
                                    }
                                },
                                error: (error) => this.handleError(error),
                            });

                        break;
                    }
                }
            }
        });

        this.internalAdminModal.componentInstance.cancelButtonEvent.subscribe((emittedValue) => {
            if (emittedValue) {
                this.internalAdminModal.close();
            }
        });
    }

    private fetchSingleUser(uid: string, returnUserObj: boolean = false): Promise<UserFullUi | void> {
        this.fetching = true;
        this.selectedUserId = uid;

        // retrieve all values for a single user record, which has joins to other
        // tables not required by the users table. Since pagination, sorting, and
        // searching are not required, a new request helper is created to leave the
        // original untouched.
        const tempRequestHelper = new AdminRequestHelper(GetType.users);
        tempRequestHelper.uid = uid;
        tempRequestHelper.getMfa = true;

        return new Promise((resolve) => {
            this.userAccessService.getUsers(tempRequestHelper).subscribe({
                next: (res: any) => {
                    this.fetching = false;
                    if (returnUserObj) {
                        resolve(res.body);
                    } else {
                        this.selectedUser = res.body;
                        resolve();
                    }
                },
                error: (error) => this.handleError(error),
            });
        });
    }

    /**
     * The "single shareholder" comes from the original list of shareholders,
     * so this search is to retrieve the User Access records for the user.
     * @private
     */
    private fetchShareholderUserAccess() {
        this.fetchingShareholderUserAccess = true;

        this.userAccessService.getShareholdersUserAccessRecords(this.requestHelperShareholderUserAccess).subscribe({
            next: (res: any) => {
                this.fetchingShareholderUserAccess = false;
                const contentRange = res.headers.get('content-range'); // "1-3/3"
                this.totalRecordsShareholderUserAccess = +contentRange.substring(contentRange.indexOf('/') + 1);
                this.shareholderUserAccessRecords = res.body;

                if (
                    this.createEditUserMode === CreateEditUserTypes.EDIT_USER ||
                    this.createEditUserMode === CreateEditUserTypes.ADD_EXISTING_USER
                ) {
                    this.shareholderUserAccessRecords = [res.body[0]];

                    // for (let i = 0; i < this.shareholderUserAccessRecords.length; i++) {
                    //     const foundShareholderName = this.shareholderUserAccessRecords[i].shareholder;
                    //     const foundDealName = 'testDeal';
                    //     const foundRoleId = 1;
                    //     this.addShareholderAccessClick(i, foundShareholderName, foundDealName, foundRoleId);
                    // }
                }
            },
            error: (error) => this.handleError(error),
        });
    }

    private fetchUsers() {
        this.fetching = true;
        this.userAccessService.getUsers(this.requestHelper).subscribe({
            next: (res: any) => {
                this.fetching = false;
                const contentRange = res.headers.get('content-range'); // "1-3/3"
                this.totalRecords = +contentRange.substring(contentRange.indexOf('/') + 1);
                this.userRecords = res.body;
            },
            error: (error) => this.handleError(error),
        });
    }

    /**
     * This needs to fetch deals for the selected user, which is not supported
     * by the API at the moment.
     * @private
     */
    private fetchDealUserAccess() {
        this.fetchingShareholderUserAccess = true;

        this.userAccessService.getDealUserAccessRecords(this.requestHelperDealUserAccess).subscribe({
            next: (res: any) => {
                this.fetchingShareholderUserAccess = false;

                const contentRange = res.headers.get('content-range'); // "1-3/3"
                this.totalRecordsDealUserAccess = +contentRange.substring(contentRange.indexOf('/') + 1);
                this.dealRecords = res.body;
            },
            error: (error) => this.handleError(error),
        });
    }

    private handleError(error: HttpErrorResponse): void {
        this.fetching = false;
        this.fetchingShareholderUserAccess = false;
        error['stack'] ? console.error(error['stack']) : console.error(error);

        let msg: string;
        if (error?.status >= 400 && error?.status < 500) {
            msg = error?.error?.message ? error.error.message : 'Server error';
        } else {
            msg = 'Server error';
        }

        this.snackBar.open(msg, undefined, {
            duration: 10000,
        });
    }
}
