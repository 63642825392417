import { Component } from '@angular/core';
import { UserService } from '../../core/services/user.service';
import { NavigationHelperService } from '../../core/services/navigation-helper.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
    constructor(
        private userService: UserService,
        private navigationHelperService: NavigationHelperService,
    ) {}

    public get allowedApps$() {
        return this.userService.allowedApps$;
    }

    appClicked(appName: string) {
        this.navigationHelperService.appClicked(appName);
    }
}
