// These are the defined columns in file table.component.html.
export enum AvailableColumns {
    ExportVendor = 'exportVendor',
    PayeeName = 'payeeName',
    AccountTitle = 'accountTitle',
    AccountNumber = 'accountNumber',
    AbaSwift = 'abaSwift',
    BankCountry = 'bankCountry',
    Currency = 'currency',
    SortCode = 'sortCode',
    UpdatedDt = 'updatedDt',
    ReviewStatus = 'reviewStatus',
    ReviewStatusInReview = 'reviewStatusInReview',
    ReviewType = 'reviewType',
    RejectionDate = 'rejectionDt',
    DealCount = 'dealCount',
    Details = 'details',
    TaxpayerId = 'taxpayerId',
    Subsidiary = 'subsidiary',
}
