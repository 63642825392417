import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export type SubmenuOptions = {
    parentPath: string;
    appName: string;
    childPaths: SubmenuChild[];
};

export type SubmenuChild = {
    path: string;
    displayName: string;
};

@Injectable({
    providedIn: 'root',
})
export class NavigationHelperService {
    private submenu: Subject<SubmenuOptions> = new BehaviorSubject<SubmenuOptions>(null);
    private selectedApp: Subject<string> = new BehaviorSubject<string>('');
    private sidebarToggle: Subject<boolean> = new BehaviorSubject<boolean>(true);

    constructor() {}

    get submenu$() {
        return this.submenu.asObservable();
    }

    get selectedApp$() {
        return this.selectedApp.asObservable();
    }

    get sidebarToggle$() {
        return this.sidebarToggle.asObservable();
    }

    addSubmenu(data: SubmenuOptions) {
        this.submenu.next(data);
    }

    clearSubmenu() {
        this.submenu.next(null);
    }

    appClicked(appName: string) {
        this.selectedApp.next(appName);
    }

    toggleSidebar() {
        this.sidebarToggle.next(true);
    }
}
