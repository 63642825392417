import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActivityComponent } from './activity.component';
import { AuthGuard } from '../core/guards/auth-guard.service';
import { AllowedRoles } from '../core/guards/allowed-roles.guard';
import { OneLoginRoles } from '../core/services/auth.service';

const routes: Routes = [
    {
        path: 'activity',
        component: ActivityComponent,
        canActivate: [AuthGuard, AllowedRoles],
        data: {
            AllowedRolesData: {
                oneOfRoles: [OneLoginRoles.activity, OneLoginRoles.bankFileExplorerStandard],
            },
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ActivityRoutingModule {}
