export interface BankValidationRequest {
    countryCode?: string;
    nationalId?: string[];
    serviceId?: string;
    accountNumber?: string;
}

export interface BankValidationResponse {
    nationalId?: string;
    responseStatus?: string;
    responseComment?: string;
    responseData?: any;
    brokerageAccountInfo?: any;
}
