<mat-card appearance="outlined" class="mat-card">
    <div id="title-bar" class="title-bar">
        <mat-card-title
            [class.mat-card-title-export]="modalData.action === 'export'"
        >
            {{ modalData.title }}
        </mat-card-title>
        <span id="x-dismiss"
              (click)="closeModal()"
              class="material-symbols-outlined x-dismiss"
              data-testid="x-dismiss">
            close
        </span>
    </div>
    <div *ngIf="modalData.action === 'export'" class="card__container">
        <ng-container
            *ngTemplateOutlet="exportSection"
        ></ng-container>
    </div>
</mat-card>

<ng-template #exportSection>
    <form [formGroup]="exportForm">
        <mat-card-content class="exportas-card-content">
            <div class="exportas-label">Export as:</div>
            <div class="exportas-data-div">
                <div class="filename-frame">
                    <div class="filename-outer-div">
                        <div class="filename-inner-div">
                            <div class="filename-input">
                                <input class="filename-value" id="filenameInput" matInput type="text" formControlName="filename" data-testid="filename"/>
                                <span class="filename-affordance">X</span>
                            </div>
                        </div>
                    </div>
                    <div class="filename-extension">
                        <div class="extension-text-field">
                            <span class="extension-input">.xlsx</span>
                        </div>
                    </div>
                </div>
                <mat-error *ngIf="exportForm.controls.filename.hasError('required')">
                    Filename is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="exportForm.controls.filename.hasError('maxLength')">
                    Maximum length is 150 characters.
                </mat-error>
            </div>
        </mat-card-content>
    </form>
    <mat-card-actions align="end">
        <button
            class="modal-button modal-button-cancel"
            mat-flat-button
            (click)="closeModal()"
            data-testid="btnCancel"
        >
            Cancel
        </button>
        <button
            class="modal-button modal-button-primary-export"
            type="submit"
            [matTooltip]="modalTooltip"
            matTooltipPosition="after"
            (click)="submit()"
            mat-flat-button
            data-testid="btnSave"
        >
            <span class="icon-button">
                <span class="modal-button-label">Export</span>
            </span>
        </button>
    </mat-card-actions>
</ng-template>
