<div id="main-div" class="main-div">
    <table mat-table matSort (matSortChange)="handleSortChange($event)" [dataSource]="dataSource" class="table-div">
        <ng-container matColumnDef="nsId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="nsId">NetSuite ID</th>
            <td mat-cell
                *matCellDef="let element"
            >
                <span class="as-link"
                   (click)="openInNewTab($event, getDealNsUrl(element.nsId))"
                   [matTooltip]="getDealNsUrl(element.nsId)"
                   matTooltipPosition="below"
                   matTooltipShowDelay="300"
                   matTooltipHideDelay="200"
                >{{element.nsId ? element.nsId.toString() : ''}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="name">Name</th>
            <td mat-cell
                *matCellDef="let element"
            >
                <span class="actionable-item"
                      (click)="handleDealSelected(element)"
                      appHighlight
                      [searchedWords]="searchText"
                      [text]="element.name"
                      [ignoreCase]="true"
                      classToApply="selected"
                >{{element.name}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="projectName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="projectName">Project Name</th>
            <td mat-cell
                *matCellDef="let element"
                appHighlight
                [searchedWords]="searchText"
                [text]="element.projectName"
                [ignoreCase]="true"
                classToApply="selected"
            >{{element.projectName}}</td>
        </ng-container>

        <ng-container matColumnDef="seller">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="seller">Selling Company</th>
            <td mat-cell
                *matCellDef="let element"
                appHighlight
                [searchedWords]="searchText"
                [text]="element.seller"
                [ignoreCase]="true"
                classToApply="selected"
            >{{element.seller}}</td>
        </ng-container>

        <ng-container matColumnDef="buyer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="buyer">Acquiring Company</th>
            <td mat-cell
                *matCellDef="let element"
                appHighlight
                [searchedWords]="searchText"
                [text]="element.buyer"
                [ignoreCase]="true"
                classToApply="selected"
            >{{element.buyer}}</td>
        </ng-container>

        <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="role">Role</th>
            <td mat-cell
                *matCellDef="let element"
                appHighlight
                [searchedWords]="searchText"
                [text]="element.role"
                [ignoreCase]="true"
                classToApply="selected"
            >{{element.role}}</td>
        </ng-container>

        <ng-container matColumnDef="closingDt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="closingDt">Closing</th>
            <td mat-cell
                *matCellDef="let element"
            >{{element.closingDt | date: 'MMM dd, yyyy' : 'UTC'}}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell
                *matCellDef="let element; index as idx;"
            >
                <div class="action-cell"
                     [class.action-cell-clicked]="idx === selectedIndex"
                     [matMenuTriggerFor]="menu"
                     aria-label="Deal action menu"
                     (click)="openMenu($event, idx)"
                     (menuClosed)="handleMenuClosedEvent($event, idx)"
                >
                    <div class="action-div">
                        <span class="material-symbols-outlined"
                        >more_horiz</span>
                    </div>
                </div>
                <mat-menu #menu="matMenu" xPosition="before" yPosition="below">
                    <div mat-menu-item class="menu-option" (click)="navToDealActivity(idx)">Deal Activity</div>
                    <div mat-menu-item class="menu-option" (click)="navToDealDashboard(idx)">Deal Dashboard</div>
                    <div *ngIf="showRevokeAccess"
                         mat-menu-item
                         class="menu-option menu-option-red"
                         (click)="handleRevokeAccessClick(element)">Revoke Access</div>
                </mat-menu>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: !showCreateUser"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="intadm-table-row"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell no-data" colspan="10"><span *ngIf="!fetching">No data</span></td>
        </tr>
    </table>
</div>
<div class="paginator-div">
    <mat-paginator [length]="totalRecords"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="[10, 25, 50, 100]"
                   aria-label="Select page"
                   showFirstLastButtons=true
                   disabled=false
                   hidePageSize=false
                   (page)="handlePageEvent($event)">
    </mat-paginator>
</div>
