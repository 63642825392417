<div id="search-div" class="search-div">
    <div id="search-box" class="search-box">
        <span class="material-icons-outlined filter-icon">filter_list</span>
        <input
            type="text"
            [placeholder]="placeholder"
            class="search-input"
            [formControl]="searchControl"
        />
    </div>
</div>
